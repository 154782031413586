<template>
    <div class="row">
        <div  class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.cadastrarparceiro')}}</h4>
                    <a @click="$router.push('/painel/fornecedor')" class="text-white ponteiro btn btn-info mt-4"> &#8592; {{$t('ser.voltar')}} </a>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.tipo')}}:</label>
                                    <select class="form-control" v-model="editedItem.tipo">
                                        <option value="F">{{$t('global.pessoafisica')}}</option>
                                        <option value="J">{{$t('global.pessoajuridica')}}</option>
                                    </select>
                                </div>
                                <div v-if="editedItem.tipo == 'F'" class="form-group col-md-auto">
                                    <label>CPF:</label>
                                    <input id="cpf_input" v-model="editedItem.cpf" 
                                        @blur="checkCPF(editedItem.cnpj)" 
                                        placeholder="XXX.XXX.XXX-XX"
                                        type="text" class="form-control">
                                    
                                </div>
                                <div v-if="editedItem.tipo == 'J'" class="form-group col-md-auto">
                                    <label>CNPJ:</label>
                                    <input id="cnpj_input" v-model="editedItem.cnpj" 
                                        @blur="checkCNPJ(editedItem.cnpj)"
                                        placeholder="XX.XXX.XXX/XXXX-XX"
                                        type="text" class="form-control">
                                </div>
                                <div v-if="editedItem.tipo == 'J' && cnpjIsValid || editedItem.tipo == 'F' && cpfIsValid" class="form-group col-md-auto mr-3">
                                    <span class="material-icons icon h3" style="color:green;">check_circle_outline</span>
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('global.categoria')}}:</label>
                                    <select class="form-control" v-model="editedItem.idtiposolicitacao">
                                        <template v-if="categoriaParceiros && categoriaParceiros.length > 0">
                                            <option v-for="cat in categoriaParceiros" v-bind:key="cat.idtiposolicitacao"
                                                :value="cat.idtiposolicitacao"
                                                :selected="editedItem.idtiposolicitacao==cat.idtiposolicitacao"
                                            >{{cat.titulo}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.nomedaempresa')}}:</label>
                                    <input v-model="editedItem.nome" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.site')}}:</label>
                                    <input v-model="editedItem.site" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.email')}}:</label>
                                    <input v-model="editedItem.email" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.telefone')}}:</label>
                                    <input v-model="editedItem.telefone" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.whatsapp')}}:</label>
                                    <input v-model="editedItem.whatsapp" type="text" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <label>Cep:</label>
                                    <input v-model="editedItem.cep" type="text" class="form-control"
                                        @keyup="searchCep()"
                                    >
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>Pais:</label>
                                    <select class="form-control"  v-model="editedItem.idpais" @change="buscarUf()">
                                        <option v-for="pais in paises" :key="pais.idpais"
                                            :value="pais.idpais"
                                            :selected="editedItem.idpais==pais.idpais"
                                        >{{pais.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.estado')}}: </label>
                                    <select class="form-control" v-model="editedItem.ufcodigoibge"
                                        @change="buscarCidade()">
                                        <option v-for="estado in ufs" :key="estado.codigoibge"
                                            :value="estado.codigoibge"
                                            :selected="editedItem.ufcodigoibge==estado.codigoibge"
                                        >{{estado.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.cidade')}}:</label>
                                    <select class="form-control"
                                        v-model="editedItem.cidadecodigoibge">
                                        <option v-for="cidade in cidades" :key="cidade.codigoibge"
                                            :value="cidade.codigoibge"
                                            :selected="editedItem.cidadecodigoibge==cidade.codigoibge"
                                        >{{cidade.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.endereco')}}:</label>
                                    <input v-model="editedItem.endereco" type="text" class="form-control">
                                </div>
                                    <div class="form-group col-md-auto">
                                    <label>Numero:</label>
                                    <input id="numero_endereco" v-model="editedItem.numero" type="text" class="form-control"  >
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.bairro')}}:</label>
                                    <input v-model="editedItem.bairro" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>Complemento:</label>
                                    <input v-model="editedItem.complemento" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-auto">
                                    <label>{{$t('ser.ativo')}}:</label>
                                    <select class="form-control" v-model="editedItem.ativo">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                            <button @click="upinsertParceiro()" type="button" class="btn btn-primary mt-4" id="toastr-danger-top-right">{{$t('ser.salvar')}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import { cpf, cnpj } from 'cpf-cnpj-validator'; 

export default {
    name: 'FornecedorEditar',
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            editedItem: {},
            categoriaParceiros: [],
            paises: [],
            ufs: [],
            cidades: [],
            cnpjIsValid: false,
            cpfIsValid: false,
        }
    },
    watch: {
        editedItem: {
        handler(val){
            if (val.tipo == "F") {
                this.cpfIsValid = this.isValidCPF(val.cpf);
            }
            if (val.tipo == "J") {
                this.cnpjIsValid = this.isValidCNPJ(val.cnpj);
            }
        },
        deep: true
  }
    },
    methods: {
        isValidCPF(number) {
            return cpf.isValid(number);
        },
        isValidCNPJ(number) {
            return cnpj.isValid(number);
        },
        checkCPF(numero) {
            if (numero) console.error(numero);
        },
        checkCNPJ(numero) {
            if (numero) console.error(numero);
        },
        setFocus(evt) {
            let elid = this.editedItem.tipo == 'F' ? 'cpf_input' : 'cnpj_input';
            console.error(`evt(${evt.target.value})`);
            console.error(`this.editedItem.tipo:${this.editedItem.tipo}`);
            console.error(`elid:${elid}`);
            let el = document.getElementById(elid);
            console.error(`el:${el}`);
            if (el) {
                console.error(`setFocus(${elid})`)
                el.focus();
            }
        },
        upinsertParceiro() {
            if (this.editedItem.idparceiro) {
                this.editarParceiro()
            } else {
                this.inserirParceiro()
            }
        },
        focar(id) {
            let el = document.getElementById(id);
            if (el) el.focus();
        },
        searchCep () {
			if(this.editedItem.cep.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ this.editedItem.cep }/json/`)
				.then( response => {
                    if (response.data.logradouro) this.editedItem.endereco = response.data.logradouro;
                    if (response.data.bairro) this.editedItem.bairro = response.data.bairro;
                    if (response.data.ibge) {
                        this.editedItem.cidadecodigoibge = response.data.ibge;
                        this.editedItem.ufcodigoibge = response.data.ibge.substring(0,2);
                        this.buscarUf();
                        this.buscarCidade();
                        this.$forceUpdate();
                    }
                    this.focar('numero_endereco');
                })
				.catch((e) => {
                    console.log(e) 
                })
			}
		},
        buscarCategorias() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "interna": true
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.categoriaParceiros = response.data
                }
            })
        },
        buscarPais() {
            axios.post(CONSTANTS.API_URL + '/fdguest/pais/select', {
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.paises = response.data 
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarUf() {
            axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', {}, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.ufs = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarCidade() {
            axios.post(CONSTANTS.API_URL + '/fdguest/cidade/select', {
                codigoufibge: this.editedItem.ufcodigoibge
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.cidades = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        inserirParceiro() {
            var parceiro = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idusuariocadastro: this.$store.getters.GetLoginInfo.idusuario,
                idtiposolicitacao: this.editedItem.idtiposolicitacao,
                nome: this.editedItem.nome,
                endereco: this.editedItem.endereco,
                numero: this.editedItem.numero,
                site: this.editedItem.site,
                email: this.editedItem.email,
                telefone: this.editedItem.telefone,
                whatsapp: this.editedItem.whatsapp,
                cep: this.editedItem.cep,
                complemento: this.editedItem.complemento,
                bairro: this.editedItem.bairro,
                codigoibge: this.editedItem.cidadecodigoibge,
                pais: this.editedItem.idpais,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                manutencao: true,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            // console.log(JSON.stringify(parceiro))
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/insert', parceiro,  {headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/fornecedor')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        editarParceiro() {
             var params = {
                idparceiro: this.editedItem.idparceiro, 
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idusuariocadastro: this.$store.getters.GetLoginInfo.idusuario,
                idtiposolicitacao: this.editedItem.idtiposolicitacao,
                nome: this.editedItem.nome,
                endereco: this.editedItem.endereco,
                numero: this.editedItem.numero,
                site: this.editedItem.site,
                email: this.editedItem.email,
                telefone: this.editedItem.telefone,
                whatsapp: this.editedItem.whatsapp,
                cep: this.editedItem.cep,
                complemento: this.editedItem.complemento,
                bairro: this.editedItem.bairro,
                codigoibge: this.editedItem.cidadecodigoibge,
                pais: this.editedItem.idpais,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                manutencao: true,
                tipo: this.editedItem.tipo,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            if (this.editedItem.tipo == 'F') {
                params.cpf = this.editedItem.cpf;
            }
            if (this.editedItem.tipo == 'J') {
                params.cnpj = this.editedItem.cnpj;
            }
            // console.error(JSON.stringify(params));
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', params,  {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/fornecedor')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        }
    },
    mounted() {
        this.editedItem = JSON.parse(this.$store.getters.GetEditItem);
        this.buscarCategorias();
        this.buscarPais();
        this.buscarUf();
        this.buscarCidade();
    }
}
</script>

<style>

</style>