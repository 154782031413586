<template>
	<div v-if="chamado.assinaturas && chamado.assinaturas.length>0">
		<!-- Título -->
		<div class="text-center">
			<h4>{{$t('assinatura.titulo')}}</h4>
		</div>
		<!-- Cabeçalho -->
		<div class="row py-2">
			<div class="col-2"><strong>{{$t('assinatura.dataevento')}}</strong></div>
			<div class="col-2"><strong>{{$t('assinatura.assinatura')}}</strong></div>
			<div class="col-2"><strong>{{$t('assinatura.documento')}}</strong></div>
			<div class="col-2"><strong>{{$t('assinatura.camposadicionais')}}</strong></div>
            <div class="col-2"><strong>{{$t('assinatura.status')}}</strong></div>
			<div class="col-2"><strong>{{$t('assinatura.pdf')}}</strong></div>
		</div>
		<!-- Produtos -->
		<div v-for="(ass,index) in chamado.assinaturas" :key="index"
			:class="{ 'es-linha-par': index%2==0, 'es-linha-impar': index%2!=0}" class="row align-items-center py-2 ponteiro">
			<div class="col-2">
				<span>{{formatarData(ass.dataevento)}}</span>
			</div>            
			<div class="col-2">
                <a v-if="ass.obrigaassinatura" v-on:click="$emit('ass',ass.idhotlinesolicitacoesassinatura)" class="ponteiro text-white btn btn-info">Ver</a>
				<span v-else>{{$t('assinatura.semassinatura')}}</span>
			</div>			
			<div class="col-2">
                <a v-if="ass.obrigaimgdocumento" v-on:click="$emit('doc',ass.idhotlinesolicitacoesassinatura)" class="ponteiro text-white btn btn-info">Ver</a>
                <span v-else>{{$t('assinatura.semdocumento')}}</span>
			</div>
			<div class="col-2">
				<span>{{ass.camposadicionais}}</span>
			</div>
			<div class="col-2">
				<span>{{ass.status}}</span>
			</div>
            <div class="col-2">
                <a @click="openPDF(ass,chamado)" 
                    :id="`pdf_${chamado.idhotlinesolicitacoes}_${ass.idhotlinesolicitacoesassinatura}`"
                    class="ponteiro text-white btn btn-info"
                >Gerar PDF</a>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'ChamadoHistorico',
    props: {
        chamado: {
            type: Object,
            required: true
        }
    },
    components: {  },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            }
        }
    },
    methods: {
        openPDF(ass,cha){
            let url = `${window.location}/pdf?sol=${cha.idhotlinesolicitacoes}&k=${ass.idhotlinesolicitacoesassinatura}&t=eyJhbGciOiJIUzI1NiJ9.MQ.5Ltiwf4Hc86sc5c4F7EQCFT2fvpQr1UPnbkNKg7kW1c`;
            window.open(url,'_blank');
        },
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('L HH:MM');
        },
    },
    computed: {
    },
    created() {
        
    },
    mounted() {
    }
}
</script>

<style scoped>
.es-linha-impar {
    background-color: #b9d7f7;
}
.es-linha-impar:hover {
    background-color: #91c3f8;
}
.es-linha-par {
    background-color: rgb(240, 239, 239);
}
.es-linha-par:hover {
    color: white;
    background-color: rgb(189, 187, 187);
}

</style>