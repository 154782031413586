<template>
	<div class="row">
		<!-- Usuarios Cadastrados -->
		<div class="col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">{{$t('upload.titulo')}}</h4>
				</div>
				<h5 class="card-text px-5 py-2">{{$t('upload.subtitulo')}}</h5>
				<h5 class="card-text px-5 py-2">Modelo da Planilha para upload no fomarto .csv 
                    <a style="color:blue;text-decoration:underline;" target="_blank" href="/planilha/modelo.csv" >Clique para Baixar Planilha</a>
                </h5>
				<h5 class="card-text px-5 py-2" style="color:red;">
                    A primeira linha deve ser o cabeçalho e a demais linhas devem seguir o mesmo padrão.
                </h5>
				<div class="card-body">
					<div class="form-group col-md-12 col-lg-12" >
						<div class="form-row" style="justify-content:center;">
							<div class="col-auto">
								<input class="p-2 m-2 rounded" style="text-align:center;" type="text" v-model="separador" />
								<input class="p-2 m-2 rounded" style="text-align:center;" type="text" v-model="codePage" />
								<input type="file" id="fileInput" style="display:none;"
									accept=".csv,.txt" @change="onFileChange" 
									placeholder="Arquivo CSV/TXT"/>
								<label for="fileInput" >
									<div class="btn btn-success my-2 ponteiro">{{$t('upload.escolher')}}</div>
								</label>
							</div>
						</div>
						<div class="form-row pb-2" style="justify-content:center;">
							<textarea v-model="fileContent" rows="10" cols="132">
							</textarea>
						</div>
						<div class="form-row" style="justify-content:center;">
							<p class="es-status">{{statusMsg}}</p>
						</div>
						<div v-if="errorLines&&errorLines.length>0" class="form-row pb-2" style="justify-content:center;">
							<textarea v-model="errorLines" rows="10" cols="132">
							</textarea>
						</div>						
					</div>
				</div>
			</div>
		</div> 
	</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    components: {  },
	data() {
		return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
			tecnicos: [],
			alertMsg: '',
			usuarioTipos: [],
			statusMsg: '',
			fileContent: '<conteúdo do arquivo aparecerá aqui>',
			codePage: 'iso-8859-1',
			separador: ',',
			errorLines: ''
		}
	},
	methods: {
		clearContent() {
			// Limpar o conteúdo anterior...
			this.fileContent = '';
			this.errorLines = '';
			this.$forceUpdate();
		},
		uploadProdutos(tipo,filename) {
			this.statusMsg = this.$t('upload.importando')
			var params = {
				idestoque: this.$store.getters.GetLoginInfo.idestoque,
				arquivo : {
					nome : filename,
					tipo: tipo,
					dados : this.fileContent,
					code: this.codePage
				},
				separador: this.separador,
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}
			//console.error(JSON.stringify(params));
			axios.post(CONSTANTS.API_URL + '/fdguest/estoque/uploadmanutencao', params, {headers: this.headers})
			.then(response => {
				if(response.status == 200) {
					//alert('Cadastro realizado com sucesso')
					this.statusMsg = `Sucesso!`;
					if (response.data.erros&&response.data.erros.length>0) {
						this.statusMsg = `Sucesso! Porém, algumas linhas deram erro. Verifique a existência de vírgula na descrição.`;
						for (var i = 0; i < response.data.erros.length; i++) {
							this.errorLines += `${response.data.erros[i]}\n`;
						}
					}
				} else {
					console.log(response)
				}
			})
			.catch((e) => {
				this.statusMsg = `${this.$t('global.erro')}:${JSON.stringify(e.message)}`
				console.error(e);
			})
		},		
		onFileChange() {
			// Limpar o conteúdo anterior...
			// this.clearContent();
			let obj = document.getElementById('fileInput');
			//Read File
			var selectedFile = document.getElementById("fileInput").files;
			//Check File is not Empty
			if (selectedFile.length > 0) {
				// Select the very first file from list
				var fileToLoad = selectedFile[0];
				// FileReader function for read the file.
				var fileReader = new FileReader();
				// As duas linhas abaixo são necessária pois 'this' não aponta para a 
				// instância Vue dentro da função de callback abaixo (onload).
				var fnUploadProdutos = this.uploadProdutos;
				var este = this
				// Ao final da leitura do arquivo salvar o pdf...
				fileReader.onload = function(fileLoadedEvent) {
					// console.error(`fileReader.onload():`)
					// console.error(fileToLoad)
					este.fileContent = fileLoadedEvent.target.result;
					fnUploadProdutos(fileToLoad.type,fileToLoad.name,fileLoadedEvent.target.result)
					if (obj) obj.value = null;
				};
				// Ler o arquivo e converter para base64
				fileReader.readAsText(fileToLoad,this.codePage);
				//fileReader.readAsText(fileToLoad);
				//fileReader.readAsDataURL(fileToLoad);
			}
		},		
        async loadTipos() {
            var params = {
                idioma: this.$i18n.locale,
                interna: true
            }
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tipos = response.data;
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ erro: `loadTipos(): ${err.message}` });
			});
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.0850;
                }, 140);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },		
		alertar(id,msg) {
			this.alertMsg = msg
			let el = document.getElementById('alert-msg-' + id);
			if (el) {
				el.style.display = 'block';
				this.fade(el)
			}
		},
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idusuario)
			var btn = document.getElementById(`btn-text-${item.idusuario}`)
			if (obj&&obj.style.display == 'none') {
				obj.style.display = 'block'
				btn.innerText = 'Fechar'
			} else {
				this.closeEdit(item)
			}
        },
        saveEdit(item) {
			var listatipos = []
			var listaitens = []
			if (item.tipos) {
				for (var i = 0; i < item.tipos.length; i++) {
					var tipo = item.tipos[i]
					if (tipo.selected) {
						listatipos.push(parseInt(tipo.idtiposolicitacao))
					}
					if (tipo.itens) {
						for (var j = 0; j < tipo.itens.length; j++) {
							var itemCorrente = tipo.itens[j]
							if (itemCorrente.selected) {
								listaitens.push(parseInt(itemCorrente.idtiposolicitacaoitem))
							}
						}
					}
				}
			}
            var params = {
				"idusuario": item.idusuario,
				"tipos": listatipos,
				"itens": listaitens,
				"merge": false
			}
			//console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tecnicos/vinculo',
                params, { headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.loadTecnicos()
                    this.closeEdit(item)
                } else {
					if(response.status == 201) {
						this.alertar(item.idusuario,response.message)
					}
				}
            })
			.catch((e) => {
				console.error('Erro:'+e.message);
			})
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idusuario)
			var btn = document.getElementById(`btn-text-${item.idusuario}`)
            if(obj&&btn) {
                obj.style.display = 'none'
				btn.innerText = 'Associar'
            }
        },
		loadTecnicos() {
			axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tecnicos', {
				ativo: true,
				idhotel: this.$store.getters.GetLoginInfo.idhotel,
				idusuariotipo: 4,
				page: 1,
                pagesize: 1000,
			}, { headers: this.headers })
			.then(response => {
				if(response.status == 200) {
					this.tecnicos = response.data.dados
				}
			})
			.catch((e) => {
				console.log({ err_usuario: e, message: e.message});
			})
		}
	},
	mounted() {
		this.loadTecnicos();
		//this.loadTipos();
	}
}
</script>

<style scoped>
	.es-status {
		color: orange;
		font-size: 1.5em;
		font-weight: bold;
	}
</style>

