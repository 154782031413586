<template>
	<div class="row">
		<!-- Avatar -->
		<div class="col-xl-12 col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Avatar</h4>								
				</div>
				<div class="container-fluid ponteiro text-center mb-4">					
					<div><h3>{{this.$store.getters.GetLoginInfo.nome}}</h3></div>
					<div class="avatar-perfil">
						<label class="ponteiro" for="file">
							<img id="img" :src="avatar" alt="">
						</label>
						<input @change="uploadImg" type="file" id="file" name="file" accept="image/png,image/jpg,image/jpeg" class="ponteiro">
					</div>
				</div>
			</div>
		</div>
		<!-- Idioma -->
		<div class="col-xl-12 col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Idioma</h4>								
				</div>
                <div class="container">
                    <div class="card">
                        <div class="card-header border-bottom">
                            <div class="row">
                                <div class="col-auto">
                                    <div class="avatar avatar-50 bg-default-light text-default rounded">
                                        <span class="material-icons">language</span>
                                    </div>
                                </div>
                                <div class="col align-self-center pl-0">
                                    <h6 class="mb-1">{{$t('perfil.escolhaumidioma')}}</h6>
                                    <p class="text-secondary">{{$t('perfil.selecioneoidiomadepreferencia')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0 px-0">
                            <ul class="list-group list-group-flush">
                                <template v-if="idiomas && idiomas.length > 0">
                                    <li class="list-group-item" v-for="idioma in idiomas" v-bind:key="idioma.ididioma">
                                        <div class="custom-control custom-switch">
                                            <input type="radio" name="language" class="custom-control-input ponteiro" 
                                                :id="'Switch_'+idioma.ididioma"
                                                :checked="idioma.default"
                                                @click="changeLocale(idioma)"
                                            >
                                            <label class="custom-control-label" :for="'Switch_'+idioma.ididioma">{{idioma.descidioma}}</label>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
			</div>
		</div>		
		<!-- Senha -->
		<div class="col-xl-12 col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Modificar Senha</h4>
				</div>
				<div class="card-body">
					<div class="basic-form">
						<form>
							<div class="form-row">
								<div class="form-group col-md-12">
									<label>Senha atual:</label>
									<input type="password" class="form-control" placeholder="" v-model="senhaAtual">
								</div>
								<div class="form-group col-md-6">
									<label>Nova senha:</label>
									<input type="password" class="form-control" placeholder="" v-model="novaSenha">
								</div>
								<div class="form-group col-md-6">
									<label>Repita a nova senha:"</label>
									<input type="password" class="form-control" v-model="novaSenhaRepetida">
								</div>
							</div>
							<button @click="salvarSenha()" 
								type="button" class="btn btn-primary mt-4" id="toastr-danger-top-right">Salvar
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    data() {
        return {
			idiomas: null,
			senhaAtual: '',
			novaSenha: '',
			novaSenhaRepetida: '',
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            avatar: CONSTANTS.API_URL + (this.$store.getters.GetLoginInfo.avatar ? 
                this.$store.getters.GetLoginInfo.avatar : '/img/perfil/P0.png'
            )
        }
    },
	methods: {
        uploadImg(){
            var formData = new FormData()
            var imagefile = document.querySelector('#file')
            formData.append("image", imagefile.files[0])
            formData.append("tipo", "cardapio_produto")
            
            var reader = new FileReader();
            reader.readAsDataURL(imagefile.files[0]);

            reader.onload = e => {
                var img = document.getElementById("img");
                img.src = e.target.result;

				// Atualizar o cabeçalho com a imagem do perfil
                var imgHeader = document.getElementById("imgHeader");
				if (imgHeader) {
					imgHeader.src = e.target.result;
				}

				axios.post(CONSTANTS.API_URL+"/fdguest/upload/usuario/perfil/"+this.$store.getters.GetLoginInfo.idusuario, 
					formData, {headers: this.headers})
				.then(() =>{
					console.log("Atualizado com Sucesso!");
				})
				.catch(() =>{
					console.log("Não foi possível Atualizar!");
				})            
            }
        },
		carregarIdiomas() {
			axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});                
		},
		changeLocale(entry) {
			// Altera o idioma na tela
			this.$i18n.locale = entry.codidioma;
			// Altera o idioma default do usuário no banco
			axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/default/usuario/set', {
				idusuario: this.$store.getters.GetLoginInfo.idusuario,
				idioma : entry.codidioma
			}, {headers: this.headers}).then(response => {
				if (response.status == 200) {
					// É preciso recarregar em caso de mudança de idioma
					// this.carregarUltimasTransacoes();
					// this.carregarMenuItens();
					// this.carregarNotificacoes();
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});                
		},
		salvarSenha() {
			if (this.novaSenha === this.novaSenhaRepetida) {
				// Altera a senha do usuário no banco
				axios.put(CONSTANTS.API_URL + '/usuarios/cadastro/senha', {
					idusuario: this.$store.getters.GetLoginInfo.idusuario,
					senha : this.novaSenha
				}, {headers: this.headers}).then(response => {
					if (response.status == 200) {
						alert('Senha alterada.')
					}
				}).catch((err) => {
					console.log({ err_menu: err });
				})
			} else {
				alert('A nova senha e sua repetição devem ser idênticas.')
			}
		},
	},
	mounted() {
		this.carregarIdiomas();
	}
}
</script>

<style>
#file {
  display: none;
}

#img {
  height: auto;
  max-width: 100%;
}

.avatar.avatar-100 {
  height: 100px;
  line-height: 100px;
  width: 100px;
}

.text-secondary {
  color: #6c757d !important;
}

.avatar-perfil img {
	border-radius: 3.55rem;
	padding: 15px;
	width: 150px;
}

</style>