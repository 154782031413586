<template>
	<!-- Índice Geral -->
	<div class="accordion__item">
		<div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="'#'+indice.codigo">
			<span class="accordion__header--text">{{indice.titulo}}</span>
			<span class="accordion__header--indicator"></span>
		</div>
		<div :id="indice.codigo" class="collapse accordion__body" data-parent="#accordion-one">
			<div class="accordion__body--text">
				<!-- Corpo do acordeão -->
				<div class="col-xl-12">
					<div class="card">
						<!-- Filtro de Pesquisa -->
						<div class="card-header">
							<div class="basic-form">
								<form>
									<div class="form-row">
										<div class="form-group col-sm-5">
											<label>Período de:</label>
											<input v-model="indice.periodo.dataInicial" type="date" class="form-control" placeholder="" >
										</div>
										<div class="form-group col-sm-5">
											<label>Até:</label>
											<input v-model="indice.periodo.dataFinal" type="date" class="form-control" placeholder="">
										</div>
										<div class="form-group col-sm-2">
											<button v-on:click="$emit('reload',indiceGeralOrdem)" type="button" class="btn btn-primary mt-4" id="toastr-danger-top-right">Filtrar</button>
										</div>
									</div>
								</form>
							</div>
						</div>
						<!-- Resultado de Pesquisa -->
						<div class="card-body">
							<div class="table-responsive">
								<table class="table table-responsive-md">
									<thead>
										<tr>
											<th v-for="(cab,index) in indice.cabecalhos" :key="index"><strong>{{cab.nome}}</strong>
												<button type="button" class="btn-rel btn-warning light"
													@click="ordenar(cab.campo)">
													<div :id="'div'+indice.codigo+'_'+cab.campo" state="neutral">&updownarrow;</div>
												</button>
											</th>
										</tr>
									</thead>
									<tbody v-for="(item,index) in indice.dados" :key="index">
										<tr>
											<td><strong>{{item[indice.cabecalhos[0]['campo']]}}</strong>
											</td>
											<td><strong>{{item[indice.cabecalhos[1]['campo']]}}</strong>
												<!-- O valor no mesmo período um ano antes -->
												<strong style="font-size:0.60rem;color:grey;">{{getAnterior(item,indice.cabecalhos[1]['campo'])}}</strong>
												<!-- Se o valor subiu é verde, se desceu é vermelho -->
												<div :style="goingUp(item,indice.cabecalhos[1]['campo'])?'font-size:0.60rem;color:green;':'font-size:0.60rem;color:red;'">
													<!-- A Seta -->
													<strong v-if="goingUp(item,indice.cabecalhos[1]['campo'])">&uarr;</strong>
													<strong v-if="goingDown(item,indice.cabecalhos[1]['campo'])">&darr;</strong>
													<!-- A Diferença -->
													<strong>{{getDiff(item,indice.cabecalhos[1]['campo'])}}</strong>
												</div>
											</td>
											<td><strong>{{item[indice.cabecalhos[2]['campo']]}}</strong>
												<!-- O valor no mesmo período um ano antes -->
												<strong style="font-size:0.60rem;color:grey;">{{getAnterior(item,indice.cabecalhos[2]['campo'])}}</strong>
												<!-- Se o valor subiu é verde, se desceu é vermelho -->
												<div :style="goingUp(item,indice.cabecalhos[2]['campo'])?'font-size:0.60rem;color:green;':'font-size:0.60rem;color:red;'">
													<!-- A Seta -->
													<strong v-if="goingUp(item,indice.cabecalhos[2]['campo'])">&uarr;</strong>
													<strong v-if="goingDown(item,indice.cabecalhos[2]['campo'])">&darr;</strong>
													<!-- A Diferença -->
													<strong>{{getDiff(item,indice.cabecalhos[2]['campo'])}}</strong>
												</div>
											</td>
											<td><strong>{{item[indice.cabecalhos[3]['campo']]}}</strong>
												<!-- O valor no mesmo período um ano antes -->
												<strong style="font-size:0.60rem;color:grey;">{{getAnterior(item,indice.cabecalhos[3]['campo'])}}</strong>
												<!-- Se o valor subiu é verde, se desceu é vermelho -->
												<div :style="goingUp(item,indice.cabecalhos[3]['campo'])?'font-size:0.60rem;color:green;':'font-size:0.60rem;color:red;'">
													<!-- A Seta -->
													<strong v-if="goingUp(item,indice.cabecalhos[3]['campo'])">&uarr;</strong>
													<strong v-if="goingDown(item,indice.cabecalhos[3]['campo'])">&darr;</strong>
													<!-- A Diferença -->
													<strong>{{getDiff(item,indice.cabecalhos[3]['campo'])}}</strong>
												</div>
											</td>
											<td><span :style="getColor(item[indice.cabecalhos[4]['campo']])">{{(item[indice.cabecalhos[4]['campo']])}}</span>
												<!-- O valor no mesmo período um ano antes -->
												<strong style="font-size:0.60rem;color:grey;">{{getAnterior(item,indice.cabecalhos[4]['campo'])}}</strong>
												<!-- Se o valor subiu é verde, se desceu é vermelho -->
												<div :style="goingUp(item,indice.cabecalhos[4]['campo'])?'font-size:0.60rem;color:green;':'font-size:0.60rem;color:red;'">
													<!-- A Seta -->
													<strong v-if="goingUp(item,indice.cabecalhos[4]['campo'])">&uarr;</strong>
													<strong v-if="goingDown(item,indice.cabecalhos[4]['campo'])">&darr;</strong>
													<!-- A Diferença -->
													<strong>{{getDiff(item,indice.cabecalhos[4]['campo'])}}</strong>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="row" style="font-size:0.6rem;">
								<div class="box" >
									* Quando disponíveis, os valores do mesmo período do ano anterior
								</div>
								<div class="box">
									aparecem após "/" em caracteres menores seguido da variação em percentual.
								</div>
							</div>
							<div class="row">
								<div class="box" style="align-self: right;">
									<button @click="gerarExcel()" type="button" class="btn btn-success mt-4" id="toastr-danger-top-right">Gerar Excel</button>
									<iframe :id="'iframe_downloader_'+this.indice.codigo" style="display:none;"></iframe>
								</div>																	
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment";
export default {
    props: {
        indice: {
            type: Object,
            required: true,
        }
    },	
	data() {
		return {
			headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
					},
			datainicial: '2020-01-01',
			datafinal: '2021-12-31',
			indiceGeralAnterior: [],
			indiceGeralOrdem: '',
			colors: [
				'#ff0000',
				'#ff1100',
				'#ff2200',
				'#ff4400',
				'#ff6600',
				'#ff8800',
				'#ff9900',
				'#ffaa00',
				'#ffcc00',
				'#ffff00',
				'#eeff00',
				'#ddff00',
				'#ccff00',
				'#bbff00',
				'#aaff00',
				'#00ff00',
				'#21df33',
				'#30cf60',
				'#35af70',
				'#408f80',
				'#445f88',
				'#440088',
				'#380076',
				'#330065',
				'#300055',
				'#250035',
				'#150025',
				'#000000'
			]
		}
	},
	methods: {
		ordenar(info){
			var setaDiv = document.getElementById('div'+this.indice.codigo+'_'+info)
			if (setaDiv) {
				for (var i=0; i < this.indice.cabecalhos.length; i++) {
					if (this.indice.cabecalhos[i]['campo'] != info) {
						var id = 'div'+this.indice.codigo+'_'+this.indice.cabecalhos[i]['campo']
						var obj = document.getElementById(id)
						if (obj) {
							obj.innerHTML = '&updownarrow;'
						}
					}
				}
				if (setaDiv.getAttribute('state')=='neutral') {
					this['indiceGeralOrdem'] = info + ' asc '
					setaDiv.setAttribute('state', 'up')
					setaDiv.innerHTML = '&uarr;'
				} else {
					if (setaDiv.getAttribute('state')=='up') {
						this['indiceGeralOrdem'] = info + ' desc '
						setaDiv.setAttribute('state', 'down')
						setaDiv.innerHTML = '&darr;'
					} else {
						this['indiceGeralOrdem'] = ''
						setaDiv.setAttribute('state', 'neutral')
						setaDiv.innerHTML = '&updownarrow;'
					}
				}
				this.$emit('reload',this['indiceGeralOrdem'])
			}
		},
		goingDown(item, info){
			var result = false
			for (var i = 0; i < this.indice.dadosAnteriores.length; i++) {
				if (this.indice.dadosAnteriores[i][this.indice.cabecalhos[0]['campo']]==item[this.indice.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.indice.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore) {
							if (infoValue < infoValueBefore && infoValueBefore > 0) {
								result = true
							} else {
								result = false
							}
						} else {
							result = false
						}
					} catch (error) {
						result = false
					}
				}
			}
			return result
		},
		goingUp(item,info){
			var result = false
			for (var i = 0; i < this.indice.dadosAnteriores.length; i++) {
				if (this.indice.dadosAnteriores[i][this.indice.cabecalhos[0]['campo']]==item[this.indice.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.indice.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							if (infoValue > infoValueBefore)
								result = true
							else						
								result = false;
						} else {
							result = false
						}
					} catch (error) {
						result = false
					}
				}
			}
			return result
		},
		getDiff(item,info){
			var result = ''
			for (var i = 0; i < this.indice.dadosAnteriores.length; i++) {
				if (this.indice.dadosAnteriores[i][this.indice.cabecalhos[0]['campo']]==item[this.indice.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.indice.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							var razao = (infoValue - infoValueBefore) / infoValueBefore
							var variacao = Math.round(razao*100, 2)
							result = ' ' + variacao.toString() + '%'
						} else {
							result = ''
						}
					} catch (error) {
						result = ''
					}
				}
			}
			return result
		},
		getAnterior(item,info) {
			var result = ''
			for (var i = 0; i < this.indice.dadosAnteriores.length; i++) {
				if (this.indice.dadosAnteriores[i][this.indice.cabecalhos[0]['campo']]==item[this.indice.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.indice.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							result = ' / '+ this.indice.dadosAnteriores[i][info]
						} else {
							result = ''
						}
					} catch (error) {
						result = ''
					}
				}
			}
			return result
		},
		download(url) {
			document.getElementById('iframe_downloader_'+this.indice.codigo).src = url
		},
		gerarExcel() {
			var plan1 = {
				nome: this.indice.titulo,
				titulo: `Período de ${this.indice.periodo.dataInicial} a ${this.indice.periodo.dataFinal}`,
				cabecalhos: this.indice.cabecalhos,
				dados: this.indice.dados
			}
			var dtini = (moment(this.indice.periodo.dataInicial).subtract(12, 'months')).format('YYYY-MM-DD')
			var dtfin = (moment(this.indice.periodo.dataFinal).subtract(12, 'months')).format('YYYY-MM-DD')
			var plan2 = {
				nome: `${this.indice.titulo} - Período Anterior`,
				titulo: `Período de ${dtini} a ${dtfin}`,
				cabecalhos: this.indice.cabecalhos,
				dados: this.indice.dadosAnteriores
			}
			var obj = {
				arquivo : `${this.indice.codigo}_${this.$store.getters.GetLoginInfo.idusuario}.xlsx`,
				planilhas : [
					plan1, plan2
				]
			}
            axios.post(CONSTANTS.API_URL + '/toexcel/fromjson',
                    obj, { headers: this.headers }).then(response => {
                if(response.status == 200) {
                    this.download(CONSTANTS.API_URL+response.data.link)
                }
            })
		},
		getColor(media) {
			var indice = Math.round((media / 5) * (this.colors.length-1))
			return `color: ${this.colors[indice]};font-size:0.85rem;font-weight:bold;`
		},
	},
	mounted() {

	}
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.btn-rel {
  display: inline-block;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}
@media (prefers-reduced-motion: reduce) {
  .btn-rel {
      transition: none; } 
}
.btn-rel:hover {
    color: #e9e7e7;
    text-decoration: none; 
}
.btn-rel:focus, .btn-rel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 47, 185, 0.25); 
}
.btn-rel.disabled, .btn-rel:disabled {
    opacity: 0.65; 
}
</style>