<template>
    <div class="deznav-scroll">
        <ul class="metismenu" id="menu">
            <li><a><i class="flaticon-381-add"></i><span>flaticon-381-add</span></a></li>
            <li><a><i class="flaticon-381-add-1"></i><span>flaticon-381-add-1</span></a></li>
            <li><a><i class="flaticon-381-add-2"></i><span>flaticon-381-add-2</span></a></li>
            <li><a><i class="flaticon-381-add-3"></i><span>flaticon-381-add-3</span></a></li>
            <li><a><i class="flaticon-381-alarm-clock"></i><span>flaticon-381-alarm-clock</span></a></li>
            <li><a><i class="flaticon-381-alarm-clock-1"></i><span>flaticon-381-alarm-clock-1</span></a></li>
            <li><a><i class="flaticon-381-album"></i><span>flaticon-381-album</span></a></li>
            <li><a><i class="flaticon-381-album-1"></i><span>flaticon-381-album-1</span></a></li>
            <li><a><i class="flaticon-381-album-2"></i><span>flaticon-381-album-2</span></a></li>
            <li><a><i class="flaticon-381-album-3"></i><span>flaticon-381-album-3</span></a></li>
            <li><a><i class="flaticon-381-app"></i><span>flaticon-381-app</span></a></li>
            <li><a><i class="flaticon-381-archive"></i><span>flaticon-381-archive</span></a></li>
            <li><a><i class="flaticon-381-back"></i><span>flaticon-381-back</span></a></li>
            <li><a><i class="flaticon-381-back-1"></i><span>flaticon-381-back-1</span></a></li>
            <li><a><i class="flaticon-381-back-2"></i><span>flaticon-381-back-2</span></a></li>
            <li><a><i class="flaticon-381-background"></i><span>flaticon-381-background</span></a></li>
            <li><a><i class="flaticon-381-background-1"></i><span>flaticon-381-background-1</span></a></li>
            <li><a><i class="flaticon-381-battery"></i><span>flaticon-381-battery</span></a></li>
            <li><a><i class="flaticon-381-battery-1"></i><span>flaticon-381-battery-1</span></a></li>
            <li><a><i class="flaticon-381-battery-2"></i><span>flaticon-381-battery-2</span></a></li>
            <li><a><i class="flaticon-381-battery-3"></i><span>flaticon-381-battery-3</span></a></li>
            <li><a><i class="flaticon-381-battery-4"></i><span>flaticon-381-battery-4</span></a></li>
            <li><a><i class="flaticon-381-battery-5"></i><span>flaticon-381-battery-5</span></a></li>
            <li><a><i class="flaticon-381-battery-6"></i><span>flaticon-381-battery-6</span></a></li>
            <li><a><i class="flaticon-381-battery-7"></i><span>flaticon-381-battery-7</span></a></li>
            <li><a><i class="flaticon-381-battery-8"></i><span>flaticon-381-battery-8</span></a></li>
            <li><a><i class="flaticon-381-battery-9"></i><span>flaticon-381-battery-9</span></a></li>
            <li><a><i class="flaticon-381-binoculars"></i><span>flaticon-381-binoculars</span></a></li>
            <li><a><i class="flaticon-381-blueprint"></i><span>flaticon-381-blueprint</span></a></li>
            <li><a><i class="flaticon-381-bluetooth"></i><span>flaticon-381-bluetooth</span></a></li>
            <li><a><i class="flaticon-381-bluetooth-1"></i><span>flaticon-381-bluetooth-1</span></a></li>
            <li><a><i class="flaticon-381-book"></i><span>flaticon-381-book</span></a></li>
            <li><a><i class="flaticon-381-bookmark"></i><span>flaticon-381-bookmark</span></a></li>
            <li><a><i class="flaticon-381-bookmark-1"></i><span>flaticon-381-bookmark-1</span></a></li>
            <li><a><i class="flaticon-381-box"></i><span>flaticon-381-box</span></a></li>
            <li><a><i class="flaticon-381-box-1"></i><span>flaticon-381-box-1</span></a></li>
            <li><a><i class="flaticon-381-box-2"></i><span>flaticon-381-box-2</span></a></li>
            <li><a><i class="flaticon-381-briefcase"></i><span>flaticon-381-briefcase</span></a></li>
            <li><a><i class="flaticon-381-broken-heart"></i><span>flaticon-381-broken-heart</span></a></li>
            <li><a><i class="flaticon-381-broken-link"></i><span>flaticon-381-broken-link</span></a></li>
            <li><a><i class="flaticon-381-calculator"></i><span>flaticon-381-calculator</span></a></li>
            <li><a><i class="flaticon-381-calculator-1"></i><span>flaticon-381-calculator-1</span></a></li>
            <li><a><i class="flaticon-381-calendar"></i><span>flaticon-381-calendar</span></a></li>
            <li><a><i class="flaticon-381-calendar-1"></i><span>flaticon-381-calendar-1</span></a></li>
            <li><a><i class="flaticon-381-calendar-2"></i><span>flaticon-381-calendar-2</span></a></li>
            <li><a><i class="flaticon-381-calendar-3"></i><span>flaticon-381-calendar-3</span></a></li>
            <li><a><i class="flaticon-381-calendar-4"></i><span>flaticon-381-calendar-4</span></a></li>
            <li><a><i class="flaticon-381-calendar-5"></i><span>flaticon-381-calendar-5</span></a></li>
            <li><a><i class="flaticon-381-calendar-6"></i><span>flaticon-381-calendar-6</span></a></li>
            <li><a><i class="flaticon-381-calendar-7"></i><span>flaticon-381-calendar-7</span></a></li>
            <li><a><i class="flaticon-381-clock"></i><span>flaticon-381-clock</span></a></li>
            <li><a><i class="flaticon-381-clock-1"></i><span>flaticon-381-clock-1</span></a></li>
            <li><a><i class="flaticon-381-clock-2"></i><span>flaticon-381-clock-2</span></a></li>
            <li><a><i class="flaticon-381-close"></i><span>flaticon-381-close</span></a></li>
            <li><a><i class="flaticon-381-cloud"></i><span>flaticon-381-cloud</span></a></li>
            <li><a><i class="flaticon-381-cloud-computing"></i><span>flaticon-381-cloud-computing</span></a></li>
            <li><a><i class="flaticon-381-command"></i><span>flaticon-381-command</span></a></li>
            <li><a><i class="flaticon-381-compact-disc"></i><span>flaticon-381-compact-disc</span></a></li>
            <li><a><i class="flaticon-381-compact-disc-1"></i><span>flaticon-381-compact-disc-1</span></a></li>
            <li><a><i class="flaticon-381-compact-disc-2"></i><span>flaticon-381-compact-disc-2</span></a></li>
            <li><a><i class="flaticon-381-compass"></i><span>flaticon-381-compass</span></a></li>
            <li><a><i class="flaticon-381-compass-1"></i><span>flaticon-381-compass-1</span></a></li>
            <li><a><i class="flaticon-381-compass-2"></i><span>flaticon-381-compass-2</span></a></li>
            <li><a><i class="flaticon-381-controls"></i><span>flaticon-381-controls</span></a></li>
            <li><a><i class="flaticon-381-controls-1"></i><span>flaticon-381-controls-1</span></a></li>
            <li><a><i class="flaticon-381-controls-2"></i><span>flaticon-381-controls-2</span></a></li>
            <li><a><i class="flaticon-381-controls-3"></i><span>flaticon-381-controls-3</span></a></li>
            <li><a><i class="flaticon-381-controls-4"></i><span>flaticon-381-controls-4</span></a></li>
            <li><a><i class="flaticon-381-controls-5"></i><span>flaticon-381-controls-5</span></a></li>
            <li><a><i class="flaticon-381-controls-6"></i><span>flaticon-381-controls-6</span></a></li>
            <li><a><i class="flaticon-381-controls-7"></i><span>flaticon-381-controls-7</span></a></li>
            <li><a><i class="flaticon-381-controls-8"></i><span>flaticon-381-controls-8</span></a></li>
            <li><a><i class="flaticon-381-controls-9"></i><span>flaticon-381-controls-9</span></a></li>
            <li><a><i class="flaticon-381-database"></i><span>flaticon-381-database</span></a></li>
            <li><a><i class="flaticon-381-database-1"></i><span>flaticon-381-database-1</span></a></li>
            <li><a><i class="flaticon-381-database-2"></i><span>flaticon-381-database-2</span></a></li>
            <li><a><i class="flaticon-381-diamond"></i><span>flaticon-381-diamond</span></a></li>
            <li><a><i class="flaticon-381-diploma"></i><span>flaticon-381-diploma</span></a></li>
            <li><a><i class="flaticon-381-dislike"></i><span>flaticon-381-dislike</span></a></li>
            <li><a><i class="flaticon-381-divide"></i><span>flaticon-381-divide</span></a></li>
            <li><a><i class="flaticon-381-division"></i><span>flaticon-381-division</span></a></li>
            <li><a><i class="flaticon-381-division-1"></i><span>flaticon-381-division-1</span></a></li>
            <li><a><i class="flaticon-381-download"></i><span>flaticon-381-download</span></a></li>
            <li><a><i class="flaticon-381-earth-globe"></i><span>flaticon-381-earth-globe</span></a></li>
            <li><a><i class="flaticon-381-earth-globe-1"></i><span>flaticon-381-earth-globe-1</span></a></li>
            <li><a><i class="flaticon-381-edit"></i><span>flaticon-381-edit</span></a></li>
            <li><a><i class="flaticon-381-edit-1"></i><span>flaticon-381-edit-1</span></a></li>
            <li><a><i class="flaticon-381-eject"></i><span>flaticon-381-eject</span></a></li>
            <li><a><i class="flaticon-381-eject-1"></i><span>flaticon-381-eject-1</span></a></li>
            <li><a><i class="flaticon-381-enter"></i><span>flaticon-381-enter</span></a></li>
            <li><a><i class="flaticon-381-equal"></i><span>flaticon-381-equal</span></a></li>
            <li><a><i class="flaticon-381-equal-1"></i><span>flaticon-381-equal-1</span></a></li>
            <li><a><i class="flaticon-381-equal-2"></i><span>flaticon-381-equal-2</span></a></li>
            <li><a><i class="flaticon-381-error"></i><span>flaticon-381-error</span></a></li>
            <li><a><i class="flaticon-381-exit"></i><span>flaticon-381-exit</span></a></li>
            <li><a><i class="flaticon-381-exit-1"></i><span>flaticon-381-exit-1</span></a></li>
            <li><a><i class="flaticon-381-exit-2"></i><span>flaticon-381-exit-2</span></a></li>
            <li><a><i class="flaticon-381-fast-forward"></i><span>flaticon-381-fast-forward</span></a></li>
            <li><a><i class="flaticon-381-fast-forward-1"></i><span>flaticon-381-fast-forward-1</span></a></li>
            <li><a><i class="flaticon-381-file"></i><span>flaticon-381-file</span></a></li>
            <li><a><i class="flaticon-381-file-1"></i><span>flaticon-381-file-1</span></a></li>
            <li><a><i class="flaticon-381-file-2"></i><span>flaticon-381-file-2</span></a></li>
            <li><a><i class="flaticon-381-film-strip"></i><span>flaticon-381-film-strip</span></a></li>
            <li><a><i class="flaticon-381-film-strip-1"></i><span>flaticon-381-film-strip-1</span></a></li>
            <li><a><i class="flaticon-381-fingerprint"></i><span>flaticon-381-fingerprint</span></a></li>
            <li><a><i class="flaticon-381-flag"></i><span>flaticon-381-flag</span></a></li>
            <li><a><i class="flaticon-381-flag-1"></i><span>flaticon-381-flag-1</span></a></li>
            <li><a><i class="flaticon-381-flag-2"></i><span>flaticon-381-flag-2</span></a></li>
            <li><a><i class="flaticon-381-flag-3"></i><span>flaticon-381-flag-3</span></a></li>
            <li><a><i class="flaticon-381-flag-4"></i><span>flaticon-381-flag-4</span></a></li>
            <li><a><i class="flaticon-381-focus"></i><span>flaticon-381-focus</span></a></li>
            <li><a><i class="flaticon-381-folder"></i><span>flaticon-381-folder</span></a></li>
            <li><a><i class="flaticon-381-folder-1"></i><span>flaticon-381-folder-1</span></a></li>
            <li><a><i class="flaticon-381-folder-10"></i><span>flaticon-381-folder-10</span></a></li>
            <li><a><i class="flaticon-381-folder-11"></i><span>flaticon-381-folder-11</span></a></li>
            <li><a><i class="flaticon-381-folder-12"></i><span>flaticon-381-folder-12</span></a></li>
            <li><a><i class="flaticon-381-folder-13"></i><span>flaticon-381-folder-13</span></a></li>
            <li><a><i class="flaticon-381-folder-14"></i><span>flaticon-381-folder-14</span></a></li>
            <li><a><i class="flaticon-381-folder-15"></i><span>flaticon-381-folder-15</span></a></li>
            <li><a><i class="flaticon-381-folder-16"></i><span>flaticon-381-folder-16</span></a></li>
            <li><a><i class="flaticon-381-folder-17"></i><span>flaticon-381-folder-17</span></a></li>
            <li><a><i class="flaticon-381-folder-18"></i><span>flaticon-381-folder-18</span></a></li>
            <li><a><i class="flaticon-381-folder-19"></i><span>flaticon-381-folder-19</span></a></li>
            <li><a><i class="flaticon-381-folder-2"></i><span>flaticon-381-folder-2</span></a></li>
            <li><a><i class="flaticon-381-folder-3"></i><span>flaticon-381-folder-3</span></a></li>
            <li><a><i class="flaticon-381-folder-4"></i><span>flaticon-381-folder-4</span></a></li>
            <li><a><i class="flaticon-381-folder-5"></i><span>flaticon-381-folder-5</span></a></li>
            <li><a><i class="flaticon-381-folder-6"></i><span>flaticon-381-folder-6</span></a></li>
            <li><a><i class="flaticon-381-folder-7"></i><span>flaticon-381-folder-7</span></a></li>
            <li><a><i class="flaticon-381-folder-8"></i><span>flaticon-381-folder-8</span></a></li>
            <li><a><i class="flaticon-381-folder-9"></i><span>flaticon-381-folder-9</span></a></li>
            <li><a><i class="flaticon-381-forbidden"></i><span>flaticon-381-forbidden</span></a></li>
            <li><a><i class="flaticon-381-funnel"></i><span>flaticon-381-funnel</span></a></li>
            <li><a><i class="flaticon-381-gift"></i><span>flaticon-381-gift</span></a></li>
            <li><a><i class="flaticon-381-heart"></i><span>flaticon-381-heart</span></a></li>
            <li><a><i class="flaticon-381-heart-1"></i><span>flaticon-381-heart-1</span></a></li>
            <li><a><i class="flaticon-381-help"></i><span>flaticon-381-help</span></a></li>
            <li><a><i class="flaticon-381-help-1"></i><span>flaticon-381-help-1</span></a></li>
            <li><a><i class="flaticon-381-hide"></i><span>flaticon-381-hide</span></a></li>
            <li><a><i class="flaticon-381-high-volume"></i><span>flaticon-381-high-volume</span></a></li>
            <li><a><i class="flaticon-381-home"></i><span>flaticon-381-home</span></a></li>
            <li><a><i class="flaticon-381-home-1"></i><span>flaticon-381-home-1</span></a></li>
            <li><a><i class="flaticon-381-home-2"></i><span>flaticon-381-home-2</span></a></li>
            <li><a><i class="flaticon-381-home-3"></i><span>flaticon-381-home-3</span></a></li>
            <li><a><i class="flaticon-381-hourglass"></i><span>flaticon-381-hourglass</span></a></li>
            <li><a><i class="flaticon-381-hourglass-1"></i><span>flaticon-381-hourglass-1</span></a></li>
            <li><a><i class="flaticon-381-hourglass-2"></i><span>flaticon-381-hourglass-2</span></a></li>
            <li><a><i class="flaticon-381-id-card"></i><span>flaticon-381-id-card</span></a></li>
            <li><a><i class="flaticon-381-id-card-1"></i><span>flaticon-381-id-card-1</span></a></li>
            <li><a><i class="flaticon-381-id-card-2"></i><span>flaticon-381-id-card-2</span></a></li>
            <li><a><i class="flaticon-381-id-card-3"></i><span>flaticon-381-id-card-3</span></a></li>
            <li><a><i class="flaticon-381-id-card-4"></i><span>flaticon-381-id-card-4</span></a></li>
            <li><a><i class="flaticon-381-id-card-5"></i><span>flaticon-381-id-card-5</span></a></li>
            <li><a><i class="flaticon-381-idea"></i><span>flaticon-381-idea</span></a></li>
            <li><a><i class="flaticon-381-incoming-call"></i><span>flaticon-381-incoming-call</span></a></li>
            <li><a><i class="flaticon-381-infinity"></i><span>flaticon-381-infinity</span></a></li>
            <li><a><i class="flaticon-381-internet"></i><span>flaticon-381-internet</span></a></li>
            <li><a><i class="flaticon-381-key"></i><span>flaticon-381-key</span></a></li>
            <li><a><i class="flaticon-381-knob"></i><span>flaticon-381-knob</span></a></li>
            <li><a><i class="flaticon-381-knob-1"></i><span>flaticon-381-knob-1</span></a></li>
            <li><a><i class="flaticon-381-layer"></i><span>flaticon-381-layer</span></a></li>
            <li><a><i class="flaticon-381-layer-1"></i><span>flaticon-381-layer-1</span></a></li>
            <li><a><i class="flaticon-381-like"></i><span>flaticon-381-like</span></a></li>
            <li><a><i class="flaticon-381-link"></i><span>flaticon-381-link</span></a></li>
            <li><a><i class="flaticon-381-link-1"></i><span>flaticon-381-link-1</span></a></li>
            <li><a><i class="flaticon-381-list"></i><span>flaticon-381-list</span></a></li>
            <li><a><i class="flaticon-381-list-1"></i><span>flaticon-381-list-1</span></a></li>
            <li><a><i class="flaticon-381-location"></i><span>flaticon-381-location</span></a></li>
            <li><a><i class="flaticon-381-location-1"></i><span>flaticon-381-location-1</span></a></li>
            <li><a><i class="flaticon-381-location-2"></i><span>flaticon-381-location-2</span></a></li>
            <li><a><i class="flaticon-381-location-3"></i><span>flaticon-381-location-3</span></a></li>
            <li><a><i class="flaticon-381-location-4"></i><span>flaticon-381-location-4</span></a></li>
            <li><a><i class="flaticon-381-locations"></i><span>flaticon-381-locations</span></a></li>
            <li><a><i class="flaticon-381-lock"></i><span>flaticon-381-lock</span></a></li>
            <li><a><i class="flaticon-381-lock-1"></i><span>flaticon-381-lock-1</span></a></li>
            <li><a><i class="flaticon-381-lock-2"></i><span>flaticon-381-lock-2</span></a></li>
            <li><a><i class="flaticon-381-lock-3"></i><span>flaticon-381-lock-3</span></a></li>
            <li><a><i class="flaticon-381-low-volume"></i><span>flaticon-381-low-volume</span></a></li>
            <li><a><i class="flaticon-381-low-volume-1"></i><span>flaticon-381-low-volume-1</span></a></li>
            <li><a><i class="flaticon-381-low-volume-2"></i><span>flaticon-381-low-volume-2</span></a></li>
            <li><a><i class="flaticon-381-low-volume-3"></i><span>flaticon-381-low-volume-3</span></a></li>
            <li><a><i class="flaticon-381-magic-wand"></i><span>flaticon-381-magic-wand</span></a></li>
            <li><a><i class="flaticon-381-magnet"></i><span>flaticon-381-magnet</span></a></li>
            <li><a><i class="flaticon-381-magnet-1"></i><span>flaticon-381-magnet-1</span></a></li>
            <li><a><i class="flaticon-381-magnet-2"></i><span>flaticon-381-magnet-2</span></a></li>
            <li><a><i class="flaticon-381-map"></i><span>flaticon-381-map</span></a></li>
            <li><a><i class="flaticon-381-map-1"></i><span>flaticon-381-map-1</span></a></li>
            <li><a><i class="flaticon-381-map-2"></i><span>flaticon-381-map-2</span></a></li>
            <li><a><i class="flaticon-381-menu"></i><span>flaticon-381-menu</span></a></li>
            <li><a><i class="flaticon-381-menu-1"></i><span>flaticon-381-menu-1</span></a></li>
            <li><a><i class="flaticon-381-menu-2"></i><span>flaticon-381-menu-2</span></a></li>
            <li><a><i class="flaticon-381-menu-3"></i><span>flaticon-381-menu-3</span></a></li>
            <li><a><i class="flaticon-381-microphone"></i><span>flaticon-381-microphone</span></a></li>
            <li><a><i class="flaticon-381-microphone-1"></i><span>flaticon-381-microphone-1</span></a></li>
            <li><a><i class="flaticon-381-more"></i><span>flaticon-381-more</span></a></li>
            <li><a><i class="flaticon-381-more-1"></i><span>flaticon-381-more-1</span></a></li>
            <li><a><i class="flaticon-381-more-2"></i><span>flaticon-381-more-2</span></a></li>
            <li><a><i class="flaticon-381-multiply"></i><span>flaticon-381-multiply</span></a></li>
            <li><a><i class="flaticon-381-multiply-1"></i><span>flaticon-381-multiply-1</span></a></li>
            <li><a><i class="flaticon-381-music-album"></i><span>flaticon-381-music-album</span></a></li>
            <li><a><i class="flaticon-381-mute"></i><span>flaticon-381-mute</span></a></li>
            <li><a><i class="flaticon-381-mute-1"></i><span>flaticon-381-mute-1</span></a></li>
            <li><a><i class="flaticon-381-mute-2"></i><span>flaticon-381-mute-2</span></a></li>
            <li><a><i class="flaticon-381-network"></i><span>flaticon-381-network</span></a></li>
            <li><a><i class="flaticon-381-network-1"></i><span>flaticon-381-network-1</span></a></li>
            <li><a><i class="flaticon-381-network-2"></i><span>flaticon-381-network-2</span></a></li>
            <li><a><i class="flaticon-381-network-3"></i><span>flaticon-381-network-3</span></a></li>
            <li><a><i class="flaticon-381-networking"></i><span>flaticon-381-networking</span></a></li>
            <li><a><i class="flaticon-381-networking-1"></i><span>flaticon-381-networking-1</span></a></li>
            <li><a><i class="flaticon-381-news"></i><span>flaticon-381-news</span></a></li>
            <li><a><i class="flaticon-381-newspaper"></i><span>flaticon-381-newspaper</span></a></li>
            <li><a><i class="flaticon-381-next"></i><span>flaticon-381-next</span></a></li>
            <li><a><i class="flaticon-381-next-1"></i><span>flaticon-381-next-1</span></a></li>
            <li><a><i class="flaticon-381-note"></i><span>flaticon-381-note</span></a></li>
            <li><a><i class="flaticon-381-notebook"></i><span>flaticon-381-notebook</span></a></li>
            <li><a><i class="flaticon-381-notebook-1"></i><span>flaticon-381-notebook-1</span></a></li>
            <li><a><i class="flaticon-381-notebook-2"></i><span>flaticon-381-notebook-2</span></a></li>
            <li><a><i class="flaticon-381-notebook-3"></i><span>flaticon-381-notebook-3</span></a></li>
            <li><a><i class="flaticon-381-notebook-4"></i><span>flaticon-381-notebook-4</span></a></li>
            <li><a><i class="flaticon-381-notebook-5"></i><span>flaticon-381-notebook-5</span></a></li>
            <li><a><i class="flaticon-381-notepad"></i><span>flaticon-381-notepad</span></a></li>
            <li><a><i class="flaticon-381-notepad-1"></i><span>flaticon-381-notepad-1</span></a></li>
            <li><a><i class="flaticon-381-notepad-2"></i><span>flaticon-381-notepad-2</span></a></li>
            <li><a><i class="flaticon-381-notification"></i><span>flaticon-381-notification</span></a></li>
            <li><a><i class="flaticon-381-off"></i><span>flaticon-381-off</span></a></li>
            <li><a><i class="flaticon-381-on"></i><span>flaticon-381-on</span></a></li>
            <li><a><i class="flaticon-381-pad"></i><span>flaticon-381-pad</span></a></li>
            <li><a><i class="flaticon-381-padlock"></i><span>flaticon-381-padlock</span></a></li>
            <li><a><i class="flaticon-381-padlock-1"></i><span>flaticon-381-padlock-1</span></a></li>
            <li><a><i class="flaticon-381-padlock-2"></i><span>flaticon-381-padlock-2</span></a></li>
            <li><a><i class="flaticon-381-panel"></i><span>flaticon-381-panel</span></a></li>
            <li><a><i class="flaticon-381-panel-1"></i><span>flaticon-381-panel-1</span></a></li>
            <li><a><i class="flaticon-381-panel-2"></i><span>flaticon-381-panel-2</span></a></li>
            <li><a><i class="flaticon-381-panel-3"></i><span>flaticon-381-panel-3</span></a></li>
            <li><a><i class="flaticon-381-paperclip"></i><span>flaticon-381-paperclip</span></a></li>
            <li><a><i class="flaticon-381-pause"></i><span>flaticon-381-pause</span></a></li>
            <li><a><i class="flaticon-381-pause-1"></i><span>flaticon-381-pause-1</span></a></li>
            <li><a><i class="flaticon-381-pencil"></i><span>flaticon-381-pencil</span></a></li>
            <li><a><i class="flaticon-381-percentage"></i><span>flaticon-381-percentage</span></a></li>
            <li><a><i class="flaticon-381-percentage-1"></i><span>flaticon-381-percentage-1</span></a></li>
            <li><a><i class="flaticon-381-perspective"></i><span>flaticon-381-perspective</span></a></li>
            <li><a><i class="flaticon-381-phone-call"></i><span>flaticon-381-phone-call</span></a></li>
            <li><a><i class="flaticon-381-photo"></i><span>flaticon-381-photo</span></a></li>
            <li><a><i class="flaticon-381-photo-camera"></i><span>flaticon-381-photo-camera</span></a></li>
            <li><a><i class="flaticon-381-photo-camera-1"></i><span>flaticon-381-photo-camera-1</span></a></li>
            <li><a><i class="flaticon-381-picture"></i><span>flaticon-381-picture</span></a></li>
            <li><a><i class="flaticon-381-picture-1"></i><span>flaticon-381-picture-1</span></a></li>
            <li><a><i class="flaticon-381-picture-2"></i><span>flaticon-381-picture-2</span></a></li>
            <li><a><i class="flaticon-381-pin"></i><span>flaticon-381-pin</span></a></li>
            <li><a><i class="flaticon-381-play-button"></i><span>flaticon-381-play-button</span></a></li>
            <li><a><i class="flaticon-381-play-button-1"></i><span>flaticon-381-play-button-1</span></a></li>
            <li><a><i class="flaticon-381-plus"></i><span>flaticon-381-plus</span></a></li>
            <li><a><i class="flaticon-381-presentation"></i><span>flaticon-381-presentation</span></a></li>
            <li><a><i class="flaticon-381-price-tag"></i><span>flaticon-381-price-tag</span></a></li>
            <li><a><i class="flaticon-381-print"></i><span>flaticon-381-print</span></a></li>
            <li><a><i class="flaticon-381-print-1"></i><span>flaticon-381-print-1</span></a></li>
            <li><a><i class="flaticon-381-privacy"></i><span>flaticon-381-privacy</span></a></li>
            <li><a><i class="flaticon-381-promotion"></i><span>flaticon-381-promotion</span></a></li>
            <li><a><i class="flaticon-381-promotion-1"></i><span>flaticon-381-promotion-1</span></a></li>
            <li><a><i class="flaticon-381-push-pin"></i><span>flaticon-381-push-pin</span></a></li>
            <li><a><i class="flaticon-381-quaver"></i><span>flaticon-381-quaver</span></a></li>
            <li><a><i class="flaticon-381-quaver-1"></i><span>flaticon-381-quaver-1</span></a></li>
            <li><a><i class="flaticon-381-radar"></i><span>flaticon-381-radar</span></a></li>
            <li><a><i class="flaticon-381-reading"></i><span>flaticon-381-reading</span></a></li>
            <li><a><i class="flaticon-381-receive"></i><span>flaticon-381-receive</span></a></li>
            <li><a><i class="flaticon-381-record"></i><span>flaticon-381-record</span></a></li>
            <li><a><i class="flaticon-381-repeat"></i><span>flaticon-381-repeat</span></a></li>
            <li><a><i class="flaticon-381-repeat-1"></i><span>flaticon-381-repeat-1</span></a></li>
            <li><a><i class="flaticon-381-resume"></i><span>flaticon-381-resume</span></a></li>
            <li><a><i class="flaticon-381-rewind"></i><span>flaticon-381-rewind</span></a></li>
            <li><a><i class="flaticon-381-rewind-1"></i><span>flaticon-381-rewind-1</span></a></li>
            <li><a><i class="flaticon-381-ring"></i><span>flaticon-381-ring</span></a></li>
            <li><a><i class="flaticon-381-ring-1"></i><span>flaticon-381-ring-1</span></a></li>
            <li><a><i class="flaticon-381-rotate"></i><span>flaticon-381-rotate</span></a></li>
            <li><a><i class="flaticon-381-rotate-1"></i><span>flaticon-381-rotate-1</span></a></li>
            <li><a><i class="flaticon-381-route"></i><span>flaticon-381-route</span></a></li>
            <li><a><i class="flaticon-381-save"></i><span>flaticon-381-save</span></a></li>
            <li><a><i class="flaticon-381-search"></i><span>flaticon-381-search</span></a></li>
            <li><a><i class="flaticon-381-search-1"></i><span>flaticon-381-search-1</span></a></li>
            <li><a><i class="flaticon-381-search-2"></i><span>flaticon-381-search-2</span></a></li>
            <li><a><i class="flaticon-381-search-3"></i><span>flaticon-381-search-3</span></a></li>
            <li><a><i class="flaticon-381-send"></i><span>flaticon-381-send</span></a></li>
            <li><a><i class="flaticon-381-send-1"></i><span>flaticon-381-send-1</span></a></li>
            <li><a><i class="flaticon-381-send-2"></i><span>flaticon-381-send-2</span></a></li>
            <li><a><i class="flaticon-381-settings"></i><span>flaticon-381-settings</span></a></li>
            <li><a><i class="flaticon-381-settings-1"></i><span>flaticon-381-settings-1</span></a></li>
            <li><a><i class="flaticon-381-settings-2"></i><span>flaticon-381-settings-2</span></a></li>
            <li><a><i class="flaticon-381-settings-3"></i><span>flaticon-381-settings-3</span></a></li>
            <li><a><i class="flaticon-381-settings-4"></i><span>flaticon-381-settings-4</span></a></li>
            <li><a><i class="flaticon-381-settings-5"></i><span>flaticon-381-settings-5</span></a></li>
            <li><a><i class="flaticon-381-settings-6"></i><span>flaticon-381-settings-6</span></a></li>
            <li><a><i class="flaticon-381-settings-7"></i><span>flaticon-381-settings-7</span></a></li>
            <li><a><i class="flaticon-381-settings-8"></i><span>flaticon-381-settings-8</span></a></li>
            <li><a><i class="flaticon-381-settings-9"></i><span>flaticon-381-settings-9</span></a></li>
            <li><a><i class="flaticon-381-share"></i><span>flaticon-381-share</span></a></li>
            <li><a><i class="flaticon-381-share-1"></i><span>flaticon-381-share-1</span></a></li>
            <li><a><i class="flaticon-381-share-2"></i><span>flaticon-381-share-2</span></a></li>
            <li><a><i class="flaticon-381-shuffle"></i><span>flaticon-381-shuffle</span></a></li>
            <li><a><i class="flaticon-381-shuffle-1"></i><span>flaticon-381-shuffle-1</span></a></li>
            <li><a><i class="flaticon-381-shut-down"></i><span>flaticon-381-shut-down</span></a></li>
            <li><a><i class="flaticon-381-silence"></i><span>flaticon-381-silence</span></a></li>
            <li><a><i class="flaticon-381-silent"></i><span>flaticon-381-silent</span></a></li>
            <li><a><i class="flaticon-381-smartphone"></i><span>flaticon-381-smartphone</span></a></li>
            <li><a><i class="flaticon-381-smartphone-1"></i><span>flaticon-381-smartphone-1</span></a></li>
            <li><a><i class="flaticon-381-smartphone-2"></i><span>flaticon-381-smartphone-2</span></a></li>
            <li><a><i class="flaticon-381-smartphone-3"></i><span>flaticon-381-smartphone-3</span></a></li>
            <li><a><i class="flaticon-381-smartphone-4"></i><span>flaticon-381-smartphone-4</span></a></li>
            <li><a><i class="flaticon-381-smartphone-5"></i><span>flaticon-381-smartphone-5</span></a></li>
            <li><a><i class="flaticon-381-smartphone-6"></i><span>flaticon-381-smartphone-6</span></a></li>
            <li><a><i class="flaticon-381-smartphone-7"></i><span>flaticon-381-smartphone-7</span></a></li>
            <li><a><i class="flaticon-381-speaker"></i><span>flaticon-381-speaker</span></a></li>
            <li><a><i class="flaticon-381-speedometer"></i><span>flaticon-381-speedometer</span></a></li>
            <li><a><i class="flaticon-381-spotlight"></i><span>flaticon-381-spotlight</span></a></li>
            <li><a><i class="flaticon-381-star"></i><span>flaticon-381-star</span></a></li>
            <li><a><i class="flaticon-381-star-1"></i><span>flaticon-381-star-1</span></a></li>
            <li><a><i class="flaticon-381-stop"></i><span>flaticon-381-stop</span></a></li>
            <li><a><i class="flaticon-381-stop-1"></i><span>flaticon-381-stop-1</span></a></li>
            <li><a><i class="flaticon-381-stopclock"></i><span>flaticon-381-stopclock</span></a></li>
            <li><a><i class="flaticon-381-stopwatch"></i><span>flaticon-381-stopwatch</span></a></li>
            <li><a><i class="flaticon-381-stopwatch-1"></i><span>flaticon-381-stopwatch-1</span></a></li>
            <li><a><i class="flaticon-381-stopwatch-2"></i><span>flaticon-381-stopwatch-2</span></a></li>
            <li><a><i class="flaticon-381-substract"></i><span>flaticon-381-substract</span></a></li>
            <li><a><i class="flaticon-381-substract-1"></i><span>flaticon-381-substract-1</span></a></li>
            <li><a><i class="flaticon-381-substract-2"></i><span>flaticon-381-substract-2</span></a></li>
            <li><a><i class="flaticon-381-success"></i><span>flaticon-381-success</span></a></li>
            <li><a><i class="flaticon-381-success-1"></i><span>flaticon-381-success-1</span></a></li>
            <li><a><i class="flaticon-381-success-2"></i><span>flaticon-381-success-2</span></a></li>
            <li><a><i class="flaticon-381-sunglasses"></i><span>flaticon-381-sunglasses</span></a></li>
            <li><a><i class="flaticon-381-switch"></i><span>flaticon-381-switch</span></a></li>
            <li><a><i class="flaticon-381-switch-1"></i><span>flaticon-381-switch-1</span></a></li>
            <li><a><i class="flaticon-381-switch-2"></i><span>flaticon-381-switch-2</span></a></li>
            <li><a><i class="flaticon-381-switch-3"></i><span>flaticon-381-switch-3</span></a></li>
            <li><a><i class="flaticon-381-switch-4"></i><span>flaticon-381-switch-4</span></a></li>
            <li><a><i class="flaticon-381-switch-5"></i><span>flaticon-381-switch-5</span></a></li>
            <li><a><i class="flaticon-381-sync"></i><span>flaticon-381-sync</span></a></li>
            <li><a><i class="flaticon-381-tab"></i><span>flaticon-381-tab</span></a></li>
            <li><a><i class="flaticon-381-target"></i><span>flaticon-381-target</span></a></li>
            <li><a><i class="flaticon-381-television"></i><span>flaticon-381-television</span></a></li>
            <li><a><i class="flaticon-381-time"></i><span>flaticon-381-time</span></a></li>
            <li><a><i class="flaticon-381-transfer"></i><span>flaticon-381-transfer</span></a></li>
            <li><a><i class="flaticon-381-trash"></i><span>flaticon-381-trash</span></a></li>
            <li><a><i class="flaticon-381-trash-1"></i><span>flaticon-381-trash-1</span></a></li>
            <li><a><i class="flaticon-381-trash-2"></i><span>flaticon-381-trash-2</span></a></li>
            <li><a><i class="flaticon-381-trash-3"></i><span>flaticon-381-trash-3</span></a></li>
            <li><a><i class="flaticon-381-turn-off"></i><span>flaticon-381-turn-off</span></a></li>
            <li><a><i class="flaticon-381-umbrella"></i><span>flaticon-381-umbrella</span></a></li>
            <li><a><i class="flaticon-381-unlocked"></i><span>flaticon-381-unlocked</span></a></li>
            <li><a><i class="flaticon-381-unlocked-1"></i><span>flaticon-381-unlocked-1</span></a></li>
            <li><a><i class="flaticon-381-unlocked-2"></i><span>flaticon-381-unlocked-2</span></a></li>
            <li><a><i class="flaticon-381-unlocked-3"></i><span>flaticon-381-unlocked-3</span></a></li>
            <li><a><i class="flaticon-381-unlocked-4"></i><span>flaticon-381-unlocked-4</span></a></li>
            <li><a><i class="flaticon-381-upload"></i><span>flaticon-381-upload</span></a></li>
            <li><a><i class="flaticon-381-upload-1"></i><span>flaticon-381-upload-1</span></a></li>
            <li><a><i class="flaticon-381-user"></i><span>flaticon-381-user</span></a></li>
            <li><a><i class="flaticon-381-user-1"></i><span>flaticon-381-user-1</span></a></li>
            <li><a><i class="flaticon-381-user-2"></i><span>flaticon-381-user-2</span></a></li>
            <li><a><i class="flaticon-381-user-3"></i><span>flaticon-381-user-3</span></a></li>
            <li><a><i class="flaticon-381-user-4"></i><span>flaticon-381-user-4</span></a></li>
            <li><a><i class="flaticon-381-user-5"></i><span>flaticon-381-user-5</span></a></li>
            <li><a><i class="flaticon-381-user-6"></i><span>flaticon-381-user-6</span></a></li>
            <li><a><i class="flaticon-381-user-7"></i><span>flaticon-381-user-7</span></a></li>
            <li><a><i class="flaticon-381-user-8"></i><span>flaticon-381-user-8</span></a></li>
            <li><a><i class="flaticon-381-user-9"></i><span>flaticon-381-user-9</span></a></li>
            <li><a><i class="flaticon-381-video-camera"></i><span>flaticon-381-video-camera</span></a></li>
            <li><a><i class="flaticon-381-video-clip"></i><span>flaticon-381-video-clip</span></a></li>
            <li><a><i class="flaticon-381-video-player"></i><span>flaticon-381-video-player</span></a></li>
            <li><a><i class="flaticon-381-video-player-1"></i><span>flaticon-381-video-player-1</span></a></li>
            <li><a><i class="flaticon-381-view"></i><span>flaticon-381-view</span></a></li>
            <li><a><i class="flaticon-381-view-1"></i><span>flaticon-381-view-1</span></a></li>
            <li><a><i class="flaticon-381-view-2"></i><span>flaticon-381-view-2</span></a></li>
            <li><a><i class="flaticon-381-volume"></i><span>flaticon-381-volume</span></a></li>
            <li><a><i class="flaticon-381-warning"></i><span>flaticon-381-warning</span></a></li>
            <li><a><i class="flaticon-381-warning-1"></i><span>flaticon-381-warning-1</span></a></li>
            <li><a><i class="flaticon-381-wifi"></i><span>flaticon-381-wifi</span></a></li>
            <li><a><i class="flaticon-381-wifi-1"></i><span>flaticon-381-wifi-1</span></a></li>
            <li><a><i class="flaticon-381-wifi-2"></i><span>flaticon-381-wifi-2</span></a></li>
            <li><a><i class="flaticon-381-windows"></i><span>flaticon-381-windows</span></a></li>
            <li><a><i class="flaticon-381-windows-1"></i><span>flaticon-381-windows-1</span></a></li>
            <li><a><i class="flaticon-381-zoom-in"></i><span>flaticon-381-zoom-in</span></a></li>
            <li><a><i class="flaticon-381-zoom-out"></i><span>flaticon-381-zoom-out</span></a></li>
        </ul>				
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
span {
    color: black;
    margin: 15px;
    font-size: 17pt;
    background-color:rgb(0, 217, 255);
}
i {
    font-size: 20pt;
    color: white;
    background-color:blue;
    padding: 5px;
    margin: 15px;
}
</style>