<template>
    <div class="row" id="wizard-teste-window">
        <!-- Novo Tipo e Novo Item -->
        <Wizard :tipo="novoTipo" 
            :item="novoItem"
        ></Wizard>

        <!-- Tipo Existente e Novo Item -->
        <!-- <Wizard :tipo="editedTipo" 
            :item="novoItem" 
            @save="saveTipo(editedTipo)" @close="close()"
        ></Wizard> -->

        <!-- Tipo Existente e Item Existente -->
        <!-- <Wizard :tipo="editedTipo" 
            :item="editedItem" 
            @save="saveTipo(editedTipo)" @close="close()"
        ></Wizard> -->

    </div>
</template>

<script>
import Wizard from './Wizard.vue'

export default {
    name: 'Wizard',
    props: {
    },
    components: { Wizard },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            editedTipo: {
                idtiposolicitacao: 1,
                idhotlinesetores: 2,
                titulo: 'Acordar no Horário',
                subtitulo: '',
                solicitacoesmultiplas: false,
                confirmacaoindividual: false,
                ativo: true,
                idioma: 'pt-BR',
                idusuario: 52
            },
            novoTipo: {
                idtiposolicitacao: 0,
                idhotlinesetores: 2,
                titulo: 'Acordar no Horário',
                subtitulo: '',
                solicitacoesmultiplas: false,
                confirmacaoindividual: false,
                ativo: true,
                idioma: 'pt-BR',
                idusuario: 52
            },
            editedItem: {
                idtiposolicitacaoitem: 26,
                idtiposolicitacao: 1,
                tipo: '3',
                titulo: 'Acordar no Horário',
                descricao: '',
                infoadicional: true,
                infoalerta: null,
                valordefault: 0,
                chaveativadora: true,
                incluirnaagenda: false,
                horarioatendimento: false,
                horariodisp: '',
                idioma: 'pt-BR'
            },
            novoItem: {
                idtiposolicitacaoitem: 26,
                idtiposolicitacao: 1,
                tipo: '3',
                titulo: 'Acordar no Horário',
                descricao: '',
                infoadicional: true,
                infoalerta: null,
                valordefault: 0,
                chaveativadora: true,
                incluirnaagenda: false,
                horarioatendimento: false,
                horariodisp: '',
                idioma: 'pt-BR'
            }
        }
    },
    methods: {
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },        
    },
    computed: {
    },
    mounted() {

    }
}
</script>

<style>

.es-wizard {
    width: 80vw;
    height: 80vh;
    border-radius: 1em;
    background-color: rgb(19, 64, 141);
    /* opacity: 0.7;  */
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;    
    margin: auto;
    z-index: 100;
}
.es-content {
    opacity: 1.0!important;
    background-color: white;
    border-radius: 1em;
    padding: 1.5em;
    margin: 1em;
    height: 97%;
    display: flex;
    flex-direction: column;
    z-index: 10000;
}
.es-title {
    height: 10%;
}
.es-step {
    height: 80%;
    overflow: hidden;
    overflow-y: auto;
}
.es-buttons {
    height: 10%;
    width: 100%;
    flex-direction: row;
    border-radius: 1em;
    align-items: center;
    align-content: space-between;
    flex-wrap: nowrap;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
}
.es-buttons button {
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.5em;
}
.es-btn-primary{
    color:white;
    background-color: rgb(10,195,242);
}
.es-btn-primary:hover{
    color:white!important;
    background-color: rgb(17, 148, 180);
}
.es-btn-secondary{
    width: 80px;
    color:white;
    background-color: rgb(42,82,206);
}
.es-btn-secondary:hover{
    color:white;
    background-color: rgb(25, 50, 126);
}
.es-btn-success{
    width: 100px;
    color:white;
    background-color: rgb(60, 29, 134);
}
.es-btn-success:hover{
    color:white;
    background-color: rgb(44, 27, 82);;
}
.es-btn-close{
    width: 100px;
    color:white;
    background-color: rgb(119, 73, 224)
}
.es-btn-close:hover{
    color:white;
    background-color: rgb(86, 52, 165)
}
.es-text {
    padding: 1em;
    border-radius: 1em;
}
.es-imagem-wizard {
    display: flex;
    direction: row-reverse;
    flex-wrap: wrap;
    align-content: space-around;
}
</style>