<template>
	<!-- Relatório N -->
	<div class="col-xl-12">
		<div class="card">
			<!-- Filtro de Pesquisa -->
			<div class="card-header">

			</div>
			<!-- Resultado de Pesquisa -->
			<div class="card-body">
				<div class="table-responsive">
					<table class="table table-responsive-md">
						<thead>
							<tr>
								<th v-for="(cab,index) in cabecalhos" :key="index"
									style="text-align:center;"
								><strong>{{cab.nome}}</strong>
									<button type="button" class="btn-rel btn-warning light"
										@click="ordenar(cab)">
										<div :id="'div'+relatorio.codigo+'_'+cab.campo" state="neutral">&updownarrow;</div>
									</button>
								</th>
							</tr>
						</thead>
						<tbody v-for="(item,index) in dados" :key="index">
							<tr>
								<td v-for="(cabecalho,index2) in cabecalhos" :key="index2"
									:style="`text-align:${cabecalhos[index2]['align']}`"
									:class="getClass(item[cabecalhos[index2]['campo']])"
								>
									<strong v-if="cabecalho.botaoDetalhe">
										<div class="botao-detalhes ponteiro" @click="$emit('abrirDetalhes',item)">Detalhes</div>
									</strong>
									<strong v-if="cabecalho.link">
										<div class="link-total ponteiro" @click="$emit('abrir',item)">{{item[cabecalhos[index2]['campo']]}}</div>
									</strong>
									<strong v-else>{{item[cabecalhos[index2]['campo']]}}</strong>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- <div class="row">
					<div class="box" style="align-self: right;">
						<button @click="gerarExcel()" type="button" class="btn btn-success mt-4" id="toastr-danger-top-right">Gerar Excel</button>
						<iframe :id="'iframe_downloader_'+this.relatorio.codigo" style="display:none;"></iframe>
					</div>																	
				</div> -->
				<div class="text-center">
					<obj-array-to-excel 
					:titulo="relatorio.titulo"
					:downloadData="dados"
					>	
					</obj-array-to-excel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment";
import ObjArrayToExcel from './ObjArrayToExcel.vue';
export default {
	name: 'RelatoriosManutencaoN',
    props: {
        relatorio: {
            type: Object,
            required: true,
        },
        dados: {
            type: Array,
            required: true,
        },
        cabecalhos: {
            type: Array,
            required: true,
        }
    },	
	data() {
		return {
			headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
					},
			datainicial: '2020-01-01',
			datafinal: '2021-12-31',
			indiceGeralAnterior: [],
			indiceGeralOrdem: '',
			orderBy: '',
			colors: [
				'#ff0000',
				'#ff1100',
				'#ff2200',
				'#ff4400',
				'#ff6600',
				'#ff8800',
				'#ff9900',
				'#ffaa00',
				'#ffcc00',
				'#ffff00',
				'#eeff00',
				'#ddff00',
				'#ccff00',
				'#bbff00',
				'#aaff00',
				'#00ff00',
				'#21df33',
				'#30cf60',
				'#35af70',
				'#408f80',
				'#445f88',
				'#440088',
				'#380076',
				'#330065',
				'#300055',
				'#250035',
				'#150025',
				'#000000'
			]
		}
	},
	watch: {
		relatorio: {
			handler() {
				this.$forceUpdate()
			},
			deep: true
		}
	},
	components : {
		ObjArrayToExcel
	},
	methods: {
		getClass(value) {
			let result = ''
			if (typeof value == 'string' && value == 'Ok') result = 'btn-success btn light sharp'
			if (typeof value == 'string' && value == 'Requisitar') result = 'btn-danger btn light sharp'
			return result
		},
		ordenar(info){
			var setaDiv = document.getElementById('div'+this.relatorio.codigo+'_'+info.campo)
			if (setaDiv) {
				for (var i=0; i < this.cabecalhos.length; i++) {
					if (this.cabecalhos[i]['campo'] != info.campo) {
						var id = 'div'+this.relatorio.codigo+'_'+this.cabecalhos[i]['campo']
						var obj = document.getElementById(id)
						if (obj) {
							obj.innerHTML = '&updownarrow;'
						}
					}
				}
				if (setaDiv.getAttribute('state')=='neutral') {
					this['indiceGeralOrdem'] = info + ' asc '
					if (info.tipo && info.tipo == 'string') {
						this.orderBy = `upper(noaccent(${info.campo})) asc `
					} else {
						this.orderBy = `${info.campo} asc `
					}
					setaDiv.setAttribute('state', 'up')
					setaDiv.innerHTML = '&uarr;'
				} else {
					if (setaDiv.getAttribute('state')=='up') {
						this['indiceGeralOrdem'] = info.campo + ' desc '
						if (info.tipo && info.tipo == 'string') {
							this.orderBy = `upper(noaccent(${info.campo})) desc `
						} else {
							this.orderBy = `${info.campo} desc `
						}
						setaDiv.setAttribute('state', 'down')
						setaDiv.innerHTML = '&darr;'
					} else {
						this['indiceGeralOrdem'] = ''
						this.orderBy = ``
						setaDiv.setAttribute('state', 'neutral')
						setaDiv.innerHTML = '&updownarrow;'
					}
				}
				this.$emit('reload',this.orderBy)
			}
		},
		goingDown(item, info){
			var result = false
			for (var i = 0; i < this.relatorio.dadosAnteriores.length; i++) {
				if (this.relatorio.dadosAnteriores[i][this.cabecalhos[0]['campo']]==item[this.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.relatorio.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore) {
							if (infoValue < infoValueBefore && infoValueBefore > 0) {
								result = true
							} else {
								result = false
							}
						} else {
							result = false
						}
					} catch (error) {
						result = false
					}
				}
			}
			return result
		},
		goingUp(item,info){
			var result = false
			for (var i = 0; i < this.relatorio.dadosAnteriores.length; i++) {
				if (this.relatorio.dadosAnteriores[i][this.cabecalhos[0]['campo']]==item[this.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.relatorio.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							if (infoValue > infoValueBefore)
								result = true
							else						
								result = false;
						} else {
							result = false
						}
					} catch (error) {
						result = false
					}
				}
			}
			return result
		},
		getDiff(item,info){
			var result = ''
			for (var i = 0; i < this.relatorio.dadosAnteriores.length; i++) {
				if (this.relatorio.dadosAnteriores[i][this.cabecalhos[0]['campo']]==item[this.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.relatorio.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							var razao = (infoValue - infoValueBefore) / infoValueBefore
							var variacao = Math.round(razao*100, 2)
							result = ' ' + variacao.toString() + '%'
						} else {
							result = ''
						}
					} catch (error) {
						result = ''
					}
				}
			}
			return result
		},
		getAnterior(item,info) {
			var result = ''
			for (var i = 0; i < this.relatorio.dadosAnteriores.length; i++) {
				if (this.relatorio.dadosAnteriores[i][this.cabecalhos[0]['campo']]==item[this.cabecalhos[0]['campo']]) {
					try {
						var infoValue = parseFloat(item[info])
						var infoValueBefore = parseFloat(this.relatorio.dadosAnteriores[i][info])
						if (infoValue != infoValueBefore && infoValueBefore > 0) {
							result = ' / '+ this.relatorio.dadosAnteriores[i][info]
						} else {
							result = ''
						}
					} catch (error) {
						result = ''
					}
				}
			}
			return result
		},
		download(url) {
			document.getElementById('iframe_downloader_'+this.relatorio.codigo).src = url
		},
		gerarExcel() {
			var plan1 = {
				nome: this.relatorio.titulo,
				titulo: `Período de ${this.relatorio.periodo.dataInicial} a ${this.relatorio.periodo.dataFinal}`,
				cabecalhos: this.cabecalhos,
				dados: this.relatorio.dados
			}
			var dtini = (moment(this.relatorio.periodo.dataInicial).subtract(12, 'months')).format('YYYY-MM-DD')
			var dtfin = (moment(this.relatorio.periodo.dataFinal).subtract(12, 'months')).format('YYYY-MM-DD')
			var plan2 = {
				nome: `${this.relatorio.titulo} - Período Anterior`,
				titulo: `Período de ${dtini} a ${dtfin}`,
				cabecalhos: this.cabecalhos,
				dados: this.relatorio.dadosAnteriores
			}
			var obj = {
				arquivo : `${this.relatorio.codigo}_${this.$store.getters.GetLoginInfo.idusuario}.xlsx`,
				planilhas : [
					plan1, plan2
				]
			}
            axios.post(CONSTANTS.API_URL + '/toexcel/fromjson',
                    obj, { headers: this.headers }).then(response => {
                if(response.status == 200) {
                    this.download(CONSTANTS.API_URL+response.data.link)
                }
            })
		},
		getColor(media) {
			var indice = Math.round((media / 5) * (this.colors.length-1))
			return `color: ${this.colors[indice]};font-size:0.85rem;font-weight:bold;`
		},
	},
	mounted() {
	}
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.btn-rel {
  display: inline-block;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}
@media (prefers-reduced-motion: reduce) {
  .btn-rel {
      transition: none; } 
}
.btn-rel:hover {
    color: #e9e7e7;
    text-decoration: none; 
}
.btn-rel:focus, .btn-rel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 47, 185, 0.25); 
}
.btn-rel.disabled, .btn-rel:disabled {
    opacity: 0.65; 
}
.link-total {
	padding: 0.5rem;
	background-color: aqua;
	border-radius: 1.5rem;
}

.botao-detalhes {
	padding: 0.5rem;
	background-color: #2c49cb;
	color: white;
	border-radius: 1.5rem;
}

.link-total:hover{
	color: white;
	background-color: #2c49cb;
}
</style>