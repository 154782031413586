<template>
    <!-- Paginação -->
    <div class="form-row pagination">
        <div class="btn" @click="ultimo()">&gt;&gt;</div>
        <div class="btn" @click="avancar()">&gt;</div>
        <div class="page">Página {{page}} de {{pageCount}}</div>
        <div class="btn" @click="recuar()">&lt;</div>
        <div class="btn" @click="primeiro()">&lt;&lt;</div>
        <div class="sel" >
            <select class="form-control-md" v-model="pageOptionIndex">
                <template v-if="pageOptions && pageOptions.length > 0">
                    <option v-for="(pageOption, index) in pageOptions" v-bind:key="index"
                        :value="index"
                        :selected="pageOptionIndex==index"
                    >{{pageOptions[index]}}
                    </option>
                </template>
            </select>
        </div>
        <div class="total">Total de registros: {{recordCount}}</div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: Number,
            required: true,
            default: 1
        },
        pageCount: {
            type: Number,
            required: true,
            default: 1
        },
        recordCount: {
            type: Number,
            required: true,
            default: 1
        },
        defaultPageOptionIndex: {
            type: Number,
            required: true,
            default: 0
        },
    },
    components: {  },
        data() {
            return {
                pageSize: 6,
                pageOptionIndex: 0,
                pageOptions: [
                    6,12,24,36,72,144,'Todas'
                ]
            }
        },
    methods: {
        primeiro() {
            //this.page = 1;
            this.$emit('newpage',1);
        },
        ultimo() {
            //this.page = this.pageCount;
            this.$emit('newpage',this.pageCount);
        },
        avancar() {
            if (this.page < this.pageCount) {
                //this.page++;
                this.$emit('newpage',this.page+1);
            }
        },
        recuar() {
            if (this.page > 1) {
                //this.page--;
                this.$emit('newpage',this.page-1);
            }
        },
        visualizar(btnId, divId) {
            var divMais = document.getElementById(divId)
            var btnMais = document.getElementById(btnId)
            if (divMais && btnMais) {
                if (divMais.style.display == 'none') {
                    divMais.style.display = 'block'
                    btnMais.innerText = '-'
                } else {
                    divMais.style.display = 'none'
                    btnMais.innerText = '+'
                }
            }
        },

    },
    mounted() {
        this.pageOptionIndex = this.defaultPageOptionIndex;
    },
    watch: {
        page(val) {
           this.$emit('update',{page:val,pageSize:this.pageSize,event:'page-change'})
        },
        pageSize(val) {
            this.$emit('update',{page:this.page,pageSize:val,event:'page-size-change'})
        },
        pageOptionIndex() {
            this.pageSize = this.pageOptionIndex==6?this.recordCount:this.pageOptions[this.pageOptionIndex]
        }
    }
}
</script>

<style scoped>
.pagination {
    width: 100%!important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.pagination > .btn {
    cursor: pointer;
    color: rgb(16,179,237);
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 1.1rem;
    font-weight: bold;
}
.pagination > .btn:hover {
    color: white;
    background-color: rgb(30, 144, 255);
}
.pagination > .page {
    text-align: center;
    padding-top: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 0.9rem;
    font-weight: bold;
}
.pagination > .sel {
    padding-top: 0.3rem;
    margin-right: 0.3rem;
    min-width: 90px;
}
.pagination > .total {
    text-align: center;
    padding-top: 0.4rem;
    padding-left: 0.4rem;
    padding-right: 0.8rem;
    font-size: 0.9rem;
    font-weight: bold;
}
</style>