<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.cadastrarpromocao')}}:  </h4>
                    <a @click="$router.push('/painel/serpromocoes')" class="ponteiro text-white btn btn-info mt-4"> &#8592; voltar </a>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label>{{$t('ser.tipo')}}:</label>
                                    <select class="form-control" v-model="editedItem.idcategoria">
                                        <template v-if="categorias && categorias.length > 0">
                                            <option v-for="cat in categorias" v-bind:key="cat.idcategoria"
                                                :value="cat.idcategoria"
                                                :selected="editedItem.idcategoria==cat.idcategoria"
                                            >{{cat.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>{{$t('ser.parceiro')}}:</label>
                                    <select class="form-control" v-model="editedItem.idparceiro">
                                        <template v-if="parceiros && parceiros.length > 0">
                                            <option  v-for="parceiro in parceiros" v-bind:key="parceiro.idparceiro"
                                                :value="parceiro.idparceiro"
                                                :selected="editedItem.idparceiro==parceiro.idparceiro"
                                            >{{parceiro.nome}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-9">
                                    <label>{{$t('ser.titulo')}}:</label>
                                    <input v-model="editedItem.titulo" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.valor')}}:</label>
                                    <input v-model="editedItem.valor" type="number" class="form-control">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label>{{$t('ser.descricao')}}:</label>
                                    <!-- <input v-model="editedItem.descricao" type="textarea" class="form-control"> -->
                                    <textarea rows="3" class="form-control es-sol-text"
                                        v-model="editedItem.descricao">
                                    </textarea>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.desconto')}}:</label>
                                    <input v-model="editedItem.desconto" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.datavalidade')}}:</label>
                                    <input v-model="editedItem.datavalidade" type="date" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.ativo')}}:</label>
                                    <select class="form-control" v-model="editedItem.ativo">
                                        <option :value="true">{{$t('ser.ativar')}}</option>
                                        <option :value="false">{{$t('ser.desativar')}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <!-- Link -->
                                <!-- <div class="form-group col-sm-auto">
                                    <label>Link:</label>
                                    <input v-model="editedItem.link" 
                                        type="text" class="form-control" placeholder="Link para acesso à promoção">
                                </div> -->
                                <!-- Imagem -->
                                <div v-if="editedItem.idpromocao>0" class="form-group col-sm-auto">
                                    <label>Imagem:</label>
                                    <div class="imagem-promocao">
                                        <label class="ponteiro" :for="'file_'+editedItem.idpromocao">
                                            <div v-if="!imagemSrc"><span class="material-icons icon form-control">hotel</span></div>
                                            <img v-if="imagemSrc" :src="'data:' + tipoImg + ';base64,' + imagemSrc" 
                                                :id="'imgPromo_'+editedItem.idpromocao"
                                            />
                                        </label>
                                        <input @change="onFileChange" type="file" :id="'file_'+editedItem.idpromocao" 
                                            :name="'file_'+editedItem.idpromocao" accept="image/png,image/jpg,image/jpeg" 
                                            class="ponteiro" style="display:none;">
                                    </div>
                                </div>
                            </div>
                            <button @click="upinsertPromocao()" type="button" class="btn btn-primary mt-4" id="toastr-success-top-right">{{$t('ser.salvar')}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import { serializarImagem } from '../utils/serializar'
export default {
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            categorias: [],
            parceiros: [],
            editedItem: {},
            imagemSrc: null,    
            tipoImg: null
        }
    },
    methods: {
        onFileChange(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.imagemSrc = resp.dados
                this.tipoImg = resp.tipo
                if (this.editedItem.idpromocao > 0) {
                    this.salvarImagem(resp.tipo);
                }
            }).catch(er =>{
                console.error(er)
            })
        },
		carregarImagem() {
            if (this.editedItem.imagem) {
                this.imagemSrc = null
                this.tipoImg = null
                axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: this.editedItem.imagem }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this.imagemSrc = Buffer.from(imgObj.dados).toString()
                            this.tipoImg = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},        
        salvarImagem(tipoImg) {
            if (!this.imagemSrc.length > 0) {
                console.log("invalido");
                return;
            }
            var params = {
                "tipo" : tipoImg,
                "nome" : "Imagem_Promocao_Parceiro_"+this.editedItem.idpromocao,
                "dados" : this.imagemSrc,
                "tabela": "promocao",
                "fk": "imagem",
                "chave": "idpromocao",
                "valor": this.editedItem.idpromocao,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/imagem/upinsert'
                    ,params, { timeout:60000, headers: this.headers })
                .then((response) => {
                    if(response.status == 200){
                        this.editedItem.imagem = response.data.result.imagem[0]
                        console.log('imagem:'+this.editedItem.imagem)
                    }else{
                        console.log(JSON.stringify(response))
                    }
                })
                .catch((error) => {
                    console.log(error.response);
            });
        },
        buscarCategorias() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoria/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarParceiros() {
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.parceiros = response.data
                    this.editedItem = this.$store.getters.GetEditItem
                    this.carregarImagem()
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        upinsertPromocao() {
            if (this.$store.getters.GetEditStatus) {
                this.editarPromocao()
            } else {
                this.inserirPromocao()
            }
        },
        inserirPromocao() {
            var servico = {
                idcategoria: this.editedItem.idcategoria,
                idparceiro: this.editedItem.idparceiro,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: this.editedItem.titulo,
                descricao: this.editedItem.descricao,
                valor: parseFloat(this.editedItem.valor),
                desconto: parseFloat(this.editedItem.desconto),
                datavalidade: this.editedItem.datavalidade,
                //link: this.editedItem.link,
                imagem: this.editedItem.imagem,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/promocao/insert', servico,  {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/serpromocoes')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        editarPromocao() {
            var params = {
                idpromocao: this.editedItem.idpromocao,
                idcategoria: this.editedItem.idcategoria,
                idparceiro: this.editedItem.idparceiro,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: this.editedItem.titulo,
                descricao: this.editedItem.descricao,
                valor: parseFloat(this.editedItem.valor),
                desconto: parseFloat(this.editedItem.desconto),
                datavalidade: this.editedItem.datavalidade,
                //link: this.editedItem.link,
                imagem: this.editedItem.imagem,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            console.log('editarPromocao:')
            console.log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/fdguest/promocao/update', params,  
                { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/serpromocoes')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
    },
    mounted() {
        this.buscarCategorias()
        this.buscarParceiros()
    }
}
</script>

<style>

</style>