<template>
        <div class="form-group col-md-12 mx-4">
            <h4 class="mb-3">{{$t('chamadoProgramado.checklist')}}</h4>
            <div class="form-row mx-4" v-for="cl in checklist" :key="cl.idhotlinesolicitacoeschecklist">
                <div class="form-group col-md-6">
                    <h6>{{cl.descricao}}</h6>
                </div>
                <div class="form-group col-md-2" style="text-align:center;">
                    <div style="align-self:center;">
                        <input v-if="somenteLeitura" v-model="cl.checked"
                            type="checkbox" class="form-check ponteiro" disabled
                            :id="`checklist_checked_${cl.idhotlinesolicitacoeschecklist}`">
                        <input v-else v-model="cl.checked" @change="$emit('update')"
                            type="checkbox" class="form-check ponteiro" 
                            :id="`checklist_checked_${cl.idhotlinesolicitacoeschecklist}`">
                    </div>
                </div>
                <div class="form-group col-md-4">
                    <h6>{{cl.obs}}</h6>
                </div>
            </div>
        </div>
</template>
<script>

export default {
  components: {  },
    props: {
        checklist: {
            type: Array,
            required: true
        },
        somenteLeitura: {
            type: Boolean,
            required: false,
            default: false
        }
    },    
    data () {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            }
        }
    },
    methods: {
        // flagChecked(cl) {
        //     cl.checked = true;
        //     this.$emit('update');
        //     this.$forceUpdate();
        // }
    },
    created() {
        
    }
}
</script>
