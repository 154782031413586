<template>
    <div class="es-wizard">
        <div class="es-content">
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <div class="es-title"><h2>{{step.titulo}}</h2></div>
                </div>
            </div>
            <div class="es-step">
                <div class="form-row">
                    <div class="form-group col-sm-2">
                        <label>Idioma:</label>
                        <select class="form-control" v-model="tipo.idioma">
                            <template v-if="idiomas && idiomas.length > 0">
                                <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                    :value="idioma.codidioma"
                                    :selected="tipo.idioma==idioma.codidioma">{{idioma.descidioma}}
                                </option>
                            </template>
                        </select>
                    </div>
                  
                    <div class="form-group col-sm-4">
                        <label>Título:</label>
                        <input v-model="tipo.titulo" 
                            type="text" class="form-control" placeholder="Dê um título ao tipo de solicitação">
                    </div>
                    <div class="form-group col-sm-4">
                        <label>Subtítulo:</label>
                        <input v-model="tipo.subtitulo" 
                            type="text" class="form-control" placeholder="Dê um subtítulo ao tipo de solicitação">
                    </div>
                    <div class="form-group col-sm-2">
                        <label>Categoria Interna:</label>
                        <select class="form-control" v-model="tipo.tipovinculado">
                                <option
                                    :value="null"
                                    :selected="!tipo.tipovinculado">Não atribuída
                                </option>
                            <template v-if="categoriasInternas && categoriasInternas.length > 0">
                                <option v-for="categoria in categoriasInternas" v-bind:key="categoria.idtiposolicitacao"
                                    :value="categoria.idtiposolicitacao"
                                    :selected="tipo.tipovinculado==categoria.idtiposolicitacao">{{categoria.titulo}}
                                </option>
                            </template>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <h3>{{step.descricao}}</h3>
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="es-imagem-wizard justify-content-center">
                            <img :src="'../../img/wizard-titulo-01.png'" class="p-10 mb-20" height="300" />
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="es-buttons">
                <button @click="primeiro()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo" disabled="disabled">Início</button>
                <button @click="anterior()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo" disabled="disabled">Anterior</button>
                <button @click="proximo()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo">Próximo</button>
                <button @click="ultimo()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo">Último</button>
                <button :disabled="saving" @click="salvar()" type="button" class="btn es-btn-success" id="salvar-titulo-tipo">{{saving?'Salvando...':'Salvar'}}</button>
                <button @click="fechar()" type="button" class="btn es-btn-close" id="fechar-titulo-tipo">Fechar</button>
            </div>

            <div class="form-row justify-content-center">
                <h5>{{`Passo ${step.passo} de ${steps.length}`}}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    name: 'WizardTipo',
    props: {
        tipo: {
            type: Object,
            required: false
        },
        step: {
            type: Object,
            required: true          
        },
        steps: {type: Array},
        idiomas: {type: Array},
        saving: {
            type: Boolean,
            required: false,
            default: false
        },
        categoriasInternas: {type: Array}
    },
    components: { },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            dialog: true,
            currentStep: {
                titulo: 'Passo 0',
                imagem: 1,
            },
            sound: true,
            disableStart: true,
        }
    },
    watch: {
        step() {
            this.$forceUpdate()
        }
    },
    methods: {
        primeiro() {
            this.$emit('first')
        },
        anterior() {
            this.$emit('previous')
        },
        proximo() {
            this.$emit('next')
        },
        ultimo(){
            this.$emit('last')
        },
        salvar(){
            this.$emit('save')
        },
        fechar(){
            this.$emit('close')
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        carregarMailConfig() {
            axios.get(CONSTANTS.API_URL + '/email/conta/get', { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.emailConfig = response.data
                }
            })
        },
        salvarMailConfig() {
            console.log('salvarMailConfig()...')
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idmailer: this.emailConfig.idmailer,
                host: this.emailConfig.host, 
                port: this.emailConfig.port,
                user: this.emailConfig.user,
                pass: this.emailConfig.pass,
                replyto: this.emailConfig.replyto,
                nametoexibition: this.emailConfig.nametoexibition,
                ativo: this.emailConfig.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            }
			//log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/email/atualizaContaEmail',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarMailConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
            .catch((e)=>{
                this.statusCode = 2
                this.statusMsg = 'Erro ao tentar salvar configuração!'
                console.error(JSON.stringify(e))
            })
        }
    },
    computed: {
    },
    mounted() {
        
    }
}
</script>
