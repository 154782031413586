<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card es-azure">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <label>{{$t('global.idioma')}}:</label>
                                <select class="form-control" v-model="localAtendimentoEditado.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="localAtendimentoEditado.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <div><span class="es-icon material-icons icon">{{localAtendimentoEditado.icone}}</span></div>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>{{$t('global.icone')}}:</label>
                                <!-- <select class="form-control" v-model="localAtendimentoEditado.icone">
                                    <template v-if="icones && icones.length > 0">
                                        <option v-for="(icone,index) in icones" v-bind:key="index"
                                            :value="icone.chave"
                                            :selected="localAtendimentoEditado.icone==icone.chave">{{icone.chave}}
                                        </option>
                                    </template>
                                </select> -->
                                <!-- <input v-model="localAtendimentoEditado.icone" 
                                    type="text" class="form-control" :placeholder="$t('global.icone')"> -->
                                
                                <Autocomplete :items="placeIcons" 
                                    v-model="localAtendimentoEditado.icone"
                                    :placeHolder="$t('global.icone')">
                                </Autocomplete>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>{{$t('localAtendimento.local')}}:</label>
                                <input v-model="localAtendimentoEditado.descricao" 
                                    type="text" class="form-control" :placeholder="$t('localAtendimento.descreva')">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',localAtendimentoEditado)" class="ponteiro text-white btn btn-info mt-4">{{$t('global.salvar')}}</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">{{$t('global.fechar')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import Autocomplete from './Autocomplete.vue'

export default {
    components: {Autocomplete},
    props: {
        localAtendimento: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            localAtendimentoEditado: {},
            icones: [
                {chave: 'face'},
                {chave: 'map'},
                {chave: 'handyman'},
                {chave: 'pool'},
                {chave: 'hotel'},
                {chave: 'room_service'},
                {chave: 'security'},
                {chave: 'local_atm'},
                {chave: 'wifi'},
                {chave: 'restaurant'},
                {chave: 'shopping_cart'}
            ],
            placeIcons: [
                'airport_shuttle',
                'all_inclusive',
                'bathtub',
                'beach_access',
                'apartment',
                'casino',
                'checkroom',
                'child_care',
                'corporate_fare',
                'business_center',
                'desk',
                'family_restroom',
                'fitness_center',
                'grass',
                'holiday_village',
                'house',
                'meeting_room',
                'other_houses',
                'spa',
                'storefront',
                'roofing',
                'room_service',
                'room_preferences',
                'sports_bar',
                'free_breakfast',
                'foundation',
                'food_bank',
                'gite',
                'villa',
                'night_shelter',
                'golf_course',
                'microwave',
                'stairs',
                'elevator',
                'wash',
                'smoking_rooms',
                'cabin',
                'house_siding',
                'hot_tub',
                'countertops',
                'water_damage',
                'charging_station',
                'soap',
                'rv_hookup',
                'baby_changing_station',
                'umbrella',
                'smoke_free',
                'tapas',
                'rice_bowl',
                'no_food',
                'fence',
                'houseboat',
                'tty',
                'wheelchair_pickup',
                'bento',
                'iron',
                'bungalow',
                'no_meeting_room',
                'do_not_step',
                'escalator',
                'dry',
                'stroller',
                'no_drinks',
                'no_cell',
                'no_flash',
                'vaping_rooms',
                'vape_free',
            ]
        }
    },
    methods: {
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.localAtendimentoEditado = {... this.localAtendimento}
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.5em;
}
</style>