<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('chamadoProgramado.titulo')}}</h4>
                <p>{{$t('chamadoProgramado.descricao')}}</p>
                <div class="row" style="alignItems:center;">
                    <div class="col-6">
                        <p><strong>{{$t('chamadoProgramado.descricaoupload')}}</strong></p>
                    </div>
                    <div class="col-3">
                        <a @click="$router.push('/painel/chamadoprogramadoupload')" class="ponteiro float-right text-white btn btn-primary btn-sm rounded">
                            {{$t('global.upload')}}
                        </a>
                    </div>
                    <div class="col-3">
                        <a @click="downloadFile()" class="ponteiro float-right text-white btn btn-primary btn-sm rounded">
                            Baixar Modelo
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('chamadoProgramado.tituloLista')}}</h4>
                    <a @click="criarChamadoProgramado()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('global.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criar-chamado-programado" style="display: none;">
                        <ChamadoProgramadoEditar 
                            :chamadoProgramado="novoChamadoProgramado"
                            :prioridades="prioridades" :tipossolicitacao="tipossolicitacao"
                            :responsaveis="responsaveis"
                            :locaisAtendimento="locaisAtendimento"
                            :idiomas="idiomas" :setores="setores"
                            :fornecedores="fornecedores"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                            v-on:newCheckList="newCheckList($event)"
                            :equipamentos="equipamentos"
                        />
                    </div>
                    <!-- Filtrar -->
                    <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px; padding-bottom:0px;">
                        <div class="es-card">
                            <div class="card-header py-2">
                                <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                                    <span v-if="!showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                                    <span v-if="showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                                    <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                                </div>
                                <h4>{{$t('sols.filtro')}}</h4>
                                <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                                    <span>Limpar Filtro</span>
                                </div>
                            </div>
                            <div id="filter_body" class="card-body" v-show="showFilter">
                                <div class="basic-form">
                                    <div class="form-row">
                                        <!-- Número do Chamado Preventivo -->
                                        <div class="form-group col-md-2">
                                            <label>#</label>
                                            <input v-model="filtro.idchamadoprogramado" 
                                                type="text" class="form-control">
                                        </div>
                                        <!-- Setor -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.setor')}}</label>
                                            <select id="filtro_setor" class="form-control" v-model="filtro.idhotlinesetores"
                                                @change="setorChanged()">
                                                <option :value="null"
                                                    :selected="!filtro.idhotlinesetores">{{$t('global.todos')}}
                                                </option>
                                                <template v-if="setores && setores.length > 0">
                                                    <option v-for="setor in setores" v-bind:key="setor.idhotlinesetores"
                                                        :value="setor.idhotlinesetores"
                                                        :selected="filtro.idhotlinesetores==setor.idhotlinesetores">{{setor.deschotlinesetor}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                        <!-- Tipo -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.tipo')}}</label>
                                            <select class="form-control" v-model="filtro.idtiposolicitacao"
                                                @change="loadTipoSolicitacaoItens(filtro.idtiposolicitacao)"
                                            >
                                                <option :value="null"
                                                    :selected="!filtro.idtiposolicitacao">{{$t('global.todas')}}
                                                </option>
                                                <template v-if="tipossolicitacao && tipossolicitacao.length > 0">
                                                    <option v-for="tiposolicitacao in tipossolicitacao" v-bind:key="tiposolicitacao.idtiposolicitacao"
                                                        :value="tiposolicitacao.idtiposolicitacao"
                                                        :selected="filtro.idtiposolicitacao==tiposolicitacao.idtiposolicitacao">{{tiposolicitacao.titulo}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                        <!-- Item -->
                                        <div class="form-group col-md-3">
                                            <label>Ítem:</label>
                                            <select class="form-control" v-model="filtro.idtiposolicitacaoitem">
                                                <option :value="null"
                                                    :selected="!filtro.idtiposolicitacaoitem">{{$t('global.todos')}}
                                                </option>
                                                <template v-if="tiposolicitacaoItens && tiposolicitacaoItens.length > 0">
                                                    <option v-for="tiposolicitacaoitem in tiposolicitacaoItens" v-bind:key="tiposolicitacaoitem.idtiposolicitacaoitem"
                                                        :value="tiposolicitacaoitem.idtiposolicitacaoitem"
                                                        :selected="filtro.idtiposolicitacaoitem==tiposolicitacaoitem.idtiposolicitacaoitem">{{tiposolicitacaoitem.titulo}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <!-- Texto da solicitação -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.texto')}}</label>
                                            <input v-model="filtro.textohotlinesolicitacoes" 
                                                type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <!-- Prioridade -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.prioridade')}}</label>
                                            <select class="form-control" v-model="filtro.idprioridade">
                                                <option :value="null"
                                                    :selected="!filtro.idprioridade">{{$t('global.todas')}}
                                                </option>
                                                <template v-if="prioridades && prioridades.length > 0">
                                                    <option v-for="prioridade in prioridades" v-bind:key="prioridade.idprioridade"
                                                        :value="prioridade.idprioridade"
                                                        >{{prioridade.descricao}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                        <!-- Responsável -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.responsavel')}}</label>
                                            <select class="form-control" v-model="filtro.idresponsavel">
                                                <option :value="null"
                                                    :selected="filtro.idresponsavel==-1">{{$t('global.todos')}}
                                                </option>
                                                <template v-if="responsaveis && responsaveis.length > 0">
                                                    <option v-for="responsavel in responsaveis" v-bind:key="responsavel.idusuario"
                                                        :value="responsavel.idusuario"
                                                        >{{responsavel.nome}}
                                                    </option>
                                                </template>
                                                <option :value="-1"
                                                    :selected="!filtro.idresponsavel">{{$t('sols.sematribuicao')}}
                                                </option>
                                            </select>
                                        </div>
                                        <!-- Local de atendimento -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.localatendimento')}}</label>
                                            <select class="form-control" v-model="filtro.idlocalatendimento">
                                                <option :value="null"
                                                    :selected="!filtro.idlocalatendimento">{{$t('global.todos')}}
                                                </option>
                                                <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                                    <option v-for="localAtendimento in locaisAtendimento" v-bind:key="localAtendimento.idlocalatendimento"
                                                        :value="localAtendimento.idlocalatendimento"
                                                        >{{localAtendimento.descricao}}
                                                    </option>
                                                </template>
                                                <option :value="-1"
                                                    :selected="!filtro.idlocalatendimento">{{$t('sols.sematribuicao')}}
                                                </option>
                                            </select>
                                        </div>
                                        <!-- Ultima alteração -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.ultimaAlteracao')}}</label>
                                            <input v-model="filtro.dataalteracao" 
                                                type="date" class="form-control">
                                        </div>                                                        
                                    </div> 
                                    <div class="form-row">
                                        <!-- Status -->
                                        <div class="form-group col-md-3">
                                            <label>{{$t('sols.status')}}</label>
                                            <select class="form-control" v-model="filtro.ativo">
                                                <option :value="null" :selected="filtro.ativo === null">{{$t('global.todos')}}</option>
                                                <option :value="true" :selected="filtro.ativo===true">{{$t('global.ativo')}}</option>
                                                <option :value="false" :selected="filtro.ativo===false">{{$t('global.inativo')}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <div class="custom-control custom-switch pl-0">
                                                <label class="pr-2 ponteiro" :for="`item-switch-filtro`" >
                                                    {{$t('sols.manutencaoexterna')}}</label>
                                                <input  type="checkbox" name="manutencaoexterna" class="ponteiro" 
                                                    :id="`item-switch-filtro`"  
                                                    v-model="filtro.manutencaoexterna"
                                                >
                                            </div> 
                                            <select v-if="filtro.manutencaoexterna" class="form-control" v-model="filtro.fornecedor">
                                                <option :value="null"
                                                    :selected="!filtro.fornecedor">{{$t('global.todos')}}
                                                </option>
                                                <template v-if="fornecedores && fornecedores.length > 0">
                                                    <option v-for="fornecedor in fornecedores" v-bind:key="fornecedor.idpessoa"
                                                        :value="fornecedor.idpessoa"
                                                        :selected="filtro.fornecedor==fornecedor.idfornecedor">{{fornecedor.nome}}
                                                    </option>
                                                </template>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div v-on:click="carregarChamadosProgramados()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                                            <span>{{$t('sols.filtro')}}</span>
                                        </div>
                                    </div>                       
                                    <!-- FIM-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>

                        <!-- <div v-if="!chamadosProgramados||!(chamadosProgramados.length>0)">Sem chamados cadastrados no momento.</div> -->
                        <table v-if="chamadosProgramados&&chamadosProgramados.length>0" class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>#</strong></th>
                                    <th><strong>{{$t('global.titulo')}}</strong></th>
                                    <th><strong>{{$t('global.categoria')}}</strong></th>
                                    <th><strong>{{$t('global.subcategoria')}}</strong></th>
                                    <th><strong>{{$t('sols.responsavel')}}</strong></th>
                                    <th><strong>{{$t('global.frequencia')}}</strong></th>
                                    <th><strong>{{$t('global.ultimaexecucao')}}</strong></th>
                                    <th><strong>{{$t('global.proximaexecucao')}}</strong></th>
                                    <th><strong>{{$t('global.status')}}</strong></th>
                                    <th><strong>{{$t('global.acao')}}</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in chamadosProgramados" :key="item.idchamadoprogramado">
                                <tr>
                                    <td><strong>{{item.idchamadoprogramado}}</strong></td>
                                    <td><strong>{{item.textohotlinesolicitacoes}}</strong></td>
                                    <td><strong>{{item.categoria}}</strong></td>
                                    <td><strong>{{item.item}}</strong></td>
                                    <td><strong>{{item.responsavel}}</strong></td>
                                    <td><strong>{{item.frequenciatarefa}}</strong></td>
                                    <td><strong>{{item.dataultimaexecucao | formatarDataHora}}</strong></td>
                                    <td><strong>{{item.dataproximaexecucao | formatarDataHora}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="ponteiro dropdown-item" @click="ativar(item, false)">{{$t('global.inativar')}}</a>
                                                <a v-if="!item.ativo" class="ponteiro dropdown-item" @click="ativar(item, true)">{{$t('global.ativar')}}</a>
                                                <a class="ponteiro dropdown-item" @click="editar(item)">{{$t('global.editar')}}</a>
                                                <a class="ponteiro dropdown-item" @click="duplicar(item)">{{$t('global.duplicar')}}</a>
                                                <a class="ponteiro dropdown-item" @click="exibirChamados(item)">{{$t('global.exibirchamados')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="10">
                                        <div :id="'Editar_'+item.idchamadoprogramado" style="display: none;">
                                            <ChamadoProgramadoEditar
                                                :chamadoProgramado="item" 
                                                :prioridades="prioridades" 
                                                :tipossolicitacao="tipossolicitacao"
                                                :responsaveis="responsaveis"
                                                :locaisAtendimento="locaisAtendimento"
                                                :idiomas="idiomas" :setores="setores"
                                                :fornecedores="fornecedores"
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                                v-on:newCheckList="newCheckList($event)"
                                                :equipamentos="equipamentos"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="chamadosProgramados.length == 0" 
                            style="text-align:center;font-size:1.3rem;color:orange;">
                            {{loadingText}}
                        </div>
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>

                    </div>
                </div>
            </div>
        </div>
        <ModalES :title="`Chamados Gerados pala Programação`" 
			:value="showModal" 
			:details="detalhes" :headers="cabecalhosDetalhes"
			@input="showModal=$event" 
		></ModalES>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import ChamadoProgramadoEditar from './ChamadoProgramadoEditar.vue'
import ModalES from './ModalES.vue'
import Pagination from './Pagination.vue'

export default {
    components: {ChamadoProgramadoEditar, ModalES, Pagination},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            chamadosProgramados: [],
            novoChamadoProgramado: {},
            prioridades: this.$t('sols.prioridades'),
            responsaveis: [],
            locaisAtendimento: [],
            idiomas: [],
            setores: [],
            tipossolicitacao: [],
            tiposolicitacaoItens: [],
            fornecedores: [],
            showModal: false,
            detalhes: [],
            chamado: null,
            cabecalhosDetalhes: [
                {nome: '#', campo: 'idhotlinesolicitacoes', align: 'center', tipo: 'integer'},
                {nome: 'Solicitação', campo: 'textohotlinesolicitacoes', align: 'left', tipo: 'string'},
                {nome: 'Categoria', campo: 'tipo', align: 'left', tipo: 'string'},
                {nome: 'Responsável', campo: 'responsavel', align: 'left', tipo: 'string'},
            ],
            equipamentos: [],
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
            loadingText: 'Carregando...',
            showFilter: false,
            filtro: {
                idchamadoprogramado: null,
                idhotlinesetores: null,
                idtiposolicitacao: null,
                idtiposolicitacaoitem: null,
                idlocalatendimento: null,
                idresponsavel: null,
                idprioridade: null,
                ativo: true,
                textohotlinesolicitacoes: '',
                interna: true,
                manutencaoexterna: false,
                fornecedor: null,
                dataalteracao: null,
            },
        }
    },
    methods: {
        loadTipoSolicitacaoItens(id) {
            //console.error(`loadTipoSolicitacaoItens(id):${id}`)
            for (var i = 0; i < this.tipossolicitacao.length; i++) {
                if (this.tipossolicitacao[i]['idtiposolicitacao'] == id) {
                    //console.error('Entrou no if...')
                    this.tiposolicitacaoItens = this.tipossolicitacao[i].itens;
                    break;
                }
            }
            this.$forceUpdate();
        },
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarChamadosProgramados();
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.showFilter = true
                } else {
                    el.style.display = 'none'
                    this.showFilter = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.chamadosProgramados = []
            this.filtro = {
                idchamadoprogramado: null,
                idhotlinesetores: null,
                idtiposolicitacao: null,
                idtiposolicitacaoitem: null,
                idlocalatendimento: null,
                idresponsavel: null,
                idprioridade: null,
                ativo: true,
                textohotlinesolicitacoes: '',
                interna: true,
                manutencaoexterna: false,
                fornecedor: null,
                dataalteracao: null,
            }
            window.setTimeout(this.carregarChamadosProgramados(), 1000);
        },
        loadEquipamentos(idchamadoprogramado) {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "exceto": idchamadoprogramado
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/equipamento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.equipamentos = response.data.dados
                }
            })
        },
        exibirChamados(item) {
           var  params = {
                "idioma" : this.$i18n.locale,
				"chamadorecorrente": item.idchamadoprogramado
            }
			//console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    console.error(JSON.stringify(response.data.dados))
					this.detalhes = response.data.dados
                    if (this.detalhes.length > 0) {
                        this.showModal = true;
                    } else {
                        this.$snackW(this.$t('global.semchamados'))
                    }
                }
            })
        },
        duplicar(item) {
            //console.error(JSON.stringify(item,null,2))
            this.novoChamadoProgramado = Object.assign({},item);
            this.novoChamadoProgramado.idchamadoprogramado = 0
            this.novoChamadoProgramado.textohotlinesolicitacoes = this.novoChamadoProgramado.textohotlinesolicitacoes + ' (cópia)'
            if (this.novoChamadoProgramado.checklist) {
                this.novoChamadoProgramado.checklist.forEach(element => {
                    element.idchamadoprogramadochecklist = 0
                    element.changed = true
                });
            }
            console.error(JSON.stringify(this.novoChamadoProgramado,null,2))
            this.criarChamadoProgramado(true);
        },
        downloadFile() {
            const fileName = 'manutencao_preventiva_padrao.ods'
            axios({
                url: `${CONSTANTS.API_URL}/modelo/${fileName}`, // URL do arquivo no backend
                method: 'GET',
                responseType: 'blob' // importante para indicar que a resposta é um arquivo
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // nome do arquivo para download
                document.body.appendChild(link);
                link.click();
            });
        },
        newCheckList(chamado) {
            let lastId = 0
            let nextId = 0
            if (chamado.checklist && chamado.checklist.length > 0) {
                lastId = chamado.checklist[chamado.checklist.length-1].idchamadoprogramadochecklist;
                nextId = lastId > 0 ? 0 : lastId-1;
            } else {
                chamado.checklist = [];
            }
            chamado.checklist.push({
                idchamadoprogramadochecklist: nextId,
                idchamadoprogramado: chamado.idchamadoprogramado,
                descricao: ``,
                changed: true,
                ativo: true,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            });
            this.$forceUpdate();
            let name = `checklist_desc_${chamado.idchamadoprogramado}_${nextId}`
            //console.error(`input Id: ${name}`)
            let clEl = document.getElementById(name)
            //console.error(`clEl: ${clEl}`)
            if (clEl) {
                //console.error(`Focus...`)
                clEl.focus();
            }            
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idchamadoprogramado: item.idchamadoprogramado,
                ativo: ativo,
                datainicio: item.datainicio,
                datafim: item.datafim,
                tipotarefa: item.tipotarefa,
                frequenciatarefa: item.frequenciatarefa,
                diasemana: item.diasemana
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarChamadoProgramado(copia=null) {
            this.loadEquipamentos(null);
            if (!copia) {
                this.novoChamadoProgramado = {
                    "idchamadoprogramado": 0,
                    "textohotlinesolicitacoes": "",
                    "coduh": "",
                    "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                    "idhotlinesetores": 4,
                    "ativo": true,
                    "idstatus": 1,
                    "datahoraagendamento": null,
                    "idtiposolicitacao": null,
                    "idtiposolicitacaoitem": null,
                    "tipoitem": 2,
                    "interna": true,
                    "idresponsavel": null,
                    "idprioridade": null,
                    "idlocalatendimento": null,
                    "manutencaoexterna": false,
                    "programacao": "* * * * * *",
                    "itens": [],
                    "checklist": []
                }
            }
            var obj = document.getElementById('criar-chamado-programado')
            if(obj) {
                obj.style.display = 'block'
                this.$forceUpdate()
            }
        },
        closeNew() {
            var obj = document.getElementById('criar-chamado-programado')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                "textohotlinesolicitacoes": item.textohotlinesolicitacoes,
                "coduh": item.coduh,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "idhotlinesetores": 4,
                "ativo": true,
                "idstatus": 1,
                "datahoraagendamento": null,
                "idtiposolicitacao": item.idtiposolicitacao,
                "idtiposolicitacaoitem": item.idtiposolicitacaoitem,
                "interna": true,
                "idresponsavel": item.idresponsavel,
                "idprioridade": item.idprioridade,
                "idlocalatendimento": item.idlocalatendimento,
                "manutencaoexterna": item.manutencaoexterna,
                "programacao": item.programacao,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
                "fornecedor": item.fornecedor,
                "garantiaemdias": item.garantiaemdias,
                "checklist": item.checklist,
                "criado_por": this.$store.getters.GetLoginInfo.idusuario,
                "alterado_por": this.$store.getters.GetLoginInfo.idusuario,
                "dataultimaexecucao": item.dataultimaexecucao,
                "datainicio": item.datainicio,
                "datafim": item.datafim,
                "tipotarefa": item.tipotarefa,
                "frequenciatarefa": item.frequenciatarefa,
                "diasemana": item.diasemana,
                "equipamentos": item.equipamentos
            }
            console.error(JSON.stringify(params));
            axios.post(CONSTANTS.API_URL + '/fdguest/chamadoprogramado/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarChamadosProgramados()
                    this.closeNew()
                    this.$snack(`Chamado Programado salvo.`)
                } else {
                    console.log('Erro: ' + response.status)
                }
            }).catch((e)=>{this.$snackE(`Erro ao salvar o Chamado Programado: ${JSON.stringify(e)}.`)})
        },
        editar(item) {
            this.loadEquipamentos(item.idchamadoprogramado);
            var obj = document.getElementById('Editar_'+item.idchamadoprogramado)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idchamadoprogramado)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                "idchamadoprogramado": item.idchamadoprogramado,
                "textohotlinesolicitacoes": item.textohotlinesolicitacoes,
                "coduh": item.coduh,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "idhotlinesetores": 4,
                "ativo": item.ativo,
                "idstatus": 1,
                "datahoraagendamento": null,
                "idtiposolicitacao": item.idtiposolicitacao,
                "idtiposolicitacaoitem": item.idtiposolicitacaoitem,
                "interna": true,
                "idresponsavel": item.idresponsavel,
                "idprioridade": item.idprioridade,
                "idlocalatendimento": item.idlocalatendimento,
                "manutencaoexterna": item.manutencaoexterna,
                "programacao": item.programacao,
                "fornecedor": item.fornecedor,
                "garantiaemdias": item.garantiaemdias,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
                "checklist": item.checklist,
                "alterado_por": this.$store.getters.GetLoginInfo.idusuario,
                "dataultimaexecucao": item.dataultimaexecucao,
                "datainicio": item.datainicio,
                "datafim": item.datafim,
                "tipotarefa": item.tipotarefa,
                "frequenciatarefa": item.frequenciatarefa,
                "diasemana": item.diasemana,
                "equipamentos": item.equipamentos
            }
            //console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/chamadoprogramado/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarChamadosProgramados()
                    this.closeEdit(item)
                    this.$snack(`Chamado Programado salvo.`)
                    return true;
                } else {
                    this.$snackW(`Atenção, Chamado Programado pode não ter sido salvo (${response.status}).`)
                    console.log('Erro: ' + response.status)
                    return false;
                }
            }).catch((e)=>{this.$snackW(`Atenção, erro ao salvar o Chamado Programado: (${JSON.stringify(e)}).`)})
        },
        carregarChamadosProgramados() {
           this.loadingText = 'Carregando...';
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                ...this.filtro
            }
            params.page = this.page;
            params.pagesize = this.pageSize;
            axios.post(CONSTANTS.API_URL + '/fdguest/chamadoprogramado/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.chamadosProgramados = response.data.dados
                    this.recordCount = response.data.recordCount
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1
                    if (this.chamadosProgramados.length == 0) {
                        this.loadingText = 'Nenhum registro encontrado. Procure limpar o filtro.'
                    }
                    this.$forceUpdate()
                }
            })
        },
        loadResponsaveis() {
            var searchParams = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
				page: 1,
                pagesize: 1000,
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tecnicos', searchParams
                , { headers: this.headers })
            .then(response => {
                try {
                    if (response.status == 200) {
                        if (response.data) {
                            this.responsaveis = response.data.dados;
                            this.responsaveis.push({idusuario: null, nome: '<não atribuído>'})
                        }
                    } else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
                }
                catch(e) {
                    console.log(e);
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            });
        },
        loadLocaisAtendimento() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.locaisAtendimento = response.data.dados
                    this.locaisAtendimento.push({idlocalatendimento: null, descricao: '<não atribuído>'})
                }
            })
        },
        loadIdiomas() {
			axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        loadSetores() {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select', {
				idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.setores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        loadTiposSolicitacao() {
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', {
                    idioma: this.$i18n.locale,
                    idhotlinesetores: 4,
                    interna: true
                }, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tipossolicitacao = response.data;
                            this.$forceUpdate();
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        loadFornecedores(){
			axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', {
				idioma: this.$i18n.locale,
                manutencao: true
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.fornecedores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
    },
    computed: {
    },
    beforeMount() {
        this.carregarChamadosProgramados();
    },
    mounted() {
        this.loadResponsaveis();
        this.loadLocaisAtendimento();
        this.loadIdiomas();
        this.loadSetores();
        this.loadTiposSolicitacao();
        this.loadFornecedores();
    }
}
</script>
