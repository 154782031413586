<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card es-azure">
                <div class="card-header">
                    <div class="basic-form">
                        <!-- Tabs -->
                        <div class="form-row mb-2">
                            <div class="row mb-2">
                                <ul class="nav nav-tabs">
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==1}" @click="changeStep(1)">{{$t('equipamento.descricao')}}</a>
                                    </li>
                                    <li class="nav-item ponteiro">
                                        <a class="nav-link" :class="{'active': step==2}" @click="changeStep(2)">{{$t('equipamento.dadoscompra')}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="step==1" class="form-row">
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.descricao')}}:</strong></label>
                                <input v-model="equipamentoEditado.descricao" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.descricao')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.marca')}}:</strong></label>
                                <input v-model="equipamentoEditado.marca" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.marca')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.modelo')}}:</strong></label>
                                <input v-model="equipamentoEditado.modelo" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.modelo')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.serie')}}:</strong></label>
                                <input v-model="equipamentoEditado.serie" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.serie')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.tombamento')}}:</strong></label>
                                <input v-model="equipamentoEditado.tombamento" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.tombamento')">
                            </div>
                            <div class="form-group col-md-3">
                                <label><strong>{{$t('equipamento.localdeuso')}}</strong></label>
                                <select class="form-control" v-model="equipamentoEditado.localdeuso"
                                    :id="`localdeuso_${equipamentoEditado.idequipamento}`">
                                    <template v-if="locaisAtendimento && locaisAtendimento.length > 0">
                                        <option v-for="local in locaisAtendimento" v-bind:key="local.idlocalatendimento"
                                            :value="local.idlocalatendimento"
                                            :selected="equipamentoEditado.localdeuso==local.idlocalatendimento">{{local.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div v-if="step==2" class="form-row">
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.nfcompra')}}:</strong></label>
                                <input v-model="equipamentoEditado.nfcompra" 
                                    type="text" class="form-control" :placeholder="$t('equipamento.nfcompra')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.datacompra')}}:</strong></label>
                                <input v-model="equipamentoEditado.datacompra" 
                                    type="datetime-local" class="form-control" :placeholder="$t('equipamento.datacompra')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.valorcompra')}}:</strong></label>
                                <input v-model="equipamentoEditado.valorcompra" step="0.01"
                                    type="number" class="form-control" :placeholder="$t('equipamento.valorcompra')">
                            </div>
                            <div class="form-group col-md-auto">
                                <label><strong>{{$t('equipamento.fornecedor')}}:</strong></label>
                                <select class="form-control" v-model="equipamentoEditado.fornecedor"
                                    :id="`idequipamento_${equipamentoEditado.idequipamento}`">
                                    <template v-if="fornecedores && fornecedores.length > 0">
                                        <option v-for="fornX in fornecedores" v-bind:key="fornX.idparceiro"
                                            :value="fornX.idparceiro"
                                            :selected="equipamentoEditado.fornecedor==fornX.idparceiro">{{fornX.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.garantiaemdias')}}:</strong></label>
                                <input v-model="equipamentoEditado.garantiaemdias" min="30"
                                    type="number" class="form-control" :placeholder="$t('equipamento.garantiaemdias')">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label><strong>{{$t('equipamento.garantidoate')}}:</strong></label>
                                <span :id="`spam_garantidoate_${equipamentoEditado.idequipamento}`" 
                                    class="form-control">{{equipamentoEditado.garantidoate}}</span>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',equipamentoEditado)" class="ponteiro text-white btn btn-info mt-4">{{$t('global.salvar')}}</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">{{$t('global.fechar')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    components: {},
    props: {
        equipamento: {
            type: Object,
            required: true,
        },
        fornecedores: {
            type: Array,
            required: true
        },
        locaisAtendimento: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            equipamentoEditado: {},
            step: 1
        }
    },
    methods: {
        changeStep(step) {
            this.step = step;
            this.$forceUpdate();
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.equipamentoEditado = {... this.equipamento}
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.5em;
}
</style>