<template>
    <div  id="main-wrapper">
        <Loader />
        <Header />
        <Menu />
        <div class="content-body rightside-event">
            <div class="container">
                <router-view/>  
            </div>
        </div>    
    </div>
</template>

<script>
import Header from '../components/Header'
import Menu from '../components/Menu'
import Loader from '../components/Loader'
export default {
    components: {
         Menu, Header, Loader
    },    
    data() {
        return {
            // Token das chamadas ao backend
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            intervalId: 0
        }
    },
    beforeCreate(){
        var corpo = document.getElementById('corpo');
        corpo.style.backgroundImage = ""
        let exScript2 = document.createElement('script');exScript2.setAttribute('src', '/vendor/bootstrap-select/dist/js/bootstrap-select.min.js');document.body.appendChild(exScript2);
        let exScript3 = document.createElement('script');exScript3.setAttribute('src', '/vendor/chart.js/Chart.bundle.min.js');document.body.appendChild(exScript3);
        let exScript4 = document.createElement('script');exScript4.setAttribute('src', '/vendor/bootstrap-datetimepicker/js/moment.js');document.body.appendChild(exScript4);
        let exScript5 = document.createElement('script');exScript5.setAttribute('src', '/vendor/bootstrap-datetimepicker/js/bootstrap-datetimepicker.min.js');document.body.appendChild(exScript5);
        let exScript6 = document.createElement('script');exScript6.setAttribute('src', '/js/custom.min.js');document.body.appendChild(exScript6);
        let exScript7 = document.createElement('script');exScript7.setAttribute('src', '/js/deznav-init.js');document.body.appendChild(exScript7);
        //let exScript8 = document.createElement('script');exScript8.setAttribute('src', '/vendor/apexchart/apexchart.js');document.body.appendChild(exScript8);
        let exScript9 = document.createElement('script');exScript9.setAttribute('src', '/vendor/peity/jquery.peity.min.js');document.body.appendChild(exScript9); 
        //let exScript10 = document.createElement('script');exScript10.setAttribute('src', '/js/dashboard/dashboard-1.js');document.body.appendChild(exScript10);
    },
    methods: {
        
    },
    created() {

    },
    mounted() {
        if(localStorage.getItem('tklogin') == '1'){
            localStorage.removeItem('tklogin')
            this.$router.go()
        }
      document.title = 'FDGuest Hotel'
    }
}
</script>

<style>

</style>