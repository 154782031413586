<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="exibirFiltro">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Número do Local -->
                            <div class="form-group col-md-2">
                                <label>#</label>
                                <input v-model="filtro.idlocalatendimento" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Descricao do local -->
                            <div class="form-group col-md-6">
                                <label>{{$t('sols.descricao')}}</label>
                                <input v-model="filtro.descricao" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Status -->
                            <div class="form-group col-md-4">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="filtro.ativo">
                                    <option :value="null">{{$t('global.todos')}}</option>
                                    <option :value="true">{{$t('global.ativo')}}</option>
                                    <option :value="false">{{$t('global.inativo')}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row justify-content-end">
                            <div v-on:click="carregarLocaisAtendimento()" 
                                class="ponteiro text-white btn btn-primary rounded btn-sm px-4 my-4">
                                <span>{{$t('sols.filtro')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('localAtendimento.tituloLista')}}</h4>
                    <a @click="criarLocalAtendimento()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('global.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarLocDiv" style="display: none;">
                        <LocalAtendimentoEditar 
                            :localAtendimento="novoLocalAtendimento"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                        />
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>#</strong></th>
                                    <th><strong>{{$t('global.icone')}}</strong></th>
                                    <th><strong>{{$t('localAtendimento.local')}}</strong></th>
                                    <th><strong>{{$t('localAtendimento.status')}}</strong></th>
                                    <th><strong>{{$t('localAtendimento.acao')}}</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in locaisAtendimento" :key="item.idlocalatendimento">
                                <tr>
                                    <td><strong>{{item.idlocalatendimento}}</strong></td>
                                    <td><div><span class="material-icons icon font-lg">{{item.icone}}</span></div></td>
                                    <td><strong>{{item.descricao}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">{{$t('global.inativar')}}</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">{{$t('global.ativar')}}</a>
                                                <a class="dropdown-item" @click="editar(item)">{{$t('global.editar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'Editar_'+item.idlocalatendimento" style="display: none;">
                                            <LocalAtendimentoEditar
                                                :localAtendimento="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import LocalAtendimentoEditar from './LocalAtendimentoEditar.vue'
import Pagination from './Pagination.vue'

export default {
    components: {LocalAtendimentoEditar,Pagination},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            locaisAtendimento: [],
            novoLocalAtendimento: {
                idlocalatendimento: 0,
                idioma: this.$i18n.locale,
                descricao: '',
                ativo: false
            },
            filtro: {
                idlocalatendimento: '',
                descricao: '',
                ativo: null
            },
            exibirFiltro: false,
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
        }
    },
    methods: {
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarLocaisAtendimento();
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.exibirFiltro = true
                } else {
                    el.style.display = 'none'
                    this.exibirFiltro = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.locaisAtendimento = []
            this.filtro = {
                idlocalatendimento: '',
                descricao: '',
                ativo: null
            }
            window.setTimeout(this.carregarLocaisAtendimento(), 1000);
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idlocalatendimento: item.idlocalatendimento,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarLocalAtendimento() {
            var obj = document.getElementById('criarLocDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarLocDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                descricao: item.descricao,
                icone: item.icone,
                ativo: false,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarLocaisAtendimento()
                    this.closeNew()
                    this.$snack('Local de atendimento cadastrado.')
                } else {
                    this.$snackW(`Erro ao cadastrar local de atendimento cadastrado. response.status: ${response.status}`)
                }
            })
            .catch((e)=>{
                this.$snackE(`Erro ao cadastrar local de atendimento cadastrado. e.message: ${e.message}`)
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idlocalatendimento)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idlocalatendimento)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idlocalatendimento: item.idlocalatendimento,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                descricao: item.descricao,
                icone: item.icone,
                ativo: item.ativo,
                idioma: item.idioma,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarLocaisAtendimento()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        carregarLocaisAtendimento() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "idlocalatendimento": this.filtro.idlocalatendimento,
                "descricao": this.filtro.descricao,
                "ativo": this.filtro.ativo,
                "page": this.page,
                "pagesize": this.pageSize
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.locaisAtendimento = response.data.dados;
                    this.recordCount = response.data.recordCount;
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1;
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarLocaisAtendimento();
    }
}
</script>

