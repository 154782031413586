<template>
  <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Cadastrar Perguntas:</h4>
                    <a @click="$router.push('/painel/gerperguntas')" class="ponteiro text-white btn btn-info mt-4"> &#8592; voltar </a>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="col-md-12 mb-3">
                                        <h4>Cadastro de tipo de Pergunta</h4>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Tipo de pergunta:</label>
                                    <input v-model="tipopergunta" type="text" class="form-control" placeholder="Ex. Pergunta sobre wifi">
                                </div>
                                    <div class="form-group col-md-4">
                                    <label>Referencia:</label>
                                        <select class="form-control" v-model="selectData">
                                        <option :value="datacheckin">Check-in</option>
                                        <option :value="datacheckoutprev">Check-out</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2">
                                    <label>Dia de envio:</label>
                                    <input v-model="contagemDias" type="number" class="form-control" placeholder="1 ou -1">
                                </div>
                                <button @click="cadTipo()" type="button" class="btn btn-primary mt-4 mb-4" id="toastr-success-top-right"> + Cadastrar</button>
                            </div>
                            <hr>
                            <div class="form-row">
                                <div class="col-md-12 mb-3">
                                        <h4>Cadastro de Categoria de pergunta</h4>
                                </div>
                                    <div class="form-group col-md-4">
                                    <label>Categoria:</label>
                                    <input v-model="catPergunta" type="text" class="form-control" placeholder="Ex. Check-in">
                                </div>
                                <button @click="cadCat()" type="button" class="btn btn-primary mt-4 mb-4" id="toastr-success-top-right"> + Cadastrar</button>
                            </div>
                            <hr>
                            <div class="form-row">
                                <div class="form-group col-md-5">
                                    <label>Tipo da Pergunta:</label>
                                    <select class="form-control" v-model="tiposelect">
                                        <option v-for="tipo in tipos" :key="tipo" :value="tipo.idtipoavaliacao">{{tipo.desctipoavaliacao}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-5">
                                    <label>Categoria da Pergunta:</label>
                                    <select class="form-control" v-model="catselect">
                                        <option v-for="categoria in categorias" :key="categoria" :value="categoria.idcategoriapergunta">{{categoria.descricao}}</option>
                                    </select>
                                </div>
                            </div>	
                            <hr/>
                            <div class="form-row">	
                                <div class="form-group col-md-7">
                                    <label>Pergunta:</label>
                                    <input v-model="textopergunta" type="text" class="form-control" placeholder="">
                                </div>
                                    <div class="form-group col-md-4">
                                    <label>Descrição:</label>
                                    <input v-model="descricao" type="text" class="form-control" placeholder="">
                                </div>
                            </div>	
                            <!-- <div class="form-row">	    
                                <div class="form-group col-md-4">
                                    <label>Opção 1:</label>
                                    <input type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Opção 2:</label>
                                    <input type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-1">
                                    <button type="submit" class="btn btn-success mb-2 mt-4">+</button>
                                </div>
                            </div>	 -->
                            <hr/>
                            <div class="form-row">		
                                <div class="form-group col-md-4">
                                    <label>Ativo:</label>
                                    <select class="form-control" v-model="pergativa">
                                        <option :value="true">Ativar</option>
                                        <option :value="false">Desativar</option>
                                    </select>
                                </div>
                            </div>
                            <button @click="cadPergunta()" type="button" class="btn btn-primary mt-4" id="toastr-success-top-right"> + Cadastrar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tipopergunta: '',
            tiposelect: '',
            contagemDias: '',
            selectData: '',
            catPergunta: '',
            catselect: '',
            tipos: [],
            categorias: [],
            textopergunta: '',
            descricao: '',
            pergativa: null,
        }
    },
    methods: {
        cadTipo() {
            var tipo = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                desctipoavaliacao: this.tipopergunta,
                ativo: true,
                idusuario: '2',
                intervalo: this.contagemDias,
                datareferida: this.selectData,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/insert',
            tipo,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    alert(response.data.message)
                    this.$router.push('/painel/dashboard')
                    } 
            })
        },
        cadCat() {
            var categoria = {
                descricao:this.catPergunta,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/insert',
            categoria, {headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    alert(response.data.message)
                    this.$router.push('/painel/dashboard')
                }
                
            })
        },
        getTipo() {
            axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/select',
                    {"ativo":true, "idioma" : "pt-BR"}, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.tipos = response.data
                }
            })
        },
        getCat() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/select',
                    {"idioma" : "pt-BR", "idhotel": this.$store.getters.GetLoginInfo.idhotel}, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data
                }
            })
        },
        cadPergunta() {
            var pergunta = { 
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                ativo: this.pergativa,
                idtipoavaliacao : this.tiposelect,
                posicao: '70',
                idcategoriapergunta: this.catselect,
                idioma: 'pt-BR',
                codreduzido: this.descricao,
                textopergunta: this.textopergunta,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/insert',
                    pergunta, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    alert(response.data.message)
                    this.$router.push('/painel/dashboard')
                }
            }).catch((err) => {
                console.log({ err_menu: err });
            })
        }
    },
    mounted() {
        this.getTipo();
        this.getCat();
    }
}
</script>

<style>

</style>