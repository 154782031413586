<template>
    <div class="perguntas-aval">
        <div class="card-header">
            <h6 class="card-title">{{avaliacao.desctipoavaliacao}}</h6>
            <a @click="criarPergunta(avaliacao)" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Nova Pergunta</a>
        </div>
        <div class="row pl-1">
            <div class="row" :id="'criarPerguntaDiv_' + avaliacao.idtipoavaliacao" style="display: none;">
                <AvaliacaoPerguntaEditar
                    :pergunta="novaPergunta"
                    v-on:save="saveNew($event)"
                    v-on:close="closeNew()"
                />
            </div>
            <div class="table-responsive">
                <table class="table table-responsive-md">
                    <thead>
                        <tr>
                            <th><strong>Categoria</strong></th>
                            <th><strong>Pergunta</strong></th>
                            <th><strong>Status</strong></th>
                            <th><strong>Ação</strong></th>
                        </tr>
                    </thead>
                    <tbody v-for="(pergunta, index) in perguntas" :key="index">
                        <tr>
                            <td>
                                <p>{{pergunta.categoria}}</p>
                            </td>
                            <td>
                                <p><strong>{{pergunta.textopergunta}}</strong></p>
                            </td>
                            <td>
                                <span :class="{ 'badge-success' : pergunta.ativo, 'badge-danger' : !pergunta.ativo }" class="badge light">{{(pergunta.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a v-if="pergunta.ativo" class="dropdown-item" @click="ativar(pergunta, false)">Inativar</a>
                                        <a v-if="!pergunta.ativo" class="dropdown-item" @click="ativar(pergunta, true)">Ativar</a>
                                        <a v-if="index >= 0 && index < (perguntas.length-1)" class="dropdown-item" @click="abaixo(pergunta)">Abaixo</a>
                                        <a v-if="index > 0 && index < perguntas.length" class="dropdown-item" @click="acima(pergunta)">Acima</a>
                                        <a class="dropdown-item" @click="editar(pergunta)">Editar</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div :id="'Editar_'+pergunta.idperguntaformavaliacao" style="display: none;">
                                    <AvaliacaoPerguntaEditar 
                                        :pergunta="pergunta" 
                                        v-on:save="saveEdit($event)"
                                        v-on:close="closeEdit(pergunta)"
                                    />
                                </div>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10">
            </div>    
            <div class="col-sm-2">
                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info">Fechar</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import AvaliacaoPerguntaEditar from '../components/AvaliacaoPerguntaEditar.vue'

export default {
    components: {AvaliacaoPerguntaEditar},
    props: {
        avaliacao: {
            type: Object,
            required: false,
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            perguntas: [],
            novaPergunta: {
                idioma: this.$i18n.locale,
                codreduzido: '',
                textopergunta: '',
                idtipoavaliacao: this.avaliacao.idtipoavaliacao,
                idcategoriapergunta: 1,
                ativo: false
            }
        }
    },
    methods: {
        abaixo (item) {
            this.ordenar(item, 'Abaixo')
        },
        acima (item) {
            this.ordenar(item, 'Acima')
        },
        ordenar(item, ordenar) {
            var params = {
                idperguntaformavaliacao: item.idperguntaformavaliacao,
                idtipoavaliacao: item.idtipoavaliacao,
                ordenar : ordenar
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/pergunta/ordenar',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadPerguntas(this.avaliacao)
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idperguntaformavaliacao: item.idperguntaformavaliacao,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarPergunta() {
            var obj = document.getElementById('criarPerguntaDiv_' + this.avaliacao.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idtipoavaliacao: item.idtipoavaliacao,
                idcategoriapergunta: item.idcategoriapergunta,
                textopergunta: item.textopergunta,
                posicao: this.perguntas.length + 1,
                ativo: item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idioma: item.idioma,
                codreduzido: item.codreduzido,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadPerguntas(this.avaliacao)
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        closeNew() {
            var obj = document.getElementById('criarPerguntaDiv_' + this.avaliacao.idtipoavaliacao)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idperguntaformavaliacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idperguntaformavaliacao)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idperguntaformavaliacao: item.idperguntaformavaliacao,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idtipoavaliacao: item.idtipoavaliacao,
                idcategoriapergunta: item.idcategoriapergunta,
                textopergunta: item.textopergunta,
                posicao: item.posicao,
                ativo: item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idioma: item.idioma,
                codreduzido: item.codreduzido,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            // alert(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/fdguest/pergunta/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.closeEdit(item)
                    this.loadPerguntas(this.avaliacao)
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        async loadPerguntas(item) {
            var params = {
                "idtipoavaliacao" : item.idtipoavaliacao,
                "idioma" : this.$i18n.locale
            }
			await axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/select', params, {headers: this.headers})
			.then(response => {
                if (response.status == 200) {
                    if (response.data) {
                        this.perguntas = response.data;
                    }
                } else {
                    console.log('Erro: ' + response.status)
                }
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
        this.loadPerguntas(this.avaliacao);
    },
    mounted() {
        this.avaliacaoEditada = {... this.avaliacao}
    }
}
</script>

<style scoped>
.perguntas-aval {
    background-color: azure!important;
    padding: 10px;
    border-radius: 10px;
}
</style>