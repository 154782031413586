<template>
    <div class="row" :id="`wizard-window`" style="display:none;z-index:10000;">
        <WizardTitulo v-if="step==0" :tipo="editedTipo" 
            :step="steps[step]" :idiomas="idiomas" :steps="steps"
            @first="first()" @last="last()" 
            @previous="previous()" @next="next()"
            @save="save()" @close="close()" :saving="saving"
            :categoriasInternas="categoriasInternas"
        ></WizardTitulo>
        <WizardMultiplo v-if="step==1" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :steps="steps"
            @previous="previous()" @next="next()"
            @save="save()" @close="close()" :saving="saving"
        ></WizardMultiplo>
        <WizardTituloItem v-if="step==2" :tipo="editedTipo" 
            :step="steps[step]" :idiomas="idiomas" :item="editedItem"
            @first="first()" @last="last()" :steps="steps"
            @previous="previous()" @next="next()"
            @save="save()" @close="close()" :saving="saving"
            :itensInternos="itensInternos"
        ></WizardTituloItem>
        <WizardTipo v-if="step==3" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :item="editedItem"  :steps="steps"
            @previous="previous()" @next="next()" :saving="saving"
            @save="save()" @close="close()" @prod="carregarGruposProdutos($event)"
        ></WizardTipo>
        <!-- <WizardProdutos v-if="step==4" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :item="editedItem"  :steps="steps"
            @previous="previous()" @next="next()"
            @save="save()" @close="close()" :saving="saving"
        ></WizardProdutos> -->
        <WizardAdicionalAlerta v-if="step==4" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :item="editedItem" :idiomas="idiomas"
            @previous="previous()" @next="next()" :steps="steps"
            @save="save()" @close="close()" :saving="saving"
        ></WizardAdicionalAlerta>
        <WizardChavePadrao v-if="step==5" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :item="editedItem" :idiomas="idiomas"
            @previous="previous()" @next="next()" :steps="steps"
            @save="save()" @close="close()" :saving="saving"
        ></WizardChavePadrao>
        <WizardHorarioAgenda v-if="step==6" :tipo="editedTipo" :step="steps[step]"
            @first="first()" @last="last()" :item="editedItem" :idiomas="idiomas"
            @previous="previous()" @next="next()" :steps="steps"
            @save="save()" @close="close()" :saving="saving"
        ></WizardHorarioAgenda>

    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import WizardTipo from './WizardTipo.vue'
import WizardTitulo from './WizardTitulo.vue'
import WizardAdicionalAlerta from './WizardAdicionalAlerta.vue'
import WizardMultiplo from './WizardMultiplo.vue'
import WizardTituloItem from './WizardTituloItem.vue'
import WizardChavePadrao from './WizardChavePadrao.vue'
import WizardHorarioAgenda from './WizardHorarioAgenda.vue'
// import WizardProdutos from './WizardProdutos.vue'

export default {
    name: 'Wizard',
    props: {
        editedTipo: {
            type: Object,
            required: true
        },
        editedItem: {
            type: Object,
            required: true
        },
        display:{
            type: Boolean,
            required: true
        },
        stepInicial: {
            type: Number,
            required: false,
            default: -1
        }
    },
    components: { WizardTitulo, WizardMultiplo, WizardTipo, WizardAdicionalAlerta
        , WizardTituloItem, WizardChavePadrao, WizardHorarioAgenda
        // , WizardProdutos
        },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            // testeTipo: {
            //     idtiposolicitacao: 1,
            //     idhotlinesetores: 2,
            //     titulo: 'Acordar no Horário',
            //     subtitulo: '',
            //     solicitacoesmultiplas: false,
            //     confirmacaoindividual: false,
            //     ativo: true,
            //     idioma: 'pt-BR',
            //     idusuario: 52
            // },
            // testeItem: {
            //     idtiposolicitacaoitem: 26,
            //     idtiposolicitacao: 1,
            //     tipo: '3',
            //     titulo: 'Acordar no Horário',
            //     descricao: '',
            //     infoadicional: true,
            //     infoalerta: null,
            //     valordefault: 0,
            //     chaveativadora: true,
            //     incluirnaagenda: false,
            //     horarioatendimento: false,
            //     horariodisp: '',
            //     idioma: 'pt-BR'
            // },
            step:0,
            steps: [
                {
                    passo: 1,
                    titulo: 'Título e subtítulo',
                    descricao: 'Esse é o título que aparece logo acima do controle do tipo de solicitação e o subtítulo aparece logo abaixo. O subtítulo é opcional.',
                },
                {
                    passo: 2,
                    titulo: 'Múltiplos Ítens de Solicitação',
                    descricao: 'Como podemos ter vários ítens diferentes em um grupo, é preciso escolher se cada um vai ter seu botão individual de confirmação. Por outro lado, caso seja um botão único pra confirmar todos selecionados, é preciso determinar se o FDGuest deve gerar uma única solicitação para todos ou se deve gerar solicitações separadas para cada ítem.',
                },
                {
                    passo: 3,
                    titulo: 'Título e Descrição do Ítem',
                    descricao: 'Esse é o título que aparece logo acima do ítem de solicitação e a descrição aparece logo abaixo. A descrição é opcional.',
                },
                {
                    passo: 4,
                    titulo: 'Tipo de Controle Visual',
                    descricao: 'Escolha o tipo de solicitação:',
                },
                // {
                //     passo: 5,
                //     titulo: 'Produtos',
                //     descricao: 'Escolha os produtos que devem aparecer para o hóspede escolher para este tipo de solicitação.',
                // },
                {
                    passo: 5,
                    titulo: 'Informação Adicional e Informação de Alerta',
                    descricao: 'Opcionalmente, é possível ao hotel informar ao hóspede sobre quaisquer condições para o atendimento em uma mensagem de alerta ao item. Também pode-se acrescentar uma caixa de texto para que o hóspede coloque informações adicionais sobre a solicitação.',
                },
                {
                    passo: 6,
                    titulo: 'Chave Seletora e Valor Padrão',
                    descricao: 'Podemos determinar também se o ítem pode ser ativado por uma chave seletora.',
                },
                {
                    passo: 7,
                    titulo: 'Disponibilidade de Horários e Agendamentos',
                    descricao: 'Podemos determinar os horários para os quais um ítem pode ser solicitado.',
                }
            ],
            idiomas: [],
            saving: false,
            categoriasInternas: [],
            itensInternos: []
        }
    },
    watch: {
        step() {
            this.$forceUpdate();
        },
        display(val) {
            let id = `wizard-window`
            let el = document.getElementById(id)
            console.error(`display (inicio): ${el.id}, step: ${this.step}`)
            if (val === true) {
                console.error('unfade...')
                this.unfade(el)
            } else {
                console.error('fade...')
                this.fade(el)
            }
            if (this.stepInicial >= 0) {
                this.step = this.stepInicial
            } else {
                this.step = 0
            }
            console.error(`display (final): ${el.id}, step: ${this.step}`)
        },
        editedItem(val) {
            this.carregarGruposProdutos(val.idtiposolicitacaoitem)
        }
    },
    methods: {
        carregarCategoriasInternas() {
            var params = {
                idioma: this.$i18n.locale,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                interna: true
            }
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.categoriasInternas = response.data;
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        
        },
        carregarItensInternos() {
            var params = {
                idioma: this.$i18n.locale,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                interna: true,
                orderby: 'categoria,titulo'
            }
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.itensInternos = response.data;
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        
        },
		carregarGruposProdutos(iditem) {
            var params = {
                idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                iditem: iditem?iditem:0,
                incluirprodutos: true,
                legado: false
            }
            //console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/produto/grupo/select',
            params, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    //this.gruposprodutos = response.data
                    this.editedItem.gruposprodutos = response.data
                }
            })
		},
        save() {
            this.saveTipo()
        },
        saveTipo() {
            let params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idhotlinesetores: this.editedTipo.idhotlinesetores,
                titulo: this.editedTipo.titulo,
                subtitulo: this.editedTipo.subtitulo,
                solicitacoesmultiplas: this.editedTipo.solicitacoesmultiplas,
                confirmacaoindividual: this.editedTipo.confirmacaoindividual,
                ativo: this.editedTipo.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                tipovinculado: this.editedTipo.tipovinculado,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            let route = `${CONSTANTS.API_URL}/fdguest/tiposolicitacao/insert`
            if (this.editedTipo.idtiposolicitacao&&this.editedTipo.idtiposolicitacao>0) {
                params.idtiposolicitacao = this.editedTipo.idtiposolicitacao
                route = `${CONSTANTS.API_URL}/fdguest/tiposolicitacao/update`
                params.alterado_por = this.$store.getters.GetLoginInfo.idusuario
            } else {
                params.criado_por = this.$store.getters.GetLoginInfo.idusuario
            }
            console.log(JSON.stringify(params))
            this.saving = true
            axios.post(route, params, { headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    // Se estiver inserindo, passar o id do tipo criado
                    if (!params.idtiposolicitacao) {
                        let idtipo = response.data.ids[0]
                        this.editedTipo.idtiposolicitacao = idtipo
                    }
                    this.saveItem();
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        saveItem() {
            // Enviar ao backend apenas os grupos com ítens modificados...
            let modifiedGroups = []
            let modifiedProds = null
            if (this.editedItem.tipo == 6) {
                for (var i = 0; i < this.editedItem.gruposprodutos.length; i++) {
                    let grupo = Object.assign({},this.editedItem.gruposprodutos[i])
                    modifiedProds = []
                    for (var j = 0; j < grupo.produtos.length; j++) {
                        let produto = grupo.produtos[j]
                        if (produto.changed) {
                            modifiedProds.push(produto)
                        }
                    }
                    if (modifiedProds.length > 0) {
                        grupo.produtos = modifiedProds
                        modifiedGroups.push(grupo)
                    }
                }
            }
            let params = {
                idtiposolicitacao: this.editedTipo.idtiposolicitacao,
                tipo: this.editedItem.tipo,
                titulo: this.editedItem.titulo,
                descricao: this.editedItem.descricao,
                chaveativadora: this.editedItem.chaveativadora,
                infoadicional: this.editedItem.infoadicional,
                valordefault: this.editedItem.valordefault,
                horarioatendimento: this.editedItem.horarioatendimento,
                horariodisp: this.editedItem.horariodisp,
                infoalerta: this.editedItem.infoalerta,
                incluirnaagenda: this.editedItem.incluirnaagenda,
                antecedencia: this.editedItem.antecedencia,
                posicao: this.editedItem.posicao?this.editedItem.posicao:1,
                ativo: this.editedItem.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idioma: this.editedItem.idioma,
                gruposprodutos: this.editedItem.tipo == 6 ? modifiedGroups : null,
                itemvinculado: this.editedItem.itemvinculado
            }
            let route = `${CONSTANTS.API_URL}/fdguest/tiposolicitacaoitem/insert`
            if (this.editedItem.idtiposolicitacaoitem&&this.editedItem.idtiposolicitacaoitem>0) {
                params.idtiposolicitacaoitem = this.editedItem.idtiposolicitacaoitem
                route = `${CONSTANTS.API_URL}/fdguest/tiposolicitacaoitem/update`
                params.alterado_por = this.$store.getters.GetLoginInfo.idusuario
            } else {
                params.criado_por = this.$store.getters.GetLoginInfo.idusuario
            }
            console.log(JSON.stringify(params))
            axios.post(route, params, { headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.$emit('close')
                    this.$emit('update')
                } else {
                    console.log('Erro: ' + response.status)
                }
                this.saving = false
            })
        },
        first() {
            this.step = 0
        },
        previous() {
            if (this.step > 0) {
                this.step--
                // Pular o passo de produtos se o item não for do tipo produtos
                // if (this.step == 4) {
                //     if (this.editedItem.tipo != 6) {
                //         this.step--
                //     }
                // }
            }
        },
        next() {
            if (this.step < this.steps.length-1) {
                this.step++
                // Pular o passo de produtos se o item não for do tipo produtos
                // if (this.step == 4) {
                //     if (this.editedItem.tipo != 6) {
                //         this.step++
                //     }
                // }
            }
        },
        last() {
            this.step = this.steps.length-1
            //this.$forceUpdate()
        },
        close() {
            //this.dialogo = false
            console.error('vai fechar...')
            // let el = document.getElementById('wizard-window')
            // this.fade(el)
            this.$emit('close')
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },        
        carregarMailConfig() {
            axios.get(CONSTANTS.API_URL + '/email/conta/get', { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.emailConfig = response.data
                }
            })
        },
        salvarMailConfig() {
            console.log('salvarMailConfig()...')
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idmailer: this.emailConfig.idmailer,
                host: this.emailConfig.host, 
                port: this.emailConfig.port,
                user: this.emailConfig.user,
                pass: this.emailConfig.pass,
                replyto: this.emailConfig.replyto,
                nametoexibition: this.emailConfig.nametoexibition,
                ativo: this.emailConfig.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            }
			//log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/email/atualizaContaEmail',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarMailConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
            .catch((e)=>{
                this.statusCode = 2
                this.statusMsg = 'Erro ao tentar salvar configuração!'
                console.error(JSON.stringify(e))
            })
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }        
    },
    computed: {
    },
    mounted() {
        this.loadIdiomas();
        this.carregarCategoriasInternas();
        this.carregarItensInternos();
    }
}
</script>

<style>

.es-wizard {
    width: 80vw;
    height: 80vh;
    border-radius: 1em;
    background-color: rgb(19, 64, 141);
    /* opacity: 0.7;  */
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;    
    margin: auto;
    z-index: 100;
}
.es-content {
    opacity: 1.0!important;
    background-color: white;
    border-radius: 1em;
    padding: 1.5em;
    margin: 1em;
    height: 97%;
    display: flex;
    flex-direction: column;
    z-index: 10000;
}
.es-title {
    height: 10%;
}
.es-step {
    height: 80%;
    overflow: hidden;
    overflow-y: auto;
}
.es-buttons {
    height: 10%;
    width: 100%;
    flex-direction: row;
    border-radius: 1em;
    align-items: center;
    align-content: space-between;
    flex-wrap: nowrap;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
}
.es-buttons button {
    padding: 0.5em;
    margin: 0.5em;
    border-radius: 0.5em;
}
.es-btn-primary{
    color:white;
    background-color: rgb(10,195,242);
}
.es-btn-primary:hover{
    color:white!important;
    background-color: rgb(17, 148, 180);
}
.es-btn-secondary{
    width: 80px;
    color:white;
    background-color: rgb(42,82,206);
}
.es-btn-secondary:hover{
    color:white;
    background-color: rgb(25, 50, 126);
}
.es-btn-success{
    width: 100px;
    color:white;
    background-color: rgb(60, 29, 134);
}
.es-btn-success:hover{
    color:white;
    background-color: rgb(44, 27, 82);;
}
.es-btn-close{
    width: 100px;
    color:white;
    background-color: rgb(119, 73, 224)
}
.es-btn-close:hover{
    color:white;
    background-color: rgb(86, 52, 165)
}
.es-text {
    padding: 1em;
    border-radius: 1em;
}
.es-imagem-wizard {
    display: flex;
    direction: row-reverse;
    flex-wrap: wrap;
    align-content: space-around;
}
</style>