<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('reviewsportal.titulo')}}</h4>
                <p>{{$t('reviewsportal.descricao')}}</p>
            </div>
        </div>
        <!-- Portais de Reviews -->
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('reviewsportal.portaiscadastrados')}}</h4>
                    <a @click="criarHotelPortal()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarHotelPortalDiv" style="display: none;">
                        <reviews-portal-editar
                            :hotelPortal="novoHotelPortal"
                            v-on:save="salvarNovoHotelPortal($event)"
                            v-on:close="fecharCriarHotelPortal()"
                        />
                    </div>
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>Nome</strong></th>
                                    <th><strong>Link</strong></th>
                                    <th><strong>Ponto limite</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>Ação</strong></th>
                                </tr>
                            </thead>
                            <div v-if="!hotelPortalItens||hotelPortalItens.length==0" class="row text-orange text-center"><strong>Carregando...</strong></div>
                            <tbody v-for="item in hotelPortalItens" :key="item.idhotelportal">
                                <tr>
                                    <td><strong>{{item.nome}}</strong></td>
                                    <td><strong>{{item.link}}</strong></td>
                                    <td><strong>{{item.pontolimite}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativarHotelPortal(item, false)">Inativar</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativarHotelPortal(item, true)">Ativar</a>
                                                <a class="dropdown-item" @click="editarHotelPortal(item)">Editar</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'EditarHotelPortal_'+item.idhotelportal" style="display: none;">
                                            <reviews-portal-editar
                                                :hotelPortal="item" 
                                                v-on:save="salvarHotelPortal($event)"
                                                v-on:close="fecharEditarHotelPortal(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import ReviewsPortalEditar from './ReviewsPortalEditar.vue'

export default {
    components: { ReviewsPortalEditar },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            novoHotelPortal: {
                idhotelportal: 0,
                idhotel: this.$store.getters.GetLoginInfo.hotel.idhotel,
                idioma: this.$i18n.locale,
                nome: '',
				link: '',
				pontolimite: 4.0,
                ativo: true
            },
			hotelPortalItens: [],
        }
    },
    methods: {
		criarHotelPortal() {
            var obj = document.getElementById('criarHotelPortalDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        fecharCriarHotelPortal() {
            var obj = document.getElementById('criarHotelPortalDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        salvarNovoHotelPortal(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                nome: item.nome,
				link: item.link,
				pontolimite: item.pontolimite,
                ativo: item.ativo,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
			// console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelportal/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarHotelPortalItens()
                    this.fecharCriarHotelPortal()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        carregarHotelPortalItens() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelportal/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.hotelPortalItens = response.data
                }
            })
        },
        editarHotelPortal(item) {
            var obj = document.getElementById('EditarHotelPortal_'+item.idhotelportal)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        fecharEditarHotelPortal(item) {
            var obj = document.getElementById('EditarHotelPortal_'+item.idhotelportal)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        salvarHotelPortal(item) {
            var params = {
                idhotelportal: item.idhotelportal,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                nome: item.nome,
				link: item.link,
                pontolimite: item.pontolimite,
                ativo: item.ativo,
                idioma: item.idioma,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
			//console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelportal/update',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.carregarHotelPortalItens()
                    this.fecharEditarHotelPortal(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
		ativarHotelPortal(item, ativo) {
            var itemAtivar = {
                idhotelportal: item.idhotelportal,
                ativo: ativo
            }
            if (this.salvarHotelPortal(itemAtivar)) {
                item.ativo = ativo;
            }
        },

    },
    computed: {
    },
    mounted() {
        this.carregarHotelPortalItens();
    }
}
</script>

<style scoped>

.material-icons {
    font-family: 'Material Icons';
    font-size: 30px;
}

</style>