<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('categoriaPergunta.titulo')}}</h4>
                <p>{{$t('categoriaPergunta.descricao')}}</p>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Categorias de pergunta cadastradas</h4>
                    <a @click="criarCategoriaPergunta()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarCatDiv" style="display: none;">
                        <CategoriaPerguntaEditar 
                            :categoriaPergunta="novaCategoriaPergunta"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                        />
                    </div>
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>Icone</strong></th>
                                    <th><strong>Categoria</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>Ação</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in categoriasPergunta" :key="item.idcategoriapergunta">
                                <tr>
                                    <td><div><span class="material-icons icon font-lg">{{item.icone}}</span></div></td>
                                    <td><strong>{{item.descricao}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">Inativar</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">Ativar</a>
                                                <a class="dropdown-item" @click="editar(item)">Editar</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'Editar_'+item.idcategoriapergunta" style="display: none;">
                                            <CategoriaPerguntaEditar
                                                :categoriaPergunta="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import CategoriaPerguntaEditar from '../components/CategoriaPerguntaEditar.vue'

export default {
    components: {CategoriaPerguntaEditar},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            categoriasPergunta: [],
            novaCategoriaPergunta: {
                idcategoriapergunta: 0,
                idioma: this.$i18n.locale,
                descricao: '',
                ativo: false
            }
        }
    },
    methods: {
        ativar(item, ativo) {
            var itemAtivar = {
                idcategoriapergunta: item.idcategoriapergunta,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarCategoriaPergunta() {
            var obj = document.getElementById('criarCatDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarCatDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                descricao: item.descricao,
                icone: item.icone,
                ativo: false,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadCategoriasPergunta()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idcategoriapergunta)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idcategoriapergunta)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idcategoriapergunta: item.idcategoriapergunta,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                descricao: item.descricao,
                icone: item.icone,
                ativo: item.ativo,
                idioma: item.idioma,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/categoriapergunta/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadCategoriasPergunta()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        loadCategoriasPergunta() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.categoriasPergunta = response.data
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.loadCategoriasPergunta();
    }
}
</script>
