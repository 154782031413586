<template>
    <div class="es-wizard">
        <div class="es-content">
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <div class="es-title"><h2>{{step.titulo}}</h2></div>
                </div>
            </div>
            <div class="es-step">

                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <h4>{{step.descricao}}</h4>
                    </div>
                </div>
                
                <div class="form-row justify-content-center">
                    <div class="form-group col-sm-6 col-md-auto">
                        <label class="container">
                            <input name="radio" type="radio" checked  value="2" v-model="item.tipo">
                            <span class="checkmark"></span>
                            <div class="checktext">Simples</div>
                        </label>                            
                        <img :src="'../../img/wizard-06.png'" class="p-10 mb-20" height="190" width="350" />
                    </div>
                    <div class="form-group col-sm-6 col-md-auto">
                        <label class="container">
                            <input name="radio" type="radio" value="1" v-model="item.tipo">
                            <span class="checkmark"></span>
                            <div class="checktext">Quantidade</div>
                        </label>
                        <img :src="'../../img/wizard-07.png'" class="p-10 mb-20" height="190" />
                    </div>
                </div>
                <div class="form-row justify-content-center">
                    <div class="form-group col-sm-6 col-md-auto">
                        <label class="container">
                            <input name="radio" type="radio" value="3" v-model="item.tipo">
                            <span class="checkmark"></span>
                            <div class="checktext">Com horário</div>
                        </label>
                        <img :src="'../../img/wizard-08.png'" class="p-10 mb-20" height="190" />
                    </div>
                    <!-- <div class="form-group col-sm-6 col-md-auto"
                        v-if="tipo.confirmacaoindividual||!tipo.confirmacaoindividual&&!tipo.solicitacoesmultiplas"
                    >
                        <label class="container">
                            <input name="radio" type="radio" value="6" v-model="item.tipo" @change="check(item.tipo)">
                            <span class="checkmark"></span>
                            <div class="checktext">Com produtos</div>
                        </label>                            
                        <img :src="'../../img/wizard-09.png'" class="p-10 mb-20" height="190" />
                    </div> -->
                </div>
            </div>
            <div class="es-buttons">
                <button @click="primeiro()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo">Início</button>
                <button @click="anterior()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo">Anterior</button>
                <button @click="proximo()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo">Próximo</button>
                <button @click="ultimo()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo">Último</button>
                <button :disabled="saving" @click="salvar()" type="button" class="btn es-btn-success" id="salvar-titulo-tipo">{{saving?'Salvando...':'Salvar'}}</button>
                <button @click="fechar()" type="button" class="btn es-btn-close" id="fechar-titulo-tipo">Fechar</button>
            </div>

            <div class="form-row justify-content-center">
                <h5>{{`Passo ${step.passo} de ${steps.length}`}}</h5>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    name: 'WizardTipo',
    props: {
        tipo: {
            type: Object,
            required: false
        },
        item: {
            type: Object,
            required: true
        },
        step: {
            type: Object,
            required: false          
        },
        steps: {type: Array},
        idiomas: {type: Array},
        saving: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            dialog: true,
            currentStep: {
                titulo: 'Passo 0',
                imagem: 1,
            },
            sound: true,
            disableStart: true,
            tiposItem: [
                {tipo: 1, descricao: 'Quantidade (só clicar na quantidade e no botão)'},
                {tipo: 2, descricao: 'Simples (só clicar no botão)'},
                {tipo: 3, descricao: 'Horário (só escolher o horário e clicar no botão)'},
                //{tipo: 4, descricao: 'Período de Tempo (só escolher horário inicial e final e clicar no botão)'},
                //{tipo: 5, descricao: 'Período de Tempo Dual (dois tipos de solicitação no mesmo quadro)'},
                //{tipo: 6, descricao: 'Produtos (com assinatura)'}
            ],
        }
    },
    watch: {
        step() {
            this.$forceUpdate()
        },
        // item: {
        //     handler(val){
        //         this.$emit('prod',val.idtiposolicitacaoitem)
        //     },
        //     deep: true
        // }
    },
    methods: {
        check(tipo) {
            if (tipo == "6") {
                this.$emit('prod',this.item.idtiposolicitacaoitem)
            }
        },
        primeiro() {
            this.$emit('first')
        },
        anterior() {
            this.$emit('previous')
        },
        proximo() {
            this.$emit('next')
        },
        ultimo(){
            this.$emit('last')
        },
        salvar(){
            this.$emit('save')
        },
        fechar(){
            this.$emit('close')
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        carregarMailConfig() {
            axios.get(CONSTANTS.API_URL + '/email/conta/get', { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.emailConfig = response.data
                }
            })
        },
        salvarMailConfig() {
            console.log('salvarMailConfig()...')
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idmailer: this.emailConfig.idmailer,
                host: this.emailConfig.host, 
                port: this.emailConfig.port,
                user: this.emailConfig.user,
                pass: this.emailConfig.pass,
                replyto: this.emailConfig.replyto,
                nametoexibition: this.emailConfig.nametoexibition,
                ativo: this.emailConfig.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            }
			//log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/email/atualizaContaEmail',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarMailConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
            .catch((e)=>{
                this.statusCode = 2
                this.statusMsg = 'Erro ao tentar salvar configuração!'
                console.error(JSON.stringify(e))
            })
        }
    },
    computed: {
    },
    mounted() {
        
    }
}
</script>
<style scoped>
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid gray;
  border-radius: 1rem;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.checktext {
  padding: 0px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>