<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('chatAPI.titulo')}}</h4>
                <p>{{$t('chatAPI.descricao')}}</p>
            </div>
        </div>
        <!-- Portais de Reviews -->
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('chatAPI.titulo')}}</h4>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" name="language" class="ponteiro custom-control-input ponteiro" 
                                    id="Switch_Config"
                                    :checked="zapConfig.ativo"
                                    v-model="zapConfig.ativo"
                                >
                                <label class="ponteiro custom-control-label" for="Switch_Config">{{$t('chatAPI.ativardesativarapichat')}}</label>
                            </div>                                
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>Instance ID:</label>
                            <input v-model="zapConfig.instanceid" 
                                type="text" class="form-control" placeholder="'Instance ID' da api de chat.">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>Token</label>
                            <input v-model="zapConfig.token" 
                                type="text" class="form-control" placeholder="Token da api de chat.">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>Link:</label>
                            <input v-model="zapConfig.apiurl" 
                                type="text" class="form-control" placeholder="Link da api de chat.">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-auto">
                            <a v-on:click="salvarZapConfig()" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                        </div>
                    </div>
                    <div id="status-message" class="col cols-12" :class="{'text-orange': statusCode == 0, 'text-green': statusCode == 1, 'text-red': statusCode == 2}">
                        <p class="center">{{statusMsg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    components: {  },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            zapConfig: {
                idwhatsappchatapi: 0,
                instanceid: "",
                token: "",
                apiurl: "",
                ativo: true,
                idhotel: this.$store.getters.GetLoginInfo.hotel.idhotel
            },
            statusMsg: '',
            statusCode: 0
        }
    },
    methods: {
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        carregarZapConfig() {
           var  params = {
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/chatapi/whatsapp/get',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.zapConfig = response.data
                }
            })
        },
        salvarZapConfig() {
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idwhatsappchatapi: this.zapConfig.idwhatsappchatapi,
                instanceid: this.zapConfig.instanceid,
                token: this.zapConfig.token,
                apiurl: this.zapConfig.apiurl,
                ativo: this.zapConfig.ativo,
                idhotel: this.$store.getters.GetLoginInfo.hotel.idhotel
            }
			//log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/chatapi/whatsapp/set',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarZapConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarZapConfig();
    }
}
</script>
