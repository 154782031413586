<template>
	<div class="row">
		<!-- Reviews -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4 class="text-info">{{$t('relatorios.titulo')}}</h4>
					<div class="card">
						<div class="card-header">
							<!-- Escala -->
							<div class="row">
								<div class="box">
									<div>Escala de cor das médias</div>
								</div>
								<div class="box">
									<div style="padding-right: 5px;">1</div>
									<div v-for="(cor,index) in colors" :key="index">
										<span :style="'align-self:center;color:'+cor+';background-color:'+cor+';'">
											0
										</span>
									</div>
									<div style="padding-left: 5px;">5</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card-header">
					<p>{{$t('relatorios.descricao')}}</p>
					<div id="div-mais" style="display:none;background-color:#fafafa;padding:10px;margin:5px;border-radius:20px;">
						<p class="text-info">{{$t('relatorios.subtitulo1')}}</p>
						<p>{{$t('relatorios.subtitulo1descricao')}}</p>
						<p class="text-info">{{$t('relatorios.subtitulo2')}}</p>
						<p>{{$t('relatorios.subtitulo2descricao')}}</p>
						<p class="text-info">{{$t('relatorios.subtitulo3')}}</p>
						<p>{{$t('relatorios.subtitulo3descricao')}}</p>
					</div>
					<div><a style="color:blue;cursor:pointer;" id="div-mais-link" @click="alternar('div-mais','div-mais-link')">...mais</a></div>
				</div>
				<div class="card-body">
					<div class="basic-form">
						<div class="col-xl-12">
							<div class="card">
								<div class="card-body">
									<div id="accordion-one" class="accordion accordion-primary">
										<!-- Índice Geral -->
										<RelatoriosIndice
											:indice="indiceGeral"
											v-on:reload="reloadResults($event)"
										/>
										<!-- Avaliações -->
										<RelatoriosIndice
											:indice="indiceAvals"
											v-on:reload="reloadAvalsResults($event)"
										/>
										<!-- Categorias -->
										<RelatoriosIndice
											:indice="indiceCats"
											v-on:reload="reloadCatsResults($event)"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment";
import RelatoriosIndice from './RelatoriosIndice.vue'

export default {
	components: {RelatoriosIndice},
	data() {
		return {
			indiceGeral: {
				titulo: 'Índice Geral',
				codigo: 'GERAL',
				periodo: {
					dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD'))
				},
				cabecalhos: [
					{nome: 'Hotel', campo: 'nomehotel'},
					{nome: 'Avaliações', campo: 'avaliacoes'},
					{nome: 'Hóspedes', campo: 'respondentes'},
					{nome: 'Respostas', campo: 'respostas'},
					{nome: 'Média', campo: 'media'}
				],
				dados: [],
				dadosAnteriores: []
			},
			indiceAvals: {
				titulo: 'Índice por Avaliação',
				codigo: 'AVALS',
				periodo: {
					dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD'))
				},
				cabecalhos: [
					{nome: 'Avaliação', campo: 'avaliacao'},
					{nome: 'Categorias', campo: 'categorias'},
					{nome: 'Hóspedes', campo: 'respondentes'},
					{nome: 'Respostas', campo: 'respostas'},
					{nome: 'Média', campo: 'media'}
				],
				dados: [],
				dadosAnteriores: []
			},
			indiceCats: {
				titulo: 'Índice por Categoria',
				codigo: 'CATS',
				periodo: {
					dataInicial: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD'))
				},
				cabecalhos: [
					{nome: 'Categoria', campo: 'categoria'},
					{nome: 'Avaliações', campo: 'avaliacoes'},
					{nome: 'Hóspedes', campo: 'respondentes'},
					{nome: 'Respostas', campo: 'respostas'},
					{nome: 'Média', campo: 'media'}
				],
				dados: [],
				dadosAnteriores: []
			},
			headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
			},
			indiceGeralOrdem: '',
			colors: [
				'#ff0000',
				'#ff1100',
				'#ff2200',
				'#ff4400',
				'#ff6600',
				'#ff8800',
				'#ff9900',
				'#ffaa00',
				'#ffcc00',
				'#ffff00',
				'#eeff00',
				'#ddff00',
				'#ccff00',
				'#bbff00',
				'#aaff00',
				'#00ff00',
				'#21df33',
				'#30cf60',
				'#35af70',
				'#408f80',
				'#445f88',
				'#440088',
				'#380076',
				'#330065',
				'#300055',
				'#250035',
				'#150025',
				'#000000'
			]
		}
	},
	methods: {
		alternar(itemId, linkId) {
			var divMais = document.getElementById(itemId)
			var linkMais = document.getElementById(linkId)
			if (divMais && linkMais) {
				if (divMais.style.display == 'none') {
					//divMais.style.display = 'block'
					this.fadeIn(divMais)
					linkMais.innerText = 'menos'
				} else {
					//divMais.style.display = 'none';
					this.fadeOut(divMais)
					linkMais.innerText = '...mais'
				}
			}
		},
        fadeOut(element) {
            var op = 1;  // initial opacity
            var timer = setInterval(function () {
                if (op <= 0.1){
                    clearInterval(timer);
                    element.style.display = 'none';
                }
                element.style.opacity = op;
                element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                op -= op * 0.085;
            }, 40);
        },
        fadeIn(element) {
            var op = 0.1;  // initial opacity
            element.style.display = 'block';
            var timer = setInterval(function () {
                if (op >= 1){
                    clearInterval(timer);
                    element.focus();
                }
                element.style.opacity = op;
                element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                op += op * 0.085;
            }, 40);
        },
		reloadResults(ordem){
			this.indiceGeralOrdem = ordem
			this.loadIndiceGeral();
			this.loadIndiceGeralAnterior();
		},
		reloadAvalsResults(ordem) {
			this.indiceGeralOrdem = ordem
			this.loadIndiceAvals()
			this.loadIndiceAvalsAnterior()
		},
		reloadCatsResults(ordem) {
			this.indiceGeralOrdem = ordem
			this.loadIndiceCats();
			this.loadIndiceCatsAnterior();
		},
        loadIndiceGeral() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  this.indiceGeral.periodo.dataInicial,
					"ate" : this.indiceGeral.periodo.dataFinal
				}
            }
			if (this.indiceGeralOrdem) {
				params.orderby = this.indiceGeralOrdem
			}
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/hotel',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceGeral.dados = response.data
                }
            })
        },
        loadIndiceGeralAnterior() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  (moment(this.indiceGeral.periodo.dataInicial).subtract(12, 'months')).format('YYYY-MM-DD'),
					"ate" : (moment(this.indiceGeral.periodo.dataFinal).subtract(12, 'months')).format('YYYY-MM-DD'),
				}
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/hotel',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceGeral.dadosAnteriores = response.data
                }
            })
        },
        loadIndiceAvals() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  this.indiceAvals.periodo.dataInicial,
					"ate" : this.indiceAvals.periodo.dataFinal
				}
            }
			if (this.indiceGeralOrdem) {
				params.orderby = this.indiceGeralOrdem
			}
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/avaliacao',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceAvals.dados = response.data
                }
            })
        },
        loadIndiceAvalsAnterior() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  (moment(this.indiceAvals.periodo.dataInicial).subtract(12, 'months')).locale(this.$i18n.locale).format('YYYY-MM-DD'),
					"ate" : (moment(this.indiceAvals.periodo.dataFinal).subtract(12, 'months')).locale(this.$i18n.locale).format('YYYY-MM-DD'),
				}
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/avaliacao',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceAvals.dadosAnteriores = response.data
                }
            })
        },
        loadIndiceCats() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  this.indiceCats.periodo.dataInicial,
					"ate" : this.indiceCats.periodo.dataFinal
				}
            }
			if (this.indiceGeralOrdem) {
				params.orderby = this.indiceGeralOrdem
			}
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/categoria',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceCats.dados = response.data
                }
            })
        },
        loadIndiceCatsAnterior() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "periodo" : {
					"de" :  (moment(this.indiceCats.periodo.dataInicial).subtract(12, 'months')).format('YYYY-MM-DD'),
					"ate" : (moment(this.indiceCats.periodo.dataFinal).subtract(12, 'months')).format('YYYY-MM-DD'),
				}
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/grp/categoria',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.indiceCats.dadosAnteriores = response.data
                }
            })
        }
	},
	mounted() {
		this.reloadResults('')
		this.reloadAvalsResults('')
		this.reloadCatsResults('')
	}

}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.btn-rel {
  display: inline-block;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; 
}
@media (prefers-reduced-motion: reduce) {
  .btn-rel {
      transition: none; } 
}
.btn-rel:hover {
    color: #e9e7e7;
    text-decoration: none; 
}
.btn-rel:focus, .btn-rel.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 47, 185, 0.25); 
}
.btn-rel.disabled, .btn-rel:disabled {
    opacity: 0.65; 
}
</style>