<template>
<div>
     <div class="nav-header">
        <div class="brand-logo">
            <!-- <img class="logo-abbr" src="../../img/logo.png" height="auto" width="auto" alt=""> -->
            <!-- <img class="logo-compact" src="../../img/logo-text.png" alt=""> -->
            <!-- <img class="brand-title" src="../../img/logo-text.png" alt=""> -->
            <img v-if="!imagemMenuHotel" class="logo-abbr" src="../../img/logo.png" height="auto" width="auto" alt=""/>
            <img class="logo-abbr" v-if="imagemMenuHotel" :src="'data:' + tipoImagemMenuHotel + ';base64,' + imagemMenuHotel" 
                :id="`img_menu_hotel`" style="max-width:80%;"
            />
        </div>
        <div class="nav-control">
            <div class="hamburger">
                <span class="line"></span><span class="line"></span><span class="line"></span>
            </div>
        </div>
    </div>
    <div class="deznav es-parent">
        <div class="deznav-scroll es-child">
            <ul class="metismenu" id="menu">
                <template v-if="menu && Array.isArray(menu.children) && menu.children.length > 0">
                    <li v-for="item in menu.children" :key="item.id">
                        <a :class="getClass(item)" @click="goToLink(item)" aria-expanded="false">
                            <i :class="item.icone"></i>
                            <span class="nav-text">{{item.name}}</span>
                        </a>
                        <template v-if="item.children && Array.isArray(item.children) && item.children.length > 0">
                            <ul aria-expanded="false">
                                <li v-for="subitem in item.children" :key="subitem.id">
                                    <a class="ponteiro" @click="goToLink(subitem)">{{subitem.name}}</a>
                                </li>
                            </ul>
                        </template>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'

export default {
    data() {
        return {
            menu: null,
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            imagemMenuHotel: null,
            tipoImagemMenuHotel: null
        }
    },
    methods: {
        async carregarImagem(obj) {
            if (obj.id) {
                this[`${obj.img}`] = null
                this[`${obj.tipo}`] = null
                await axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: obj.id }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this[`${obj.img}`] = Buffer.from(imgObj.dados).toString()
                            this[`${obj.tipo}`] = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},
        getClass(item) {
            return (item.children&&item.children.length>0) ? `has-arrow ai-icon ponteiro` : `ai-icon ponteiro`;
        },
        goToLink(item) {
            this.$store.dispatch('set_Current_Item', item);
            if (item.link.trim()) {
                if (this.$route.path != item.link) {
                    this.$router.push(item.link)
                }
            }
        },
        async filterMenu() {
            let params = { idsistema: CONSTANTS.FDGUEST_ID, idcliente: CONSTANTS.ID_CLIENTE_ECONOMY };
            //console.error(JSON.stringify(params));
            await this.$http.post(
                CONSTANTS.APIES_URL+"/modulos/selecionar-relacao", 
                params, {headers:{'x-access-token': CONSTANTS.TOKEN_API_ES}})
            .then((resp) => {
                if (resp&&resp.status == 200) {
                    //console.error(`resp.data: ${JSON.stringify(resp.data)}`);
                    let modulosdisponiveis = []
                    resp.data.modulos.map(modulo => {
                        resp.data.relacoes.map(relacao => {
                            if(modulo.id == relacao.relacao.idmodulo){
                                modulosdisponiveis.push(modulo.id.toString())
                            }
                        })
                    })
                    //console.error(`Modulos disponíveis: ${JSON.stringify(modulosdisponiveis)}`);
                    this.menu.children = this.menu.children.filter(item => {
                        if(item.idmodulo != ''){
                            if(modulosdisponiveis.includes(item.idmodulo)){
                                return true
                            }else{
                                return false
                            }                  
                        }else{
                            return true
                        }
                    })
                }
            })
            .catch((error) => {
                //console.error(`Erro ao filtrar módulos no menu: ${JSON.stringify(error)}`);
                console.error(error);
            });        
        } 
    },
    mounted() {
        var obj = this.$store.getters.GetRootItem
        if (obj && obj.objeto) {
            //this.menu = JSON.parse(obj.objeto)
            this.menu = this.$store.getters.GetLoginInfo.menu;
            // Filtrar o menu para desabilitar os módulos não contratados...
            // this.filterMenu();
            // dashboard como primeiro item e item corrente
            this.$store.dispatch('set_Current_Item', this.menu.children[0]);
        }
        this.carregarImagem({id: this.$store.getters.GetLoginInfo.imagem_logo_hotel, img: 'imagemMenuHotel', tipo: 'tipoImagemMenuHotel'})
    }
}
</script>

<style scoped>
.imagem-menu {
    display: flex;
    max-width: 50px;
    max-height: 50px;
    z-index: 999999;
}
.es-parent {
    /* overflow-y:scroll!important; */
    /* width: 100%;
    height: 100%; */
    overflow: hidden;
}
.es-child {
width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */    
}
</style>