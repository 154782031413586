<template>
  <div class="row">
    <!-- Dados -->
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Info Cliente</h4>
        </div>
        <div class="card-body">
          <div class="row mb-5">
            <div class="col-md-12">
              <div class="card" v-if="informacoes">
                <div class="card-body">
                  <h6 class="card-subtitle my-2"><strong>IP:</strong> {{ informacoes.ip }}</h6>
                  <h6 class="card-subtitle my-2"><strong>GEOLOCALIZAÇÃO:</strong> {{ informacoes.geo.latitude}}, {{ informacoes.geo.longitude}}</h6>
                  <h6 class="card-subtitle my-2"><strong>DISPOSITIVO:</strong> {{ informacoes.dispositivo.userAgent }}</h6>
                  <h6 class="card-subtitle my-2"><strong>TELA:</strong> {{ informacoes.dispositivo.screenWidth }} x {{ informacoes.dispositivo.screenHeight }}</h6>
                  <h6 class="card-subtitle my-2"><strong>DATA e HORA:</strong> {{ informacoes.datahora }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: 'InfoCliente',
  components: {  },
  data() {
    return {
      informacoes: {
        ip: null,
        geo: {
          latitude:null, 
          longitude:null
        },
        dispositivo: {
          userAgent: null,
          screenWidth: null,
          screenHeight: null
        },
        datahora: null
      },
    };
  },
  methods: {
    carregarInfoCliente() {
      axios
        .get('https://api.ipify.org?format=json')
        .then(response => {
          //console.error(JSON.stringify(response.data,null,2))
          if (response.data) this.informacoes.ip = response.data.ip;
          //this.informacoes.datahora =  Date();
          this.informacoes.datahora = moment().locale(this.$i18n.locale).format('LLL');
          // Obtendo a geolocalização usando o navegador
          if (navigator.geolocation) {
            const este = this;
            navigator.geolocation.getCurrentPosition(function(position) {
              
              este.informacoes.geo = {
                latitude: position.coords?.latitude,
                longitude: position.coords?.longitude
              };
              
              // Obtendo informações sobre o dispositivo do usuário
              este.informacoes.dispositivo = {
                userAgent: navigator.userAgent,
                screenWidth: window.screen.width,
                screenHeight: window.screen.height
              };

              console.log(este.informacoes); // Exibe as informações no console
            });
          } else {
            console.log('Geolocalização não é suportada pelo seu navegador.');
          }
        })
        .catch(error => {
          console.error('Erro ao obter informações:', error);
        });    },
  },
  computed: {},
  mounted() {
    this.carregarInfoCliente();
  },
};
</script>
