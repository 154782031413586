<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.filtrar')}}</h4>
                    <div @click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label>#</label>
                                    <input v-model="filtro.idpromocao" 
                                        @keyup="buscarPromocoes()" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.tipo')}}:</label>
                                    <select class="form-control" v-model="filtro.idcategoria"
                                        @change="buscarPromocoes()">
                                        <template v-if="categoria && categoria.length > 0">
                                            <option v-for="cat in categoria" v-bind:key="cat.idcategoria"
                                                :value="cat.idcategoria"
                                                :selected="filtro.idcategoria==cat.idcategoria"
                                            >{{cat.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>                                
                                <div class="form-group col-md-5">
                                    <label>{{$t('ser.parceiro')}}</label>
                                    <select class="form-control" v-model="filtro.idparceiro"
                                        @change="buscarPromocoes()">
                                        <template v-if="parceiros && parceiros.length > 0">
                                            <option  v-for="parceiro in parceiros" v-bind:key="parceiro.idparceiro"
                                                :value="parceiro.idparceiro"
                                                :selected="filtro.idparceiro==parceiro.idparceiro"
                                            >{{parceiro.nome}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label>{{$t('ser.promocao')}}:</label>
                                    <input v-model="filtro.titulo" 
                                        @keyup="buscarPromocoes()" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.valor')}}:</label>
                                    <input v-model="filtro.valor"
                                        @keyup="buscarPromocoes()" type="number" class="form-control">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.status')}}:</label>
                                    <select class="form-control" v-model="filtro.ativo"
                                        @change="buscarPromocoes()">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Promoções -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.servicoscadastrados')}}:</h4>
                    <a @click="cadastrarPromocao()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('ser.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{$t('ser.tipo')}}</strong></th>
                                    <th><strong>{{$t('ser.parceiro')}}</strong></th>
                                    <th><strong>{{$t('ser.promocao')}}</strong></th>
                                    <th><strong>{{$t('ser.valor')}}</strong></th>
                                    <th><strong>{{$t('ser.status')}}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, idx) in promocoes" :key="idx">
                                <tr>
                                    <td><strong>{{item.idpromocao}}</strong></td>
                                    <td>{{item.categoria}}</td>
                                    <td>{{item.nome}}</td>
                                    <td>{{item.titulo}}</td>
                                    <td>{{item.valor}}</td>                                                
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" :class="{ 'btn-success' : item.ativo == true, 'btn-warning' : item.ativo == false   }" class="btn light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu" v-if="item.ativo == true">
                                                <a @click="editarPromocao(item)" class="dropdown-item" >{{$t('ser.editar')}}</a>
                                                <a @click="desativarPromocao(item)" class="dropdown-item ponteiro">{{$t('ser.desativar')}}</a>
                                            </div>
                                            <div class="dropdown-menu" v-else>
                                                <a @click="editarPromocao(item)" class="dropdown-item" >{{$t('ser.editar')}}</a>
                                                <a @click="ativarPromocao(item)" class="dropdown-item ponteiro">{{$t('ser.ativar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>	
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from 'moment'

export default {
    data() {
        return{
            promocoes: [],
            parceiros: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            nomeParceiro: '',
            selectcat: '',
            categoria: [],
            filtro: {
                idpromocao: '',
                idcategoria: -1,
                idparceiro: -1,
                titulo: '',
                valor: null,
                ativo: null
            },
            newItem: {
                idcategoria: -1,
                idparceiro: -1,
                titulo: '',
                valor: 0,
                desconto: 0,
                datavalidade: moment().format('yyyy-MM-DD'),
                ativo: true
            }
        }
    },
    methods: {
        limparFiltro() {
            this.filtro =  {
                idpromocao: '',
                idcategoria: -1,
                idparceiro: -1,
                titulo: '',
                valor: null,
                ativo: null
            }
            this.buscarPromocoes();
        },
        buscarPromocoes() {
            var params = {
                idhotel : this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale,
            }
            if (this.filtro.idpromocao > 0) {
                params.idpromocao = this.filtro.idpromocao
            }
            if (this.filtro.idcategoria > 0) {
                params.idcategoria = this.filtro.idcategoria
            }
            if (this.filtro.idparceiro > 0) {
                params.idparceiro = this.filtro.idparceiro
            }
            if (this.filtro.titulo.trim()) {
                params.titulo = this.filtro.titulo.trim()
            }
            if (this.filtro.valor && this.filtro.valor.trim()) {
                params.valor = parseFloat(this.filtro.valor.trim())
            }
            if (this.filtro.ativo != null) {
                params.ativo = this.filtro.ativo
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/promocao/select', params, {headers: this.headers})
            .then(response => {
                if (response.status == 200) {
                    this.promocoes = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarCatServico() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoria/select', {
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idioma: this.$i18n.locale
                }, { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.categoria = response.data
                } else {
                    console.log(JSON.stringify(response))
                }

            })
        },
        buscarParceiros() {
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', { 
                 idhotel: this.$store.getters.GetLoginInfo.idhotel, 
                 idioma: this.$i18n.locale
                }, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.parceiros = response.data
                }
            })
        },
        desativarPromocao(item) {
            var params = {
                idpromocao: item.idpromocao,
                ativo: false,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/promocao/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarPromocoes()
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        ativarPromocao(item){
            var params = {
                idpromocao: item.idpromocao,
                ativo: true,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/promocao/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarPromocoes()
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        cadastrarPromocao() {
            this.$store.dispatch('set_Edit_Status', false);
            this.$store.dispatch('set_Edit_Item', this.newItem);
            this.$router.push('/painel/cadpromocoes')
        },
        editarPromocao(item) {
            this.$store.dispatch('set_Edit_Status', true);
            this.$store.dispatch('set_Edit_Item', item);
            this.$router.push('/painel/cadpromocoes')
        }
    }, 
    mounted() {
        this.buscarPromocoes();
        this.buscarCatServico();
        this.buscarParceiros();
    }
}
</script>

<style>

</style>