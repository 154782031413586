<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('promocoeshotel.titulo')}}</h4>
                <p>{{$t('promocoeshotel.descricao')}}</p>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Promoções do hotel cadastradas</h4>
                    <a @click="criarPromocaoHotel()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarPromHotel" style="display: none;">
                        <PromocoesHotelEditar 
                            :promocaoHotel="novaPromocaoHotel"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                        />
                    </div>
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>Título</strong></th>
                                    <th><strong>Subtítulo</strong></th>
                                    <th><strong>Validade</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>Ação</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in promocoeshotel" :key="item.idhotelpromocoes">
                                <tr>
                                    <td><strong>{{item.titulo}}</strong></td>
                                    <td><strong>{{item.subtitulo}}</strong></td>
                                    <td><strong>{{formatarData(item.validadeinicio)}} - {{formatarData(item.validadefim)}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">Inativar</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">Ativar</a>
                                                <a class="dropdown-item" @click="editar(item)">Editar</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'EditarPromo_'+item.idhotelpromocoes" style="display: none;">
                                            <PromocoesHotelEditar
                                                :promocaoHotel="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import PromocoesHotelEditar from './PromocoesHotelEditar.vue'
import moment from 'moment'

export default {
    components: {PromocoesHotelEditar},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            promocoeshotel: [],
            novaPromocaoHotel: {
                idhotelpromocoes: 0,
                idioma: this.$i18n.locale,
                titulo: "",
                subtitulo: "",
                link: "",
                validadeinicio: moment().format('YYYY-MM-DD'),
                validadefim: moment().format('YYYY-MM-DD'),
                ativo: false
            }
        }
    },
    methods: {
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('L');
        },        
        ativar(item, ativo) {
            var itemAtivar = {
                idhotelpromocoes: item.idhotelpromocoes,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar,false)) {
                item.ativo = ativo;
            }
        },
        criarPromocaoHotel() {
            var obj = document.getElementById('criarPromHotel')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarPromHotel')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            if (!item.titulo.trim() || !item.subtitulo.trim()) {
                this.$snackW(this.$t('ser.preenchertitulosubtitulo'));
                return;
            }
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: item.titulo,
                subtitulo: item.subtitulo,
                link: item.link,
                validadeinicio: item.validadeinicio,
                validadefim: item.validadefim,
                ativo: false,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelpromocoes/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadPromocoesHotel()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('EditarPromo_'+item.idhotelpromocoes)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('EditarPromo_'+item.idhotelpromocoes)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item, checktitle = true) {
            if (checktitle) {
                if (!item.titulo || !item.titulo.trim() || !item.subtitulo || !item.subtitulo.trim()) {
                    this.$snackW(this.$t('ser.preenchertitulosubtitulo'));
                    return;
                }
            }
            var params = {
                idhotelpromocoes: item.idhotelpromocoes,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: item.titulo,
                subtitulo: item.subtitulo,
                link: item.link,
                validadeinicio: item.validadeinicio,
                validadefim: item.validadefim,
                ativo: item.ativo,
                idioma: item.idioma,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelpromocoes/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadPromocoesHotel()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        loadPromocoesHotel() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelpromocoes/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.promocoeshotel = response.data
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.loadPromocoesHotel();
    }
}
</script>
