<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px; padding-bottom:0px;">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="exibirFiltro">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Período -->
                            <div class="form-group col-md-auto">
                                <label>Período de:</label>
                                <input v-model="filtro.periodo.de" type="date" class="form-control" placeholder="" 
                                    @change="filtroModificado=true">
                            </div>
                            <div class="form-group col-md-auto">
                                <label>Até:</label>
                                <input v-model="filtro.periodo.ate" type="date" class="form-control" placeholder=""
                                    @change="filtroModificado=true">
                            </div>
                            <!-- Tabelas -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('logs.tabela')}}</label>
                                    <select class="form-control" v-model="filtro.tabela" @change="filtroModificado=true">
                                        <option :value="null" :selected="filtro.tabela==''">{{$t(`global.todas`)}}
                                        </option>
                                        <template v-if="tabelas && tabelas.length > 0">
                                            <option v-for="tabela in tabelas" v-bind:key="tabela.tabela"
                                                :value="tabela.tabela"
                                                :selected="filtro.tabela==tabela.tabela">{{tabela.tabela}}
                                            </option>
                                        </template>                                
                                </select>
                            </div>
                            <!-- Tipos de Operação -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('logs.tipo_operacao')}}</label>
                                    <select class="form-control" v-model="filtro.tipo_operacao" @change="filtroModificado=true">
                                        <option :value="null" :selected="filtro.tipo_operacao==null">{{$t(`global.todos`)}}
                                        </option>
                                        <template v-if="operacoes && operacoes.length > 0">
                                            <option v-for="operacao in operacoes" v-bind:key="operacao.tipo_operacao"
                                                :value="operacao.tipo_operacao"
                                                :selected="filtro.tipo_operacao==operacao.tipo_operacao">{{operacao.descricao}}
                                            </option>
                                        </template>                                
                                </select>
                            </div>
                            <!-- Usuarios -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('logs.usuario')}}</label>
                                    <select class="form-control" v-model="filtro.usuario" @change="filtroModificado=true">
                                        <option :value="null" :selected="filtro.tipo_operacao==null">{{$t(`global.todos`)}}
                                        </option>
                                        <template v-if="usuarios && usuarios.length > 0">
                                            <option v-for="usuario in usuarios" v-bind:key="usuario.usuario"
                                                :value="usuario.usuario"
                                                :selected="filtro.usuario==usuario.usuario">{{usuario.nomeusuario}}
                                            </option>
                                        </template>                                
                                </select>
                            </div>
                        </div>
                        <div class="form-row justify-content-end">
                            <div v-on:click="carregarLogs()" 
                                class="ponteiro text-white btn btn-primary rounded btn-sm px-4 my-4">
                                <span>{{$t('logs.filtro')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('logs.titulo')}}</h4>
                </div>
                <div class="card-body">

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>Data</strong></th>
                                    <th><strong>{{$t('logs.tabela')}}</strong></th>
                                    <th><strong>{{$t('logs.nomeusuario')}}</strong></th>
                                    <th><strong>{{$t('logs.tipo_operacao')}}</strong></th>
                                    <th><strong>{{$t('logs.modificacao')}} 
                                        <span style="color: #7e7e7e;">&lang;{{$t('logs.antes')}}&rang;</span> - 
                                        <span style="color:blue;">&lang;{{$t('logs.depois')}}&rang;</span></strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in logs" :key="item.id">
                                <tr>
                                    <td><strong>{{item.data | formatarDataHora}}</strong></td>
                                    <td><strong>{{item.tabela}}</strong></td>
                                    <td><strong>{{item.nomeusuario}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.tipo_operacao == 'I', 'badge-danger' : item.tipo_operacao == 'D', 'badge-primary' : item.tipo_operacao == 'U' }" class="badge light">{{(item.operacao)}}</span></td>
                                    <td>
                                        <div><strong style="color:blue;">{{item.modificacao.depois}}</strong></div>
                                        <div><strong>{{item.modificacao.antes}}</strong></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import CONSTANTS from '../constants'
import Pagination from './Pagination.vue'

export default {
    components: {Pagination},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            logs: [],
            filtro: {
                periodo: {
					de: (dayjs().subtract(30,'days').format('YYYY-MM-DD')),
					ate: (dayjs().format('YYYY-MM-DD'))
				},
                id: '',
                tabela: null,
                usuario: null,
                tipo_operacao: null
            },
            tabelas: [],
            usuarios: [],
            operacoes: [],
            exibirFiltro: false,
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
            filtroModificado: false
        }
    },
    methods: {
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarLogs();
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.exibirFiltro = true
                } else {
                    el.style.display = 'none'
                    this.exibirFiltro = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.logs = []
            this.filtro = {
                periodo: {
					de: (dayjs().subtract(30,'days').format('YYYY-MM-DD')),
					ate: (dayjs().format('YYYY-MM-DD'))
				},
                id: '',
                tabela: null,
                usuario: null,
                tipo_operacao: null
            }
            window.setTimeout(this.carregarLogs(), 1000);
        },
        carregarTabelas() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/dblog/tabelas',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.tabelas = response.data;
                }
            })
        },
        carregarOperacoes() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/dblog/tipos',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.operacoes = response.data;
                }
            })
        },
        carregarUsuarios() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/dblog/usuarios',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.usuarios = response.data;
                }
            })
        },
        carregarLogs() {
           var  params = {
                "periodo": this.filtro.periodo,
                "idioma" : this.$i18n.locale,
                "id": this.filtro.id,
                "tabela": this.filtro.tabela,
                "tipo_operacao": this.filtro.tipo_operacao,
                "usuario": this.filtro.usuario,
                "page": this.page,
                "pagesize": this.pageSize
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/dblog/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.logs = response.data.dados;
                    this.recordCount = response.data.recordCount;
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1;
                    this.filtroModificado=false;
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarTabelas();
        this.carregarOperacoes();
        this.carregarUsuarios();
        this.carregarLogs();
    }
}
</script>

