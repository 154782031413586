<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Cadastrar Serviço:  </h4>
                    <a @click="$router.push('/painel/sersalaospa')" class="ponteiro text-white btn btn-info mt-4"> &#8592; {{$t('ser.voltar')}} </a>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.setor')}}:</label>
                                    <select class="form-control" v-model="editedItem.idcategoriaservico">
                                        <template v-if="categoriaServicos && categoriaServicos.length > 0">
                                            <option v-for="cat in categoriaServicos" v-bind:key="cat.idcategoriaservico"
                                                :value="cat.idcategoriaservico"
                                                :selected="editedItem.idcategoriaservico==cat.idcategoriaservico"
                                            >{{cat.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.titulo')}}:  </label>
                                    <input v-model="editedItem.titulo" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.subtitulo')}}:</label>
                                    <input v-model="editedItem.subtitulo" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label>{{$t('ser.descricao')}}:</label>
                                    <input v-model="editedItem.descricao" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.valor')}}:</label>
                                    <input v-model="editedItem.valor" type="number" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.desconto')}}:</label>
                                    <input v-model="editedItem.desconto" type="number" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.ativo')}}:</label>
                                    <select class="form-control" v-model="editedItem.ativo">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                            <button @click="upinsertServico()" type="button" class="btn btn-primary mt-4" id="toastr-success-top-right">{{$t('ser.salvar')}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            categoriaServicos: [],
            editedItem: {}
        }
    },
    methods: {
        upinsertServico() {
            if (this.$store.getters.GetEditStatus) {
                this.editarServico()
            } else {
                this.inserirServico()
            }
        },
        buscarCatServico() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriaservico/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma:this.$i18n.locale,
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categoriaServicos = response.data
                    this.editedItem = this.$store.getters.GetEditItem
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        inserirServico() {
            var servico = {
                idhotel:this.$store.getters.GetLoginInfo.idhotel,
                idcategoriaservico: this.editedItem.idcategoriaservico,
                titulo: this.editedItem.titulo,
                subtitulo: this.editedItem.subtitulo,
                descricao: this.editedItem.descricao,
                valor: parseFloat(this.editedItem.valor),
                desconto: parseFloat(this.editedItem.desconto),
                ativo: this.editedItem.ativo,
                idioma: this.editedItem.idioma,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.error(JSON.stringify(servico))
            axios.post(CONSTANTS.API_URL + '/fdguest/servico/insert', servico,  {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/sersalaospa')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
         editarServico() {
            var servico = {
                idservico: this.editedItem.idservico,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idcategoriaservico: this.editedItem.idcategoriaservico,
                titulo: this.editedItem.titulo,
                subtitulo: this.editedItem.subtitulo,
                descricao: this.editedItem.descricao,
                valor:  parseFloat(this.editedItem.valor),
                desconto: parseFloat(this.editedItem.desconto),
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/servico/update', servico,  {headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/sersalaospa')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
    },
    beforeMount() {
        this.buscarCatServico();
    },
    mounted() {
        
    }
}
</script>

<style>

</style>