<template>
    <div class="row">
        <div  class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.cadastrarparceiro')}}</h4>
                    <a @click="$router.push('/painel/serconcierge')" class="text-white ponteiro btn btn-info mt-4"> &#8592; {{$t('ser.voltar')}} </a>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.tipo')}}:</label>
                                    <select class="form-control" v-model="editedItem.idcategoria">
                                        <template v-if="categoriaParceiros && categoriaParceiros.length > 0">
                                            <option v-for="cat in categoriaParceiros" v-bind:key="cat.idcategoria"
                                                :value="cat.idcategoria"
                                                :selected="editedItem.idcategoria==cat.idcategoria"
                                            >{{cat.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.nomedaempresa')}}:</label>
                                    <input v-model="editedItem.nome" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.telefone')}}:</label>
                                    <input v-model="editedItem.telefone" type="text" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.whatsapp')}}:</label>
                                    <input v-model="editedItem.whatsapp" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-3">
                                    <label>Pais:</label>
                                    <select class="form-control"  v-model="editedItem.idpais" @change="buscarUf()">
                                        <option v-for="pais in paises" :key="pais.idpais"
                                            :value="pais.idpais"
                                            :selected="editedItem.idpais==pais.idpais"
                                        >{{pais.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.estado')}}: </label>
                                    <select class="form-control" v-model="editedItem.ufcodigoibge"
                                        @change="buscarCidade()">
                                        <option v-for="estado in ufs" :key="estado.codigoibge"
                                            :value="estado.codigoibge"
                                            :selected="editedItem.ufcodigoibge==estado.codigoibge"
                                        >{{estado.nome}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label>{{$t('ser.cidade')}}:</label>
                                    <select class="form-control"
                                        v-model="editedItem.cidadecodigoibge">
                                        <option v-for="cidade in cidades" :key="cidade.codigoibge"
                                            :value="cidade.codigoibge"
                                            :selected="editedItem.cidadecodigoibge==cidade.codigoibge"
                                        >{{cidade.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-2">
                                    <label>Cep:</label>
                                    <input v-model="editedItem.cep" type="text" class="form-control">
                                </div>
                                    <div class="form-group col-md-3">
                                    <label>{{$t('ser.endereco')}}:</label>
                                    <input v-model="editedItem.endereco" type="text" class="form-control">
                                </div>
                                    <div class="form-group col-md-2">
                                    <label>Numero:</label>
                                    <input v-model="editedItem.numero" type="text" class="form-control"  >
                                </div>
                                    <div class="form-group col-md-3">
                                    <label>Complemento:</label>
                                    <input v-model="editedItem.complemento" type="text" class="form-control">
                                </div>
                                <div class="form-group col-md-2">
                                    <label>{{$t('ser.ativo')}}:</label>
                                    <select class="form-control" v-model="editedItem.ativo">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                            <button @click="upinsertParceiro()" type="button" class="btn btn-primary mt-4" id="toastr-danger-top-right">{{$t('ser.salvar')}}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            editedItem: {},
            categoriaParceiros: [],
            paises: [],
            ufs: [],
            cidades: [],
            endereco: null
        }
    },
    methods: {
        upinsertParceiro() {
            if (this.$store.getters.GetEditStatus) {
                this.editarParceiro()
            } else {
                this.inserirParceiro()
            }
        },
        searchCep () {
			if(this.editedItem.cep.length == 8) {
				axios.get(`https://viacep.com.br/ws/${ this.editedItem.cep }/json/`)
				.then( response => {
                    this.endereco = response.data, console.log(this.endereco) 
                })
				.catch((e) => {
                    console.log(e) 
                })
			}
		},
        buscarCategorias() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoria/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
            }, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.categoriaParceiros = response.data
                    this.editedItem = this.$store.getters.GetEditItem
                    this.buscarUf()
                    this.buscarCidade()
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarPais() {
            axios.post(CONSTANTS.API_URL + '/fdguest/pais/select', {
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.paises = response.data 
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarUf() {
            axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', {}, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.ufs = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarCidade() {
            axios.post(CONSTANTS.API_URL + '/fdguest/cidade/select', {
                codigoufibge: this.editedItem.ufcodigoibge
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.cidades = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        inserirParceiro() {
            var parceiro = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idusuariocadastro: this.$store.getters.GetLoginInfo.idusuario,
                idcategoria: this.editedItem.idcategoria,
                nome: this.editedItem.nome,
                endereco: this.editedItem.endereco,
                numero: this.editedItem.numero,
                telefone: this.editedItem.telefone,
                whatsapp: this.editedItem.whatsapp,
                cep: this.editedItem.cep,
                complemento: this.editedItem.complemento,
                bairro: this.editedItem.bairro,
                codigoibge: this.editedItem.cidadecodigoibge,
                pais: this.editedItem.idpais,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(parceiro))
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/insert', parceiro,  {headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/serconcierge')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        editarParceiro() {
             var servico = {
                idparceiro: this.editedItem.idparceiro, 
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idusuariocadastro: this.$store.getters.GetLoginInfo.idusuario,
                idcategoria: this.editedItem.idcategoria,
                nome: this.editedItem.nome,
                endereco: this.editedItem.endereco,
                numero: this.editedItem.numero,
                telefone: this.editedItem.telefone,
                whatsapp: this.editedItem.whatsapp,
                cep: this.editedItem.cep,
                complemento: this.editedItem.complemento,
                bairro: this.editedItem.bairro,
                codigoibge: this.editedItem.cidadecodigoibge,
                pais: this.editedItem.idpais,
                ativo: this.editedItem.ativo,
                idioma: this.$i18n.locale,
                alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', servico,  {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.$router.push('/painel/serconcierge')
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        }
    },
    mounted() {
        this.buscarCategorias();
        this.buscarPais();
    }
}
</script>

<style>

</style>