<template>
	<div v-if="produtos && produtos.length>0">
		<!-- Cabeçalho -->
		<div class="row py-2">
			<div class="col-2"><strong>Código</strong></div>
			<div class="col-2"><strong>PMS</strong></div>
			<div class="col-3"><strong>Descrição</strong></div>
			<div class="col-1 text-center px-2"><strong>Unidade</strong></div>
			<div class="col-1 text-center px-2"><strong>Qtde.Sol.</strong></div>
			<div class="col-1 text-center px-2"><strong>Qtde.Lib.</strong></div>
			<div class="col-1 text-center px-2"><strong>Qtde.Ent.</strong></div>
		</div>
		<!-- Produtos -->
		<div v-for="(produto,index) in produtos" :key="index"
			:class="{ 'es-even': index%2==0, 'es-odd': index%2!=0}" class="row my-1 py-1">
			<div class="col-2">
				<span>{{produto.idproduto}}</span>
			</div>
			<div class="col-2">
				<span>{{produto.codprodutopms}}</span>
			</div>			
			<div class="col-3">
				<span>{{produto.descricao}}</span>
			</div>
			<div class="col-1 text-center px-2">
				<span>{{produto.unidade}}</span>
			</div>
			<div class="col-1 text-center px-2">
				<span>{{produto.qtdesolicitada}}</span>
			</div>
			<div class="col-1 text-center px-2">
				<span>{{produto.qtdeliberada}}</span>
			</div>
			<div class="col-1 text-center px-2">
				<span>{{produto.qtdeentregue}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'produtos'
	],
    components: {
         
    },
    data() {
        return {

        }
	},
	computed: {
		totalprodutos() {
			var somaProdutos = 0
			for (var i = 0; i < this.produtos.length; i++) {
				var prod = this.produtos[i]
				somaProdutos += parseFloat(prod.precoimportado) * parseInt(prod.qtde)
			}
			return somaProdutos
		},
		totalatender() {
			var somaProdutos = 0
			for (var i = 0; i < this.produtos.length; i++) {
				var prod = this.produtos[i]

				if (prod.atender) {
					somaProdutos += parseFloat(prod.precoimportado) * parseInt(prod.qtde)
				}
			}
			return somaProdutos
		},
		totalentregue() {
			var somaProdutos = 0
			for (var i = 0; i < this.produtos.length; i++) {
				var prod = this.produtos[i]
				if (prod.entregue) {
					somaProdutos += parseFloat(prod.precoimportado) * parseInt(prod.qtde)
				}
			}
			return somaProdutos
		}
	},
	methods: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.es-even {
	align-items: baseline;
	color: black!important;
	background-color: rgb(238, 239, 252)!important;
}
.es-odd {
	color: black!important;
	align-items: baseline;
	background-color: rgb(255, 255, 255)!important;
}
.es-sim {
	align-items: baseline;
	color: rgb(7, 206, 50)!important;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
}
.es-nao {
	align-items: baseline;
	color: rgb(241, 12, 12)!important;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
}
</style>