<template>
    <div class="row d-flex align-items-center justify-content-center">
        <span class="material-icons icon font-lg ponteiro mx-2"
          :class="vote == 'up'?'es-up':'es-up-no-vote'"
            @click="$emit('up')"
        >
            thumb_up
        </span>
        <span class="material-icons icon font-lg ponteiro mx-2"
          :class="vote == 'down'?'es-down':'es-down-no-vote'"
            @click="$emit('down')"
        >
            thumb_down
        </span>
    </div>
</template>

<script>

export default {
    name: 'ThumbsUpDown',
    components: {},
    data() {
      return {
      }
    },
    props: {
      vote: {
        type: String,
        required: false,
        default: ''
      }
    },
    watch: {
      
    },
    methods: {
        
    },
    mounted() {
      
    }
  }
</script>
<style scoped>
.es-up-no-vote {
    color: gray;
}
.es-down-no-vote {
    color: gray;
}
.es-up-no-vote:hover {
    color: green;
}
.es-down-no-vote:hover {
    color: red;
}
.es-up {
    color: green;
}
.es-down {
    color: red;
}
/* The animation code */
/* @keyframes example {
  0% {color: gray;transform: rotate(0deg);}
  50%  {color: orange;transform: rotate(180deg);}
  100%  {color: red;transform: rotate(0deg);}
} */

/* The element to apply the animation to */
/* .es-down {
  color: gray;
  animation: example 2s ease-in-out;
  position: relative;
} */

</style>