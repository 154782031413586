<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card azure">
                <div class="card-header">
                    <div class="basic-form" style="width:100%">
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <label>Nome:</label>
                                <input v-model="hotel.nome" 
                                    type="text" class="form-control" placeholder="Nome do hotel">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Telefone:</label>
                                <input v-model="hotel.telefone" 
                                    type="text" class="form-control" placeholder="Telefone do hotel">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>País:</label>
                                <select class="form-control" v-model="hotel.idpais">
                                    <template v-if="paises && paises.length > 0">
                                        <option v-for="pais in paises" v-bind:key="pais.idpais"
                                            :value="pais.idpais"
                                            :selected="pais.idpais==hotel.idpais"
                                        >{{pais.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <label>Cep:</label>
                                <input v-model="hotel.cep" 
                                    type="text" class="form-control" placeholder="Cep do hotel">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>UF:</label>
                                <select class="form-control" v-model="hotel.ufcodigoibge"
                                    @change="loadCidades()">
                                    <template v-if="ufs && ufs.length > 0">
                                        <option v-for="uf in ufs" v-bind:key="uf.iduf"
                                            :value="uf.codigoibge"
                                            :selected="uf.codigoibge==hotel.ufcodigoibge"
                                        >{{uf.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Cidade:</label>
                                <select class="form-control" v-model="hotel.cidadecodigoibge">
                                    <template v-if="cidades && cidades.length > 0">
                                        <option v-for="cidade in cidades" v-bind:key="cidade.idcidade"
                                            :value="cidade.codigoibge"
                                            :selected="cidade.codigoibge==hotel.cidadecodigoibge"
                                        >{{cidade.nome}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Bairro:</label>
                                <input v-model="hotel.bairro" type="text" class="form-control">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Endereço:</label>
                                <input v-model="hotel.endereco" type="text" class="form-control">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Número:</label>
                                <input v-model="hotel.numero" type="text" class="form-control">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Complemento:</label>
                                <input v-model="hotel.complemento" type="text" class="form-control">
                            </div>
                        </div>
                        <!-- Imagem -->
                        <div v-if="hotel.idhotel>0" class="form-group col-sm-auto">
                            <label><strong>Imagens (prefira usar imagens de fundo transparente):</strong></label><br>
                            <label>Imagem para o logo no aplicativo do hóspede.</label>
                            <div class="imagem-promocao">
                                <label class="ponteiro" :for="'hotel_imagem_'+hotel.idhotel">
                                    <div v-if="!imagemSrc"><span class="material-icons icon form-control">hotel</span></div>
                                    <img class="img-config quadriculado" v-if="imagemSrc" :src="'data:' + hotel.tipoImg + ';base64,' + imagemSrc" 
                                        :id="'imgHotel_'+hotel.idhotel"
                                    />
                                </label>
                                <input @change="onFileChange" type="file" :id="'hotel_imagem_'+hotel.idhotel" 
                                    :name="'hotel_imagem_'+hotel.idhotel" accept="image/png,image/jpg,image/jpeg" 
                                    class="ponteiro" style="display:none;">
                            </div>
                            <!-- Configuração das Imagens (apenas dev) -->
                            <template v-if="(env === 'development')">
                                <label>Imagem para a tela de login do hotel.</label>
                                <div class="imagem-promocao">
                                    <label class="ponteiro" :for="`hotel_imagem_login_hotel${hotel.idhotel}`">
                                        <div v-if="!imagemSrcLoginHotel"><span class="material-icons icon form-control">hotel</span></div>
                                        <img class="img-config quadriculado" v-if="imagemSrcLoginHotel" :src="'data:' + hotel.tipoImgLoginHotel + ';base64,' + imagemSrcLoginHotel" 
                                            :id="`img_login_hotel${hotel.idhotel}`"
                                        />
                                    </label>
                                    <input @change="onFileChangeLoginHotel" type="file" :id="`hotel_imagem_login_hotel${hotel.idhotel}`" 
                                        :name="`hotel_imagem_login_hotel${hotel.idhotel}`" accept="image/png,image/jpg,image/jpeg" 
                                        class="ponteiro" style="display:none;">
                                </div>
                                <label>Imagem para para o topo do menu do hotel.</label>
                                <div class="imagem-promocao">
                                    <label class="ponteiro" :for="`hotel_imagem_logo_hotel${hotel.idhotel}`">
                                        <div v-if="!imagemSrcLogoHotel"><span class="material-icons icon form-control">hotel</span></div>
                                        <img class="img-config quadriculado" v-if="imagemSrcLogoHotel" :src="'data:' + hotel.tipoImgLogoHotel + ';base64,' + imagemSrcLogoHotel" 
                                            :id="`img_logo_hotel${hotel.idhotel}`"
                                        />
                                    </label>
                                    <input @change="onFileChangeLogoHotel" type="file" :id="`hotel_imagem_logo_hotel${hotel.idhotel}`" 
                                        :name="`hotel_imagem_logo_hotel${hotel.idhotel}`" accept="image/png,image/jpg,image/jpeg" 
                                        class="ponteiro" style="display:none;">
                                </div>
                                <label>Imagem para a tela de login do hóspede.</label>
                                <div class="imagem-promocao">
                                    <label class="ponteiro" :for="`hotel_imagem_login_hospede${hotel.idhotel}`">
                                        <div v-if="!imagemSrcLoginHospede"><span class="material-icons icon form-control">hotel</span></div>
                                        <img class="img-config quadriculado" v-if="imagemSrcLoginHospede" :src="'data:' + hotel.tipoImgLoginHospede + ';base64,' + imagemSrcLoginHospede" 
                                            :id="`img_login_hospede${hotel.idhotel}`"
                                        />
                                    </label>
                                    <input @change="onFileChangeLoginHospede" type="file" :id="`hotel_imagem_login_hospede${hotel.idhotel}`" 
                                        :name="`hotel_imagem_login_hospede${hotel.idhotel}`" accept="image/png,image/jpg,image/jpeg" 
                                        class="ponteiro" style="display:none;">
                                </div>
                            </template>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',hotel)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import { serializarImagem } from '../utils/serializar'
export default {
    name: 'ConfigHotelEditar',
    props: {
        hotel: {
            type: Object,
            required: true,
        },
        paises: {
            type: Array,
            required: true
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            imagemSrc: null,    
            tipoImg: null,
            imagemSrcLoginHotel: null,
            tipoImgLoginHotel: null,
            imagemSrcLogoHotel: null,
            tipoImgLogoHotel: null,
            imagemSrcLoginHospede: null,
            tipoImgLoginHospede: null,
            cidades: [],
            ufs: [],
            env: process.env.NODE_ENV
        }
    },
    methods: {
		loadUFS() {
			axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', {
				idioma:this.$i18n.locale}, {headers: this.headers})
			.then(response => {
				if(response.status == 200) {
					this.ufs = response.data
                    this.loadCidades()
				}
			})			
		},
		loadCidades() {
			var params = {
				idioma: this.$i18n.locale,
				codigoufibge: this.hotel.ufcodigoibge
			}
			axios.post(CONSTANTS.API_URL + '/fdguest/cidade/select', params, {headers: this.headers})
			.then(response => {
				if(response.status == 200) {
					this.cidades = response.data
				}
			})			
		},        
        onFileChange(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.imagemSrc = resp.dados
                this.tipoImg = resp.tipo
                if (this.hotel.idhotel > 0) {
                    this.salvarImagem(resp.tipo, resp.dados, 'imagem');
                }
            }).catch(er =>{
                console.error(er)
            })
        },
        onFileChangeLoginHotel(e) {
            serializarImagem(e, 1.0).then(resp =>{
                this.imagemSrcLoginHotel = resp.dados
                this.tipoImgLoginHotel = resp.tipo
                if (this.hotel.idhotel > 0) {
                    this.salvarImagem(resp.tipo,resp.dados,'imagem_login_hotel');
                }
            }).catch(er =>{
                console.error(er)
            })
        },
        onFileChangeLogoHotel(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.imagemSrcLogoHotel = resp.dados
                this.tipoImgLogoHotel = resp.tipo
                if (this.hotel.idhotel > 0) {
                    this.salvarImagem(resp.tipo,resp.dados,'imagem_logo_hotel');
                }
            }).catch(er =>{
                console.error(er)
            })
        },
        onFileChangeLoginHospede(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.imagemSrcLoginHospede = resp.dados
                this.tipoImgLoginHospede = resp.tipo
                if (this.hotel.idhotel > 0) {
                    this.salvarImagem(resp.tipo,resp.dados,'imagem_login_hospede');
                }
            }).catch(er =>{
                console.error(er)
            })
        },
        salvarImagem(tipoImg,img,campo) {
            if (!img.length > 0) {
                console.error("Imagem inválida!");
                return;
            }
            var params = {
                "tipo" : tipoImg,
                "nome" : "Imagem_Hotel_"+this.hotel.idhotel,
                "dados" : img,
                "tabela": "hotel",
                "fk": `${campo}`,
                "chave": "idhotel",
                "valor": this.hotel.idhotel,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
                "deleteold": true
            }
            //console.error(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/imagem/upinsert'
                    ,params, { timeout:60000, headers: this.headers })
                .then((response) => {
                    if(response.status == 200){
                        this.hotel[`${campo}`] = response.data.result.imagem[0]
                        console.log('response.data:'+response.data)
                    }else{
                        console.log(JSON.stringify(response))
                    }
                })
                .catch((error) => {
                    console.log(error.response);
            });
        },
		async carregarImagem(obj) {
            if (obj.id) {
                this[`${obj.img}`] = null
                this[`${obj.tipo}`] = null
                await axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: obj.id }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this[`${obj.img}`] = Buffer.from(imgObj.dados).toString()
                            this[`${obj.tipo}`] = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},
        loadIdiomas() {
			axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.carregarImagem({id: this.$store.getters.GetLoginInfo.imagem, img: 'imagemSrc', tipo: 'tipoImg'})
        this.carregarImagem({id: this.$store.getters.GetLoginInfo.imagem_login_hotel, img: 'imagemSrcLoginHotel', tipo: 'tipoImgLoginHotel'})
        this.carregarImagem({id: this.$store.getters.GetLoginInfo.imagem_logo_hotel, img: 'imagemSrcLogoHotel', tipo: 'tipoImgLogoHotel'})
        this.carregarImagem({id: this.$store.getters.GetLoginInfo.imagem_login_hospede, img: 'imagemSrcLoginHospede', tipo: 'tipoImgLoginHospede'})
        //this.carregarImagem(this.$store.getters.GetLoginInfo.imagem_login_hotel)
		this.loadUFS()
    }
}
</script>

<style scoped>
.azure {
    background-color: azure!important;
}
.img-config {
    max-width: 250px;
}
.quadriculado {
  background:
    linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(-45deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 10px 10px;
  background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}
</style>