<template>
    <div>
        <!-- Filtro -->
        <div class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Filtrar Solicitações:</h4>								
                    </div>
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-md-4">
                                        <label>Setor:</label>
                                        <select class="form-control" v-model="setorPesquisa"
                                            @change="buscarTipos()"
                                        >
                                            <option value=0>Todos</option>
                                            <option v-for="setor in setores" :key="setor.idhotlinesetores" 
                                                :value="setor.idhotlinesetores" >{{setor.deschotlinesetor}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Tipo:</label>
                                        <select class="form-control" v-model="tipoPesquisa"
                                            @change="filterChanged=true"
                                        >
                                            <option value=0>Todos</option>
                                            <option v-for="tipo in tipos" :key="tipo.idtiposolicitacao" 
                                                :value="tipo.idtiposolicitacao" >{{tipo.titulo}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Status:</label>
                                        <select class="form-control" v-model="statusPesquisa"
                                            @change="filterChanged=true"
                                        >
                                            <option value=0>Todos</option>
                                            <option v-for="st in statusList" :key="st.idhotlinesolicitacoesstatus" 
                                                :value="st.idhotlinesolicitacoesstatus" >{{st.descricao}}
                                            </option>
                                        </select>
                                    </div>
                                </div>	
                                <div class="form-row">	
                                    <div class="form-group col-md-8">
                                        <label>Nome Hóspede:</label>
                                        <input v-model="nomehospede" type="text" class="form-control" placeholder=""
                                            @keyup="filterChanged=true"
                                        >
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Quarto:</label>
                                        <input v-model="uh" type="text" class="form-control">
                                    </div>
                                </div>	
                                <div class="form-row">	    
                                    <div class="form-group col-md-4">
                                        <label>Data Inicial:</label>
                                        <input v-model="dataInicial" type="date" class="form-control" placeholder=""
                                            @change="filterChanged=true"
                                        >
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Data Final:</label>
                                        <input v-model="dataFinal" type="date" class="form-control"
                                            @change="filterChanged=true"
                                        >
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>Data de Referência:</label>
                                        <select class="form-control" v-model="dataReferencia"
                                            @change="filterChanged=true"
                                        >
                                            <option v-for="(ref,index) in datasRef" :key="index" 
                                                :selected="index == dataReferencia"
                                                :value="index" >{{datasRef[index]}}
                                            </option>
                                        </select>
                                    </div>
                                </div>	
                                <span class="text-orange" v-if="filterChanged">Clique em  Pesquisar para atualizar a lista.</span>
                                <hr/>
                                <button @click="carregarSolicitacoes()" type="button" class="btn btn-primary mt-4" id="toastr-success-top-right">Pesquisar</button>
                            </form>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Resultado -->
        <div class="row">     
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Solicitações{{solicitacoes.length>0?'('+solicitacoes.length+')':''}}</h4>
                        <strong><span class="text-orange" v-if="solicitacoes.length==0&&!filterChanged">{{textoInfo}}</span></strong>
                        <button type="button" class="btn btn-success"
                            @click="JSON2Excel(solicitacoes, reportFileName)"
                        ><i class="fa fa-table"></i> Exportar</button>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <th><strong>#</strong></th>
                                        <th><strong>Solicitação</strong></th>
                                        <th><strong>DataSolicitação</strong></th>
                                        <th><strong>Responsável</strong></th>
                                        <th><strong>Quarto</strong></th>
                                        <th><strong>Local</strong></th>
                                        <th><strong>Setor</strong></th>
                                        <th><strong>Tipo</strong></th>
                                        <th><strong>Status</strong></th>
                                        <th><strong>Hóspede</strong></th>
                                        <th><strong>Reserva</strong></th>
                                        <th><strong>Agendamento</strong></th>
                                    </tr>
                                </thead>
                                <tbody v-for="solicitacao in solicitacoes" :key="solicitacao.numero">
                                    <tr>
                                        <td>{{solicitacao.numero}}</td>
                                        <td class="es-sol-text"
                                            :title="solicitacao.solicitacao"
                                        >{{encurtar(solicitacao)}}
                                            <template v-if="solicitacao.excedente">
                                                <span>...</span>
                                                <div>
                                                    <button type="button" class="btn btn-warning light sharp"
                                                        @click="toggleModal(solicitacao.solicitacao)"
                                                    ><svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                                    </button>
                                                </div>
                                            </template>
                                        </td>
                                        <td>{{solicitacao.data_hora}}</td>
                                        <td>{{solicitacao.responsavel}}</td>
                                        <td>{{solicitacao.quarto}}</td>
                                        <td>{{solicitacao.localatendimento}}</td>
                                        <td>{{solicitacao.setor}}</td>
                                        <td>{{solicitacao.tipo}}</td>
                                        <td>{{solicitacao.status}}</td>
                                        <td>{{solicitacao.hospede}}</td>
                                        <td>{{solicitacao.reserva}}</td>
                                        <td>{{solicitacao.datahoraagendamento}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalES :title="`Texto da Solicitação`" :text="modalText" :value="showModal" @input="showModal=$event"></ModalES>
    </div>
                
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment"
import ModalES from './ModalES.vue'

export default {
    name: 'RelSolicitacoes',
    components: {ModalES},
    data() {
        return {
            solicitacoes: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            setores: [],
            tipos: [],
            statusList: [],
            setorPesquisa: 0,
            tipoPesquisa: 0,
            statusPesquisa: 0,
            nomehospede: '',
            uh: '',
            dataInicial: (moment().subtract(30,'days').format('YYYY-MM-DD')),
            dataFinal: (moment().format('YYYY-MM-DD')),
            datasRef: [
                this.$t('tipoSolicitacao.datadasolicitacao'),
                this.$t('tipoSolicitacao.datadoagendamento'),
            ],
            dataReferencia: 0,
            filterChanged: false,
            loaded: false,
            textoInfo: 'Pesquisando...',
            tamanhoMaximo: 100,
            modalText: '',
            showModal: false
        }
    },
    methods: {
        toggleModal(texto) {
            console.error(texto)
            this.modalText = texto;
            this.showModal = !this.showModal;
        },
        encurtar(obj){
            if (obj.solicitacao.length > this.tamanhoMaximo) {
                obj.excedente = true;
                obj.textoExcedente = obj.solicitacao.substring(this.tamanhoMaximo);
                obj.textoCurto = obj.solicitacao.substring(0, this.tamanhoMaximo);
            }
            return obj.textoCurto ? obj.textoCurto : obj.solicitacao;
        },
        formatarData(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('L');
        }, 
        formatarHora(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('H:HH');
        }, 
        carregarSolicitacoes() {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale,
                datareferencia: this.dataReferencia
            }
            if (this.dataInicial&&this.dataFinal) {
                params.periodo = {
                    de: this.dataInicial,
                    ate: this.dataFinal
                }
            }
            if (this.setorPesquisa > 0) {
                params.idhotlinesetores = this.setorPesquisa
            }
            if (this.tipoPesquisa > 0) {
                params.idtiposolicitacao = this.tipoPesquisa
            }
            if (this.statusPesquisa > 0) {
                params.idstatus = this.statusPesquisa
            }
            if (this.nomehospede.trim()) {
                params.nome = this.nomehospede
            }
            if (this.uh.trim()) {
                params.coduh = this.uh
            }
            //console.error(JSON.stringify(params))
            this.solicitacoes = []
            this.textoInfo = 'Carregando...'
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select/simples', 
                params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.solicitacoes = response.data
                    this.filterChanged = false;
                    this.loaded = true;
                    if (this.solicitacoes.length==0) {
                        this.textoInfo = 'Nenhum registro encontrado.'
                    }
                }
            }) 
        },
        buscarTipos() {
            this.tipos = [];
            this.filterChanged = true;
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                {
                    idioma: this.$i18n.locale,
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idhotlinesetores: this.setorPesquisa
                }, { headers: this.headers })
                .then(response => {
                    if(response.status == 200) {
                        this.tipos = response.data
                        if (this.tipos.length > 0) {
                            this.tipoPesquisa = 0
                        }
                        if (!this.loaded) {
                            this.carregarSolicitacoes();
                        }
                    }
            })
        },
        buscarSetores() {
            this.setores = []     
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select',
                { idhotel: this.$store.getters.GetLoginInfo.idhotel }, { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.setores = response.data
                    if (this.setores.length>0) {
                        this.setorPesquisa = 0
                        this.buscarTipos()
                    }
                }
            })
        },
        buscarStatus() {
            this.statusList = []     
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoesstatus/select',
                { idioma: this.$i18n.locale, orderby: "descricao" }, { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.statusList = response.data
                    if (this.statusList.length>0) {
                        this.statusPesquisa = 0
                        this.buscarSetores();
                    }
                }
            })
        },
        JSON2Excel(JSONData, ReportTitle) {
            // Se não tiver dados retorna...
            if (JSONData.length == 0) return;
            // Número de campos para agrupar as células do título
            var num_campos = Object.keys(JSONData[0]).length
            var HTML = `
            <table>
                <tr>
                    <th colspan="${num_campos}">
                        ${ReportTitle}
                    </th>
                </tr>
                <tr></tr>
            `
            // Extrair os nomes dos campos
            var row = '<tr>'
            for (var field in JSONData[0]) {
                row += '<th>' + field + '</th>';
            }
            row += '</tr>\r\n'
            HTML += row
            // Primeiro loop para as linhas
            for (var i = 0; i < JSONData.length; i++) {
                row = "<tr>";
                // Segundo loop para as colunas
                for (field in JSONData[i]) {
                    row += '<td>' + JSONData[i][field] + '</td>';
                }
                // Quebra de linha...
                row += '</tr>\r\n';
                HTML += row;
            }
            HTML += '</table>';
            // Gerar um nome para o arquivo
            var fileName = "";
            // Substituir os espaços em branco do título por underline
            fileName += ReportTitle.replace(/ /g, "_");
            // Inicializar com o tipo de arquivo (xls)
            var uri = 'data:application/vnd.ms-excel;charset=utf-8, ' + escape(HTML);
            // Criar um link temporário <a /> tag
            var link = document.createElement("a");
            link.href = uri;
            // Tornar o link invisível
            link.style = "visibility:hidden";
            link.download = fileName + ".xls";
            // Acrescentar o link à página
            document.body.appendChild(link);
            // Iniciar o download
            link.click();
            // Excluir o link temporário
            document.body.removeChild(link);
        }        
    },
    computed: {
        reportFileName() {
            return 'Rel_Sols_' + moment().format('YYYYMMDD_HHmmss')
        }
    },
    mounted() {
        this.buscarStatus();
    }
}
</script>

<style>

</style>