<template>
    <div class="row ml-3">
        <div class="col-sm-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-sm-5">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="perguntaEditada.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="perguntaEditada.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-7">
                                <label>Categoria:</label>
                                <select class="form-control" v-model="perguntaEditada.idcategoriapergunta">
                                    <template v-if="categorias && categorias.length > 0">
                                        <option v-for="categoria in categorias" v-bind:key="categoria.idcategoriapergunta"
                                            :value="categoria.idcategoriapergunta"
                                            :selected="perguntaEditada.idcategoriapergunta==categoria.idcategoriapergunta"
                                        >{{categoria.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-12">
                                <label>Pergunta:</label>
                                <input v-model="perguntaEditada.textopergunta" 
                                    type="text" class="form-control" placeholder="Elabore uma pergunta ao hóspede">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('save',perguntaEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        pergunta: {
            type: Object,
            required: true,
        }
    },    
    data() {
        return {
            idiomas: [],
            categorias: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            perguntaEditada: {}
        }
    },
    methods: {
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        async loadCategorias() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/select', {
				idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.categorias = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
        this.loadCategorias();
    },
    mounted() {
        this.perguntaEditada = {... this.pergunta}
    }
}
</script>

<style>

</style>