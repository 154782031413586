<template>
	<div class="row">
        <!-- Filtrar -->
        <div class="col-lg-12" >
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="exibirFiltro">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Id do usuário -->
                            <div class="form-group col-md-2">
                                <label>#</label>
                                <input v-model="filtro.idusuario" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Nome completo do usuário -->
                            <div class="form-group col-md-6">
                                <label>{{$t('sols.nome')}}</label>
                                <input v-model="filtro.nome" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Nome de usuário -->
                            <div class="form-group col-md-6">
                                <label>{{$t('logs.nomeusuario')}}</label>
                                <input v-model="filtro.nomeusuario" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Status -->
                            <div class="form-group col-md-4">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="filtro.ativo">
                                    <option :value="null">{{$t('global.todos')}}</option>
                                    <option :value="true">{{$t('global.ativo')}}</option>
                                    <option :value="false">{{$t('global.inativo')}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row justify-content-end">
                            <div v-on:click="carregarUsuarios()" 
                                class="ponteiro text-white btn btn-primary rounded btn-sm px-4 my-4">
                                <span>{{$t('sols.filtro')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>		
		<!-- Usuarios Cadastrados -->
		<div class="col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Usuarios Cadastrados</h4>
					<a @click="criarUsuario()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar novo usuário</a>
				</div>
				<div class="card-body">
					<!-- Novo Usuário -->
                    <div class="row" id="criarUsuarioDiv" style="display: none;">
                        <UsuarioEditar 
                            :usuario="novoUsuario"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
							:alert="alertMsg"
							:usuarioTipos="usuarioTipos"
							:hoteis="hoteis"
							:setores="setores"
							:salvando="salvando"
                        />
                    </div>

					<!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

					<div class="table-responsive">
						<table class="table table-responsive-md">
							<thead>
								<tr>
									<th style="width:80px;"><strong>#</strong></th>
									<th><strong>{{$t('sols.nome')}}</strong></th>
									<th><strong>{{$t('logs.nomeusuario')}}</strong></th>
									<th><strong>{{$t('ser.email')}}</strong></th>
									<th><strong>{{$t('sols.status')}}</strong></th>
									<th></th>
								</tr>
							</thead>
							<tbody v-for="(item, idx) in usuarios" :key="idx">
								<tr>
									<td><strong>{{item.idusuario}}</strong></td>
									<td>{{item.nome}}</td>
									<td>{{item.nomeusuario}}</td>
									<td>{{item.emailusuario}}</td>                                                
									<!-- <td><span :class="{ 'badge-success' : item.ativo == true, 'badge-warning' : item.ativo == false   }" class="badge light">{{item.ativo}}</span></td> -->
									<td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
									<td>
										<div class="dropdown">
											<button type="button" :class="{ 'btn-success' : item.ativo == true, 'btn-warning' : item.ativo == false   }" class="btn light sharp" data-toggle="dropdown">
												<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
											</button>
											<div class="dropdown-menu" v-if="item.ativo == true">
												<a @click="editar(item)" class="dropdown-item ponteiro">Editar</a>
												<a @click="ativarDesativar(item, false)" class="dropdown-item ponteiro">Desativar</a>
											</div>
											<div class="dropdown-menu" v-else>
												<a @click="editar(item)" class="dropdown-item ponteiro">Editar</a>
												<a @click="ativarDesativar(item, true)" class="dropdown-item ponteiro">Ativar</a>
											</div>
										</div>
									</td>
								</tr>
                                <tr>
									<!-- Editar Usuário -->
                                    <td colspan="4">
                                        <div :id="'Editar_'+item.idusuario" style="display: none;">
                                            <UsuarioEditar
                                                :usuario="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
												:alert="alertMsg"
												:usuarioTipos="usuarioTipos"
												:hoteis="hoteis"
												:setores="setores"
												:salvando="salvando"
                                            />
                                        </div>
                                    </td>
                                </tr>								
							</tbody>
						</table>
					</div>

					<!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

				</div>
			</div>
		</div> 
	</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import UsuarioEditar from './UsuarioEditar.vue'
import Pagination from './Pagination.vue'

export default {
    components: { UsuarioEditar, Pagination},
	data() {
		return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
			usuarios: [],
			usuarioModelo: {
				"idusuario": 0,
				"idusuariocadastro" : this.$store.getters.GetLoginInfo.idusuario,
				"nomeusuario": 'NovoUsuario',
				"nome": 'Novo Usuário',
				"telefone": '',
				"email": '',
				"idioma": 'pt-BR',
				"hoteis": [],
				"setores": [],
				"acessos": []
            },
			novoUsuario: {},
			alertMsg: '',
			usuarioTipos: [],
			setores: [],
			hoteis: [],
			page: 1,
            pagesize: 6,
			pageCount: 1,
            recordCount: 0,
			filtro: {
                idusuario: '',
                nome: '',
				nomeusuario: '',
                ativo: null
            },
			exibirFiltro: false,
			salvando: false
		}
	},
	methods: {
		updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarUsuarios();
        },
		exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.exibirFiltro = true
                } else {
                    el.style.display = 'none'
                    this.exibirFiltro = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.usuarios = []
            this.filtro = {
                idusuario: '',
                nome: '',
				nomeusuario: '',
                ativo: null
            }
            window.setTimeout(this.carregarUsuarios(), 1000);
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.0850;
                }, 140);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },		
		alertar(id,msg) {
			this.alertMsg = msg
			let el = document.getElementById('alert-msg-' + id);
			if (el) {
				el.style.display = 'block';
				this.fade(el)
			}
		},
        criarUsuario() {
			this.novoUsuario = Object.assign({}, this.usuarioModelo) 
			if (this.novoUsuario.hoteis.length == 0 && this.hoteis.length > 0 && this.hoteis[0]) {
				this.novoUsuario.hoteis.push(this.hoteis[0])
				this.novoUsuario.hoteis[0].checked = true
				this.novoUsuario.idhotel = this.hoteis[0].idhotel
			}
			if (this.novoUsuario.setores.length == 0 && this.setores.length > 0) {
				this.novoUsuario.setores = this.setores;
			}
			this.$forceUpdate()

			this.$forceUpdate()
            var obj = document.getElementById('criarUsuarioDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        saveNew(item) {
			var listahoteis = []
			for (var i = 0; i < item.hoteis.length; i++) {
				var hotel = item.hoteis[i]
				listahoteis.push(parseInt(hotel.idhotel))
			}
			var listasetores = []
			for (var j = 0; j < item.setores.length; j++) {
				var setor = item.setores[j]
				listasetores.push(parseInt(setor.idhotlinesetores))
			}
			var listaacessos = []
			//console.error(JSON.stringify(item.acessos));
			for (var k = 0; k < item.acessos.length; k++) {
				var acesso = item.acessos[k]
				listaacessos.push({
					idmenu: parseInt(acesso.idmenu),
					idhotel: listahoteis.length>0?listahoteis[0]:parseInt(this.$store.getters.GetLoginInfo.idhotel),
					nivel: parseInt(acesso.nivel)
				})
			}
            var params = {
				"idusuariocadastro" : this.$store.getters.GetLoginInfo.idusuario,
				"nomeusuario": item.nomeusuario.trim(),
				"nome": item.nome.trim(),
				"telefone": item.telefone.trim(),
				"email": item.emailusuario.trim(),
				"senha": item.novasenha.trim(),
				"idioma": item.idioma,
				"hoteis": listahoteis,
				"idhotlinesetores": listasetores,
				"acessos": listaacessos,
				"escalas": item.escalas,
				"ativo": true,
				"idhotel": item.idhotel,
				"idusuariotipo": item.idusuariotipo,
                "criado_por": this.$store.getters.GetLoginInfo.idusuario,
				"folgas": item.folgas
            }
			//console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/insert',
                params, { headers: this.headers })
			.then(response => {
                if(response.status == 200) {
					this.$snack('Usuário salvo com sucesso.')
                    this.carregarUsuarios()
                    this.closeNew()
                } else {
					if(response.status == 201) {
						this.alertar(item.idusuario, response.data.message)
					}
				}
            })
			.catch((e) => {
				console.log({ err_usuario: e, message: e.message});
			})
        },
        closeNew() {
            var obj = document.getElementById('criarUsuarioDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idusuario)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        saveEdit(item) {
			this.salvando = true;
			var listahoteis = []
			for (var i = 0; i < item.hoteis.length; i++) {
				var hotel = item.hoteis[i]
				listahoteis.push(parseInt(hotel.idhotel))
			}

			var listaacessos = []
			// console.error(JSON.stringify(item.acessos));
			for (var k = 0; k < item.acessos.length; k++) {
				var acesso = item.acessos[k]
				listaacessos.push({
					idmenu: parseInt(acesso.idmenu), 
					idhotel: listahoteis.length>0?listahoteis[0]:parseInt(this.$store.getters.GetLoginInfo.idhotel),
					nivel: parseInt(acesso.nivel)
				})
			}
            var params = {
				"idusuario": item.idusuario,
				"idusuariocadastro" : this.$store.getters.GetLoginInfo.idusuario,
				"tecnico_manutencao": item.tecnico_manutencao? true:false,
				"nomeusuario": item.nomeusuario.trim(),
				"nome": item.nome.trim(),
				"telefone": item.telefone?.trim(),
				"email": item.emailusuario.trim(),
				"idioma": item.idioma,
				"hoteis": listahoteis,
				"setores": item.setores,
				"acessos": listaacessos,
				"escalas": item.escalas,
				"ativo": true,
				"idhotel": item.idhotel,
				"idusuariotipo": item.idusuariotipo,
				"alterado_por": this.$store.getters.GetLoginInfo.idusuario,
				"folgas": item.folgas
            }
			if (item.novasenha && (item.novasenha == item.senhaconfirmada)) {
				params.senha = item.novasenha
			}
			console.log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/fdguest/usuarios/update',
                params, { headers: this.headers })
			.then(response => {
				this.salvando = false;
                if(response.status == 200) {
					this.$snack('Usuário salvo com sucesso.')
                    this.carregarUsuarios()
                    this.closeEdit(item)
                } else {
					if(response.status == 201) {
						this.alertar(item.idusuario,response.message)
					}
				}
            })
			.catch((e) => {
				this.salvando = false;
				console.error('Erro:'+e.message);
			})
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idusuario)
            if(obj) {
                obj.style.display = 'none'
            }
        },
		carregarUsuarios() {
			let params = {
				nome: this.filtro.nome,
				nomeusuario: this.filtro.nomeusuario,
				ativo: this.filtro.ativo,
				page: this.page,
                pagesize: this.pageSize
			}
			
			if (this.filtro.idusuario.trim()) params.idusuario = this.filtro.idusuario

			axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/select', params, { headers: this.headers })
			.then(response => {
				this.usuarios = response.data.dados;
				this.recordCount = response.data.recordCount;
                this.pageCount =  response.data.pageCount?response.data.pageCount:1;
			})
			.catch((e) => {
				console.log({ err_usuario: e, message: e.message});
			})
		}, 
        async loadTiposUsuarios() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tipos', {}
                , {headers: this.headers})
			.then(response => {
				this.usuarioTipos = response.data;
			}).catch((err) => {
				console.log({ Erro: err.message });
			});
        },
        async loadSetores() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select', {
				idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				this.setores = response.data;
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        async loadHoteis() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/hotel/select', {
				idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				this.hoteis = response.data;
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
		ativarDesativar(usuario, valor) {
            var params = {
				idusuariocadastro: this.$store.getters.GetLoginInfo.idusuario,
                idusuario: usuario.idusuario, 
                ativo: valor,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
			axios.put(CONSTANTS.API_URL + '/fdguest/usuarios/update', params, { headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.carregarUsuarios()
                }
            })
			.catch((e) => {
				console.log({ err_usuario: e, message: e.message});
			})
        }
	},
	mounted() {
		this.loadTiposUsuarios().then(()=>{
			this.carregarUsuarios();
			this.loadHoteis();
			this.loadSetores();
		});
	}
}
</script>

<style>
	.tamanho {
		width: 8px !important;
		height: 8px !important;
	}
</style>

