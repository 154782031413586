<template>
    <div>
        <button @click="clickButton('teste')" class="btn btn-primary" type="submit">Socket Test</button>
    </div>
</template>
<script>
export default {

    data(){
        return {

        }
    },
    methods: {
        clickButton: function () {
        this.$socket.disconnect();
        //this.$socket.io.opts.query.usuario = localStorage.getItem('checkinpay-email')
        this.$socket.io.opts.query.email = 'mauricio@email.com'
        //this.$socket.io.opts.query.tipo_acesso = 'CHECKINPAY_'+localStorage.getItem('checkinpay-email')
        this.$socket.connect();
        this.$socket.emit('testandoHerlessi')
        console.log('Botao clicado')
        },
        connectScocket: function() {
            this.$socket.disconnect();
            
            this.$socket.io.opts.query.email = 'mauricio@email.com'
            
            this.$socket.connect();
                    this.sockets.subscribe("RETORNOBE", (dados) => {
            alert(dados.msg)
            });
        }        
    },
    mounted(){
        this.connectScocket()
    },
    beforeDestroy(){
        this.sockets.unsubscribe("RETORNOBE")
    }
}
</script>