<template>
    <div class="es-history-container">
        <div class="es-history-reload" @click="carregarHistorico()">
            Recarregar
            <span class="material-icons icon">refresh</span>
        </div>
        <div class="es-history">
            <div v-for="(msg,index) in historico" :key="index" 
                :class="{ 'left' : msg.hospede, 'right' : !msg.hospede }"
                class="es-history-message"
            >
                <div class="es-history-message-container">
                    <div class="es-history-text">{{msg.texto}}</div>
                    <div v-if="msg.havefile == true" class="es-history-text">
                    <small>{{msg.namefile}}</small>
                    <div v-if="msg.fileremoved">
                        <i class="material-icons btn-danger mt-3 ">disabled_by_default</i>
                        <span class="btn pt-0 pb-2">Imagem Removida por Vencimento</span>
                    </div>
                    <button v-else @click="downloadFile(msg.id)" class="btn btn-success mr-2 ml-2 btn-sm">Download</button>
                    </div>
                    <div class="es-history-author">{{msg.nome}} - {{formatarData(msg.dataevento)}} - {{formatarHora(msg.dataevento)}}</div>
                </div>
            </div>
        </div>
        <div class="es-history-empty" v-if="semregistro">
            Histórico sem registros.
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    props: {
        idhotlinesolicitacoes: {
            type: Number,
            required: true,
            default: 1870
        },
        interna: {
            type: Boolean,
            required: true
        }
    },
    components: {  },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            msgs: [
                {"id":333,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:05:40.898163","texto":"Atendida. Sua solicitação foi atendida!","idevento":null,"produtos":null},
                {"id":332,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:05:02.640138","texto":"Solicitação contestada.: foi não","idevento":null,"produtos":null},
                {"id":331,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:04:46.90049","texto":"Atendida. Sua solicitação foi atendida!","idevento":null,"produtos":null},
                {"id":330,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:04:27.028751","texto":"Solicitação cancelada.: deixa pra la","idevento":null,"produtos":null},
                {"id":329,"de":255083,"nome":"Edilson Vieira Vieira","hospede":false,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 18:03:53.695031","texto":"Solicitação visualizada.: Estamos cientes de sua solicitação que será atendida mediante disponibilidade!","idevento":null,"produtos":null},
                {"id":324,"de":367615,"nome":"Denilson Guimarães","hospede":true,"idhotlinesolicitacoes":1823,"dataevento":"2021-11-30 17:46:43.240696","texto":"Solicitação criada.. Gostaria de solicitar: Lençol: 1; ","idevento":null,"produtos":null}
            ],
            historico: null,
            semregistro: false,
        }
    },
    methods: {
        async downloadFile(id){

            let params = {
                idhotlinesolicitacoeseventos : id
            }
            //console.log(JSON.stringify(this.historico))
            await axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select/files',
                    params, { headers: this.headers})
            .then(response => {
                //console.log(JSON.stringify(response))
                var a = document.createElement("a"); //Create <a>
                a.href = response.data[0].documento; //Image Base64 Goes here
                a.download = response.data[0].nomedocumento; //File name Here
                a.click();
                //console.log(response.data[0].documento)

                //download(response.data[0].documento, response.data[0].nomedocumento, 'image/png')
            }).catch(e =>{
                console.error(JSON.stringify(e))
            })


        },
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('LL');
        },
        formatarHora(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('LTS');
        },
        carregarHistorico() {
            this.historico = []
            var  params = {
                idioma: this.$i18n.locale,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idestoque: this.$store.getters.GetLoginInfo.idestoque,
                usuario: this.$store.getters.GetLoginInfo.idusuario,
                idhotlinesolicitacoes: this.idhotlinesolicitacoes
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/historico',
                    params, { headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    if (response.data.length > 0) {
                        this.historico = response.data
                        //console.log(JSON.stringify(this.historico))
                        this.semregistro = false
                    } else {
                        this.semregistro = true
                    }
                } else {
                    console.log(JSON.stringify(response))
                }
            })            
        }        
    },
    computed: {
    },
    created() {
        this.carregarHistorico()
    },
    mounted() {
    }
}
</script>

<style scoped>
.es-history-container {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.es-history {
    display: flex;
    flex-direction: column;
    background-color: azure;
    border-radius: 15px 15px 15px 15px;
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
}
.es-history-message {
    display: flex;
    flex-direction: row;
}
.es-history-message-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: rgb(135, 225, 245);
    padding: 0.7rem;
    margin: 0.7rem;
}
.left .es-history-message-container {
    border-radius: 15px 15px 15px 0px;
    box-shadow: 10px 10px 5px grey;
}
.right .es-history-message-container {
    border-radius: 15px 15px 0px 15px;
    box-shadow: 10px 10px 5px grey;
}
.es-history-message.left {
    display: flex;
    flex-direction: row;
}
.es-history-message.right {
    display: flex;
    flex-direction: row-reverse;
}
.es-history-text {
    white-space: pre-wrap!important;
    font-weight: bold;
    color: rgb(46, 44, 31);
    margin-bottom: 15px;
}
.es-history-author {
    color: rgb(88, 88, 88);
    font-size: 0.7rem;
    font-weight: bold;
}
.right .es-history-author {
    text-align: right;
}
.es-history-reload {
    text-align: center;
    color: black;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 0.4rem;
}
.es-history-empty {
    text-align: center;
    color: orange;
}
.es-cancel {
    font-size: 1.9rem;
    color: grey;
}
</style>