<template>
    <div style="max-width:100vw;padding-left:30px;padding-right:30px;">
    <!-- Pesquisa -->
    <!-- <div class="card-header"> -->
        <div class="form-row mt-3" style="align-items:center;">
            <div class="form-group col-sm-auto">
                <h2>Duvidas Frequentes</h2>
            </div>
            <div class="form-group col-sm-auto">
                <input class="form-control" type="text" v-model="textoPesquisa"  />
            </div>
            <div class="form-group col-sm-auto">
                <a @click="buscarFaqs()" class="ponteiro text-white btn btn-info">Pesquisar</a>
            </div>
        </div>
    <!-- </div> -->
    <!-- Conteúdo -->
    <div style="padding-top:20px;">
        <div class="card" v-for="categoria in Object.keys(menus)" :key="categoria">
            <div class="card-header">
                <h3>{{categoria}}</h3>
            </div>
            <div class="card-text p-2" v-for="perg in menus[categoria]" :key="perg.id">
                <h4 class="px-4 es-perg"
                    @click="abrirResposta(perg);"
                ><strong>{{perg.pergunta}}</strong></h4>
                <!-- <h5 class="px-5">{{perg.resposta}}</h5> -->
                <div class="p-4" v-if="perg.resposta">
                    <VueEditor disabled="true" 
                        v-model="perg.resposta"
                        :editorOptions="editorOptions"
                    ></VueEditor>
                </div>
                <div class="es-loading" v-if="perg.carregando && !perg.resposta">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">{{textoCarregando}}...</span>
                    </div>                    
                </div>
            </div>
        </div>            
    </div>
  
  </div>
</template>

<script>
import axios from 'axios'
import { VueEditor } from "vue2-editor";
//import { tokenapies } from '@/constantes.js'
import CONSTANTS from '../constants.js'

export default {
    data: () => ({
        menus:[],
        textoPesquisa:'',
        respostaAtual: null,
        carregando: false,
        textoCarregando: '',
        editorOptions: {
            modules: {
                toolbar: false,
            },
        }
    }),
    components:{
        VueEditor
    },
    methods: {
        groupByKey (list, key) {
            return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
        },
        buscarFaqs() {
            let params = {
                idsistema: CONSTANTS.FDGUEST_ID,
                idcliente: this.$store.getters.GetLoginInfo.idcliente,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                pergunta: this.textoPesquisa ? this.textoPesquisa : null,
                somentepergunta: true,
                idsistemas: this.$store.getters.GetLoginInfo.idsistemas
            }
            //console.log('>>> 1 <<<')
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.APIES_URL+"/faq/pergunta/endpoint/select", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200) {
                    this.menus = this.groupByKey(response.data, 'nomecategoria')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        abrirResposta(perg) {
            if (perg.resposta) {
                perg.resposta = null;
                this.$forceUpdate();
                return;
            }
            let params = {
                idsistema: CONSTANTS.FDGUEST_ID,
                idcliente: CONSTANTS.ID_CLIENTE_ECONOMY,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                id: perg.id
            }
            perg.carregando = true;
            this.textoCarregando = this.$t('global.carregando');
            this.$forceUpdate();
            console.log('>>> 2 <<<')
            console.log(JSON.stringify(params))
            axios.post(CONSTANTS.APIES_URL+"/faq/pergunta/endpoint/select", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200 && response.data.length > 0) {
                    perg.resposta = response.data[0].resposta;
                    this.respostaAtual = perg.resposta;
                    perg.carregando = false;
                    this.$forceUpdate();
                }
            })
            .catch((error) => {
                console.log(error);
                perg.carregando = false;
                this.$forceUpdate();
            });            
        }
    },
    mounted() {

        // if (localStorage.getItem('recarregou')) {
        //    localStorage.removeItem('recarregou')
        // } else {
        //     localStorage.setItem('recarregou','sim')
        //     location.reload()
        // }
        this.buscarFaqs();
    },
    created(){
        //INCLUSAO DO CHAT
        // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        // console.log("Tawk_LoadStart")
        // console.log(Tawk_LoadStart)

        // var s1=document.createElement("script")
        // console.log("s1")
        // console.log(s1)
        // var s0=document.getElementsByTagName("script")[0];
        // console.log("s0")
        // console.log(s0)
        // s1.async=true;
        // s1.src='https://embed.tawk.to/60af9787de99a4282a19e931/1f6mufr75';
        // s1.charset='UTF-8';
        // s1.setAttribute('crossorigin','*');
        // s0.parentNode.insertBefore(s1,s0);
     
        //FINAL DA INCLUSAO DO CHAT
    },
    beforeDestroy(){
        //EXCLUIR O CHAT
        // var elementos = document.getElementsByTagName('iframe')
        // while(elementos[0]) elementos[0].parentNode.removeChild(elementos[0])
        //FIM DO EXCLUIR CHAT
    }
};
</script>

<style scoped>
    .es-perg {
        cursor: pointer;
    }
    .es-perg:hover {
        color: #2781d5;
    }
    .es-loading {
        text-align: center;
        padding: 0.5rem;
        margin: 0.5rem;
    }
</style>
