<template>
  <div>
    <div style="z-index: 10000;">
        <HelpViewer :idPergunta="idPergunta" 
            :show="showHelpViewer" :vote="vote"
            @closeviewer="showHelpViewer=false;"
            @vote="setVote($event)"
        ></HelpViewer>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document" style="width: 75%;">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Entre com uma palavra chave e clique em "Procurar"</h5>
                  <button type="button" class="close" @click="$emit('close')">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body" >
                    <div class="row mb-2">
                        <ul class="nav nav-tabs">
                            <li class="nav-item ponteiro">
                                <a class="nav-link" :class="{'active': page==1}" @click="page=1">FAQ's</a>
                            </li>
                            <li class="nav-item ponteiro">
                                <a class="nav-link" :class="{'active': page==2}" @click="page=2">Vídeos</a>
                            </li>
                        </ul>
                    </div>
                    <!-- Campo de Busca -->
                    <div class="row mb-2 mt-4">
                        <div class="col-md-8">
                            <input autofocus class="form-control mr-sm-2" type="search" placeholder="Procurar" aria-label="Procurar"
                                v-model="textoPesquisa"
                            >
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-outline-success my-2 my-sm-0" @click="buscar()">Procurar</button>
                        </div>
                        <!-- <nav class="navbar navbar-light ">
                            <div class="form-inline">
                                <input autofocus class="form-control mr-sm-2" type="search" placeholder="Procurar" aria-label="Procurar">
                                <button class="btn btn-outline-success my-2 my-sm-0" @click="buscarFaqs()">Procurar</button>
                            </div>
                        </nav> -->
                    </div>
                    <!-- Filtro de Categorias -->
                    <!-- <div style="text-align:center;"> <label class="text-center" for="filtro-cat">Categorias</label> </div>
                    <div id="filtro-cat" class="row px-4" style="justify-content:center;">
                        <div :class="{ 'badge-success' : cat.selected , 'badge-dark' : !cat.selected }"
                            class="m-1 badge badge-pill col-md-auto ponteiro" 
                            v-for="(cat,index) in categorias" :key="index"
                            @click="filter(cat)"
                        >
                            {{cat.nome}}
                        </div>
                    </div> -->
                    <!-- Resultado das FAQS -->
                    <div v-if="page==1" class="es-aba-help">
                        <div class="card" v-for="categoria in Object.keys(perguntasFAQ?perguntasFAQ:{})" :key="categoria">
                            <div class="card-header">
                                <h4>{{categoria}}</h4>
                            </div>
                            <div class="card-text p-2" v-for="perg in perguntasFAQ[categoria]" :key="perg.id">
                                <h5 class="px-4 es-perg"
                                    @click="abrirResposta(perg);"
                                ><strong>{{perg.pergunta}}</strong></h5>
                                <!-- <h5 class="px-5">{{perg.resposta}}</h5> -->
                                <div class="es-loading" v-if="perg.carregando && !perg.resposta">
                                    <div class="spinner-border text-info" role="status">
                                        <span class="sr-only">{{textoCarregando}}...</span>
                                    </div>                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Vídeo -->
                    <div v-if="page==2" key="Vídeo" class="es-aba-help">
                        <template v-if="tutorial">
                            <div class="mt-4" style="display:flex;align-items:center;justify-content:center;width:100%;height:450px;">
                                <iframe class="iframe-video" style="height:100%;width:100%;"
                                    :src="`${tutorial.video}`">
                                </iframe>
                            </div>
                            <div class="row text-gray d-flex align-items-center justify-content-center pt-3">
                                <div class="col-md-auto">
                                    <button class="btn btn-outline-primary btn-sm my-2 my-sm-0 mx-3 px-2" @click="tutorial=null">{{$t('global.voltar')}}</button>
                                </div>
                                <div class="col-md-auto">
                                    <h5 style="padding:10px;margin:10px;" @click="tutorial=null">Este vídeo foi útil?</h5>
                                </div>
                                <div class="col-md-auto">
                                    <ThumbsUpDown :vote="vote"
                                        @up="userVoteTutorial('up')"
                                        @down="userVoteTutorial('down')"
                                    ></ThumbsUpDown>
                                </div>                                
                            </div>
                        </template>
                        <div v-else class="row ponteiro es-video" v-for="tut in tutoriais" :key="tut.id">
                            <div class="col-12" style="display:flex;flex-direction:row;align-itens:center;"
                                    @click="showVideo(tut)">
                                <span class="material-icons-outlined material-icons icon font-lg ponteiro mx-2">
                                    play_circle_filled
                                </span>
                                <div style="align-self:center;">
                                    <h5 style="padding:0px;margin:0px;"><strong>{{tut.titulo}}</strong></h5>
                                    <h6 style="padding:0px;margin:0px;">{{tut.descricao}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"
                    @click="$emit('close')"
                  >{{$t('sols.fechar')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>  
</template>

<script>
  import axios from 'axios'
  import CONSTANTS from '../constants.js'
  import HelpViewer from './HelpViewer.vue'
  import ThumbsUpDown from './ThumbsUpDown.vue'

  export default {
    name: 'Help',
    components: {HelpViewer, ThumbsUpDown},
    data() {
      return {
        validEntregue: true,
        validLiberada: true,
        showModal: false,
        textoPesquisa:'',
        perguntasFAQ: [],
        categorias: [],
        tutoriais: [],
        page: 1,
        showHelpViewer: false,
        idPergunta: 0,
        textoCarregando: '',
        tutorial: null,
        vote: ''
      }
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      filtroCat: {
        type: String,
        required: false,
        default: null
      },
      initialPage : {
        type: Number,
        required: false,
        default: 1
      }
    },
    watch: {
      show(val) {
        if (val) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
      },
      filtroCat() {
        this.atualizarFiltro();
        this.buscar();
      }
    },
    methods: {
        buscar() {
            if (this.page == 1 ) {
                this.buscarFaqs();
            } else {
                this.buscarTutoriais();
            }
        },
        setVote(newVote) {
            this.vote = newVote.vote;
            this.$forceUpdate();
        },
        showVideo(tut) {
            console.error(`tut: ${tut}`)
            this.tutorial = tut;
            this.vote = tut.fl_curtida==null?'':(tut.fl_curtida?'up':'down');
            this.$forceUpdate();
        },
        atualizarFiltro() {
            for (var i = 0; i < this.categorias.length; i++) {
                let thisCat = this.categorias[i];
                thisCat.selected = (thisCat.id == parseInt(this.filtroCat));
            }
        },
        abrirResposta(perg) {
            // console.error(`perg:${JSON.stringify(perg)}`);
            this.idPergunta = perg.id;
            this.vote =  perg.curtidasfaq?'up':'down';
            this.showHelpViewer = !this.showHelpViewer;
        },
        groupByKey (list, key) {
            return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
        },
        filter(cat) {
            if (this.filtroCat.includes(cat.selected)) {
                console.error(`filtroCat.includes(cat): ${JSON.stringify(cat)}`)
            } else {
                cat.selected = true;
                console.error(`filtroCat does not includes(cat): ${JSON.stringify(cat)}`)
            }
        },
        userVoteTutorial(updown) {
            let params = {
                "id_video": this.tutorial.id,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
                "fl_curtida": (updown == 'up'),
                "idcliente": CONSTANTS.ID_CLIENTE_ECONOMY
            }
            axios.post(CONSTANTS.APIES_URL+"/tutorial/inserirAtualizarCurtida", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200) {
                    this.vote = updown
                    this.tutorial.fl_curtida = (updown == 'up')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        buscarFaqs() {
            let params = {
                idsistema: CONSTANTS.FDGUEST_ID,
                idcliente: CONSTANTS.ID_CLIENTE_ECONOMY,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                pergunta: this.textoPesquisa ? this.textoPesquisa : null,
                somentepergunta: true,
                categorias: this.filtroCat ? this.filtroCat : "",
                idsistemas: this.$store.getters.GetLoginInfo.idsistemas
            }
            this.perguntasFAQ = null;
            console.log('>>> 3 <<<')
            console.log(JSON.stringify(params))
            axios.post(CONSTANTS.APIES_URL+"/faq/pergunta/endpoint/select", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200) {
                    this.perguntasFAQ = this.groupByKey(response.data, 'nomecategoria')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        buscarTutoriais() {
            let params = {
                sistema: CONSTANTS.FDGUEST_ID,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idcliente: CONSTANTS.ID_CLIENTE_ECONOMY,
                search: this.textoPesquisa ? this.textoPesquisa : null,
                categorias: this.filtroCat,
                tokenapies: "5e47648febb18324243329f0bd0ef5e871ecd41f"
            }
            console.error(params)
            this.tutoriais = null;
            axios.post(CONSTANTS.APIES_URL+"/tutorial/select", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200) {
                    this.tutoriais = response.data
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        buscarCats() {
            let params = {
                idsistema: CONSTANTS.FDGUEST_ID
            }
            axios.post(CONSTANTS.APIES_URL+"/faq/categoria/select", 
                params, 
                { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
            .then((response) => {
                if (response.status == 200) {
                    this.categorias = response.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        checkValid(p){
            let qtde_liberada = 0;
            let qtde_entregue = 0;
            let qtde_estoque = 0;
            let result = true;
            try {
                qtde_liberada = parseFloat(p.qtdeliberada);
                qtde_entregue = parseFloat(p.qtdeentregue);
                qtde_estoque = parseFloat(p.quantidade);
            } catch (e) {
                result = false;
                console.error(JSON.stringify(e));
                return result;
            }
            
            if (qtde_liberada > qtde_estoque) {
                p.validLiberada = false;
                result = false;
            } else {
                p.validLiberada = true;
            }
            if (qtde_entregue > qtde_liberada) {
                p.validEntregue = false;
                result = false;
            } else {
                p.validEntregue = true;
            }

            return result;
        },
        setProduto(p,$event) {
            this.$emit('item-changed',{p: p, index: $event})
        },
        valid(p) {
          let pArray = [];
          if (p) {
            pArray = [p];
          } else {
            pArray = this.perguntasFAQ;
          }
          let result = true;
          for (var i = 0; i < pArray.length; i++) {
            let x = pArray[i]
            if (x.qtdeliberada > x.quantidade || x.qtdeentregue > x.qtdeliberada) {
              result = false;
              break;
            }
          }
          return result;
        },
        abrirMovimentacaoEstoque(p) {
            let el = document.getElementById(`save_edit_${p.idproduto}`);
            this.$unfade(el);
        },
        excluirItem(p,index) {
            this.$emit('item-deleted',{p: p, index: index})
        },
        salvarEstoque(item,itemSol) {
            this.$emit('save-stock',{item: item, itemSol: itemSol})
        },
        fecharEstoque(p) {
            this.$fade(document.getElementById(`save_edit_${p.idproduto}`));
        },
        addProd() {
            this.$emit('add-prod')
        }
    },
    mounted() {
      this.buscarCats();
    }
  }
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.es-aba-help {
    min-height: 600px;
    max-height: 600px;
    overflow: hidden;
}
.es-perg {
    cursor: pointer;
}
.es-perg:hover {
    color: DodgerBlue;
}
.es-video {
    cursor: pointer;
    padding: 0.5rem;
    background-color: white;
    border: 1px grey;
}
.es-video:hover {
    color: DodgerBlue;
}
.es-up {
    color: gray;
}
.es-up:hover {
    color: green;
}
.es-down:hover {
    color: red;
}
/* The animation code */
/* @keyframes example {
  0% {color: gray;transform: rotate(0deg);}
  50%  {color: orange;transform: rotate(180deg);}
  100%  {color: red;transform: rotate(0deg);}
} */

/* The element to apply the animation to */
/* .es-down {
  color: gray;
  animation: example 2s ease-in-out;
  position: relative;
} */
.es-tab {
    color: gray;
    cursor: pointer;
}
.es-active-tab {
    color: dodgerblue;
}

</style>