<template>
  <div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document" style="width: 80%;">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{pergunta.pergunta}}</h4>
                  <button type="button" class="close" @click="$emit('closeviewer')">
                    <span>&times;</span>
                  </button>
                </div>
                <div v-if="!pergunta.carregando || pergunta.resposta" id="modal-content-div" 
                    class="modal-body" style="overflow-y:scroll;height:700px;">
                        <VueEditor :disabled="true" 
                            v-model="pergunta.resposta"
                            :editorOptions="editorOptions"
                        ></VueEditor>
                        <div class="row justify-content-center align-items-center py-2">
                          <div class="col-md-auto">
                            <h5>Esta resposta foi útil?</h5>
                          </div>
                          <div class="col-md-auto">
                            <ThumbsUpDown :vote="vote"
                              @up="userVoteFAQ('up')"
                              @down="userVoteFAQ('down')"
                            ></ThumbsUpDown>
                          </div>
                        </div>
                </div>
                <div class="es-loading" v-if="pergunta.carregando && !pergunta.resposta">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">{{textoCarregando}}...</span>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"
                    @click="$emit('closeviewer')"
                  >{{$t('sols.fechar')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>  
</template>

<script>
  import axios from 'axios'
  import CONSTANTS from '../constants.js'
  import { VueEditor } from "vue2-editor";
  import ThumbsUpDown from './ThumbsUpDown.vue'

  export default {
    name: 'HelpViewer',
    components: {VueEditor,ThumbsUpDown},
    data() {
      return {
        showModal: false,
        textoPesquisa:'',
        perguntasFAQ: [],
        categorias: [],
        tutoriais: [],
        page: 1,
        textoCarregando: '',
        pergunta: {},
        resposta: {},
        video: '',
        editorOptions: {
            modules: {
                toolbar: false,
            },
        }
      }
    },
    props: {
      show: {
        type: Boolean,
        required: true
      },
      vote: {
        type: String,
        required: false,
        default: ''
      },
      idPergunta : {
        type: Number,
        required: true
      },
      titulo: {
        type: String,
        required: false,
        default:'Entre com uma palavra chave e clique em "Procurar"'
      }
    },
    watch: {
      show(val) {
        if (val) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
      },
      idPergunta(val) {
        this.atualizarConteudo(val);
      }
    },
    methods: {
      userVoteFAQ(updown) {
        let params = {
          "id_faq":this.idPergunta,
          "idusuario":this.$store.getters.GetLoginInfo.idusuario,
          "fl_curtida": (updown == 'up'),
          "idcliente": CONSTANTS.ID_CLIENTE_ECONOMY
        }
        axios.post(CONSTANTS.APIES_URL+"/faq/inserirAtualizarCurtida", 
            params, 
            { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
        .then((response) => {
            if (response.status == 200) {
                //this.vote = updown;
                this.$emit('vote',{vote: updown})
            }
        })
        .catch((error) => {
            console.log(error);
        });
      },
      atualizarConteudo(pergId) {
          if (this.pergunta.resposta) {
              this.pergunta.resposta = null;
              this.$forceUpdate();
              return;
          }
          let params = {
              idsistema: CONSTANTS.FDGUEST_ID,
              idcliente: CONSTANTS.ID_CLIENTE_ECONOMY,
              idusuario: this.$store.getters.GetLoginInfo.idusuario,
              id: pergId
          }
          this.pergunta.carregando = true;
          this.textoCarregando = this.$t('global.carregando');
          this.$forceUpdate();
          console.log('>>> 4 <<<')
          console.log(JSON.stringify(params))
          axios.post(CONSTANTS.APIES_URL+"/faq/pergunta/endpoint/select", 
              params, 
              { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
          .then((response) => {
              if (response.status == 200 && response.data.length > 0) {
                  // let obj = document.getElementById('modal-content-div')
                  // if (obj) {
                  //     obj.innerHTML = response.data[0].resposta
                  // }
                  this.pergunta = response.data[0];
                  this.resposta = response.data[0].resposta
                  this.pergunta.carregando = false;
                  this.$forceUpdate();
              }
          })
          .catch((error) => {
              console.log(error);
              this.pergunta.carregando = false;
              this.$forceUpdate();
          });        
      },
      buscarCats() {
          let params = {
              idsistema: CONSTANTS.FDGUEST_ID
          }
          axios.post(CONSTANTS.APIES_URL+"/faq/categoria/select", 
              params, 
              { headers: {'x-access-token':'5e47648febb18324243329f0bd0ef5e871ecd41f'}})
          .then((response) => {
              if (response.status == 200) {
                  this.categorias = response.data;
              }
          })
          .catch((error) => {
              console.log(error);
          });
      }
    },
    mounted() {
      this.buscarCats();
    }
  }
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 10998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.es-aba-help {
    min-height: 600px;
    max-height: 600px;
    overflow-y: scroll;
}
.es-perg {
        cursor: pointer;
}
.es-perg:hover {
    color: #2781d5;
}
.es-loading {
    text-align: center;
    padding: 0.5rem;
    margin: 0.5rem;
}
.es-modal-body {
    height:70%;
    overflow-y: scroll;
}
</style>