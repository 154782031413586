<template>
  <div class="modal" :class="{ 'is-active': exibirModal }">
    <div class="modal-background" @click="fecharModal"></div>
    <div class="modal-content">
      <div class="box text-center my-2">
        <div v-if="objeto" class="row">
          <div class="col col-auto" v-for="(prop, index) in Object.keys(objeto)" :key="index">
            <label :for="`prop_${index}`"><strong>{{$t(`global.${prop}`)}}</strong></label>
            <span :id="`prop_${index}`" class="form-control">
              {{formatarProp(objeto[prop])}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large ponteiro" aria-label="close" @click="fecharModal">X</button>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: 'ModalESObjeto',
  components: {
  },
  props: {
    valor:{
      type: Boolean,
      default: false
    },
    objeto: {
      type: Object,
      required: false,
      default: null
    },
  },
  methods: {
    fecharModal() {
      this.$emit('fechar');
    },
    formatarData(aDate) {
        return moment(aDate).locale(this.$i18n.locale).format('LLL');
    },
    dataValida(dateString) {
      const umaData = new Date(dateString);
      return !isNaN(umaData.getTime())
    },
    formatarProp(prop) {
      let ret = prop
      if (Array.isArray(prop)) {
        ret = prop.join(',')
      } else {
        if (isNaN(prop)&&this.dataValida(prop)) {
          ret = this.formatarData(prop)         
        }
      }
      return ret;
    }
  },
  watch: {
    valor(val) {
      this.exibirModal = val;
    }
  },
  data() {
    return {
      exibirModal: false
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.modal-content {
  width: fit-content;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 200;
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-close:hover {
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

.botoes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

</style>