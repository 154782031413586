<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <div class="box">
        <p>{{ text }}</p>
      </div>
      <template v-if="details && details.length > 0">
        <div class="table-responsive">
					<table class="table table-responsive-md">
						<thead>
							<tr>
                <th v-for="(cab, indice) in headers" :key="indice">
                  <div><strong>{{cab.nome}}</strong></div>
                </th>
              </tr>
						</thead>
            <tbody class="row-select">
              <tr class="ponteiro" v-for="(item,index) in details" :key="index">
                  <td v-for="(itemColumn, ind) in headers" :key="ind"
                    :style="`text-align:${headers[ind]['align']}`"
                  >
                    <div v-if="itemColumn.tipo == 'date'" class="form-control dense">{{formatarData(item[itemColumn.campo])}}</div>
                    <div v-if="itemColumn.tipo == 'integer'" 
                      :class="{ 'abrir-chamado': itemColumn.campo == 'idhotlinesolicitacoes' }" 
                      class="form-control" @click="abrirDetalhe(item[itemColumn.campo])"
                    >{{item[itemColumn.campo]}}
                      <!-- <span class="tooltiptext">{{$t('global.abrirchamado')}}</span> -->
                    </div>
                    <div v-if="itemColumn.tipo == 'number'" class="form-control">{{formatarNumero(item[itemColumn.campo])}}</div>
                    <div v-if="itemColumn.tipo == 'string' && item[itemColumn.campo]?.length < 20" class="form-control">{{item[itemColumn.campo]}}</div>
                    <div v-if="itemColumn.tipo == 'string' && item[itemColumn.campo]?.length >= 20" class="form-control" style="display:flex;flex-direction:row;align-items:center;justify-content:space-between;">
                        <span>{{item[itemColumn.campo].substring(0,20)}}...</span>
                        <div class="pl-2">
                            <button type="button" class="btn btn-warning light sharp"
                                @click="abrirModal(item, item[itemColumn.campo])"
                            ><svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                            </button>
                        </div>
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <ModalAbrirChamado :titulo="`Chamado`" 
        :texto="textoChamado" :exibir="exibirModalChamado" :chamado="chamado"
        @fechar="exibirModalChamado=false" @abrir="abrirDetalhe()"
      ></ModalAbrirChamado>
    </div>
    <button class="modal-close is-large ponteiro" aria-label="close" @click="closeModal">X</button>
  </div>
</template>

<script>
import moment from 'moment'
import ModalAbrirChamado from './ModalAbrirChamado.vue'

export default {
  name: 'ModalES',
  components: {
    ModalAbrirChamado
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    details: {
      type: Array,
      required: false,
      default: null
    },
    headers: {
      type: Array,
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    abrirDetalhe(chamado=null) {
      if (chamado) this.chamado = chamado
			this.$store.dispatch('set_Sol_Atual', this.chamado);
      //console.error(`abrirDetalhe(${this.$store.getters.GetSolAtual})`)
			this.$router.push('/painel/solicitacaodetalhe')
		},
    abrirModal(item,texto) {
      this.textoChamado = texto;
      this.chamado = item.idhotlinesolicitacoes
      this.exibirModalChamado = true;
    },
    formatarData(aDate, f = 'lll') {
      return moment(aDate).locale(this.$i18n.locale).format(f);
    },
    formatarNumero(aNumber) {
      return parseFloat(aNumber).toFixed(2);
    },
    closeModal() {
      this.$emit('input', false);
    }
  },
  watch: {
    value(val) {
      this.showModal = val;
    }
  },
  data() {
    return {
      showModal: false,
      exibirModalChamado: false,
      chamado: null,
      textoChamado: ''
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-content {
  width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-close:hover {
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

/* Estilos para itens ímpares */
.row-select tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* Estilos para itens pares */
.row-select tr:nth-child(even) {
  background-color: #ddd;
}

.row-select tr:hover {
  background-color: #aaa;
}

.abrir-chamado {
  display: flex;
  color: #2c49cb;
  align-items: center;
  justify-content: center;
}

.abrir-chamado:hover {
  color: white;
  background-color: #1a248e
}

</style>