<template>
    <Solicitacao 
        :filtro="this.filtro"
        v-on:clear="clearFilter()"
        :nova="true" 
    />
</template>

<script>
import Solicitacao from './Solicitacao.vue'
import moment from 'moment'

export default {
    components: { Solicitacao },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            filtro: {
                periodo: {
					dataInicial: (moment().subtract(30,'days').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD')),
                    data_filtro: 'datasolicitacao'
				},
                idhotlinesolicitacoes: null,
                idhotlinesetores: null,
                idtiposolicitacao: null,
                idstatus: null,
                nome: '',
                coduh: '',
                numreserva: '',
                textohotlinesolicitacoes: ''
            },
            itemCorrente: null,
            showFilter: false
        }
    },
    methods: {
        clearFilter() {
            this.filtro = {
                periodo: {
					dataInicial: (moment().subtract(30,'days').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD'))
				},
                idhotlinesolicitacoes: null,
                idhotlinesetores: null,
                idtiposolicitacao: null,
                idstatus: null,
                nome: '',
                coduh: '',
                numreserva: '',
                textohotlinesolicitacoes: ''
            }
        }        
    },
    computed: {
    },
    beforeMount() {
        this.itemCorrente = this.$store.getters.GetCurrentItem;
    },
    mounted() {
        
    }
}
</script>

<style scoped>

</style>