<template>
    <Solicitacao 
        :filtro="this.filtro"
        v-on:clear="clearFilter()"
    />
</template>

<script>
import Solicitacao from './Solicitacao.vue'
import moment from 'moment'

export default {
    components: { Solicitacao },
    data() {
        return {
            filtro: {
                periodo: {
					dataInicial: (moment().subtract(30,'days').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD')),
                    data_filtro: 'datasolicitacao'
				},
                idhotlinesolicitacoes: null,
                idhotlinesetores: 6,
                idtiposolicitacao: null,
                idstatus: null,
                nome: '',
                coduh: '',
                numreserva: '',
                textohotlinesolicitacoes: '',
                interna: false
            },
            showFilter: false
        }
    },
    methods: {
        clearFilter() {
            this.filtro = {
                periodo: {
					dataInicial: (moment().subtract(30,'days').format('YYYY-MM-DD')),
					dataFinal: (moment().format('YYYY-MM-DD'))
				},
                idhotlinesolicitacoes: null,
                idhotlinesetores: 6,
                idtiposolicitacao: null,
                idstatus: null,
                nome: '',
                coduh: '',
                numreserva: '',
                textohotlinesolicitacoes: '',
                interna: false
            }
        }       
    },
    computed: {
    },
    beforeMount() {
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>