<template>
    <div class="es-wizard">
        <div class="es-content">
            <div class="form-row">
                <div class="form-group col-sm-12">
                    <div class="es-title"><h2>{{step.titulo}}</h2></div>
                </div>
            </div>
            <div class="es-step">
                <div class="form-row">
                    <div class="form-group col-sm-6">
                        <label>Gerar botão de confirmação para cada ítem?</label>
                        <select class="form-control" v-model="tipo.confirmacaoindividual"
                            @change="verificarMultipla(tipo)"
                        >
                            <option :value="true" :selected="tipo.confirmacaoindividual"
                            >Sim</option>
                            <option :value="false" :selected="!tipo.confirmacaoindividual"
                            >Não</option>
                        </select>
                    </div>                            
                    <div :id="'divMultipla_'+tipo.idtiposolicitacao" class="form-group col-sm-6"
                        :style="tipo.confirmacaoindividual?'display:none;':'display:block;'"
                    >
                        <label>Gerar múltiplas solicitações para cada ítem?</label>
                        <select class="form-control" v-model="tipo.solicitacoesmultiplas"
                            :disabled="multiplaDisabled"
                        >
                            <option :value="true" :selected="tipo.solicitacoesmultiplas"
                            >Sim</option>
                            <option :value="false" :selected="!tipo.solicitacoesmultiplas"
                            >Não</option>
                        </select>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <h4>{{step.descricao}}</h4>
                    </div>
                </div>
                
                <div class="form-row">
                    <div class="form-group col-sm-12">
                        <div class="es-imagem-wizard justify-content-center">
                            <img :src="'../../img/wizard-05.png'" class="p-10 mb-20" width="300" />
                            <img :src="'../../img/wizard-04.png'" class="p-10 mb-20" height="320" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="es-buttons">
                <button @click="primeiro()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo">Início</button>
                <button @click="anterior()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo">Anterior</button>
                <button @click="proximo()" type="button" class="btn es-btn-secondary" id="salvar-titulo-tipo">Próximo</button>
                <button @click="ultimo()" type="button" class="btn es-btn-primary" id="salvar-titulo-tipo">Último</button>
                <button :disabled="saving" @click="salvar()" type="button" class="btn es-btn-success" id="salvar-titulo-tipo">{{saving?'Salvando...':'Salvar'}}</button>
                <button @click="fechar()" type="button" class="btn es-btn-close" id="fechar-titulo-tipo">Fechar</button>
            </div>

            <div class="form-row justify-content-center">
                <h5>{{`Passo ${step.passo} de ${steps.length}`}}</h5>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    name: 'WizardMultiplo',
    props: {
        tipo: {
            type: Object,
            required: false
        },
        step: {
            type: Object,
            required: false          
        },
        steps: {type: Array},
        idiomas: {type: Array},
        saving: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: { },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            dialog: true,
            currentStep: {
                titulo: 'Passo 0',
                imagem: 1,
            },
            sound: true,
            disableStart: true,
            multiplaDisabled: false
        }
    },
    watch: {
        step() {
            this.$forceUpdate()
        },
    },
    methods: {
        verificarMultipla(tipo) {
            if (tipo.confirmacaoindividual) {
                tipo.solicitacoesmultiplas = false
                this.multiplaDisabled = true
                this.fade(document.getElementById('divMultipla_'+tipo.idtiposolicitacao))
            } else {
                tipo.solicitacoesmultiplas = true
                this.multiplaDisabled = false
                this.unfade(document.getElementById('divMultipla_'+tipo.idtiposolicitacao))
            }
        },        
        primeiro() {
            this.$emit('first')
        },
        anterior() {
            this.$emit('previous')
        },
        proximo() {
            this.$emit('next')
        },
        ultimo(){
            this.$emit('last')
        },
        salvar(){
            this.$emit('save')
        },
        fechar(){
            this.$emit('close')
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        carregarMailConfig() {
            axios.get(CONSTANTS.API_URL + '/email/conta/get', { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.emailConfig = response.data
                }
            })
        },
        salvarMailConfig() {
            console.log('salvarMailConfig()...')
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idmailer: this.emailConfig.idmailer,
                host: this.emailConfig.host, 
                port: this.emailConfig.port,
                user: this.emailConfig.user,
                pass: this.emailConfig.pass,
                replyto: this.emailConfig.replyto,
                nametoexibition: this.emailConfig.nametoexibition,
                ativo: this.emailConfig.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            }
			//log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/email/atualizaContaEmail',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarMailConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
            .catch((e)=>{
                this.statusCode = 2
                this.statusMsg = 'Erro ao tentar salvar configuração!'
                console.error(JSON.stringify(e))
            })
        }
    },
    computed: {
    },
    mounted() {
        
    }
}
</script>
