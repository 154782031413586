<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px; padding-bottom:0px;">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="showFilter" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('ser.filtrar')}}</h4>
                    <div @click="limparFiltro()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="showFilter">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label>{{$t('ser.tipo')}}:</label>
                                <select class="form-control" @change="buscarParceiros()" v-model="filtro.tipo" >
                                    <option value="F">{{$t('global.pessoafisica')}}</option>
                                    <option value="J">{{$t('global.pessoajuridica')}}</option>
                                    <option :value="null">{{$t('global.todos')}}</option>
                                </select>
                            </div>
                            <div v-if="filtro.tipo == 'F'" class="form-group col-md-3">
                                <label>CPF:</label>
                                <input v-model="filtro.cpf" 
                                    @keyup="buscarParceiros()" placeholder="XXX.XXX.XXX-XX"
                                    type="text" class="form-control">
                            </div>
                            <div v-if="filtro.tipo == 'J'" class="form-group col-md-3">
                                <label>CNPJ:</label>
                                <input v-model="filtro.cnpj" 
                                    @keyup="buscarParceiros()" placeholder="XX.XXX.XXX/XXXX-XX"
                                    type="text" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-2">
                                <label>#</label>
                                <input v-model="filtro.idparceiro"
                                    type="text" class="form-control"
                                    @keyup="buscarParceiros()">
                            </div>
                            <div class="form-group col-md-4">
                                <label>{{$t('global.categoria')}}:</label>
                                <select class="form-control"  v-model="filtro.idtiposolicitacao" 
                                    @change="buscarParceiros()">
                                    <option :value="null">{{$t('global.todas')}}</option>
                                    <option v-for="cat in categorias" :key="cat.idtiposolicitacao" 
                                        :value="cat.idtiposolicitacao"
                                    >{{cat.titulo}}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label>{{$t('ser.empresa')}}:</label>
                                <input v-model="filtro.nome" 
                                    @keyup="buscarParceiros()" 
                                    type="text" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label>{{$t('ser.email')}}:</label>
                                <input v-model="filtro.email" type="text" class="form-control" placeholder="" v-on:keyup="buscarParceiros()">
                            </div>
                            <div class="form-group col-md-4">
                                <label>{{$t('ser.whatsapp')}}:</label>
                                <input v-model="filtro.whatsapp" type="text" class="form-control" placeholder="" v-on:keyup="buscarParceiros()">
                            </div>
                            <div class="form-group col-md-2">
                                <label>{{$t('ser.cep')}}:</label>
                                <input v-model="filtro.cep" type="text" class="form-control" v-on:keyup="buscarParceiros()">
                            </div>
                            <div class="form-group col-md-4">
                                <label>{{$t('ser.endereco')}}:</label>
                                <input v-model="filtro.endereco" type="text" class="form-control" v-on:keyup="buscarParceiros()">
                            </div>
                            <div class="form-group col-md-2">
                                <label>{{$t('ser.status')}}:</label>
                                <select class="form-control" v-model="filtro.ativo"
                                    @change="buscarParceiros()">
                                    <option :value="null">{{$t('global.todos')}}</option>
                                    <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                    <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Parceiros -->
        <div class="col-xl-12" style="margin: 0px; padding: 3px; padding-top:0px;">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.empresascadastradas')}}</h4>
                    <a @click="cadastrarParceiro()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('ser.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{$t('global.categoria')}}</strong></th>
                                    <th><strong>{{$t('ser.empresa')}}</strong></th>
                                    <th><strong>{{$t('ser.whatsapp')}}</strong></th>
                                    <th><strong>{{$t('ser.cep')}}</strong></th>
                                    <th><strong>{{$t('ser.endereco')}}</strong></th>
                                    <th><strong>{{$t('ser.status')}}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, idx) in parceiros" :key="idx">
                                <tr>
                                    <td><strong>{{item.idparceiro}}</strong></td>
                                    <td>{{item.categoriainterna}}</td>
                                    <td>{{item.nome}}</td>
                                    <td>{{item.whatsapp}}</td>
                                    <td>{{item.cep}}</td>
                                    <td>{{item.endereco}}, {{item.numero}}</td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" :class="{ 'btn-success' : item.ativo == true, 'btn-warning' : item.ativo == false   }" class="btn light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu" v-if="item.ativo == true">
                                                <a @click="editarParceiro(item)" class="dropdown-item ponteiro " >{{$t('ser.editar')}}</a>
                                                <a @click="desativarParceiro(item)" class="dropdown-item ponteiro">{{$t('ser.desativar')}}</a>
                                            </div>
                                            <div class="dropdown-menu" v-else>
                                                <a @click="editarParceiro(item)" class="dropdown-item ponteiro" >{{$t('ser.editar')}}</a>
                                                <a @click="ativarParceiro(item)" class="dropdown-item ponteiro">{{$t('ser.ativar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>	
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    data() {
        return{
            parceiros: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            newItem: {
                idtiposolicitacao: -1,
                tipo: 'J',
                nome: '',
                telefone: '',
                whatsapp: '',
                idpais: 29,
                estado: -1,
                cidadecodigoibge: -1,
                ufsigla: '',
                endereco: '',
                numero: '',
                complemento: '',
                cep: '',
                bairro: '',                
                ativo: true
            },
            filtro: {
                idparceiro: '',
                idtiposolicitacao: null,
                nome: '',
                email: '',
                whatsapp: '',
                endereco: '',
                cep: '',
                ativo: null,
                tipo: null,
                cpf: '',
                cnpj: ''
            },
            nomeParceiro: '',
            categorias: [],
            selectcat: '',
            telParceiro: '',
            cidParceiro: '',
            uf: [],
            ufselected: '',
            showFilter: false,
        }
    },
    methods: {
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    this.$unfade(el);
                    this.showFilter = true
                } else {
                    this.$unfade(el);
                    this.showFilter = false
                }
            }
        },
        limparFiltro() {
            this.filtro = {
                idparceiro: '',
                idtiposolicitacao: null,
                nome: '',
                email: '',
                whatsapp: '',
                cep: '',
                endereco: '',
                ativo: null,
                tipo: null,
                cpf: '',
                cnpj: ''
            }
            this.buscarParceiros()
        },
        buscarParceiros() {
            var params = {
                idhotel : this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale,
                manutencao: true
            }
            if (this.filtro.idparceiro.trim()) {
                params.idparceiro = this.filtro.idparceiro.trim()
            }
            if (this.filtro.tipo) {
                params.tipo = this.filtro.tipo
            }
            if (this.filtro.tipo&&this.filtro.tipo =='F'&&this.filtro.cpf.trim()) {
                params.cpf = this.filtro.cpf.trim()
            }
            if (this.filtro.tipo&&this.filtro.tipo =='J'&&this.filtro.cnpj.trim()) {
                params.cnpj = this.filtro.cnpj.trim()
            }
            if (this.filtro.idtiposolicitacao && this.filtro.idtiposolicitacao > 0) {
                params.idtiposolicitacao = this.filtro.idtiposolicitacao
            }
            if (this.filtro.nome.trim()) {
                params.nome = this.filtro.nome.trim()
            }
            if (this.filtro.email.trim()) {
                params.email = this.filtro.email.trim()
            }
            if (this.filtro.whatsapp.trim()) {
                params.whatsapp = this.filtro.whatsapp.trim()
            }
            if (this.filtro.cep.trim()) {
                params.cep = this.filtro.cep.trim()
            }
            if (this.filtro.endereco.trim()) {
                params.endereco = this.filtro.endereco.trim()
            }
            if (this.filtro.ativo != null) {
                params.ativo = this.filtro.ativo
            }
            //console.error(JSON.stringify(params));
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.parceiros = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        buscarCategorias() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "interna": true
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data
                }
            })
        },
        desativarParceiro(item) {
            var params = {
                idparceiro: item.idparceiro, 
                ativo: false,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarParceiros()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        ativarParceiro(item) {
             var params = {
                idparceiro: item.idparceiro, 
                ativo: true,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarParceiros()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        cadastrarParceiro() {
            this.$store.dispatch('set_Edit_Status', false);
            this.$store.dispatch('set_Edit_Item', JSON.stringify(this.newItem));
            this.$router.push('/painel/fornecedoreditar')
        },
        editarParceiro(item) {
            this.$store.dispatch('set_Edit_Status', true);
            this.$store.dispatch('set_Edit_Item', JSON.stringify(item));
            this.$router.push('/painel/fornecedoreditar')
        },
        buscarUf() {
            axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', {}, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.uf = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
    }, 
    mounted() {
        this.buscarParceiros();
        this.buscarCategorias();
        this.buscarUf();
    }
}
</script>

<style>

</style>