<template>
    <div>
        <!-- Pesquisas Respondidas -->
        <div class="row">
            <div class="col-md-4">
                <div class="widget-stat card">
                    <div class="card-body p-2">
                        <div class="media ai-icon">
                            <span class="mr-3 bgl-primary text-primary">
                                <svg id="icon-customers" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                            </span>
                            <div class="media-body">
                                <p class="mb-1">Pesquisas Respondidas</p>
                                <h4 class="mb-0">{{recordCount}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="widget-stat card">
                    <div class="card-body p-2">
                        <div class="media ai-icon">
                            <span class="mr-3 bgl-primary text-primary">
                                <i class="material-icons">query_stats</i>
                            </span>
                            <div class="media-body">
                                <p class="mb-1">Média</p>
                                <h4 class="mb-0">{{average}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- Filtro de Pesquisa -->
            <div class="col-xl-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Pesquisar Respostas:</h4>
                    </div>
                    <div class="card-body">
                        <div class="basic-form">
                            <form>
                                <div class="form-row">
                                    <div class="form-group col-sm-auto">
                                        <label>Período de:</label>
                                        <input v-on:change="zerarRespostas()" v-model="filtro.periodo.de" type="date" class="form-control" placeholder="" >
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Até:</label>
                                        <input v-on:change="zerarRespostas()" v-model="filtro.periodo.ate" type="date" class="form-control" placeholder="">
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>UH:</label>
                                        <input v-on:keyup="zerarRespostas()" v-model="filtro.coduh" type="text" class="form-control">
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Número da reserva:</label>
                                        <input v-on:keyup="zerarRespostas()" v-model="filtro.numreserva" type="text" class="form-control">
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Nome Hóspede:</label>
                                        <input v-on:keyup="zerarRespostas()" v-model="filtro.nomeHospede" type="text" class="form-control">
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Avaliação:</label>
                                        <select class="form-control" v-model="filtro.idtipoavaliacao"
                                            v-on:change="zerarRespostas()">
                                            <template v-if="avaliacoes && avaliacoes.length > 0">
                                                <option v-for="item in avaliacoes" v-bind:key="item.idtipoavaliacao"
                                                    :value="item.idtipoavaliacao"
                                                    :selected="filtro.idtipoavaliacao==item.idtipoavaliacao"
                                                >{{item.desctipoavaliacao}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Categoria:</label>
                                        <select class="form-control" v-model="filtro.idcategoriapergunta"
                                            v-on:change="zerarRespostas()">
                                            <template v-if="categorias && categorias.length > 0">
                                                <option v-for="categoria in categorias" v-bind:key="categoria.idcategoriapergunta"
                                                    :value="categoria.idcategoriapergunta"
                                                    :selected="filtro.idcategoriapergunta==categoria.idcategoriapergunta"
                                                >{{categoria.descricao}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Pergunta:</label>
                                        <input v-on:keyup="zerarRespostas()" v-model="filtro.textopergunta" type="text" class="form-control">
                                    </div>
                                    <div class="form-group col-sm-auto">
                                        <label>Resposta Textual:</label>
                                        <select class="form-control" v-model="filtro.idopcaotextual"
                                            v-on:change="zerarRespostas()">
                                            <template v-if="ocpoesTextual && ocpoesTextual.length > 0">
                                                <option v-for="ocpaoTextual in ocpoesTextual" v-bind:key="ocpaoTextual.idopcaotextual"
                                                    :value="ocpaoTextual.idopcaotextual"
                                                    :selected="filtro.idopcaotextual==ocpaoTextual.idopcaotextual"
                                                >{{ocpaoTextual.descricao}}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <div style="align-self: center;margin-top:10px;margin-left:10px;">
                                        <button type="button" @click="loadRespostas()" class="btn btn-primary">Pesquisar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Resultado da Pesquisa -->
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Respostas:</h4>
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-responsive-md">
                                <!-- cabeçalos -->
                                <thead>
                                    <tr>
                                        <th><strong>Hóspede</strong></th>
                                        <th><strong>UH</strong></th>
                                        <th><strong>Reserva</strong></th>
                                        <th><strong>E-mail</strong></th>
                                        <th><strong>Checkin</strong></th>
                                        <th><strong>Checkout</strong></th>
                                        <th><strong>Nota Média</strong></th>
                                        <th><strong>Expandir</strong></th>
                                    </tr>
                                </thead>
                                <!-- Linhas de dados -->
                                <template v-if="buscandoDados">
                                    <div class="carregando"><strong>{{carregandoDadosTexto}}</strong></div>
                                </template>
                                <template v-if="filtroModificado">
                                    <div class="carregando"><strong>{{textoFiltroModificado}}</strong></div>
                                </template>
                                <tbody v-for="resposta in respostasmedia" :key="resposta.idrespostaformavaliacao">
                                    <tr>
                                        <td>{{resposta.nome}}</td>
                                        <td>{{resposta.coduh}}</td>
                                        <td>{{resposta.numreserva}}</td>
                                        <td>{{resposta.email}}</td>
                                        <td>{{resposta.data_checkin}}</td>
                                        <td>{{resposta.data_checkout}}</td>
                                        <td><span class="badge nota" :class="'nota-' + Math.floor(resposta.nota_media)"
                                        >{{resposta.nota_media}}</span></td>
                                        <td>
                                            <button :id="`btnMais_${resposta.numreserva}_${resposta.idhospedepms}`" type="button" class="btn btn-warning light sharp"
                                                @click="visualizar(resposta)">
                                                +
                                            </button>
                                        </td>
                                    </tr>
                                    <!-- Área expandida -->
                                    <tr>
                                        <td colspan="7">
                                            <div :id="`divMais_${resposta.numreserva}_${resposta.idhospedepms}`" 
                                                class="card" style="display:none;background-color:azure;"
                                            >
                                                <template v-if="respostasIndividuais&&respostasIndividuais.length > 0">
                                                    <div v-for="resps in respostasIndividuais" :key="`aval_${resps.idtipoavaliacao}`">
                                                        <div class="card-header">
                                                            <h4>Avaliação: {{resps.desctipoavaliacao}}</h4>
                                                            <h4 :class="'nota nota-' + resps.nota_media">Média: {{resps.nota_media}}</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="basic-form">
                                                                <table class="table table-responsive-md">
                                                                    <thead>
                                                                        <tr>
                                                                            <th><strong>Categoria</strong></th>
                                                                            <th><strong>Pergunta</strong></th>
                                                                            <th><strong>Nota</strong></th>
                                                                            <th><strong>Resposta</strong></th>
                                                                        </tr>
                                                                    </thead>
                                                                <tbody>
                                                                    <tr v-for="r in resps.respostas" :key="`resp_${r.idrespostaformavaliacao}`">
                                                                        <td>
                                                                            <div class="text-area">
                                                                                <span class="material-icons icon">{{r.icone}}</span>
                                                                                {{r.categoria}}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="text-area">{{r.pergunta}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div :class="`nota nota-${r.nota}`">{{r.nota}}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div class="text-area">{{r.resposta}}</div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <!-- Paginação -->
                        <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                            @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                            @update="evt => {updatePage(evt)}"></pagination>

                    </div>
                </div>
            </div>
        </div>          
  </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
import moment from "moment"
import Pagination from './Pagination.vue'
export default {
  components: { Pagination },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            filtro: {
                periodo: {
					de: (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD')),
					ate: (moment().format('YYYY-MM-DD'))
                },
                coduh: '',
                numreserva: '',
                nomeHospede: '',
                textopergunta: '',
                idtipoavaliacao: 0,
                idcategoriapergunta: 0,
                idopcaotextual: 0
            },
            avaliacoes: [],
            categorias: [],
            ocpoesTextual: [
                {idopcaotextual: 0, descricao: 'Todas'},
                {idopcaotextual: 1, descricao: 'Com resposta textual'},
                {idopcaotextual: 2, descricao: 'Com ao menos 3 palavras na resposta textual'}
            ],
            respostasmedia: [],
            recordCount: 0, 
            pageCount: 1,
            average: 0,
            page: 1,
            pageSize: 6,
            pageOptionIndex: 0,
            pageOptions: [
                6,12,24,36,72,144,'Todas'
            ],
            carregandoDadosTexto:'Carregando dados. Aguarde...',
            buscandoDados: false,
            filtroModificado: false,
            textoFiltroModificado: 'Filtro modificado. Clique em Pesquisar para atualizar os resultados.',
            respostasIndividuais: []
        }
    },
    methods: {
        zerarRespostas() {
            this.respostasmedia = [];
            this.page = 1;
            this.pageCount = 1,
            this.recordCount = 0;
            this.filtroModificado = true;
        },
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.loadRespostas();
        },
        formatarData(aDate) {
            return moment(aDate).locale(this.$i18n.locale).format('L');
        },
        buscarRespostasIndividuais(numreserva, idhospedepms) {
            var params = {
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }

            params.numreserva = numreserva;
            params.idhospedepms = idhospedepms;

            if (this.filtro.periodo) {
                if (this.filtro.periodo.de && this.filtro.periodo.ate) {
                    params.periodo = this.filtro.periodo
                }
            }

            if (this.filtro.coduh) {
                params.coduh = this.filtro.coduh
            }

            if (this.filtro.nomeHospede) {
                params.nome = this.filtro.nomeHospede
            }

            if (this.filtro.idopcaotextual > 0) {
                params.idopcaotextual = this.filtro.idopcaotextual
            }

            this.respostasIndividuais = []
            axios.post(CONSTANTS.API_URL + '/fdguest/respostas/hospede/select',params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.respostasIndividuais = response.data
                }
            })
            .catch((e)=>{
                console.error(e)
            })
        },
        visualizar(resposta) {
            console.error(JSON.stringify(resposta))
            const btnId = `btnMais_${resposta.numreserva}_${resposta.idhospedepms}`;
            const divId = `divMais_${resposta.numreserva}_${resposta.idhospedepms}`;
            var divMais = document.getElementById(divId)
            var btnMais = document.getElementById(btnId)
            if (divMais && btnMais) {
                if (divMais.style.display == 'none') {
                    divMais.style.display = 'block'
                    btnMais.innerText = '-'
                    this.buscarRespostasIndividuais(resposta.numreserva, resposta.idhospedepms);
                } else {
                    divMais.style.display = 'none'
                    btnMais.innerText = '+'
                }
            }
        },
        loadRespostas() {
            var params = {
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }

            if (this.filtro.periodo) {
                if (this.filtro.periodo.de && this.filtro.periodo.ate) {
                    params.periodo = this.filtro.periodo
                }
            }

            if (this.filtro.coduh) {
                params.coduh = this.filtro.coduh
            }

            if (this.filtro.numreserva) {
                params.numreserva = this.filtro.numreserva
            }

            if (this.filtro.nomeHospede) {
                params.nome = this.filtro.nomeHospede
            }

            if (this.filtro.idopcaotextual > 0) {
                params.idopcaotextual = this.filtro.idopcaotextual
            }

            params.pagesize = this.pageSize
            params.page = this.page
            this.respostasmedia = []
            this.carregandoDadosTexto = 'Carregando dados. Aguarde...'
            //console.log(JSON.stringify(params))
            this.buscandoDados = true;
            this.filtroModificado = false;
            axios.post(CONSTANTS.API_URL + '/fdguest/respostas/media/select',params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.respostasmedia = response.data.dados
                    this.recordCount = response.data.recordCount
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1
                    this.average = response.data.average
                    if (response.data.dados.length == 0) {
                        this.carregandoDadosTexto = 'Nenhum registro encontrado. Verifique os filtros e clique em "Pesquisar".'
                    }
                }
                this.buscandoDados = false;
            })
            .catch((e)=>{
                this.buscandoDados = false;
                console.error(e)
            })
        },
        loadAvaliacoes() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.avaliacoes = response.data
                    this.avaliacoes.unshift({
                        "idtipoavaliacao": 0,
                        "desctipoavaliacao": "Todas"
                    })
                }
            })
        },
        loadCategorias() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data
                    this.categorias.unshift({
                        "idcategoriapergunta": 0,
                        "descricao": "Todas"
                    })
                }
            })
        },
    },
    mounted() {
        this.loadAvaliacoes();
        this.loadCategorias();
        this.loadRespostas();
    }
}
</script>

<style scoped>
.nota {
    padding: 0.4rem;
    border-radius: 50%;
    text-align: center;
}
.nota-1 {
  color: #fff;
  background-color: #ff2625; }
  a.nota-1:hover, a.nota-1:focus {
    color: #fff;
    background-color: #f10100; }
  a.nota-1:focus, a.nota-1.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 38, 37, 0.5); }

.nota-2 {
  color: #fff;
  background-color: #ff7a01; }
  a.nota-2:hover, a.nota-2:focus {
    color: #fff;
    background-color: #cd6200; }
  a.nota-2:focus, a.nota-2.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 122, 1, 0.5); }

.nota-3 {
  color: #000;
  background-color: #ffe600; }
  a.nota-3:hover, a.nota-3:focus {
    color: #212529;
    background-color: #ccb800; }
  a.nota-3:focus, a.nota-3.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 230, 0, 0.5); }

.nota-4 {
  color: #fff;
  background-color: #8ddb95; }
  a.nota-4:hover, a.nota-4:focus {
    color: #fff;
    background-color: #5cb364; }
  a.nota-4:focus, a.nota-4.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(126, 218, 135, 0.5); }

.nota-5 {
  color: #fff;
  background-color: #21b731; }
  a.nota-5:hover, a.nota-5:focus {
    color: #fff;
    background-color: #198c25; }
  a.nota-5:focus, a.nota-5.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(33, 183, 49, 0.5); }

.text-area {
    color: black;
    background-color: rgb(255, 255, 235);
    padding: 10px;
    border-radius: 1rem;
}
.carregando {
    padding: 10px;
    text-align: center;
    font-size:1.3rem;
    color:orange;
}
.pagination {
    width: 100%!important;
    display: flex;
    flex-direction: row-reverse;
}
.pagination > .btn {
    cursor: pointer;
    color: rgb(16,179,237);
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 1.1rem;
    font-weight: bold;
}
.pagination > .btn:hover {
    color: white;
    background-color: rgb(30, 144, 255);
}
.pagination > .page {
    text-align: center;
    padding-top: 0.8rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 0.8rem;
    font-weight: bold;
}
.pagination > .sel {
    padding-top: 0.3rem;
    margin-right: 0.3rem;
    min-width: 90px;
}
</style>