<template>
  <div class="row">
					
					<div class="col-xl-12 col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Pesquisar Perguntas:</h4>
								<a @click="$router.push('/painel/cadperguntas')" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                            </div>
                            <div class="card-body">
                                <div class="basic-form">
                                    <form>

                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label>Título da Pergunta:</label>
                                                <input v-model="textopergunta" v-on:keyup="searchPerguntas()" type="text" class="form-control" placeholder="">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Descrição:</label>
                                                <input v-model="textodesc" v-on:keyup="searchDesc()" type="text" class="form-control">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label>Status:</label>
                                                <select class="form-control">
													<option>Ativo</option>
													<option>Desativado</option>
												</select>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn btn-primary">Pesquisar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
					</div>
					
					
					
					
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Perguntas Cadastradas:</h4>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-responsive-md">
                                        <thead>
                                            <tr>
                                                <th style="width:80px;"><strong>#</strong></th>
                                                <th><strong>PERGUNTA</strong></th>
                                                <th><strong>DESCRIÇÃO</strong></th>
                                                <th><strong>STATUS</strong></th>
                                                <th><strong>AÇÃO</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for=" item in perguntas" :key="item">
                                                <td><strong>01</strong></td>
                                                <td>{{item.textopergunta}}</td>
                                                <td>{{item.codreduzido}} </td>
												<td><span class="badge light badge-success">{{item.ativo}}</span></td>
                                                <td>
													<div class="dropdown">
														<button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
															<svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
														</button>
														<div class="dropdown-menu" v-show="item.ativo">
															<a class="dropdown-item" href="#">Inativar</a>
															<a class="dropdown-item" href="#">Excluir</a>
														</div>
                                                        <div class="dropdown-menu" v-show="item.ativo == false">
															<a class="dropdown-item" href="#">Inativar</a>
															<a class="dropdown-item" href="#">Excluir</a>
														</div>
													</div>
												</td>
                                            </tr>
										
											
											
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
					
				
					
					
                </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    data() {
        return {
            perguntas: [],
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            textopergunta: '',
            textodesc: '',
        }
    },
    methods: {
        Perguntas() {
           var  perg = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: 'pt-BR'
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/select',
            perg, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.perguntas = response.data
                }
            })
        },
         searchPerguntas(){
             axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/select', 
                { idhotel: this.$store.getters.GetLoginInfo.idhotel
                , idioma: 'pt-Br', textopergunta: this.textopergunta}, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.perguntas = response.data
                    
                }
            })
        },
          searchDesc(){
             axios.post(CONSTANTS.API_URL + '/fdguest/pergunta/select', 
                { idhotel: this.$store.getters.GetLoginInfo.idhotel, idioma: 'pt-Br', codreduzido: this.textodesc}, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.perguntas = response.data
                    
                }
            })
        },
    },
        mounted() {
            this.Perguntas()
        }
}
</script>

<style>

</style>