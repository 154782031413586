<template>
  <div>
    <div>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ solicitacao.interna ? $t("estoquerelatorios.chamado") : $t("sols.solicitacao") }} #{{
              solicitacao.idhotlinesolicitacoes
            }}
          </h4>
          <div style="font-size: 1rem !important" class="text-center">
            {{ formatarData(solicitacao.datasolicitacao) }}
          </div>
          <strong>
            <span v-if="solicitacao.interna"
              >OS Interna</span
            >
          </strong>
        </div>
      </div>
      <div class="card-body">
        <div class="form-row">
            <div class="form-group col-sm-12 px-4">
                <solicitacao-equipamentos :equipamentos="solicitacao.equipamentos"></solicitacao-equipamentos>
            </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-auto">
            <label>{{ $t("sols.setor") }}</label>
            <input
              :value="solicitacao.deschotlinesetor"
              readonly
              type="text"
              class="form-control"
              style="font-weight: bold"
            />
          </div>
          <div class="form-group col-md-auto">
            <label>{{ $t("sols.tipo") }}</label>
            <input
              :value="solicitacao.tipo"
              readonly
              type="text"
              class="form-control"
              style="font-weight: bold"
            />
          </div>
          <div v-if="solicitacao.localatendimento" class="form-group col-md-auto">
            <label>{{ $t("sols.localatendimento") }}</label>
            <input
              :value="solicitacao.localatendimento"
              readonly
              type="text"
              class="form-control"
              style="font-weight: bold"
            />
          </div>
          <div v-if="solicitacao.responsavel" class="form-group col-md-auto">
            <label>{{ $t("sols.responsavelinterno") }}</label>
            <input
              :value="solicitacao.responsavel"
              readonly
              type="text"
              class="form-control"
              style="font-weight: bold"
            />
          </div>
          <div v-if="solicitacao.nome || solicitacao.sobrenome" class="form-group col-md-auto">
            <label>{{ $t("sols.nomehospede") }}</label>
            <input
              readonly
              type="text"
              class="form-control"
              :value="`${solicitacao.nome ? solicitacao.nome : ''} ${
                solicitacao.sobrenome ? solicitacao.sobrenome : ''
              }`"
              style="font-weight: bold"
            />
          </div>
          <div v-if="solicitacao.coduh" class="form-group col-md-auto">
            <label>{{ $t("sols.uh") }}</label>
            <input 
              v-model="solicitacao.coduh"
              readonly
              type="text"
              class="form-control m-1"
              style="font-weight: bold; max-width: 150px"
            />
          </div>
          <div v-if="solicitacao.numreserva" class="form-group col-md-auto">
            <label>{{ $t("sols.reserva") }}</label>
            <input
              v-model="solicitacao.numreserva"
              readonly
              type="text"
              class="form-control"
              style="font-weight: bold; max-width: 150px"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label>{{ $t("sols.descricao") }}</label>
            <textarea
              rows="7"
              readonly
              class="form-control es-sol-text"
              style="resize: none"
              v-model="solicitacao.textohotlinesolicitacoes"
            >
            </textarea>
          </div>
        </div>        
        <template v-if="solicitacao.idstatus == 6">
          <div class="form-row">
            <label>{{ $t("sols.motivo") }}</label>
            <div class="form-group col-md-12">
              <input
                v-model="solicitacao.textocancelamento"
                readonly
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </template>

        <template v-if="dados.camposadicionais">
            <div class="form-row">
            <div
                v-for="campo in dados.camposadicionais.campos"
                :key="campo.id"
                class="form-group col-auto"
            >
                <label>{{campo.texto}}</label>
                <input
                    type="text"
                    class="form-control"
                    style="font-weight: bold"
                    v-model="campo.valor"
                    readonly
                />
            </div>
            </div>
        </template>
      </div>
    </div>

    <div class="form-row">
        <VueSignaturePad
        width="100%"
        height="30vh"
        ref="signaturePad"
        style="background: white"
        saveType="image/png"
        :options="{
            onBegin: () => {
            $refs.signaturePad.resizeCanvas();
            },
        }"
        />
    </div>

    <div class="dense text-center">
      <hr id="linha_assinatura" />
      <small>Assine no espaço acima</small>
      <br />
      <br />
    </div>
    <div v-if="dados.obrigaimgdocumento">
      <label for="inputFile" class="ml-4">{{
        !fileSrc.length > 0 ? "Documento Obrigatório" : ""
      }}</label>
      <div class="mb-4 text-center">
        <input
          type="file"
          class="btn-warning rounded small py-1 pl-2"
          @change="setFile"
          name="fileUploaded"
          value="ttt"
          id="inputFile"
        />
      </div>
    </div>
    <div class="form-row align-items-center justify-content-center">
        <div
          class="btn btn-primary rounded m-2"
          v-on:click.stop.prevent="limpar_assinatura()"
        >
          {{ $t("global.apagar") }}
          <i class="material-icons vm text-white"></i>
        </div>
        <div
          class="btn btn-success rounded m-2"
          v-on:click.stop.prevent="enviarAssinatura()"
        >
          {{ $t("global.ok") }}
          <i class="material-icons vm text-white"></i>
        </div>
    </div>

    <!--MODAL DE RETORNO-->
    <div
      class="modal fade"
      id="modalAss"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLongTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLongTitle">Assinatura</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <span>{{ msgModal }}</span
            ><br />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="btnCloseModal"
            >
              {{ $t("sols.fechar") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      hidden
      type="submit"
      id="btnOpenModal"
      data-dismiss="modal"
      data-toggle="modal"
      data-target="#modalAss"
    ></button>
    <!--FIM MODAL-->
  </div>
</template>

<script>
import axios from "axios";
import CONSTANTS from "../constants";
import moment from "moment";
import SolicitacaoEquipamentos from "./SolicitacaoEquipamentos.vue";

export default {
  props: ["nome", "sobrenome"],
  components: { SolicitacaoEquipamentos },
  data() {
    return {
      headers: {},
      token: "",
      isImg: false,
      fileSrc: "",
      assSrc: "",
      solicitacao: {},
      setores: [],
      produtos: [],
      campos: [],
      idhotlinesolicitacoes: 0,
      idhotlinesolicitacoesassinatura: 0,
      tipossolicitacao: [],
      statusList: [
        { id: 1, descricao: this.$t("sols.pendente") },
        { id: 2, descricao: this.$t("sols.ematendimento") },
        { id: 3, descricao: this.$t("sols.atendida") },
        { id: 4, descricao: this.$t("sols.naoatendida") },
        { id: 5, descricao: this.$t("sols.contestada") },
        { id: 6, descricao: this.$t("sols.cancelada") },
      ],
      status: {
        1: this.$t("sols.pendente"),
        2: this.$t("sols.ematendimento"),
        3: this.$t("sols.atendida"),
        4: this.$t("sols.naoatendida"),
        5: this.$t("sols.contestada"),
        6: this.$t("sols.cancelada"),
      },
      responsaveis: [],
      locaisAtendimento: [],
      prioridades: this.$t("sols.prioridades"),
      dados: {},
      msgModal: "",
      usuario: null
    };
  },
  methods: {
    limpar_assinatura() {
      //this.$refs.signaturePad.undoSignature();
      this.$refs.signaturePad.clearSignature();
      this.$emit("limpar");
    },
    confirmar_assinatura() {
      let objetoAssinatura = this.$refs.signaturePad.saveSignature();
      if (!objetoAssinatura.isEmpty) {
        let img = objetoAssinatura.data.split(",")[1];
        if (!img.length > 0) {
          console.log("Imagem invalida");
          return;
        }
        this.$emit("confirmar", img);
      }
    },
    carregarLocaisAtendimento() {
      var params = {
        idioma: this.$i18n.locale,
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/localatendimento/select", params, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.locaisAtendimento = response.data.dados;
          }
        });
    },
    loadResponsaveis() {
      var searchParams = {
        idioma: this.$i18n.locale,
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
				page: 1,
        pagesize: 1000,
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/usuarios/tecnicos", searchParams, {
          headers: this.headers,
        })
        .then((response) => {
          try {
            if (response.status == 200) {
              if (response.data) {
                this.responsaveis = response.data.dados;
              }
            } else {
              console.log("Erro:" + JSON.stringify(response));
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    formatarData(aDate) {
      return moment(aDate).locale(this.$i18n.locale).format("lll");
    },
    hideTexts(id) {
      var obj = document.getElementById("At_" + id);
      if (obj) obj.style.display = "none";
      obj = document.getElementById("At_Produtos_" + id);
      if (obj) obj.style.display = "none";
      obj = document.getElementById("Em_At_Produtos_" + id);
      if (obj) obj.style.display = "none";
      obj = document.getElementById("Em_At_" + id);
      if (obj) obj.style.display = "none";
      obj = document.getElementById("Cancel_" + id);
      if (obj) obj.style.display = "none";
    },
    async loadSolicitacao() {
      const urlParams = new URLSearchParams(window.location.search);

      var params = {
        idioma: this.$i18n.locale,
        // idhotel: this.$store.getters.GetLoginInfo.idhotel,
        //usuario: this.$store.getters.GetLoginInfo.idusuario,
        idhotlinesolicitacoes: urlParams.get("sol"),
      };
      console.log(JSON.stringify(params));
      await axios
        .post(
          CONSTANTS.API_URL + "/fdguest/hotlinesolicitacoes/select",
          params,
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            if (response.data.dados.length > 0) {
              this.solicitacao = response.data.dados[0];
              //alert(JSON.stringify(this.solicitacao))
            } else {
              console.log("Solicitação inválida:" + this.idhotlinesolicitacoes);
            }
          } else {
            console.log(JSON.stringify(response));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async loadDadosAssinar() {
      const urlParams = new URLSearchParams(window.location.search);

      var params = {
        idhotlinesolicitacoes: this.idhotlinesolicitacoes,
        idhotlinesolicitacoesassinatura: (this.idhotlinesolicitacoesassinatura =
          urlParams.get("k")),
      };

      await axios
        .post(
          CONSTANTS.API_URL +
            "/fdguest/hotlinesolicitacoes/assinatura/listardadosassinar",
          params,
          { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
            this.dados = response.data[0];
            //alert(JSON.stringify(this.dados))
          } else {
            alert("Impressao invalida para assinatura");
            window.location.href = "/";
          }
        }); ///fdguest/hotlinesolicitacoes/assinatura/listardadosassinar
    },
    loadUrlParams() {
      const urlParams = new URLSearchParams(window.location.search);
      this.idhotlinesolicitacoes = urlParams.get("sol");
      this.idhotlinesolicitacoesassinatura = urlParams.get("k");
      this.headers = { "x-access-token": urlParams.get("t") };
      this.token = urlParams.get("t");
      this.usuario = urlParams.get("u");
    },
    async setFile(e) {
      const file = e.target.files[0];
      this.nomeDocumento = e.target.files[0].name;
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.isImg = true;
          this.fileSrc = event.target.result;
          //alert(this.fileSrc)
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async enviarAssinatura() {
      if (this.dados.obrigaimgdocumento == true && !this.fileSrc.length > 0) {
        this.msgModal = "Imagem do documento é obrigatorio.";
        document.getElementById("btnOpenModal").click();
        setTimeout(() => {
          document.getElementById("btnCloseModal").click();
        }, 2500);
        return;
      }

      let img = "";
      let objetoAssinatura = this.$refs.signaturePad.saveSignature();
      if (!objetoAssinatura.isEmpty) {
        img = objetoAssinatura.data.split(",")[1];
      }

      if (!img.length > 0) {
        this.msgModal = "Assinatura é obrigatório";
        document.getElementById("btnOpenModal").click();
        setTimeout(() => {
          document.getElementById("btnCloseModal").click();
        }, 2500);
        return;
      }

      let params = {
        imgdocumento: this.fileSrc,
        imgassinatura: img,
        idhotlinesolicitacoes: this.dados.idhotlinesolicitacoes,
        idhotlinesolicitacoesassinatura: this.dados.idhotlinesolicitacoesassinatura,
        alterado_por: this.usuario
      };
      await axios
        .post(
          CONSTANTS.API_URL +
            "/fdguest/hotlinesolicitacoes/assinatura/enviarassinatura",
          params,
          { headers: this.headers }
        )

        .then((response) => {
          if (response.status == 200) {
            this.msgModal = "Assinado com sucesso";
            document.getElementById("btnOpenModal").click();
            setTimeout(() => {
              document.getElementById("btnCloseModal").click();
            }, 2000);
            setInterval(() => {
              //window.location.href(window.location.replace('/painel/solicitacaodetalhe/pdf?sol='+this.dados.idhotlinesolicitacoes+'&k='+this.dados.idhotlinesolicitacoesassinatura+'&t='+this.token))
              window.close();
              //window.open(window.location.replace('/painel/solicitacaodetalhe/pdf?sol='+this.dados.idhotlinesolicitacoes+'&k='+this.dados.idhotlinesolicitacoesassinatura+'&t='+this.token));
            }, 1500);
          } else {
            console.log(response.status);
          }
        });
    },
  },
  computed: {
    assinaturaValida() {
      let { isEmpty } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        console.error("A assinatura é obrigatoria");
        return false;
      }
      return !isEmpty;
    },
  },
  mounted() {
    this.loadUrlParams();
    this.loadDadosAssinar();
    //this.loadResponsaveis();
    //this.carregarLocaisAtendimento();
    //this.loadUrlParams()
    this.loadSolicitacao();
  },
};
</script>

<style>
</style>