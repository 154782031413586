<template>

<div style="display: flex; flex-direction: column;">
  <div class="form-group" >
        <button class="btn btn-success mt-2" type="submit">Buscar</button>
  </div>
<!-- <form> -->

    <div style="display: flex; flex-direction:row">
  <div class="form-group col-auto">
    <label for="FormControlSelect1">TIPO</label>
    <select class="form-control mr-2" id="FormControlSelect1" v-model="tipoPessoa" style="width: 250px!important;">
      <option value="F">Pessoa Física</option>
      <option value="J">Pessoa Jurídica</option>
    </select>
  </div>
    <div class="form-group col-auto">
        <ComboBox
            :items="paises"
            id="idpais"
            value="nome"
            label="País"
        ></ComboBox>
    </div>

  <div class="form-group col-auto" v-if="tipoPessoa == 'F'">
    <label for="InputCPF">CPF</label>
    <input :required="true ? tipoPessoa == 'F' : false" v-model="cpf" type="text" class="form-control" id="InputCPF" aria-describedby="emailHelp" placeholder="XXX.XXX.XXX-XX" style="width: 250px;">
    <small id="emailHelp" class="form-text text-muted">Informe o CPF.</small>
  </div>

  <div class="form-group col-auto" v-if="tipoPessoa == 'J'">
    <label for="exampleInputCPF">CNPJ</label>
    <input :required="true ? tipoPessoa == 'J' : false" v-model="cnpj" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="XX.XXX.XXX/XXXX-XX" style="width: 250px;">
    <small id="emailHelp" class="form-text text-muted">Informe o CNPJ.</small>
  </div>

  </div>

  <div class="form-group">
    <label for="exampleInputEmail1">Nome</label>
    <input required v-model="nome" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="inputName" placeholder="Nome completo" style="width: 450px;">
    <small id="inputName" class="form-text text-muted"></small>
  </div>
  <div class="form-group">
    <label for="fone">Contato</label>
    <input  required v-model="email" type="email" class="form-control" id="fone" placeholder="E-mail" style="width: 450px;">
    <small id="inputName" class="form-text text-muted"></small>
    <div style="display: flex; flex-direction:row;">
        <input required v-model="ddd" type="text" class="form-control" id="ddd" placeholder="DDD" style="width: 80px;">
        <input required v-model="fone" type="text" class="form-control" id="fone" placeholder="Telefone" style="width: 200px;">
    </div>
  </div>
  <div class="form-group">
    <label for="">Endereço</label>
    <div class="form-group">

    
    
    </div>
    

    <div style="display: flex; flex-direction:row">
    <div style="display: flex;">
    <label  for="cep">CEP</label>
        <input @keyup="searchCep()" @blur="searchCep()" required v-model="cep" type="text" class="form-control" id="cep" placeholder="CEP" style="width: 150px;">
        <label for="logr">Logradouro</label>
        <input required v-model="logradouro" type="text" class="form-control mr-2" id="logr" placeholder="Logradouro" style="width: 350px;">
    </div>
    
    
    <label for="logr">Nº</label>
    <input required v-model="numeroEndereco" type="text" class="form-control mr-2" id="nend" placeholder="Nº" style="width: 100px;">
    </div>
    </div>
    <div>
 
    <div style="display: flex; flex-direction:row!important;">
        <div style="display: flex;">
        <label for="logr">Bairro</label>
        <input required v-model="bairro" type="text" class="form-control mr-2" id="nend" placeholder="Bairro" style="width:250px;">
        <label for="logr">Complemento</label>
        <input v-model="complemento" type="text" class="form-control mr-2" id="nend" placeholder="Complemento" style="width: 250px;">
        <small id="inputCompl" class="form-text text-muted"></small>
    </div>
    
    </div>
    <div style="display: flex; flex-direction: row;">
    <label for="logr">Pais</label>
    <select required @change="loadUfs()" name="" id="" v-model="idPais" class="form-control mr-2 mt-2">
        <option v-for="pais in paises" :key="pais.idpais" :value="pais.idpais">{{ pais.nome.trim() }}</option>
    </select>
    <!-- <input type="text" class="form-control mr-2 mt-2" id="logr" placeholder="País" style="width: 350px;"> -->
    <label for="logr">UF</label>
    <!-- <input type="text" class="form-control mr-2 mt-2" id="nend" placeholder="UF" style="width: 100px;"> -->
    <select  required @change="loadCidades()" name="" id="" v-model="codigoufibge" class="form-control mr-2 mt-2">
        <option v-for="uf in ufs" :key="uf.iduf" :value="uf.codigoibge">{{ uf.sigla.trim() }}</option>
    </select>
    <label for="logr">Cidade</label>
    <!-- <input type="text" class="form-control mr-2 mt-2" id="nend" placeholder="Cidade" style="width: 300px;"> -->
    <select required name="" id="" v-model="codigoibge" class="form-control mr-2 mt-2">
        <option v-for="cidade in cidades" :key="cidade.codigoibge" :value="cidade.codigoibge">{{ cidade.nome.trim() }}</option>
    </select>
    <small id="inputCEP" class="form-text text-muted"></small>
    </div>
  </div>

  <div class="form-group form-check">
    <input v-model="ativo" type="checkbox" class="form-check-input" id="checkAtivo">
    <label class="form-check-label" for="checkAtivo">Ativo</label>
  </div>
  <div style="display: flex; flex-direction :row-reverse">
    <button @click="salvar()" class="btn btn-primary">Salvar</button>
  </div>
  
  <!-- <a @click="salvar()" class="btn btn-primary" >Save Here</a> -->
<!-- </form> -->



</div>
</template>
<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import ComboBox from './ComboBox.vue'

export default {
    components: {ComboBox},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tipoPessoa : 'F',
            ativo : true ,
            paises : [],
            ufs : [],
            cidades : [],
            idPais : 29,
            idUf : 0,
            codigoufibge : 0,
            codigoibge : 0,
            idCidade : 0,
            cpf : '',
            cnpj : '',
            nome : '',
            email : '',
            fone : '',
            ddd : '',
            cep : '',
            logradouro : '',
            numeroEndereco : '',
            bairro : '',
            complemento : '',
            dataCep : {}
        }
    },
    methods :{
        async salvar(){
            let params = {
                tipo : this.tipoPessoa,
                ativo : this.ativo ,
                pais : this.idPais,
                codigoufibge : this.codigoufibge,
                codigoibge : this.codigoibge,
                idCidade : this.idCidade,
                cpf : this.cpf,
                cnpj : this.cnpj,
                nome : this.nome,
                email : this.email,
                numeroendereco : this.fone,
                cep : this.cep,
                endereco : this.logradouro,
                numero : this.numeroEndereco,
                bairro : this.bairro,
                complemento : this.complemento,
                fornecedor : true,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            console.error(JSON.stringify(params));
            await axios.post(CONSTANTS.API_URL + '/fdguest/fornecedor/insert', params
                , {headers: this.headers}).then(response => {
                if(response.status != 200) {
                    console.error(JSON.stringify(response))
                }
            })
        },
        async loadPaises() {
            let params = {}
            await axios.post(CONSTANTS.API_URL + '/fdguest/pais/select', params
                , {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.paises = response.data
                } else {
                    console.error(JSON.stringify(response))
                }
            })
        },
        async loadUfs(){

            let params = {
                idpais : this.idPais
            }
            await axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', params
                , {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.ufs = response.data
                    //console.log(JSON.stringify(this.ufs))
                } else {
                    console.error(JSON.stringify(response))
                }
            })            
        },
        async loadCidades(){
            let params = {
                codigoufibge : this.codigoufibge
            }
            await axios.post(CONSTANTS.API_URL + '/fdguest/cidade/select', params
                , {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.cidades = response.data
                    //console.log(JSON.stringify(this.cidades))
                } else {
                    console.error(JSON.stringify(response))
                }
            })            
        },
        searchCep () {
            
            let er = /[^a-z0-9]/gi;
            let cepLimpo = this.cep.replace(er, "");
			
            if(cepLimpo.length == 8) {
                
                 
				axios.get(`https://viacep.com.br/ws/${ cepLimpo }/json/`)
				.then( response => {
                    this.dataCep = response.data
                    this.bairro = response.data.bairro
                    this.logradouro = response.data.logradouro
                    this.ddd = response.data.ddd

                    //console.log(this.dataCep)
                }
                )
				.catch( error => console.log(error) )
			}
		}
    },
    mounted(){
        this.loadPaises(),
        this.loadUfs()
    }
}
</script>