import { render, staticRenderFns } from "./SolicitacaoDetalhe.vue?vue&type=template&id=a87c8580&scoped=true&"
import script from "./SolicitacaoDetalhe.vue?vue&type=script&lang=js&"
export * from "./SolicitacaoDetalhe.vue?vue&type=script&lang=js&"
import style0 from "./SolicitacaoDetalhe.vue?vue&type=style&index=0&id=a87c8580&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a87c8580",
  null
  
)

export default component.exports