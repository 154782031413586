<template>
  
  <div class="row">
	<div class="col-xl-12">
		<div v-if="(env === 'development')">
			<h6>{{largura}}x{{altura}}</h6>
			<h6>{{$i18n.locale}}</h6>
		</div>
		<div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
			<h4 class="text-info">{{$t('dashboard.bemvindoaopainelfdguest')}}</h4>
			<p>{{$t('dashboard.tenhacontroletotalsobreassolicitacoes')}}</p>
			<img src="images/svg/welcom-card.svg" alt="" class="position-absolute" width="200">
		</div>
	</div>
	<div class="col-xl-12">
		<div id="user-activity" class="card">
			<div class="card-header border-0 pb-0 d-sm-flex d-block">
				<div>
					<h4 class="card-title mb-1">{{$t('dashboard.quantidadesolicitacoes')}}</h4>
				</div>
				<div class="card-action card-tabs mt-3 mt-sm-0">
					<ul class="nav nav-tabs" role="tablist">
						<li class="nav-item">
							<a class="nav-link active ponteiro" data-toggle="tab" @click="fillLineData(1)" role="tab" aria-selected="true">
								{{$t('sols.pendente')}}
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link ponteiro" data-toggle="tab" @click="fillLineData(2)" role="tab" aria-selected="false">
								{{$t('sols.ematendimento')}}
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link ponteiro" data-toggle="tab" @click="fillLineData(3)" role="tab" aria-selected="false">
								{{$t('sols.atendida')}}
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link ponteiro" data-toggle="tab" @click="fillLineData(4)" role="tab" aria-selected="false">
								{{$t('sols.naoatendida')}}
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link ponteiro" data-toggle="tab" @click="fillLineData(5)" role="tab" aria-selected="false">
								{{$t('sols.contestada')}}
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link ponteiro" data-toggle="tab" @click="fillLineData(6)" role="tab" aria-selected="false">
								{{$t('sols.cancelada')}}
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="card-body">
				<div class="tab-content" id="myTabContent">
					<line-chart :chart-data="linedata" :options="lineoptions"></line-chart>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xl-6 col-xxxl-12 col-lg-6">
		<div class="card">
			<div class="card-header border-0 pb-3 d-sm-flex d-block ">
				<h4 class="card-title">{{$t('dashboard.atendimentosolicitacoes')}}</h4>
				<div class="d-flex mt-3 mt-sm-0">
					<div class="dropdown ">
						<button id="atendimento" type="button" class="btn btn-primary dropdown-toggle light btn-sm btn-rounded" data-toggle="dropdown" aria-expanded="false">
							{{$t('dashboard.mes')}}
						</button>
						<div class="dropdown-menu" >
							<a class="dropdown-item" @click="fillDoughnutData ('D');">{{$t('dashboard.dia')}}</a>
							<a class="dropdown-item" @click="fillDoughnutData ('S');">{{$t('dashboard.semana')}}</a>
							<a class="dropdown-item" @click="fillDoughnutData ('M');">{{$t('dashboard.mes')}}</a>
						</div>
					</div>
				</div>
			</div>
			<div class="card-body">
				<div class="row mx-0 align-items-center">
					<!-- Gráfico Doghnut -->
					<div class="col-sm-8 col-md-7 col-xxl-7 px-0 text-center mb-3 mb-sm-0">
						<div>
							<doughnut-chart :chart-data="doughnutdata" :options="doughnutoptions"></doughnut-chart>
						</div>
					</div>
					<!-- Legenda -->
					<div class="col-sm-4 col-md-5 col-xxl-5 px-0">
						<div class="chart-deta">
							<div v-for="(item, index) in doughnutitens" :key="index" class="col px-0">
								<span :class="coresLegenda[item.idstatus-1]"></span>	
								<div>
									<p class="fs-14">{{item.label}}</p>
									<h3>{{item.total}}</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xl-6 col-xxxl-12 col-lg-6">
		<div class="card widget-media">
			<div class="card-header border-0 pb-0 ">
				<h4 class="text-black">{{$t('dashboard.ultimassolicitacoes')}}</h4>								
			</div>
			<div class="card-body timeline pb-2" v-if="ultimasSolicitacoes && ultimasSolicitacoes.length>0">
				<div v-for="(sol,index) in ultimasSolicitacoes" :key="index"
					class="timeline-panel align-items-end es-sol-link" 
					@click="abrirSolicitacao(sol)"
				>
					<div class="media-body">
						<h5 v-if="sol&&sol.nome&&sol.nome.trim()" class="mb-1"><a class="text-black" href="javascript:void(0);">{{sol.nome+' '+sol.sobrenome}}</a></h5>
						<h4 v-else>{{sol.deschotlinesetor}}</h4>
						<h4>{{sol.textohotlinesolicitacoes}}</h4>
						<p class="d-block mb-0 text-primary"><i class="las la-ticket-alt mr-2 scale5 ml-1"></i>{{$t('dashboard.quarto')}}: {{sol.coduh}}</p>
					</div>
					<p class="mb-0 fs-14"><small>{{formatarData(sol.datasolicitacao)}}</small></p>
				</div>
			</div>
			<div class="card-footer border-0 pt-0 text-center">
				<router-link to="/painel/solrecepcao">{{$t('dashboard.vertodos')}}</router-link>
			</div>
		</div>
	</div>
	<div class="col-xl-12">
		<div class="card">
			<div class="card-body">
				<div class="row mx-0">
					<div class="col-sm-12 col-lg-4 px-0">
						<h4 class="fs-20 text-black font-w600">{{$t('dashboard.solicitadas')}} x {{$t('dashboard.atendidas')}}</h4>
						<p class="font-w600 fs-20 text-black">{{solicitadas}} x {{atendidas}}</p>
					</div>
					<div class="col-sm-12 col-lg-8 px-0">
						<bar-chart :chart-data="bardata" :options="baroptions"></bar-chart>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import LineChart from './ChartLine.js'
import DoughnutChart from './ChartDoughnut.js'
import BarChart from './ChartBar.js'
import moment from "moment";
import {iniciarIntercom} from './importacaoTelecom.js'

export default {
  components: { LineChart, DoughnutChart, BarChart },
    data () {
      return {
		// Gráfico de linhas
        linedata: {},
		lineoptions:{
			legend: {
				display: false
			},
			maintainAspectRatio: false
		},
		// Gráfico doughnut
		doughnutdata: {},
		doughnutoptions:{
			legend: {
				display: false
			}
		},
		idstatus: [],
		statusConst: {
            "1": 'sols.pendente',
            "2": 'sols.ematendimento',
			"3": 'sols.atendida',
			"4": 'sols.naoatendida',
            "5": 'sols.contestada',
            "6": 'sols.cancelada'
		},
		coresGrafico: ['#ffe600' , '#2c49cb', '#21b731', '#ff2625', '#ff7a01', '#6e6e6e'],
		coresLegenda: ['bg-secondary', 'bg-primary', 'bg-success', 'bg-danger', 'bg-warning', 'bg-dark'],
		// Gráfico de barras
		bardata: {},
		baroptions:{
			legend: {
				display: false
			}, 
			title: {
				display: false
			},
			tooltips: {
				mode: 'index',
				intersect: false
			},
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [{
					display: true, 
					stacked: true,
					ticks: {
						display: true
					}, 
					gridLines: {
						display: false, 
						drawBorder: false
					}
				}],
				yAxes: [{
					display: true, 
					stacked: true, 
					gridLines: {
						display: false, 
						drawBorder: false
					}, 
					ticks: {
						display: false
					}
				}]				
			},
		},
		ultimasSolicitacoes: [],
		solicitadas: 0,
		atendidas: 0,
		// Token das chamadas ao backend
		headers: {
			'x-access-token': this.$store.getters.GetLoginInfo.token
		},
		largura: 0,
		altura: 0,
		env: ''
      }
    },
    computed: {
		doughnutitens() {
			var result = []
			if (this.doughnutdata.datasets) {
				var ds = this.doughnutdata.datasets[0]
				for (var i = 0; i < ds.data.length; i++) {
					result.push({
						label: this.doughnutdata.labels[i],
						total: ds.data[i],
						idstatus: this.idstatus[i]
					})
				}
			}
			return result
		}
    },
    methods:{
        abrirSolicitacao(item) {
            this.$store.dispatch('set_Sol_Atual', item.idhotlinesolicitacoes);
            this.$router.push('solicitacaodetalhe')
        },
		fillLineData (status) {
			var params = {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"idstatus" : status,
					"idioma": this.$i18n.locale
				}
			console.log(JSON.stringify(params))
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/historico/todas', params, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						//alert(this.statusConst["0"])
						this.linedata = {
							labels: response.data.rotulos,
							datasets: [
								{
									// label: this.$t(status[status.toString()]),
									label: this.$t(this.statusConst[status]),
									backgroundColor: '#eceffc',
									borderColor: '#2c4bcc',
									borderWidth: '6',
									data: response.data.dados
								}
							]
						}
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
		fillDoughnutData (periodo) {
			var params = {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"periodo" : periodo,
					"idioma": this.$i18n.locale
				}
			//console.log(JSON.stringify(params))
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/totais', params, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						var obj = document.getElementById('atendimento')
						if (obj) {
							if (periodo == 'D') {
								obj.innerText = this.$t('dashboard.dia')
							} else {
								if (periodo == 'S') {
									obj.innerText = this.$t('dashboard.semana')
								} else {
									obj.innerText = this.$t('dashboard.mes')
								}
							}
						}
						this.idstatus = response.data.idstatus
						this.doughnutdata = {
							labels: response.data.rotulos,
							datasets: [
								{
									backgroundColor: this.idstatus.map(val => {
										return this.coresGrafico[val-1]
									}),
									borderWidth: '6',
									data: response.data.dados
								}
							]
						}
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
		fillBarData () {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/comparativo', {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"idioma": this.$i18n.locale
				}, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.bardata = {
							labels: response.data.rotulos,
							datasets: [
								{
									label: this.$t('dashboard.solicitadas'),
									backgroundColor: '#2130b8',
									borderColor: '#2130b8',
									borderWidth: '3',
									barThickness: '15',
									data: response.data.solicitadas
								},
								{
									label: this.$t('dashboard.atendidas'),
									backgroundColor: '#f0f0f0',
									borderColor: '#f0f0f0',
									borderWidth: '3',
									barThickness: '15',
									data: response.data.atendidas
								}
							]
						}
						for (var i = 0; i < response.data.solicitadas.length; i++) {
							this.solicitadas += parseInt(response.data.solicitadas[i])
						}
						for (var j = 0; j < response.data.solicitadas.length; j++) {
							this.atendidas += parseInt(response.data.atendidas[j])
						}
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
		loadUltimasSolicitacoes () {
			var params = {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"idioma": this.$i18n.locale,
					"limit": 6
				}
			// console.log(JSON.stringify(params))
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesolicitacoes/select', params, 
				{headers: this.headers}).then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.ultimasSolicitacoes = response.data.dados
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
        formatarData(aDate) {
            //return moment(aDate).format('DD-MM-YY HH:MM');
            return moment(aDate).locale(this.$i18n.locale).format('LLL');
        },
		onResize() {
			this.largura = window.innerWidth
			this.altura = window.innerHeight
		},		
	},
	beforeMount() {
		//window.addEventListener("resize", this.onResize);
	},	
	mounted() {
		iniciarIntercom(this)
		this.fillLineData(3);
		this.fillDoughnutData('M');
		this.fillBarData();
		this.loadUltimasSolicitacoes();
		this.onResize();
		window.addEventListener("resize", this.onResize);
	},
	created() {
		this.env = process.env.NODE_ENV;
		this.$i18n.locale = this.$store.getters.GetLoginInfo.idioma.codidioma;
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style>
.es-sol-link {
	cursor: pointer;
	padding: 0.3rem;
	border-radius: 0.5rem;
}
.es-sol-link:hover {
	color: white;
	background-color: rgb(129, 224, 245);
	
}
</style>