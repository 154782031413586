<template>
	<div v-if="equipamentos && equipamentos.length>0">
		<!-- Título -->
		<div class="text-center">
			<h4>{{$t('equipamento.tituloLista')}}</h4>
		</div>
		<!-- Cabeçalho -->
		<div class="row py-2">
			<div class="col-1"><strong>{{$t('chamadoProgramado.ideq')}}</strong></div>
			<div class="col-3"><strong>{{$t('chamadoProgramado.desceq')}}</strong></div>
			<div class="col-2"><strong>{{$t('chamadoProgramado.marca')}}</strong></div>
			<div class="col-2"><strong>{{$t('chamadoProgramado.modelo')}}</strong></div>
			<div class="col-2"><strong>{{$t('chamadoProgramado.serie')}}</strong></div>
			<div class="col-2"><strong>{{$t('chamadoProgramado.tombamento')}}</strong></div>
		</div>
		<!-- Produtos -->
		<div v-for="(equipamento,index) in equipamentos" :key="index"
			:class="{ 'es-even': index%2==0, 'es-odd': index%2!=0}" class="row my-1 py-1">
			<div class="col-1">
				<span>{{equipamento.idequipamento}}</span>
			</div>
			<div class="col-3">
				<span>{{equipamento.descricao}}</span>
			</div>			
			<div class="col-2">
				<span>{{equipamento.marca}}</span>
			</div>
			<div class="col-2">
				<span>{{equipamento.modelo}}</span>
			</div>
			<div class="col-2">
				<span>{{equipamento.serie}}</span>
			</div>
			<div class="col-2">
				<span>{{equipamento.tombamento}}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'equipamentos'
	],
    components: {
         
    },
    data() {
        return {

        }
	},
	computed: {
	},
	methods: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.es-even {
	align-items: baseline;
	color: black!important;
	background-color: rgb(238, 239, 252)!important;
}
.es-odd {
	color: black!important;
	align-items: baseline;
	background-color: rgb(255, 255, 255)!important;
}
.es-sim {
	align-items: baseline;
	color: rgb(7, 206, 50)!important;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
}
.es-nao {
	align-items: baseline;
	color: rgb(241, 12, 12)!important;
	margin-left: 0.6rem;
	margin-right: 0.6rem;
}
</style>