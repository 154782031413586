
const dayjs = require('dayjs')
import Intercom from '@intercom/messenger-js-sdk';
import CONSTANTS from '../constants.js'
import axios from 'axios'

async function iniciarIntercom(contexto){

    let headers= {
        'x-access-token': CONSTANTS.TOKEN_API_FDGUEST
    }

    let id_cliente_economy = await axios.get(CONSTANTS.API_URL + "/fdguest/ambiente/getdadosambiente",{ headers:headers}).then(resp => {
                                return resp.data.BUILD_ID_CLIENTE
                            }).catch(error => {
                                console.log(error)
                                return null
                            })



    if(id_cliente_economy > 0){

        Intercom({
            app_id: 'tgvox9ou',
            user_id:'3'+id_cliente_economy+''+contexto.$store.getters.GetLoginInfo.idusuario, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: contexto.$store.getters.GetLoginInfo.nome, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: contexto.$store.getters.GetLoginInfo.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            created_at: dayjs().unix(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        });

    }
}

export  {iniciarIntercom}