<template>
  <div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    {{ $t("sols.concluirchamados") }} ({{
                      totalSelecionado
                    }}) {{$t("sols.selecionados")}}
                  </h5>
                  <button type="button" class="close" @click="$emit('close')">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group col-md-12">
                    <p v-if="atendidas.length>0">{{`${atendidas.length}`}} {{$t("sols.jaatendidas")}}</p>
                    <p v-if="naoAtendidas.length>0">{{`${naoAtendidas.length}`}} {{$t("sols.janegadas")}}</p>
                    <p v-if="canceladas.length>0">{{`${canceladas.length}`}} {{$t("sols.jacanceladas")}}</p>
                    <p v-if="canceladas.length+naoAtendidas.length+atendidas.length>0"><strong>{{$t("sols.apenaspendentes")}} {{`${totalAtualizar}`}} {{$t("sols.chamadosseraoconcluidos")}}</strong></p>
                    <label>{{ $t("sols.atentimento")}}:</label>
                    <textarea rows="5" class="form-control" v-model="textoConclusao" type="text" />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    @click="concluirChamados()"
                    type="button"
                    class="btn btn-primary"
                  >
                    {{ $t("ser.salvar") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="$emit('close')"
                  >
                    {{ $t("sols.fechar") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolicitacaoConcluirChamados",
  components: {},
  data() {
    return {
      showModal: false,
      responsavelSelecionado: -1,
      mensagemAlerta: '',
      textoConclusao: this.$t('sols.colaboradoressemacesso')
    };
  },
  props: {
    totalSelecionado: {
      type: Number,
      required: true,
    },
    atendidas: {
      type: Array,
      required: false,
      default: () => []
    },
    naoAtendidas: {
      type: Array,
      required: false,
      default: () => []
    },
    canceladas: {
      type: Array,
      required: false,
      default: () => []
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    totalAtualizar() {
      return (this.totalSelecionado - 
        (this.atendidas.length + this.naoAtendidas.length + this.canceladas.length)
      )
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.showModal = true;
        this.mensagemAlerta = '';
        this.responsavelSelecionado = -1;
      } else {
        this.showModal = false;
      }
    },
  },
  methods: {
    concluirChamados() {
      let palavras = this.textoConclusao.trim().split(' ')
      if (palavras.length < 3) {
        this.$snackW('Justifique a conclusão dos chamados com algumas palavras.')
        return
      } else {
        this.$emit("save", this.textoConclusao);
      }
    },
  }
};
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
</style>