<template>
  <div class="modal" :class="{ 'is-active': exibirModal }">
    <div class="modal-background" @click="$emit('fechar')"></div>
    <div class="modal-content">
      <div class="box">
        <p>{{ texto }}</p>
      </div>
      <div v-if="chamado" class="d-flex justify-content-end">
        <button v-on:click="$emit('abrir', chamado)" type="button" 
          class="btn btn-primary" id="toastr-danger-top-right">{{$t('global.abrirchamado')}}</button>
      </div>
    </div>
    <button class="modal-close is-large ponteiro" aria-label="close" @click="$emit('fechar')">X</button>
  </div>
</template>

<script>
export default {
  name: 'ModalAbrirChamado',
  props: {
    titulo: {
      type: String,
      default: ''
    },
    texto: {
      type: String,
      default: ''
    },
    chamado: {
      type: Number,
      default: 0
    },
    exibir: {
      type: Boolean,
      default: false
    }
  },
  methods: {
  },
  watch: {
    exibir(val) {
      this.exibirModal = val;
    }
  },
  data() {
    return {
      exibirModal: false
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 25;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-content {
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-close:hover {
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

/* Estilos para itens ímpares */
.row-select tr:nth-child(odd) {
  background-color: #f2f2f2;
}

/* Estilos para itens pares */
.row-select tr:nth-child(even) {
  background-color: #ddd;
}

.row-select tr:hover {
  background-color: #aaa;
}

</style>