<template>
  <div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    {{ $t("sols.atribuirresponsavel") }} ({{
                      totalSelecionado
                    }}) {{$t("sols.selecionados")}}
                  </h5>
                  <button type="button" class="close" @click="$emit('close')">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group col-md-5">
                    <label>{{ $t("sols.responsavel") }}</label>
                    <select
                      class="form-control"
                      v-model="responsavelSelecionado"
                      @change="apresentarAlerta()"
                    >
                      <template v-if="responsaveis && responsaveis.length > 0">
                        <option
                          v-for="responsavel in responsaveis"
                          v-bind:key="responsavel.idusuario"
                          :value="responsavel.idusuario"
                        >
                          {{ responsavel.nome }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <div>
                    <strong><span style="color:orange;">{{mensagemAlerta}}</span></strong>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    @click="salvarResponsavel()"
                    type="button"
                    class="btn btn-primary"
                  >
                    {{ $t("ser.salvar") }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="$emit('close')"
                  >
                    {{ $t("sols.fechar") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolicitacaoAtribuirResponsavel",
  components: {},
  data() {
    return {
      showModal: false,
      responsavelSelecionado: -1,
      mensagemAlerta: ''
    };
  },
  props: {
    totalSelecionado: {
      type: Number,
      required: true,
    },
    responsaveis: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.showModal = true;
        this.mensagemAlerta = '';
        this.responsavelSelecionado = -1;
      } else {
        this.showModal = false;
      }
    },
  },
  methods: {
    apresentarAlerta() {
      this.mensagemAlerta = this.$t('sols.alertaresponsavel')
    },
    setResponsavel($event) {
      this.$emit("save", $event);
    },
    salvarResponsavel() {
      this.$emit("save", this.responsavelSelecionado);
    },
  }
};
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
</style>