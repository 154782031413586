<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.filtrar')}}</h4>
                    <div @click="limparFiltro()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-2">
                                    <label>#</label>
                                    <input v-model="filtro.idparceiro"
                                        type="text" class="form-control"
                                        @keyup="buscarParceiros()">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.tipo')}}:</label>
                                    <select class="form-control"  v-model="filtro.idcategoria" 
                                        @click="buscarParceiros()">
                                        <option v-for="cat in categorias" :key="cat.idcategoria" 
                                            :value="cat.idcategoria"
                                        >{{cat.descricao}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>{{$t('ser.empresa')}}:</label>
                                    <input v-model="filtro.nome" 
                                        @keyup="buscarParceiros()" 
                                        type="text" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.whatsapp')}}:</label>
                                    <input v-model="filtro.whatsapp" type="text" class="form-control" placeholder="" v-on:keyup="buscarParceiros()">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.endereco')}}:</label>
                                    <input v-model="filtro.endereco" type="text" class="form-control" v-on:keyup="buscarParceiros()">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.status')}}:</label>
                                    <select class="form-control" v-model="filtro.ativo"
                                        @change="buscarParceiros()">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Parceiros -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.empresascadastradas')}}</h4>
                    <a @click="cadastrarParceiro()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('ser.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{$t('ser.tipo')}}</strong></th>
                                    <th><strong>{{$t('ser.empresa')}}</strong></th>
                                    <th><strong>{{$t('ser.whatsapp')}}</strong></th>
                                    <th><strong>{{$t('ser.endereco')}}</strong></th>
                                    <th><strong>{{$t('ser.status')}}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, idx) in parceiros" :key="idx">
                                <tr>
                                    <td><strong>{{item.idparceiro}}</strong></td>
                                    <td>{{item.categoria}}</td>
                                    <td>{{item.nome}}</td>
                                    <td>{{item.whatsapp}}</td>                                                
                                    <td>{{item.endereco}}, {{item.numero}}</td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" :class="{ 'btn-success' : item.ativo == true, 'btn-warning' : item.ativo == false   }" class="btn light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu" v-if="item.ativo == true">
                                                <a @click="editarParceiro(item)" class="dropdown-item ponteiro " >{{$t('ser.editar')}}</a>
                                                <a @click="desativarParceiro(item)" class="dropdown-item ponteiro">{{$t('ser.desativar')}}</a>
                                            </div>
                                            <div class="dropdown-menu" v-else>
                                                <a @click="editarParceiro(item)" class="dropdown-item ponteiro" >{{$t('ser.editar')}}</a>
                                                <a @click="ativarParceiro(item)" class="dropdown-item ponteiro">{{$t('ser.ativar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>	
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>   
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
    data() {
        return{
            parceiros: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            newItem: {
                idcategoria: -1,
                nome: '',
                telefone: '',
                whatsapp: '',
                idpais: -1,
                estado: -1,
                cidadecodigoibge: -1,
                ufsigla: '',
                endereco: '',
                numero: '',
                complemento: '',
                cep: '',
                bairro: '',                
                ativo: null
            },
            filtro: {
                idparceiro: '',
                idcategoria: -1,
                nome: '',
                whatsapp: '',
                endereco: '',
                ativo: null
            },
            nomeParceiro: '',
            categorias: [],
            selectcat: '',
            telParceiro: '',
            cidParceiro: '',
            uf: [],
            ufselected: '',
        }
    },
    methods: {
        limparFiltro() {
            this.filtro = {
                idparceiro: '',
                idcategoria: -1,
                nome: '',
                whatsapp: '',
                endereco: '',
                ativo: null
            }
            this.buscarParceiros()
        },
        buscarParceiros() {
            var params = {
                idhotel : this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale,
            }
            if (this.filtro.idparceiro.trim()) {
                params.idparceiro = this.filtro.idparceiro.trim()
            }
            if (this.filtro.idcategoria > 0) {
                params.idcategoria = this.filtro.idcategoria
            }
            if (this.filtro.nome.trim()) {
                params.nome = this.filtro.nome.trim()
            }
            if (this.filtro.whatsapp.trim()) {
                params.whatsapp = this.filtro.whatsapp.trim()
            }
            if (this.filtro.endereco.trim()) {
                params.endereco = this.filtro.endereco.trim()
            }
            if (this.filtro.ativo != null) {
                params.ativo = this.filtro.ativo
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', params, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.parceiros = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        buscarCatServico() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoria/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categorias = response.data
                }
            })
        },
        desativarParceiro(item) {
            var params = {
                idparceiro: item.idparceiro, 
                ativo: false,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarParceiros()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        ativarParceiro(item) {
             var params = {
                idparceiro: item.idparceiro, 
                ativo: true,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/parceiro/update', params,{headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.buscarParceiros()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
        cadastrarParceiro() {
            this.$store.dispatch('set_Edit_Status', false);
            this.$store.dispatch('set_Edit_Item', this.newItem);
            this.$router.push('/painel/cadconcierge')
        },
        editarParceiro(item) {
            this.$store.dispatch('set_Edit_Status', true);
            this.$store.dispatch('set_Edit_Item', item);
            this.$router.push('/painel/cadconcierge')
        },
        buscarUf() {
            axios.post(CONSTANTS.API_URL + '/fdguest/uf/select', {}, {headers: this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.uf = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
            .catch((e) => {
                console.log(e)
            })
        },
    }, 
    mounted() {
        this.buscarParceiros();
        this.buscarCatServico();
        this.buscarUf();
    }
}
</script>

<style>

</style>