<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="welcome-card rounded pl-5 pt-5 pb-4 mt-3 position-relative mb-5">
                <h4 class="text-info">{{$t('contaEmail.titulo')}}</h4>
                <p>{{$t('contaEmail.descricao')}}</p>
            </div>
        </div>
        <!-- Portais de Reviews -->
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('contaEmail.titulo')}}</h4>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" name="language" class="ponteiro custom-control-input ponteiro" 
                                    id="Switch_Config"
                                    :checked="emailConfig.ativo"
                                    v-model="emailConfig.ativo"
                                >
                                <label class="ponteiro custom-control-label" for="Switch_Config">{{$t('contaEmail.ativardesativarcontaemail')}}</label>
                            </div>                                
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.nametoexibition')}}:</label>
                            <input v-model="emailConfig.nametoexibition" 
                                type="text" class="form-control" placeholder="Nome da conta de email">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.host')}}:</label>
                            <input v-model="emailConfig.host" 
                                type="text" class="form-control" placeholder="Endereço do servidor SMTP">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.port')}}</label>
                            <input v-model="emailConfig.port" 
                                type="text" class="form-control" placeholder="Porta do servidor SMTP">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.replyto')}}:</label>
                            <input v-model="emailConfig.replyto" 
                                type="text" class="form-control" placeholder="Endereço de email para resposta.">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.user')}}:</label>
                            <input v-model="emailConfig.user" 
                                type="text" class="form-control" placeholder="Usuário da conta do servidor SMTP">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-8">
                            <label>{{$t('contaEmail.pass')}}:</label>
                            <input v-model="emailConfig.pass" 
                                type="password" class="form-control" placeholder="Senha da conta do servidor SMTP">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-auto">
                            <a v-on:click="salvarMailConfig()" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                        </div>
                    </div>
                    <div id="status-message" class="col cols-12" :class="{'text-orange': statusCode == 0, 'text-green': statusCode == 1, 'text-red': statusCode == 2}">
                        <p class="center">{{statusMsg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    components: {  },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            emailConfig: {
                idmailer: 0,
                host: "",
                port: "",
                user: "",
                pass: "",
                replyto: "",
                nametoexibition: "",
                ativo: true,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            },
            statusMsg: '',
            statusCode: 0
        }
    },
    methods: {
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.085;
                }, 40);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },
        carregarMailConfig() {
            axios.get(CONSTANTS.API_URL + '/email/conta/get', { headers:this.headers})
            .then(response => {
                if(response.status == 200) {
                    this.emailConfig = response.data
                }
            })
        },
        salvarMailConfig() {
            console.log('salvarMailConfig()...')
            this.statusMsg = 'Salvando...'
            var el = document.getElementById('status-message');
            if (el) { 
                el.style.opacity = 1;
                el.style.display = 'block';
            }
            var params = {
                idmailer: this.emailConfig.idmailer,
                host: this.emailConfig.host, 
                port: this.emailConfig.port,
                user: this.emailConfig.user,
                pass: this.emailConfig.pass,
                replyto: this.emailConfig.replyto,
                nametoexibition: this.emailConfig.nametoexibition,
                ativo: this.emailConfig.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario
            }
			//log(JSON.stringify(params))
            axios.put(CONSTANTS.API_URL + '/email/atualizaContaEmail',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.statusCode = 1
                    this.statusMsg = 'Salvo.'
                    this.fade(el)
                    this.carregarMailConfig()
                    return true;
                } else {
                    this.statusCode = 2
                    this.statusMsg = 'Erro ao salvar!'
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
            .catch((e)=>{
                this.statusCode = 2
                this.statusMsg = 'Erro ao tentar salvar configuração!'
                console.error(JSON.stringify(e))
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarMailConfig();
    }
}
</script>
