<template>
	<div class="row">
		<Wizard :display="displayWizard"
			:editedTipo="editedTipo"
			:editedItem="editedItem"
			:stepInicial="step"
			@close="displayWizard=false"
			@update="updateList()"
		/>
		<!-- Setores -->
		<div class="col-xl-12 col-lg-12">
			<div class="card">
				<!-- <div class="card-header">
					<h4 class="card-title">Setores:</h4>								
				</div> -->
				<div class="card-body">
					<div class="basic-form">
						<div class="col-xl-12">
							<!-- <div id="accordion-one" class="accordion accordion-primary">
								<div v-for="setor in setores" :key="setor.idhotlinesetores" class="accordion__item">
									<div class="accordion__header collapsed rounded-lg" data-toggle="collapse" :data-target="`#tipos-sol-${setor.idhotlinesetores}`">
										<div class="es-accordion-header">
											<span class="es-icon-header material-icons icon">{{setor.icone}}</span>
											<span class="es-text-header accordion__header--text">{{setor.deschotlinesetor}}</span>
											<span class="accordion__header--indicator"></span>
										</div>
									</div>
									<div :id="`tipos-sol-${setor.idhotlinesetores}`" class="collapse" data-parent="#accordion-one">
										<div class="accordion__body--text"> -->
											<div class="col-xl-12 col-lg-12">
												<div class="card">
													<div class="card-body">
														<tipo-solicitacao :idhotlinesetores="null" 
															:interna="true"
															@show="showWizard($event)"></tipo-solicitacao>
													</div>
												</div>
											</div>
										<!-- </div>
									</div>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'

import TipoSolicitacao from './TipoSolicitacao.vue'
import Wizard from './Wizard.vue'

export default {
	name: 'ManutencaoItem',
	components: { 
		TipoSolicitacao, 
		Wizard
	},
	data() {
		return {
			site: '',
			headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
			},
			editedTipo: {},
			editedItem: {},
			displayWizard: false,
			step: 0,
			setores: []
		}
	},
	methods: {
		updateList() {
			//window.location.reload();
			console.error('atualizar lista...')
		},
		showWizard(data){
			this.editedTipo = data.tipo
			this.editedItem = data.item
			this.step = data.step
			this.displayWizard = true
		},
        closeNew() {
            var obj = document.getElementById('criarSobreDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: item.titulo,
				subtitulo: item.subtitulo,
				texto: item.texto,
                ativo: true,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelsobre/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadHotelSobreItens()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idhotelsobre)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idhotelsobre)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idhotelsobre: item.idhotelsobre,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                titulo: item.titulo,
				subtitulo: item.subtitulo,
                texto: item.texto,
                ativo: item.ativo,
                idioma: item.idioma,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
			//console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/hotelsobre/update',
                params,{ headers: this.headers })
			.then(response => {
                if(response.status == 200) {
                    this.loadHotelSobreItens()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idhotelsobre: item.idhotelsobre,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
		carregarSetores() {
			axios.post(CONSTANTS.API_URL + '/fdguest/hotlinesetores/select', {
				idioma:this.$i18n.locale}, {headers: this.headers})
			.then(response => {
				if(response.status == 200) {
					this.setores = response.data
				}
			})			
		},		
	},
	computed: {
	},
	created() {
		this.carregarSetores(this.$store.getters.GetLoginInfo.idhotel);
	},
	mounted() {
		
	}
}
</script>

<style>
.carregando {
	padding: 3rem;
	padding-top: 5.7rem;
	padding-bottom: 5.7rem;
	color: orange;
	font-weight: bold;
}
.es-accordion-header {
	align-items: center;
}
.es-icon-header {
	padding: 0.3em;
	font-size: 1.5em;
}
.es-text-header {
	font-size: 1.3em;
	top: -0.2em;
	position: relative;
}
</style>