<template>
	<div v-if="produtos && produtos.length>0">
		<!-- Grupos de Produtos -->
		<div class="row py-2">
			<div class="col-2"><strong>Código</strong></div>
			<div class="col-5"><strong>Descrição</strong></div>
			<div class="col-3 text-right"><strong>Preço</strong></div>
			<div class="col-2"><strong>Ativo</strong></div>
		</div>
		<div v-for="(produto,index) in produtos" :key="index"
			:class="{ 'es-even': index%2==0, 'es-odd': index%2!=0}" class="row my-1 py-1">
			<div class="col-2">
				<span>{{produto.codprodutopms}}</span>
			</div>
			<div class="col-5">
				<span>{{produto.descproduto}}</span>
			</div>
			<div class="col-3 text-right">
				<span>{{produto.precoimportado | formatarMoeda}}</span>
			</div>			
			<div class="col-2">
				<div class="custom-control custom-switch">
					<input  type="checkbox" name="horario" class="ponteiro" :id="'item-switch-'+produto.idproduto" :checked="produto.ativo" 
						v-model="produto.ativo" @change="produto.changed=true"
					>
					<!-- <div class="ponteiro">
						<input  type="checkbox" name="horario" class="custom-control-input ponteiro" :id="'item-switch-'+produto.idproduto" :checked="produto.ativo" v-model="produto.ativo">
						<label class="custom-control-label ponteiro" :for="'item-switch-'+produto.idproduto">{{produto.ativo?'Sim':'Não'}}</label>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'produtos'
	],
    components: {
         
    },
    data() {
        return {

        }
	},
	methods: {

	},
	mounted() {

	}
}
</script>

<style scoped>
.es-even {
	align-items: baseline;
	color: black!important;
	background-color: rgb(238, 239, 252)!important;
}
.es-odd {
	color: black!important;
	align-items: baseline;
	background-color: rgb(255, 255, 255)!important;
}
</style>