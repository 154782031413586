<template>
	<div class="row">
		<!-- Usuarios Cadastrados -->
		<div class="col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4 class="card-title">Técnicos Cadastrados</h4>
				</div>
				<div class="card-body">
					<!-- Campo de Busca -->
                    <div class="row mb-2 mt-4">
                        <div class="col-md-8">
                            <input autofocus class="form-control mr-sm-2" type="search" placeholder="Procurar" aria-label="Procurar"
                                v-model="textoPesquisa"
                            >
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-outline-primary my-2 my-sm-0" @click="loadTecnicos()">Procurar</button>
                        </div>
                    </div>
					<!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

					<div class="table-responsive">
						<table class="table table-responsive-md">
							<thead>
								<tr>
									<th><strong>{{$t('vincularTecnicos.nome')}}</strong></th>
									<th><strong>{{$t('vincularTecnicos.nomeusuario')}}</strong></th>
									<th><strong>{{$t('vincularTecnicos.email')}}</strong></th>
									<th><strong>{{$t('vincularTecnicos.perfil')}}</strong></th>
									<th><strong>Ação</strong></th>
								</tr>
							</thead>
							<tbody v-for="(item, idx) in tecnicos" :key="idx">
								<tr>
									<td>{{item.nome}}</td>
									<td>{{item.nomeusuario}}</td>
									<td>{{item.email}}</td>
									<td>{{item.perfil}}</td>
									<td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item ponteiro" @click="editarAtribuicoes(item)">{{$t('global.atribuicoes')}}</a>
												<a class="dropdown-item ponteiro" @click="editarEscala(item)">{{$t('global.escala')}}</a>
                                            </div>
                                        </div>
                                    </td>
								</tr>
                                <tr>
									<!-- Atribuições -->
                                    <td colspan="3">
                                        <div :id="'EditarAtribuicoes_'+item.idusuario" style="display: none;">
                                            <VincularTecnicoEdit
                                                :usuario="item" 
                                                v-on:save="salvar(item)"
                                                v-on:close="fecharAtribuicoes(item)"
												:alert="alertMsg"
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
									<!-- Escala de Trabalho -->
                                    <td colspan="3">
                                        <div :id="'EditarEscala_'+item.idusuario" style="display: none;">
											<h4 class="card-title">{{$t('global.escaladetrabalhoefolgas')}}</h4>
                                            <UsuarioEscala :escalas="item.escalas"
												v-on:update="updateHorarios(item,$event)"
											></UsuarioEscala>
											<DatePicker
												:titulo="'Determine as datas de folga do usuário:'"
												:datas="item.folgas"
												v-on:atualizar="atualizarDatasFolga(item,$event)"
											></DatePicker>
											<!-- Salvar e Fechar Escala -->
											<div class="form-row">
												<div class="form-group col-sm-auto">
													<a v-on:click="salvar(item)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
												</div>
												<div class="form-group col-sm-auto">
													<a v-on:click="fecharEscala(item)" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
												</div>
											</div>											
                                        </div>
                                    </td>
                                </tr>
							</tbody>
						</table>
					</div>

					<!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>
				</div>
			</div>
		</div> 
	</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import VincularTecnicoEdit from './VincularTecnicoEdit.vue'
import Pagination from './Pagination.vue'
import UsuarioEscala from './UsuarioEscala.vue'
import DatePicker from './DatePicker.vue'

export default {
    components: { VincularTecnicoEdit, Pagination, UsuarioEscala, DatePicker},
	data() {
		return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
			tecnicos: [],
			alertMsg: '',
			usuarioTipos: [],
			page:1,
			pageCount:1,
			recordCount:0,
			textoPesquisa:''
		}
	},
	methods: {
		updateHorarios(item,evt){
            let existe = false;
            //console.error(JSON.stringify(evt))
            if (item.escalas && item.escalas.length > 0) {
                let escala = item.escalas.find((x)=>x.dia === evt.dia)
                if (escala) {
                    escala.horarios = evt.horarios
                    existe = true;
                } else {
                    existe = false;
                }
            }
            if (!existe) {
                if (!item.escalas) item.escalas = []
                item.escalas.push({
                    idusuario: item.idusuario,
                    dia: evt.dia,
                    horarios: evt.horarios
                })
            }
        },
		atualizarDatasFolga(item,datas) {
            item.folgas = datas;
        },
		updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.loadTecnicos();
        },
        async loadTipos() {
            var params = {
                idioma: this.$i18n.locale,
                interna: true
            }
			axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select', params, { headers: this.headers })
            .then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.tipos = response.data;
						}
					} else {
                        console.log('Erro: '+ JSON.stringify(response));
                    }                    
			}).catch((err) => {
				console.log({ erro: `loadTipos(): ${err.message}` });
			});
        },
        fade(element) {
            if (element) {
                var op = 1;  // initial opacity
                var timer = setInterval(function () {
                    if (op <= 0.1){
                        clearInterval(timer);
                        element.style.display = 'none';
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op -= op * 0.0850;
                }, 140);
            }
        },
        unfade(element) {
            if (element) {
                var op = 0.1;  // initial opacity
                element.style.display = 'block';
                var timer = setInterval(function () {
                    if (op >= 1){
                        clearInterval(timer);
                        element.focus();
                    }
                    element.style.opacity = op;
                    element.style.filter = 'alpha(opacity=' + op * 100 + ")";
                    op += op * 0.085;
                }, 40);
            }
        },		
		alertar(id,msg) {
			this.alertMsg = msg
			let el = document.getElementById('alert-msg-' + id);
			if (el) {
				el.style.display = 'block';
				this.fade(el)
			}
		},
        editarAtribuicoes(item) {
            var obj = document.getElementById('EditarAtribuicoes_'+item.idusuario)
			if (obj&&obj.style.display == 'none') {
				obj.style.display = 'block'
			} else {
				this.fecharAtribuicoes(item)
			}
        },
		editarEscala(item) {
            var obj = document.getElementById('EditarEscala_'+item.idusuario)
			if (obj&&obj.style.display == 'none') {
				obj.style.display = 'block'
			} else {
				this.fecharEscala(item)
			}
		},
		fecharEscala(item) {
            var obj = document.getElementById('EditarEscala_'+item.idusuario)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        salvar(item) {
			var listatipos = []
			var listaitens = []
			if (item.tipos) {
				for (var i = 0; i < item.tipos.length; i++) {
					var tipo = item.tipos[i]
					if (tipo.selected) {
						listatipos.push(parseInt(tipo.idtiposolicitacao))
					}
					if (tipo.itens) {
						for (var j = 0; j < tipo.itens.length; j++) {
							var itemCorrente = tipo.itens[j]
							if (itemCorrente.selected) {
								listaitens.push(parseInt(itemCorrente.idtiposolicitacaoitem))
							}
						}
					}
				}
			}
            var params = {
				"idusuario": item.idusuario,
				"tipos": listatipos,
				"itens": listaitens,
				"merge": false,
				"escalas": item.escalas,
				"folgas": item.folgas
			}
			//console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tecnicos/vinculo',
                params, { headers: this.headers })
			.then(response => {
                if(response.status == 200) {
					this.$snack("Dados salvos com sucesso.")
                    this.loadTecnicos()
                    this.fecharAtribuicoes(item)
					this.fecharEscala(item)
                } else {
					if(response.status == 201) {
						this.alertar(item.idusuario,response.message)
					}
				}
            })
			.catch((e) => {
				console.error('Erro:'+e.message);
			})
        },
        fecharAtribuicoes(item) {
            var obj = document.getElementById('EditarAtribuicoes_'+item.idusuario)
            if(obj) {
                obj.style.display = 'none'
            }
        },
		loadTecnicos() {
			axios.post(CONSTANTS.API_URL + '/fdguest/usuarios/tecnicos', {
				ativo: true,
				idhotel: this.$store.getters.GetLoginInfo.idhotel,
				page: this.page,
                pagesize: this.pageSize,
				pesquisa: this.textoPesquisa
			}, { headers: this.headers })
			.then(response => {
				if(response.status == 200) {
					this.tecnicos = response.data.dados
					this.recordCount = response.data.recordCount;
					this.pageCount =  response.data.pageCount?response.data.pageCount:1;
				}
			})
			.catch((e) => {
				console.log({ err_usuario: e, message: e.message});
			})
		}
	},
	mounted() {
		this.loadTecnicos();
		//this.loadTipos();
	}
}
</script>

<style>
	.tamanho {
		width: 8px !important;
		height: 8px !important;
	}
</style>

