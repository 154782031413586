<template>
  <div class="autocomplete">
    <input class="form-control"
      type="text" :placeholder="placeHolder"
      @input="onChange"
      v-model="value"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
    />
    <ul
      id="autocomplete-results"
      v-show="isOpen"
      class="autocomplete-results"
    >
      <li
        class="loading"
        v-if="isLoading"
      >
        Loading results...
      </li>
      <li :id="`autocomplete-item-${i}`"
        v-else
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      > <span class="material-icons icon search-icon">{{result}}</span>
        <span class="search-text">{{ result }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'SearchAutocomplete',
    props: {
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      placeHolder: {
        type: String,
        required: false,
      },
      value: {
        type: String,
        required: false,
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
        results: [],
        search: this.value,
        isLoading: false,
        arrowCounter: -1,
      };
    },
    watch: {
      items: function (value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value;
          this.isLoading = false;
        }
      },
      search(val) {
          this.$emit('input', val);
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setResult(result) {
        this.search = result;
        this.$emit('input', this.search);
        this.isOpen = false;
      },
      filterResults() {
        this.results = this.items.filter((item) => {
          return item.toLowerCase().indexOf(this.value.toLowerCase()) > -1;
        });
      },
      onChange() {
        this.$emit('input', this.value);

        if (this.isAsync) {
          this.isLoading = true;
        } else {
          this.filterResults();
          this.isOpen = true;
        }
        this.$forceUpdate();
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      scroll(i) {
        //let ul = document.getElementById(`autocomplete-results`);
        let li = document.getElementById(`autocomplete-item-${i}`);
        if (li) {
          let options = {
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          }
          li.scrollIntoView(options)
          //li.scrollTo(0,10)
        }
        //if (ul) {
          //ul.scrollBy(0,1)
        //}
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
          this.scroll(this.arrowCounter);
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
          this.scroll(this.arrowCounter);
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1;
      },
    },
  };
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
  .search-icon {
    font-size: 2em;
  }
  .search-text {
    padding-left: 1em;
  }
</style>