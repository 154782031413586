<template>
    <div class="row">
        <!-- Filtrar -->
        <div class="col-xl-12">
            <div class="es-card">
                <div class="card-header py-2">
                    <div @click="exibirOcultarFiltro()" class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip" style="z-index: 1;">
                        <span v-if="!exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_down</span>
                        <span v-if="exibirFiltro" class="material-icons icon icone-filtro ponteiro">arrow_drop_up</span>
                        <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
                    </div>
                    <h4>{{$t('sols.filtro')}}</h4>
                    <div v-on:click="limparFiltro()" class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div id="filter_body" class="card-body" v-show="exibirFiltro">
                    <div class="basic-form">
                        <div class="form-row">
                            <!-- Número do Equipamento -->
                            <div class="form-group col-md-auto">
                                <label>#</label>
                                <input v-model="filtro.idequipamento" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Descricao do equipamento -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('equipamento.descricao')}}</label>
                                <input v-model="filtro.descricao" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Marca do equipamento -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('equipamento.marca')}}</label>
                                <input v-model="filtro.marca" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Modelo do equipamento -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('equipamento.modelo')}}</label>
                                <input v-model="filtro.modelo" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Série do equipamento -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('equipamento.serie')}}</label>
                                <input v-model="filtro.serie" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Tombamento do equipamento -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('equipamento.tombamento')}}</label>
                                <input v-model="filtro.tombamento" 
                                    type="text" class="form-control">
                            </div>
                            <!-- Status -->
                            <div class="form-group col-md-auto">
                                <label>{{$t('sols.status')}}</label>
                                <select class="form-control" v-model="filtro.ativo">
                                    <option :value="null">{{$t('global.todos')}}</option>
                                    <option :value="true">{{$t('global.ativo')}}</option>
                                    <option :value="false">{{$t('global.inativo')}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row justify-content-end">
                            <div v-on:click="carregarEquipamentos()" 
                                class="ponteiro text-white btn btn-primary rounded btn-sm px-4 my-4">
                                <span>{{$t('sols.filtro')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('equipamento.tituloLista')}}</h4>
                    <a @click="criarEquipamento()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('global.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="row" id="criarLocDiv" style="display: none;">
                        <EquipamentoEditar 
                            :equipamento="novoEquipamento"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                            :fornecedores="fornecedores"
                            :locaisAtendimento="locaisAtendimento"
                        />
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>#</strong></th>
                                    <th><strong>{{$t('equipamento.descricao')}}</strong></th>
                                    <th><strong>{{$t('equipamento.marca')}}</strong></th>
                                    <th><strong>{{$t('equipamento.modelo')}}</strong></th>
                                    <th><strong>{{$t('equipamento.serie')}}</strong></th>
                                    <th><strong>{{$t('equipamento.tombamento')}}</strong></th>
                                    <th><strong>{{$t('equipamento.status')}}</strong></th>
                                    <th><strong>{{$t('equipamento.acao')}}</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in equipamentos" :key="item.idequipamento">
                                <tr>
                                    <td><strong>{{item.idequipamento}}</strong></td>
                                    <td><strong>{{item.descricao}}</strong></td>
                                    <td><strong>{{item.marca}}</strong></td>
                                    <td><strong>{{item.modelo}}</strong></td>
                                    <td><strong>{{item.serie}}</strong></td>
                                    <td><strong>{{item.tombamento}}</strong></td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">{{$t('global.inativar')}}</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">{{$t('global.ativar')}}</a>
                                                <a class="dropdown-item" @click="editar(item)">{{$t('global.editar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <div :id="'Editar_'+item.idequipamento" style="display: none;">
                                            <EquipamentoEditar
                                                :equipamento="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                                :fornecedores="fornecedores"
                                                :locaisAtendimento="locaisAtendimento"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Paginação -->
                    <pagination :page="page" :pageCount="pageCount" :recordCount="recordCount"
                        @newpage="evt => {page = evt}" :defaultPageOptionIndex="0" 
                        @update="evt => {updatePage(evt)}"></pagination>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import EquipamentoEditar from './EquipamentoEditar.vue'
import Pagination from './Pagination.vue'

export default {
    components: {EquipamentoEditar,Pagination},
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            equipamentos: [],
            novoEquipamento: {
                idequipamento: 0,
                descricao: '',
                marca: '',
                modelo: '',
                serie: '',
                tombamento: '',
                ativo: false
            },
            filtro: {
                idequipamento: '',
                descricao: '',
                marca: '',
                modelo: '',
                serie: '',
                tombamento: '',
                ativo: null
            },
            exibirFiltro: false,
            page: 1,
            pageSize: 6,
            pageCount: 1,
            recordCount: 0,
            fornecedores: [],
            locaisAtendimento: []
        }
    },
    methods: {
        loadLocaisAtendimento() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/localatendimento/select',
                    params, {headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.locaisAtendimento = response.data.dados
                    this.locaisAtendimento.push({idlocalatendimento: null, descricao: '<não atribuído>'})
                }
            })
        },
        loadFornecedores(){
			axios.post(CONSTANTS.API_URL + '/fdguest/parceiro/select', {
				idioma: this.$i18n.locale,
                manutencao: true
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.fornecedores = response.data;
						}
					} else {
                        console.log('Erro:'+ JSON.stringify(response));
                    }
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        updatePage(evt){
            this.page = evt.page
            this.pageSize = evt.pageSize
            this.carregarEquipamentos();
        },
        exibirOcultarFiltro(){
            var el = document.getElementById('filter_body')
            if (el) {
                if (el.style.display == 'none') {
                    el.style.display = 'block'
                    this.exibirFiltro = true
                } else {
                    el.style.display = 'none'
                    this.exibirFiltro = false
                }
            }
        },
        limparFiltro() {
            this.page = 1
            this.pageSize = 6
            this.pageCount = 1
            this.recordCount = 0
            this.equipamentos = []
            this.filtro = {
                idequipamento: '',
                descricao: '',
                marca: '',
                modelo: '',
                serie: '',
                tombamento: '',
                ativo: null
            }
            window.setTimeout(this.carregarEquipamentos(), 1000);
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idequipamento: item.idequipamento,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarEquipamento() {
            var obj = document.getElementById('criarLocDiv')
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeNew() {
            var obj = document.getElementById('criarLocDiv')
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveNew(item) {
            var params = {
                descricao: item.descricao,
                marca: item.marca,
                modelo: item.modelo,
                serie: item.serie,
                tombamento: item.tombamento,
                localdeuso: item.localdeuso,
                nfcompra: item.nfcompra,
                datacompra: item.datacompra,
                valorcompra: item.valorcompra,
                fornecedor: item.fornecedor,
                garantiaemdias: item.garantiaemdias,
                ativo: false,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/equipamento/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.carregarEquipamentos()
                    this.closeNew()
                    this.$snack('Equipamento cadastrado.')
                } else {
                    this.$snackW(`Erro ao cadastrar equipamento. response.status: ${response.status}`)
                }
            })
            .catch((e)=>{
                this.$snackE(`Erro ao cadastrar equipamento: ${e.message}`)
            })
        },
        editar(item) {
            var obj = document.getElementById('Editar_'+item.idequipamento)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            var obj = document.getElementById('Editar_'+item.idequipamento)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        saveEdit(item) {
            var params = {
                idequipamento: item.idequipamento,
                descricao: item.descricao,
                marca: item.marca,
                modelo: item.modelo,
                serie: item.serie,
                tombamento: item.tombamento,
                localdeuso: item.localdeuso,
                nfcompra: item.nfcompra,
                datacompra: item.datacompra,
                valorcompra: item.valorcompra,
                fornecedor: item.fornecedor,
                garantiaemdias: item.garantiaemdias,
                ativo: item.ativo,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/equipamento/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.$snack('Equipamento salvo.')
                    this.carregarEquipamentos()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        carregarEquipamentos() {
           var  params = {
                "idequipamento": this.filtro.idequipamento,
                "descricao": this.filtro.descricao,
                "marca": this.filtro.marca,
                "modelo": this.filtro.modelo,
                "serie": this.filtro.serie,
                "tombamento": this.filtro.tombamento,
                "ativo": this.filtro.ativo,
                "page": this.page,
                "pagesize": this.pageSize
            }
            //console.log(params)
            axios.post(CONSTANTS.API_URL + '/fdguest/equipamento/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.equipamentos = response.data.dados;
                    this.recordCount = response.data.recordCount;
                    this.pageCount =  response.data.pageCount?response.data.pageCount:1;
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.carregarEquipamentos();
        this.loadFornecedores();
        this.loadLocaisAtendimento();
    }
}
</script>

