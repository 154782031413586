<template>
  <div class="modal" :class="{ 'is-active': exibirModal }">
    <div class="modal-background" @click="fecharModal"></div>
    <div class="modal-content">
      <div class="box text-center my-2">
        <img
          v-if="imgSrc&&imgSrc.length > 0"
          :src="`${imgTipo}${imgSrc}`"
          id="imagem_modal_es"
          width="90%"
          height="auto"
        />
      </div>
    </div>
    <button class="modal-close is-large ponteiro" aria-label="close" @click="fecharModal">X</button>
  </div>
</template>

<script>
export default {
  name: 'ModalESImagem',
  components: {
  },
  props: {
    valor:{
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgTipo: {
      type: String,
      require: false,
      default: 'data:image/png;base64,'
    },
  },
  methods: {
    fecharModal() {
      this.$emit('fechar');
    }
  },
  watch: {
    valor(val) {
      this.exibirModal = val;
    }
  },
  data() {
    return {
      exibirModal: false
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.is-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-content {
  width: fit-content;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-close:hover {
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }
}

.botoes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

</style>