<template>
  <div>
    <div class="form-row pt-3">
      <div class="form-group col-md-auto">
        <label for="job-type">Tipo de Tarefa:</label>
        <select
          class="form-control"
          id="job-type"
          v-model="chamado.tipotarefa"
        >
          <option value="recorrente" :selected="chamado.tipotarefa == 'recorrente'">Execução periódica</option>
          <option value="unica" :selected="chamado.tipotarefa == 'unica'">Execução única</option>
        </select>
      </div>
      <div v-if="chamado.tipotarefa === 'recorrente'" class="form-group col-md-auto">
        <label for="frequencia">Frequência de Execução:</label>
        <select
          class="form-control"
          id="frequencia"
          v-model="chamado.frequenciatarefa"
          @change="restringirDia()"
        >
          <option
            v-for="(freq, index) in frequencias"
            :key="index"
            :value="freq.nome"
          >
            {{ freq.descricao }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-auto">
        <label for="start-date">Data e Hora de Início:</label>
        <input
          class="form-control"
          type="datetime-local"
          id="start-date"
          v-model="chamado.datainicio"
          @change="restringirDia()"
        />
      </div>
      <div v-if="chamado.tipotarefa === 'recorrentex'" class="form-group col-md-auto">
        <label for="end-date">Data e Hora de Término:</label>
        <input
          class="form-control"
          type="datetime-local"
          id="end-date"
          v-model="chamado.datafim"
        />
      </div>
      <div v-if="chamado.tipotarefa === 'recorrente'" class="form-group col-md-12 py-2">
        <label for="diaSemanaEscolhidos"
          >Dias da Semana em que a tarefa deve ser executada:</label
        >
        <div id="diaSemanaEscolhidos" class="row py-3">
          <div v-for="dia in chamado.diasemana" :key="dia.valor" class="col-auto">
            <label class="pr-2" :for="`cb_${dia.valor}`"> {{ dia.nome }}</label>
            <input :disabled="ehSemanal"
              multiple
              :id="`cb_${dia.valor}`"
              class="ponteiro"
              type="checkbox"
              v-model="dia.selected"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import dayjs from 'dayjs'

export default {
  name: "ChamadoProgramacao",
  props: {
    chamado: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      frequencias: [
        { nome: "mensal", descricao: "Mensal" },
        { nome: "bimestral", descricao: "Bimestral" },
        { nome: "trimestral", descricao: "Trimestral" },
        { nome: "semestral", descricao: "Semestral" },
        { nome: "semanal", descricao: "Semanal" },
        { nome: "quinzenal", descricao: "Quinzenal" },
        { nome: "anual", descricao: "Anual" },
        { nome: "diaria", descricao: "Diária" },
      ],
      // diaSemana: [
      //   { nome: "Domingo", valor: 0, selected: true },
      //   { nome: "Segunda", valor: 1, selected: true },
      //   { nome: "Terça", valor: 2, selected: true },
      //   { nome: "Quarta", valor: 3, selected: true },
      //   { nome: "Quinta", valor: 4, selected: true },
      //   { nome: "Sexta", valor: 5, selected: true },
      //   { nome: "Sábado", valor: 6, selected: true },
      // ],
      diasSelecionados: [],
    };
  },
  computed: {
    ehSemanal() {
      return this.chamado.frequenciatarefa === 'semanal'
    }
  },
  methods: {
    restringirDia() {
      if (this.chamado.frequenciatarefa === 'semanal') {
        let dataInicioSemanal = new Date(this.chamado.datainicio);
        let diaSemana = dataInicioSemanal.getDay();
        for(var i = 0; i < this.chamado.diasemana.length; i++) {
          let dia = this.chamado.diasemana[i];
          if (dia.valor == diaSemana) {
            this.chamado.diasemana[i].selected = true;
          } else {
            this.chamado.diasemana[i].selected = false;
          }
        }
        this.$forceUpdate()
      }
    }
  },
  mounted() {
    if (process.env.NODE_ENV == 'development') {
      this.frequencias.push({ nome: "hora", descricao: "Hora" })
      this.frequencias.push({ nome: "minuto", descricao: "Minuto" })
    }
  }
};
</script>