<template>
    <div class="row ml-3">
        <div class="col-sm-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-sm-5">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="itemEditado.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="itemEditado.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div v-if="!interna" class="form-group col-sm-7">
                                <label>Tipo:</label>
                                <select class="form-control" v-model="itemEditado.tipo"
                                    @change="checkDeps(itemEditado)"
                                >
                                    <template v-if="tiposItem && tiposItem.length > 0">
                                        <option v-for="tipoItem in tiposItem" v-bind:key="tipoItem.tipo"
                                            :value="tipoItem.tipo"
                                            :selected="itemEditado.tipo==tipoItem.tipo"
                                        >{{tipoItem.descricao}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div :class="interna?'form-group col-sm-7':'form-group col-sm-12'">
                                <label>{{interna?$t('global.descricao'):$t('global.titulo')}}:</label>
                                <input v-model="itemEditado.titulo" 
                                    type="text" class="form-control" placeholder="Título do item">
                            </div>
                            <div v-if="!interna" class="form-group col-sm-12">
                                <label>Descrição:</label>
                                <input v-model="itemEditado.descricao" 
                                    type="text" class="form-control" placeholder="Descrição do item">
                            </div>
                            <div v-if="!interna" class="form-group col-sm-12">
                                <label>Informação de Alerta:</label>
                                <input v-model="itemEditado.infoalerta" 
                                    type="text" class="form-control" placeholder="Informação de alerta para o ítem">
                            </div>
                            <div v-if="(itemEditado.tipo == 1 || itemEditado.tipo == 3)&&!interna" class="form-group col-sm-6">
                                <label>Valor padrão:</label>
                                <input v-model="itemEditado.valordefault" 
                                    type="text" class="form-control" placeholder="Valor padrão para o ítem">
                            </div>
                            <div v-if="!interna" class="form-group col-sm-6">
                                <label>Incluir chave para ativar o ítem:</label>
                                <select class="form-control" v-model="itemEditado.chaveativadora"
                                    :disabled="itemEditado.tipo==1">
                                    <option :value="true" :selected="itemEditado.chaveativadora"
                                    >Sim</option>
                                    <option :value="false" :selected="!itemEditado.chaveativadora"
                                    >Não</option>
                                </select>
                            </div>
                            <div v-if="!interna" class="form-group col-sm-6">
                                <label>Incluir informação adicional para o ítem:</label>
                                <select class="form-control" v-model="itemEditado.infoadicional">
                                    <option :value="true" :selected="itemEditado.infoadicional"
                                    >Sim</option>
                                    <option :value="false" :selected="!itemEditado.infoadicional"
                                    >Não</option>
                                </select>
                            </div>
                            <div v-if="itemEditado.tipo != 3 && !interna" class="form-group col-sm-6">
                                <label>Incluir período horário para o atendimento:</label>
                                <select class="form-control" v-model="itemEditado.horarioatendimento">
                                    <option :value="true" :selected="itemEditado.horarioatendimento"
                                    >Sim</option>
                                    <option :value="false" :selected="!itemEditado.horarioatendimento"
                                    >Não</option>
                                </select>
                            </div>
                            <div v-if="itemEditado.horarioatendimento && !interna" class="form-group col-sm-12">
                                <label>Horário de Disponibilidade:</label>
                                <time-picker :horarios="itemEditado.horariodisp" 
                                    v-on:update="updateHorario($event)"></time-picker>
                            </div>
                            <template v-if="itemEditado.tipo == 3 && !interna" >
                                <div class="form-group col-sm-6">
                                    <label>Incluir a solicitação na agenda:</label>
                                    <select class="form-control" v-model="itemEditado.incluirnaagenda">
                                        <option :value="true" :selected="itemEditado.incluirnaagenda"
                                        >Sim</option>
                                        <option :value="false" :selected="!itemEditado.incluirnaagenda"
                                        >Não</option>
                                    </select>
                                </div>
                                <!-- <div class="form-group col-sm-6">
                                    <label>Avisar com antecedência (em minutos):</label>
                                    <input v-model="itemEditado.antecedencia" 
                                        type="text" class="form-control" placeholder="Tempo de antecedência para o aviso."
                                    >
                                </div> -->
                            </template>
                        </div>
                        <div class="form-row">
                            <div v-if="itemEditado.tipo == 6" class="form-group col-sm-12">
                                <label>Ative ou desative os produtos:</label>
                                <produtos-grupos :grupos="gruposprodutos"></produtos-grupos>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-auto">
                                <a v-on:click="salvar(itemEditado)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                        <div v-if="alerta" 
                            style="text-align:center;font-size:1.3rem;color:orange;">
                            {{alerta}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import TimePicker from './TimePicker.vue'
import ProdutosGrupos from './ProdutosGrupos.vue'
export default {
    components: {
        TimePicker, ProdutosGrupos
    },
    props: {
        tipoSolicitacaoItem: {
            type: Object,
            required: true,
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        }
    },    
    data() {
        return {
            idiomas: [],
            tiposItem: [
                {tipo: 1, descricao: 'Quantidade (só clicar na quantidade e no botão)'},
                {tipo: 2, descricao: 'Simples (só clicar no botão)'},
                {tipo: 3, descricao: 'Horário (só escolher o horário e clicar no botão)'},
                //{tipo: 4, descricao: 'Período de Tempo (só escolher horário inicial e final e clicar no botão)'},
                //{tipo: 5, descricao: 'Período de Tempo Dual (dois tipos de solicitação no mesmo quadro)'},
                {tipo: 6, descricao: 'Produtos (com assinatura)'}
            ],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            itemEditado: {},
            gruposprodutos: [],
            alerta : null
        }
    },
    methods: {
        validarCampos(item){

            
            var self_ = this
            setTimeout(() => {
               self_.alerta = null
            }, 3000);

            if(!item.titulo){
                this.alerta = "Titulo é um Campo Obrigatorio!"
                return false
            }


            return true
        },
        salvar(itemEditado){

            if(this.validarCampos(itemEditado)){
                this.$emit('save',itemEditado)
            }
        },
        checkDeps(item) {
            if (item.tipo == 3) {
                this.itemEditado.horarioatendimento = false
            }
        },
		carregarGruposProdutos(iditem) {
			var params = {
                idhotel :  this.$store.getters.GetLoginInfo.idhotel,
                iditem: iditem,
				incluirprodutos: true,
                legado: false
			}
            axios.post(CONSTANTS.API_URL + '/fdguest/produto/grupo/select',
            params, {headers: this.headers}).then(response => {
                if(response.status == 200) {
					this.gruposprodutos = response.data
                    this.itemEditado.gruposprodutos = response.data
                }
            })
		},
        updateHorario(evt) {
            this.itemEditado.horariodisp = evt
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.itemEditado = {... this.tipoSolicitacaoItem}
        if (this.itemEditado.idtiposolicitacaoitem) {
            this.carregarGruposProdutos(this.itemEditado.idtiposolicitacaoitem)
        }
    },
    watch: {
        tipoSolicitacaoItem(val) {
            this.itemEditado = {... val}
        }
    }
}
</script>

<style>

</style>