<template>
    <div class="card">
        <div v-if="title" class="card-header">
            <h4>{{title}}</h4>
        </div>
        <div class="card-body">
            <div class="basic-form">
                <form>
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label>Horário inicial</label>
                            <input v-model="horarioInicial" type="time" class="form-control" placeholder="">
                        </div>
                        <div class="form-group col-md-3">
                            <label>Horário final</label>
                            <input v-model="horarioFinal" type="time" class="form-control">
                        </div>
                        <div class="form-group col-md-2 card-header mx-3">
                            <button id="btnTimeAdd" @click="adicionarHorario()" 
                                type="button" class="btn btn-primary rounded" 
                                :disabled="!validTimes"
                            >
                                Adicionar
                            </button>
                        </div>
                        <div class="form-group col-md-2 card-header mx-3">
                            <button id="btnTimeClear" @click="limparHorarios()" 
                                type="button" class="btn btn-success rounded" 
                            >
                                Limpar
                            </button>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Horários</label>
                            <input v-model="horarios" type="text" readonly class="form-control">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // props: ['title','horarios'],
    props: {
        index: {
            type: Number,
            required: false,
            default: 0
        },
        title: {
            type: String,
            required: false
        },
        horarios: {
            type: String,
            required: false
        },
        horainicial: {
            type: String,
            required: false,
            default: '12:00'
        },
        horafinal: {
            type: String,
            required: false,
            default: '14:00'
        }
    },
    data() {
        return{
            horarioInicial: this.horainicial,
            horarioFinal: this.horafinal,
        }
    },
	computed: {
		validTimes() {
			var aStr = this.horarioInicial.replace(/[:]/g, '')
			var a = parseInt(aStr)
			var bStr = this.horarioFinal.replace(/[:]/g, '')
			var b = parseInt(bStr)
			return (a <= b);
		}
	},
    methods: {
        adicionarHorario() {
            var timeStr = this.horarioInicial + '-' + this.horarioFinal + '; '
            if (!this.horarios.includes(timeStr)) {
                this.$emit('update', this.horarios + timeStr)
            }
        },
        limparHorarios() {
            this.$emit('update', '')
        }
    }
}
</script>

<style>
 .center-vertically {
    text-align: center!important;
 }

</style>