<template>
   <div id="preloader" style="z-index: 1000;">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
        var s = document.getElementById('preloader').style;
        s.opacity = 1;
        (function fade(){(s.opacity-=.05)<0?s.display="none":setTimeout(fade,70)})();
    }
}
</script>

<style>

</style>