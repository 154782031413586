<template>
    <div>
        <div class="row mb-2">
            <ul class="nav nav-tabs">
                <li v-for="dia in dias" :key="dia.valor" class="nav-item ponteiro">
                    <a class="nav-link" :class="{'active': diaSelecionado==dia.valor}" @click="diaSelecionado=dia.valor">{{dia.nome}}</a>
                </li>
            </ul>
        </div>

        <div v-for="dia in dias" :key="dia.valor" class="form-row">
            <div v-if="diaSelecionado == dia.valor" class="form-group col-md-12">
                <time-picker :id="`time-picker-${dia.valor}`" :title="`Horários de ${dia.nome}:`"
                    :horarios="escalaAtual"
                    :horainicial="'08:00'" :horafinal="'18:00'"
                    v-on:update="updateHorarios(dia.valor,$event)"
                ></time-picker>
            </div>
        </div>
    </div>
</template>

<script>
import TimePicker from './TimePicker.vue';
export default {
  props: ['escalas'],
  components: {TimePicker},
  data() {
    return {
      usuarioNaEscala: null,
      diaSelecionado: 1,
      escala: '',
      dias: [
        { valor: 1, nome: 'Segunda' },
        { valor: 2, nome: 'Terça' },
        { valor: 3, nome: 'Quarta' },
        { valor: 4, nome: 'Quinta' },
        { valor: 5, nome: 'Sexta' },
        { valor: 6, nome: 'Sábado' },
        { valor: 0, nome: 'Domingo' }
      ]
    }
  },
  computed: {
    escalaAtual() {
        if (this.escalas && this.escalas.length > 0) {
            let escalaEncontrada = this.escalas?.find((x)=>x.dia == this.diaSelecionado)
            if (escalaEncontrada) {
                return escalaEncontrada.horarios;
            }
        }
        return ''
    },
  },
  created() {
  },
  methods: {
    updateHorarios(dia,horarios) {
        this.$emit('update', {dia: dia, horarios: horarios});
    }
  }
}
</script>