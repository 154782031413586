import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store (
    {
        plugins: [createPersistedState({
            storage: window.sessionStorage,
        })],
        state: {
            componentName: '',
            currentItem: null,
            itens: [],
            totalSols: 0,
            solsPendentes: [],
            solsTipo1: [],
            solsTipo2: [],
            solsTipo3: [],
            solsTipo4: [],
            solsTipo5: [],
            solsTipo6: [],
            solsTipo7: [],
            solsTipo8: [],
            solsTipo9: [],
            solsTipo10: [],
            solsTipo11: [],
            solsTipo12: [],
            solsTipo13: [],
            solsEmAndamento: [],
            solsAtendidas: [],
            solAtual: null,
            suportedLanguages: [],
            loginInfo: null,
            notaAtual: null,
            notificacoes: [],
            currentProduto:null,
            ultimasTransacoes: [],
            primeirosItens: [],
            demaisItens: [],
            rootItem: null,
            pageLoaded: false,
            editStatus: false,
            editItem: [],
            tokenAssinatura: ''
        },
        mutations: {
           setEditStatus(state, payload) {
                state.editStatus = payload
            },
            setEditItem(state, payload) {
                state.editItem = payload
            },
            setComponentName(state, payload) {
                state.componentName = payload
            },
            setCurrentItem(state, payload) {
                state.currentItem = payload
            },
            setCurrentProduto(state, payload) {
                state.currentProduto = payload
            },
            setItens(state, payload) {
                state.itens = payload
            },
            setTotalSols(state, payload) {
                state.totalSols = payload
            },
            setSolsTipo1(state, payload) {
                state.solsTipo1 = payload
            },
            setSolsTipo2(state, payload) {
                state.solsTipo2 = payload
            },
            setSolsTipo3(state, payload) {
                state.solsTipo3 = payload
            },
            setSolsTipo4(state, payload) {
                state.solsTipo4 = payload
            },
            setSolsTipo5(state, payload) {
                state.solsTipo5 = payload
            },
            setSolsTipo6(state, payload) {
                state.solsTipo6 = payload
            },
            setSolsTipo7(state, payload) {
                state.solsTipo7 = payload
            },
            setSolsTipo8(state, payload) {
                state.solsTipo8 = payload
            },
            setSolsTipo9(state, payload) {
                state.solsTipo9 = payload
            },
            setSolsTipo10(state, payload) {
                state.solsTipo10 = payload
            },
            setSolsTipo11(state, payload) {
                state.solsTipo11 = payload
            },
            setSolsTipo12(state, payload) {
                state.solsTipo12 = payload
            },
            setSolsTipo13(state, payload) {
                state.solsTipo13 = payload
            },
            setSolsEmAndamento(state, payload) {
                state.solsEmAndamento = payload
            },
            setSolAtual(state, payload) {
                state.solAtual = payload
            },
            setSolsAtendidas(state, payload) {
                state.solsAtendidas = payload
            },
            setSuportedLanguages(state, payload) {
                state.suportedLanguages = payload
            },
            setLoginInfo(state, payload) {
                state.loginInfo = payload
            },
            setNotaAtual(state, payload) {
                state.notaAtual = payload
            },
            setNotificacoes(state, payload) {
                state.notificacoes = payload
            },
            setUltimasTransacoes(state, payload) {
                state.ultimasTransacoes = payload
            },
            setPrimeirosItens(state, payload) {
                state.primeirosItens = payload
            },
            setDemaisItens(state, payload) {
                state.demaisItens = payload
            },
            setRootItem(state, payload) {
                state.rootItem = payload
            },
            setPageLoaded(state, payload) {
                state.pageLoaded = payload
            },
            settokenAssinatura(state, payload) {
                state.tokenAssinatura = payload
            }
        },
        actions: {
            set_Edit_Status({commit}, payload ) {
                commit('setEditStatus', payload)
            },
            set_Edit_Item({commit}, payload ) {
                commit('setEditItem', payload)
            },
            set_Component_Name({commit}, payload ) {
                commit('setComponentName', payload)
            },
            set_Current_Item({commit}, payload ) {
                commit('setCurrentItem', payload)
            },
            set_Current_produto({commit}, payload ) {
                commit('setCurrentProduto', payload)
            },
            set_Itens({commit}, payload ) {
                commit('setItens', payload)
            },
            set_Total_Sols({commit}, payload ) {
                commit('setTotalSols', payload)
            },
            set_Sols_Pendentes({commit}, payload ) {
                commit('setSolsPendentes', payload)
            },
            set_Sols_Tipo1({commit}, payload ) {
                commit('setSolsTipo1', payload)
            },
            set_Sols_Tipo2({commit}, payload ) {
                commit('setSolsTipo2', payload)
            },
            set_Sols_Tipo3({commit}, payload ) {
                commit('setSolsTipo3', payload)
            },
            set_Sols_Tipo4({commit}, payload ) {
                commit('setSolsTipo4', payload)
            },
            set_Sols_Tipo5({commit}, payload ) {
                commit('setSolsTipo5', payload)
            },
            set_Sols_Tipo6({commit}, payload ) {
                commit('setSolsTipo6', payload)
            },
            set_Sols_Tipo7({commit}, payload ) {
                commit('setSolsTipo7', payload)
            },
            set_Sols_Tipo8({commit}, payload ) {
                commit('setSolsTipo8', payload)
            },
            set_Sols_Tipo9({commit}, payload ) {
                commit('setSolsTipo9', payload)
            },
            set_Sols_Tipo10({commit}, payload ) {
                commit('setSolsTipo10', payload)
            },
            set_Sols_Tipo11({commit}, payload ) {
                commit('setSolsTipo11', payload)
            },
            set_Sols_Tipo12({commit}, payload ) {
                commit('setSolsTipo12', payload)
            },
            set_Sols_Tipo13({commit}, payload ) {
                commit('setSolsTipo13', payload)
            },
            set_Sols_Em_Andamento({commit}, payload ) {
                commit('setSolsEmAndamento', payload)
            },
            set_Sol_Atual({commit}, payload ) {
                commit('setSolAtual', payload)
            },
            set_Sols_Atendidas({commit}, payload ) {
                commit('setSolsAtendidas', payload)
            },
            set_Suported_Languages({commit}, payload ) {
                commit('setSuportedLanguages', payload)
            },
            set_Login_Info({commit}, payload ) {
                commit('setLoginInfo', payload)
            },
            set_Nota_Atual({commit}, payload ) {
                commit('setNotaAtual', payload)
            },
            set_Notificacoes({commit}, payload ) {
                commit('setNotificacoes', payload)
            },
            set_Ultimas_Transacoes({commit}, payload ) {
                commit('setUltimasTransacoes', payload)
            },
            set_Primeiros_Itens({commit}, payload ) {
                commit('setPrimeirosItens', payload)
            },
            set_Demais_Itens({commit}, payload ) {
                commit('setDemaisItens', payload)
            },
            set_Root_Item({commit}, payload ) {
                commit('setRootItem', payload)
            },
            set_Page_Loaded({commit}, payload ) {
                commit('setPageLoaded', payload)
            },
            set_Token_Assinatura({commit}, payload ) {
                commit('setTokenAssinatura', payload)
            }
        },
        getters: {
            GetEditStatus(state) {
                return state.editStatus
            },
            GetEditItem(state) {
                return state.editItem
            },
            GetComponentName(state) {
                return state.componentName
            },
            GetCurrentItem(state) {
                return state.currentItem
            },
            GetCurrentProduto(state) {
                return state.currentProduto
            },
            GetItens(state) {
                return state.itens
            },
            GetTotalSols(state) {
                return state.totalSols
            },
            GetSolsPendentes(state) {
                return state.solsPendentes
            },
            GetSolsTipo1(state) {
                return state.solsTipo1
            },
            GetSolsTipo2(state) {
                return state.solsTipo2
            },
            GetSolsTipo3(state) {
                return state.solsTipo3
            },
            GetSolsTipo4(state) {
                return state.solsTipo4
            },
            GetSolsTipo5(state) {
                return state.solsTipo5
            },
            GetSolsTipo6(state) {
                return state.solsTipo6
            },
            GetSolsTipo7(state) {
                return state.solsTipo7
            },
            GetSolsTipo8(state) {
                return state.solsTipo8
            },
            GetSolsTipo9(state) {
                return state.solsTipo9
            },
            GetSolsTipo10(state) {
                return state.solsTipo10
            },
            GetSolsTipo11(state) {
                return state.solsTipo11
            },
            GetSolsTipo12(state) {
                return state.solsTipo12
            },
            GetSolsTipo13(state) {
                return state.solsTipo13
            },
            GetSolsEmAndamento(state) {
                return state.solsEmAndamento
            },
            GetSolAtual(state) {
                return state.solAtual
            },
            GetSolsAtendidas(state) {
                return state.solsAtendidas
            },
            GetDefaultLanguage(state) {
                return state.defaultLanguage
            },
            GetSuportedLanguages(state) {
                return state.suportedLanguages
            },
            GetLoginInfo(state) {
                return state.loginInfo
            },
            GetNotaAtual(state) {
                return state.notaAtual
            },
            GetNotificacoes(state) {
                return state.notificacoes
            },
            GetUltimasTransacoes(state) {
                return state.ultimasTransacoes
            },
            GetPrimeirosItens(state) {
                return state.primeirosItens
            },
            GetDemaisItens(state) {
                return state.demaisItens
            },
            GetRootItem(state) {
                return state.rootItem
            },
            GetPageLoaded(state) {
                return state.pageLoaded
            },
            GetTokenAssinatura(state) {
                return state.tokenAssinatura
            }
        }
    }
)
