import { render, staticRenderFns } from "./SolicitacaoConcluirChamados.vue?vue&type=template&id=ceddaccc&scoped=true&"
import script from "./SolicitacaoConcluirChamados.vue?vue&type=script&lang=js&"
export * from "./SolicitacaoConcluirChamados.vue?vue&type=script&lang=js&"
import style0 from "./SolicitacaoConcluirChamados.vue?vue&type=style&index=0&id=ceddaccc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceddaccc",
  null
  
)

export default component.exports