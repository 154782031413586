<template>
    <!-- Cadastro de Usuário -->
    <div class="col-xl-12 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{$t('vincularTecnicos.categoriaseitens')}}</h4>
                <!-- <span class="material-icons icon es-link-icon">{{vincularSelecao?'link':'link_off'}}</span>
                <div class="es-link-text" :class="vincularSelecao?'es-link-on-off':'es-link-on-off on'"
                    @click="vincularSelecao=!vincularSelecao">
                    {{vincularSelecao?'Desvincular':'Vincular'}} seleção
                </div> -->
            </div>
            <div class="card-body">
                <div :id="'accordion-usr-' + usuario.idusuario" class="accordion accordion-primary">
                    
                        <div class="card" v-for="categoria in usuario.tipos" :key="categoria.idtiposolicitacao">
                            <div class="card-header">
                                <div style="position:relative;top: -0.5em;left: 1.7em;"
                                    class="custom-control custom-switch row pt-3">
                                    <input type="checkbox" name="language" class="ponteiro custom-control-input ponteiro" 
                                        :id="`switch-usr-${usuario.idusuario}-cat-${categoria.idtiposolicitacao}`"
                                        v-model="categoria.selected"
                                        @change="toggleItens(categoria)"
                                    >
                                    <label class="ponteiro custom-control-label es-cat-label" 
                                        :class="categoria.selected?'es-cat-label-on':''"
                                        :for="`switch-usr-${usuario.idusuario}-cat-${categoria.idtiposolicitacao}`">{{categoria.titulo}}</label>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="es-item-group">
                                    <div class="es-item" v-for="item in categoria.itens" :key="item.idsolicitacaoitem">
                                        <div style="margin-left: 1.2em;" 
                                            class="custom-control custom-switch py-2">
                                            <input type="checkbox" name="language" class="ponteiro custom-control-input ponteiro" 
                                                :id="`switch-usr-${usuario.idusuario}itm-${item.idtiposolicitacaoitem}`"
                                                v-model="item.selected"
                                                @change="toggleCat(categoria)"
                                            >
                                            <label class="ponteiro custom-control-label es-label" 
                                                :class="item.selected?'es-label-on':''"
                                                :for="`switch-usr-${usuario.idusuario}itm-${item.idtiposolicitacaoitem}`">
                                                    {{item.titulo}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <!-- Mensagem de alerta -->
                    <div class="form-row">
                        <p :id="'alert-msg-' + usuario.idusuario" style="color:orange;display:none;">{{alert}}</p>
                    </div>                                        
					
					<!-- Salvar e Fechar -->
                    <div class="form-row">
                        <div class="form-group col-sm-auto">
                            <!-- <a v-on:click="$emit('save', usuario)" class="ponteiro text-white btn btn-info mt-4">Salvar</a> -->
                            <a v-on:click="salvar()" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                        </div>
                        <div class="form-group col-sm-auto">
                            <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        usuario: {
            type: Object,
            required: true,
        },
        alert: {
            type: String,
            required: true
        }
    }, 
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            idiomas: [],
            //usuarioEditado: {},
            vincularSelecao: true
        }
    },
    methods: {
        toggleCat(cat){
            if (this.vincularSelecao) {
                if (cat.itens) {
                    let result = true
                    for (var i = 0; i < cat.itens.length; i++) {
                        let item = cat.itens[i]
                        if (!item.selected) {
                            result = false
                            break
                        }
                    }
                    cat.selected = result
                }
            }
        },
        toggleItens(cat) {
            if (this.vincularSelecao) {
                if (cat.itens) {
                    for (var i = 0; i < cat.itens.length; i++) {
                        let item = cat.itens[i]
                        item.selected = cat.selected
                    }
                }
            }
            this.$forceUpdate()
        },
        salvar() {
            //this.$emit('save', this.usuarioEditado)
            this.$emit('save')
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {

    },
    beforeMount() {
        // Listas de valores
        this.loadIdiomas();
    },
    mounted() {
        // Usuário
        //this.usuarioEditado = Object.assign({}, this.usuario) 
    }
}
</script>

<style scoped>
.azure {
    background-color: azure!important;
}
.es-item-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 1.0em;
}
.es-item{
    align-self: center;
}
.es-link-on-off {
    display: flex;
    align-content: center;
    font-size: 1.2em;
    padding: 0.5em;
    cursor: pointer;
    color: gray;
    background-color: azure;
    border-radius: 0.3em;
}
.on {
    color: rgb(1, 58, 16);
}
.es-link-icon {
    color: lightgreen;
    font-size: 2em;
}
.es-link-text {
    padding: 0.1rem;
    align-self:center;
}
.es-link-on-off:active {
    color: green;
}
.es-label {
    font-size: 1.2em;
    color: rgb(44, 48, 44)!important;
}
.es-label-on {
    color: green!important;
}
.es-cat-label {
    font-size: 1.4em;
    color: rgb(44, 48, 44)!important;
    font-weight: bold;
}
.es-cat-label-on {
    font-size: 1.4em;
    color: green!important;
    font-weight: bold;
}
</style>