<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card azure">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="promocaoHotelEditada.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="promocaoHotelEditada.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Título:</label>
                                <input v-model="promocaoHotelEditada.titulo" 
                                    type="text" class="form-control" placeholder="Dê um título à promoção">
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Subtítulo:</label>
                                <input v-model="promocaoHotelEditada.subtitulo" 
                                    type="text" class="form-control" placeholder="Dê um subtítulo à promoção">
                            </div>
                        </div>
                        <div class="form-row">
                            <!-- Link -->
                            <div class="form-group col-sm-12 col-md-6">
                                <label>Link:</label>
                                <input v-model="promocaoHotelEditada.link" 
                                    type="text" class="form-control" placeholder="Link para acesso à promoção">
                            </div>
                            <!-- Imagem -->
                            <div v-if="promocaoHotelEditada.idhotelpromocoes>0" class="form-group col-sm-auto">
                                <label>Imagem:</label>
                                <div class="imagem-promocao">
                                    <label class="ponteiro" :for="'file_'+promocaoHotelEditada.idhotelpromocoes">
                                        <div v-if="!imagemSrc"><span class="material-icons icon form-control">hotel</span></div>
                                        <img v-if="imagemSrc" :src="'data:' + tipoImg + ';base64,' + imagemSrc" 
                                            :id="'imgPromo_'+promocaoHotelEditada.idhotelpromocoes"
                                        />
                                    </label>
                                    <input @change="onFileChange" type="file" :id="'file_'+promocaoHotelEditada.idhotelpromocoes" 
                                        :name="'file_'+promocaoHotelEditada.idhotelpromocoes" accept="image/png,image/jpg,image/jpeg" 
                                        class="ponteiro" style="display:none;">
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',promocaoHotelEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import { serializarImagem } from '../utils/serializar'

export default {
    props: {
        promocaoHotel: {
            type: Object,
            required: true,
        }
    },
    data () {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            promocaoHotelEditada: {},
            imagemSrc: null,    
            tipoImg: null
        }
    },
    methods: {
        onFileChange(e) {
            serializarImagem(e, 0.5).then(resp =>{
                this.imagemSrc = resp.dados
                this.tipoImg = resp.tipo
                if (this.promocaoHotelEditada.idhotelpromocoes > 0) {
                    this.salvarImagem(resp.tipo);
                }
            }).catch(er =>{
                console.error(er)
            })
        },
		carregarImagem() {
            if (this.promocaoHotelEditada.imagem) {
                this.imagemSrc = null
                this.tipoImg = null
                axios.post(CONSTANTS.API_URL + '/fdguest/imagem/select', 
                    { id: this.promocaoHotelEditada.imagem }, { headers: this.headers })
                .then((response) => {
                    if (response.status == 200) {
                        var imgObj = response.data[0]
                        if (imgObj) {
                            this.imagemSrc = Buffer.from(imgObj.dados).toString()
                            this.tipoImg = imgObj.tipo
                        }
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
            }
		},        
        salvarImagem(tipoImg) {
            if (!this.imagemSrc.length > 0) {
                console.log("invalido");
                return;
            }
            var params = {
                "tipo" : tipoImg,
                "nome" : "Imagem_Promocao_"+this.promocaoHotelEditada.idhotelpromocoes,
                "dados" : this.imagemSrc,
                "tabela": "hotelpromocoes",
                "fk": "imagem",
                "chave": "idhotelpromocoes",
                "valor": this.promocaoHotelEditada.idhotelpromocoes,
                "idusuario": this.$store.getters.GetLoginInfo.idusuario,
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/imagem/upinsert'
                    ,params, { timeout:60000, headers: this.headers })
                .then((response) => {
                    if(response.status == 200){
                        //this.hotel.logo = response.data.result.imagem[0]
                    }else{
                        console.log(JSON.stringify(response))
                    }
                })
                .catch((error) => {
                    console.log(error.response);
            });
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.promocaoHotelEditada = {... this.promocaoHotel}
        this.carregarImagem();
    }
}
</script>

<style scoped>
.azure {
    background-color: azure!important;
}
.imagem-promocao img {
	border-radius: 2.55rem;
	padding: 10px;
	width: auto;
}
</style>