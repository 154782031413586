<template>
    <div class="card es-azure">
        <div class="card-header">
            <div class="basic-form">
                <div class="form-row">
                    <!-- Infos do Produto -->
                    <div class="col-md-9">
                        <div class="form-row">
                            <!-- Codigo -->
                            <div class="form-group col-md-2" style="align-items:center;">
                                <label>{{$t('global.codigo')}}</label>
                                <div class="form-control" style="background-color:transparent;">{{`${estoqueEditado.idproduto} / ${estoqueEditado.codprodutopms}`}}</div>
                            </div>

                            <!-- Produto -->
                            <div class="form-group col-md-4">
                                <div><label>{{$t('estoque.produto')}}</label></div>
                                <div class="form-control" style="background-color:transparent;">{{`${estoqueEditado.descproduto}`}}</div>
                            </div>

                            <!-- Unidade -->
                            <div class="form-group col-md-2">
                                <label>{{$t('estoque.unidade')}}:</label>
                                <input v-model="estoqueEditado.unidade" min="0"
                                    type="text" class="form-control" :placeholder="$t('estoque.unidade')">
                            </div>

                            <!-- Quantidade -->
                            <div class="form-group col-md-4" style="text-align:center;">
                                <label>{{$t('estoque.quantidade')}}:</label>
                                <div class="input-group cart-count cart-count-lg rounded py-0 my-0">
                                    <div class="input-group-prepend">
                                        <button @click="decrementar()" 
                                            class="es-spin es-minus rounded" type="button">-</button>
                                    </div>
                                    <input type="text" class="es-input form-control rounded" placeholder="" min="0" 
                                        v-model="estoqueEditado.quantidade" style="text-align:center;"
                                    >
                                    <div class="input-group-append">
                                        <button @click="incrementar()" 
                                            class="es-spin es-plus rounded" type="button">+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Botões -->
                    <div class="col-md-3">
                        <div class="form-row" style="flex-direction:row-reverse;">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close',estoqueEditado)" class="ponteiro text-white btn btn-sm btn-secondary mt-4">{{$t('global.fechar')}}</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',estoqueEditado)" class="ponteiro text-white btn btn-sm btn-info mt-4">{{$t('global.salvar')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        estoque: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            estoqueEditado: {},
            statusList: [
                {id: 1, descricao: this.$t('global.ativo'), valor: true},
                {id: 2, descricao: this.$t('global.inativo'), valor: false},
            ]
        }
    },
    methods: {
        incrementar() {
            this.estoqueEditado.quantidade++;
            this.$forceUpdate();
        },
        decrementar() {
            if (this.estoqueEditado.quantidade > 0) {
                this.estoqueEditado.quantidade--;
                this.$forceUpdate();
            }
        }
    },
    computed: {
    },
    beforeMount() {
    },
    mounted() {
        this.estoqueEditado = {... this.estoque}
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.3em;
}
.es-spin {
    font-weight: bold;
    font-size: 1.2em;
    height: 45px;
    width: 45px;
    align-self: center;
}
.es-minus {
    color: red;
    border: 1px solid red;
}
.es-minus:hover {
    color: white;
    background-color: red;
}
.es-plus {
    color: green;
    border: 1px solid green;
}
.es-plus:hover {
    color: white;
    background-color: green;
}
.es-btn {
    font-weight: bold;
    font-size: 1.2em;
}
.es-input {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 70px!important;
    font-size: 1.6em;
}
.es-control {
    height: 70px;
    align-self: center;
}
</style>