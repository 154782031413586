<template>
    <div class="container">
        <time-picker :title="'Restrinja o horário para esta solicitação:'"
            :horarios="horariospermitidos"
            v-on:update="update($event)"
        ></time-picker>
        <date-picker
            :titulo="'Determine as datas de folga do usuário:'"
            :datas="datas"
            v-on:atualizar="atualizarDatas($event)"
        >
        </date-picker>
        <p>{{datas}}</p>
        <div class="fluid-container">
            <div class="fluid-item">Item 1</div>
            <div class="fluid-item">Item 2</div>
            <div class="fluid-item">Item 3</div>
            <div class="fluid-item">Item 4</div>
        </div>
    </div>
</template>

<script>
import TimePicker from './TimePicker.vue'
import DatePicker from './DatePicker.vue'

export default {
    name: 'Tester',
    components: { TimePicker, DatePicker },
    data(){
        return{
            horariospermitidos: '10:00-14:00; ',
            datas: ''
        }
    },
    methods: {
        update(evt) {
            this.horariospermitidos = evt
        },
        atualizarDatas(evt) {
            this.datas = evt
        }
    }
}
</script>

<style scoped>
    .fluid-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: #fff;
        /* background-color: orange; */
        /* gap: 0.3rem; */
    }

    .fluid-item {
        flex: 1 1 100px;
        background-color: #fff;
        border: 1px solid gray;
        margin: 0.3rem;
        border-radius: 0.3rem;
        padding: 0.5rem;
    }
</style>