<template>
    <div class="itens-sol">
        <div class="card-header">
            <h6 class="card-title">{{tipoSolicitacao.titulo}}</h6>
            <a @click="criarItem(tipoSolicitacao)" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Novo Ítem</a>
        </div>
        <div class="row pl-1">
            <div class="row" :id="'criarItemDiv_' + tipoSolicitacao.idtiposolicitacao" style="display: none;">
                <TipoSolicitacaoItemEditar
                    :tipoSolicitacaoItem="novoItem"
                    v-on:save="saveNew($event)"
                    v-on:close="closeNew()"
                    :interna="interna"
                />
                <!-- <Wizard :display="displayNovoItem"
                    :editedTipo="tipoSolicitacao"
                    :editedItem="novoItem"
                    @close="closeNew()"
                /> -->
            </div>
            <div class="table-responsive">
                <table class="table table-responsive-md">
                    <thead>
                        <tr>
                            <th><strong>Ítem</strong></th>
                            <th v-if="!interna"><strong>Status</strong></th>
                            <th><strong>Ação</strong></th>
                        </tr>
                    </thead>
                    <tbody v-for="(tipoSolicitacaoItem, index) in itens" :key="index">
                        <tr>
                            <td>
                                <p>{{tipoSolicitacaoItem.titulo}}</p>
                            </td>
                            <td v-if="!interna">
                                <span :class="{ 'badge-success' : tipoSolicitacaoItem.ativo, 'badge-danger' : !tipoSolicitacaoItem.ativo }" class="badge light">{{(tipoSolicitacaoItem.ativo?$t('tipoSolicitacao.ativo'):$t('tipoSolicitacao.inativo'))}}</span>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a v-if="tipoSolicitacaoItem.ativo&&!interna" class="dropdown-item" @click="ativar(tipoSolicitacaoItem, false)">Inativar</a>
                                        <a v-if="!tipoSolicitacaoItem.ativo&&!interna" class="dropdown-item" @click="ativar(tipoSolicitacaoItem, true)">Ativar</a>
                                        <a v-if="index >= 0 && index < (itens.length-1)" class="dropdown-item" @click="abaixo(tipoSolicitacaoItem)">Abaixo</a>
                                        <a v-if="index > 0 && index < itens.length" class="dropdown-item" @click="acima(tipoSolicitacaoItem)">Acima</a>
                                        <a class="dropdown-item" @click="editar(tipoSolicitacaoItem)">Editar</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div :id="'EditarItem_'+tipoSolicitacaoItem.idtiposolicitacaoitem" style="display: none;">
                                    <TipoSolicitacaoItemEditar 
                                        :tipoSolicitacaoItem="tipoSolicitacaoItem" 
                                        v-on:save="saveEdit($event)"
                                        v-on:close="closeEdit(tipoSolicitacaoItem)"
                                        :interna="interna"
                                    />
                                    <!-- <Wizard :display="displayEditarItem"
                                        :editedTipo="tipoSolicitacao"
                                        :editedItem="tipoSolicitacaoItem"
                                        @close="closeEdit(tipoSolicitacaoItem)"
                                    /> -->
                                </div>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10">
            </div>    
            <div class="col-sm-2">
                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info">Fechar</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import TipoSolicitacaoItemEditar from './TipoSolicitacaoItemEditar.vue'
//import Wizard from './Wizard.vue'


export default {
    components: {
        TipoSolicitacaoItemEditar,
        //Wizard
    },
    props: {
        tipoSolicitacao: {
            type: Object,
            required: false,
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            itens: [],
            novoItem: {
                idtiposolicitacao: this.tipoSolicitacao.idtiposolicitacao,
                descricao: '',
                infoalerta: '',
                valordefault: '0',
                chaveativadora: false,
                infoadicional: false,
                horarioatendimento: false,
                horariodisp: '',
                tipo: 1,
                ativo: this.interna?true:false,
                idioma: this.$i18n.locale
            },
            //displayNovoItem: false,
            //displayEditarItem: false
        }
    },
    methods: {
        abaixo (item) {
            this.ordenar(item, 'Abaixo')
        },
        acima (item) {
            this.ordenar(item, 'Acima')
        },
        ordenar(item, ordenar) {
            var params = {
                idtiposolicitacaoitem: item.idtiposolicitacaoitem,
                idtiposolicitacao: item.idtiposolicitacao,
                ordenar : ordenar
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/ordenar',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadItens(this.tipoSolicitacao)
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        ativar(item, ativo) {
            var itemAtivar = {
                idtiposolicitacaoitem: item.idtiposolicitacaoitem,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarItem() {
            if (this.interna) {
                this.displayNovoItem = true
                var obj = document.getElementById('criarItemDiv_' + this.tipoSolicitacao.idtiposolicitacao)
                if(obj) {
                    obj.style.display = 'block'
                }                
            } else {
                this.$emit('show',{tipo: this.tipoSolicitacao, item: this.novoItem, step: 2})
            }
        },
        saveNew(item) {
            var params = {
                idtiposolicitacao: item.idtiposolicitacao,
                tipo: item.tipo,
                titulo: item.titulo,
                descricao: item.descricao,
                chaveativadora: item.chaveativadora,
                infoadicional: item.infoadicional,
                valordefault: item.valordefault,
                horarioatendimento: item.horarioatendimento,
                horariodisp: item.horariodisp,
                infoalerta: item.infoalerta,
                incluirnaagenda: item.incluirnaagenda,
                antecedencia: item.antecedencia,
                posicao: this.itens.length + 1,
                ativo: item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idioma: item.idioma,
                gruposprodutos: item.tipo == 6 ? item.gruposprodutos : null,
                interna: this.interna,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadItens(this.tipoSolicitacao)
                    this.closeNew()
                    this.novoItem =  {
                        idtiposolicitacao: this.tipoSolicitacao.idtiposolicitacao,
                        descricao: '',
                        infoalerta: '',
                        valordefault: '0',
                        chaveativadora: false,
                        infoadicional: false,
                        horarioatendimento: false,
                        horariodisp: '',
                        tipo: 1,
                        ativo: false,
                        idioma: this.$i18n.locale
                    }
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        closeNew() {
            if (this.interna) {
                var obj = document.getElementById('criarItemDiv_' + this.tipoSolicitacao.idtiposolicitacao)
                if(obj) {
                    obj.style.display = 'none'
                }
            } else {
                this.displayNovoItem = false
            }
        },
        editar(item) {
            if (this.interna) {
                var obj = document.getElementById('EditarItem_'+item.idtiposolicitacaoitem)
                if(obj) {
                    obj.style.display = 'block'
                }
            } else {
                this.$emit('show',{tipo: this.tipoSolicitacao, item: item, step: 2})
            }
        },
        closeEdit(item) {
            if (this.interna) {
                var obj = document.getElementById('EditarItem_'+item.idtiposolicitacaoitem)
                if(obj) {
                    obj.style.display = 'none'
                }
            } else {
                this.displayEditarItem = false
            }
        },
        saveEdit(item) {
            //console.log(JSON.stringify(item.gruposprodutos))
            var params = {
                idtiposolicitacaoitem: item.idtiposolicitacaoitem,
                idtiposolicitacao: item.idtiposolicitacao,
                tipo: item.tipo,
                titulo: item.titulo,
                descricao: item.descricao,
                chaveativadora: item.chaveativadora,
                infoadicional: item.infoadicional,
                valordefault: item.valordefault,
                horarioatendimento: item.horarioatendimento,
                horariodisp: item.horariodisp,
                infoalerta: item.infoalerta,
                incluirnaagenda: item.incluirnaagenda,
                antecedencia: item.antecedencia,
                posicao: item.posicao,
                ativo: item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                idioma: item.idioma,
                gruposprodutos: item.tipo == 6 ? item.gruposprodutos : null,
                interna: this.interna,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.closeEdit(item)
                    this.loadItens(this.tipoSolicitacao)
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
        async loadItens(item) {
            var params = {
                "idtiposolicitacao" : item.idtiposolicitacao,
                "idioma" : this.$i18n.locale
            }
			await axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacaoitem/select', params, {headers: this.headers})
			.then(response => {
                if (response.status == 200) {
                    if (response.data) {
                        this.itens = response.data;
                    }
                } else {
                    console.log('Erro: ' + response.status)
                }
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
        this.loadItens(this.tipoSolicitacao);
    },
    mounted() {
        this.avaliacaoEditada = {... this.tipoSolicitacao}
    }
}
</script>

<style scoped>
.itens-sol {
    background-color: azure!important;
    padding: 10px;
    border-radius: 10px;
}
</style>