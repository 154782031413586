<template>
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{interna?'Categorias':'Tipos de Solicitação Cadastradas'}}</h4>
                    <a @click="criarTipoSolicitacao()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ Cadastrar</a>
                </div>
                <div class="card-body">
                    <div class="row" :id="'criarTipoSolDiv'+idhotlinesetores" style="display: none;">
                        <TipoSolicitacaoEditar 
                            :tipoSolicitacao="novoTipoSolicitacao"
                            v-on:save="saveNew($event)"
                            v-on:close="closeNew()"
                            :interna="interna"
                        />
                        <!-- <Wizard :display="novaSolicitacao"
                            :editedTipo="novoTipoSolicitacao"
                            :editedItem="novoItem"
                            v-on:close="closeNew()"
                        /> -->
                    </div>
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th><strong>{{interna?$t('global.descricao'):$t('global.titulo')}}</strong></th>
                                    <th v-if="!interna"><strong>Subtítulo</strong></th>
                                    <th><strong>Status</strong></th>
                                    <th><strong>Ação</strong></th>
                                </tr>
                            </thead>
                            <tbody v-for="item in tiposSolicitacao" :key="item.idtiposolicitacao">
                                <tr>
                                    <td><strong>{{item.titulo}}</strong></td>
                                    <td v-if="!interna">{{item.subtitulo}}</td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('tipoSolicitacao.ativo'):$t('tipoSolicitacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" class="btn btn-warning light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu">
                                                <a v-if="item.ativo" class="dropdown-item" @click="ativar(item, false)">Inativar</a>
                                                <a v-if="!item.ativo" class="dropdown-item" @click="ativar(item, true)">Ativar</a>
                                                <a v-if="interna" class="dropdown-item" @click="editar(item)">Editar</a>
                                                <a class="dropdown-item" @click="editarItens(item)">Itens</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4">
                                        <div :id="'EditarTipo_'+item.idtiposolicitacao" style="display: none;">
                                            <TipoSolicitacaoEditar 
                                                :tipoSolicitacao="item" 
                                                v-on:save="saveEdit($event)"
                                                v-on:close="closeEdit(item)"
                                                :interna="interna"
                                            />
                                            <!-- <Wizard :display="editarSolicitacao" 
                                                :editedTipo="item"
                                                :editedItem="novoItem"
                                                @close="closeEdit(item)"
                                            /> -->
                                        </div>
                                        <div :id="'Itens_'+item.idtiposolicitacao" style="display: none;">
                                            <TipoSolicitacaoItem
                                                :tipoSolicitacao="item" 
                                                v-on:close="closeItens(item)"
                                                @show="$emit('show',$event)"
                                                :interna="interna"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import TipoSolicitacaoEditar from './TipoSolicitacaoEditar.vue'
import TipoSolicitacaoItem from './TipoSolicitacaoItem.vue'
// import Wizard from './Wizard.vue'

export default {
    components: {
        TipoSolicitacaoEditar, 
        TipoSolicitacaoItem, 
    //    Wizard
    },
    props: {
        idhotlinesetores: {
            type: Number,
            required: false,
            default: 2
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tiposSolicitacao: [],
            novoTipoSolicitacao: {
                idtiposolicitacao: 0,
                titulo: null,
                subtitulo: null,
                ativo: true,
                idioma: this.$i18n.locale,
            },
            novoItem: {
                idtiposolicitacaoitem: 0,
                idtiposolicitacao: 0,
                descricao: '',
                infoalerta: '',
                valordefault: '0',
                chaveativadora: false,
                infoadicional: false,
                horarioatendimento: false,
                horariodisp: '',
                tipo: 1,
                ativo: false,
                idioma: this.$i18n.locale
            },
            // novaSolicitacao: false,
            // editarSolicitacao: false
        }
    },
    methods: {
        ativar(item, ativo) {
            var itemAtivar = {
                idtiposolicitacao: item.idtiposolicitacao,
                ativo: ativo
            }
            if (this.saveEdit(itemAtivar)) {
                item.ativo = ativo;
            }
        },
        criarTipoSolicitacao() {
            if (this.interna) {
                var obj = document.getElementById('criarTipoSolDiv'+this.idhotlinesetores)
                if(obj) {
                    obj.style.display = 'block'
                }
            } else {
                this.novaSolicitacao = true
                this.novoTipoSolicitacao = {
                    idtiposolicitacao: 0,
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idhotlinesetores: this.idhotlinesetores,
                    titulo: '',
                    subtitulo: '',
                    solicitacoesmultiplas: false,
                    confirmacaoindividual: true,
                    ativo: true,
                    idioma: this.$i18n.locale,
                    idusuario: this.$store.getters.GetLoginInfo.idusuario
                }
                this.$emit('show',{tipo:this.novoTipoSolicitacao,item:this.novoItem,step:0})
            }
        },
        closeNew() {
            if (this.interna) {
                var obj = document.getElementById('criarTipoSolDiv'+this.idhotlinesetores)
                if(obj) {
                    obj.style.display = 'none'
                }
            } else {
                this.novaSolicitacao = false
                this.novoTipoSolicitacao = {
                    idtiposolicitacao: 0,
                    idhotel: this.$store.getters.GetLoginInfo.idhotel,
                    idhotlinesetores: this.idhotlinesetores,
                    titulo: '',
                    subtitulo: '',
                    solicitacoesmultiplas: false,
                    confirmacaoindividual: true,
                    ativo: true,
                    idioma: this.$i18n.locale,
                    idusuario: this.$store.getters.GetLoginInfo.idusuario
                }
            }
        },
        saveNew(item) {
            var params = {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idhotlinesetores: this.idhotlinesetores?this.idhotlinesetores:1,
                titulo: item.titulo,
                subtitulo: item.subtitulo,
                solicitacoesmultiplas: item.solicitacoesmultiplas,
                confirmacaoindividual: item.confirmacaoindividual,
                ativo: this.interna ? true : false,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                interna: this.interna,
                criado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/insert',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadTiposSolicitacao()
                    this.closeNew()
                } else {
                    console.log('Erro: ' + response.status)
                }
            })
        },
        editar(item) {
            var obj = document.getElementById('EditarTipo_'+item.idtiposolicitacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeEdit(item) {
            if (this.interna) {
                var obj = document.getElementById('EditarTipo_'+item.idtiposolicitacao)
                if(obj) {
                    obj.style.display = 'none'
                }
            } else {
                this.editarSolicitacao = false
            }
        },
        saveEdit(item) {
            var params = {
                idtiposolicitacao: item.idtiposolicitacao,
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idhotlinesetores: this.idhotlinesetores?this.idhotlinesetores:1,
                titulo: item.titulo,
                subtitulo: item.subtitulo,
                solicitacoesmultiplas: item.solicitacoesmultiplas,
                confirmacaoindividual: item.confirmacaoindividual,
                ativo: this.interna?true:item.ativo,
                idusuario: this.$store.getters.GetLoginInfo.idusuario,
                interna: this.interna,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/update',
                params,{ headers: this.headers}
            ).then(response => {
                if(response.status == 200) {
                    this.loadTiposSolicitacao()
                    this.closeEdit(item)
                    return true;
                } else {
                    console.log('Erro: ' + response.status)
                    return false;
                }
            })
        },
        editarItens(item) {
            var obj = document.getElementById('Itens_'+item.idtiposolicitacao)
            if(obj) {
                obj.style.display = 'block'
            }
        },
        closeItens(item) {
            var obj = document.getElementById('Itens_'+item.idtiposolicitacao)
            if(obj) {
                obj.style.display = 'none'
            }
        },
        loadTiposSolicitacao() {
           var  params = {
                "idioma" : this.$i18n.locale,
                "idhotel": this.$store.getters.GetLoginInfo.idhotel,
                "idhotlinesetores": this.idhotlinesetores?this.idhotlinesetores:1,
                "interna": this.interna
            }
            //console.log(JSON.stringify(params))
            axios.post(CONSTANTS.API_URL + '/fdguest/tiposolicitacao/select',
                    params, { headers:this.headers}).then(response => {
                if(response.status == 200) {
                    this.tiposSolicitacao = response.data
                }
            })
        }
    },
    computed: {
    },
    mounted() {
        this.loadTiposSolicitacao();
    }
}
</script>

<style>

</style>