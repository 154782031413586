<template>
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{$t('estoque.historico')}}</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-responsive-md">
                        <thead>
                            <tr>
                                <th><strong>{{$t('global.operacao')}}</strong></th>
                                <th><strong>{{$t('global.dataehora')}}</strong></th>
                                <th><strong>{{$t('global.por')}}</strong></th>
                                <th colspan="2">
                                    <strong class="es-old">{{$t('global.antigovalor')}}</strong>
                                    <strong class="es-new">{{` / ${$t('global.novovalor')}`}}</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(historico,index) in historicoEstoqueProduto" :key="index">
                            <tr>
                                <td><strong>{{$t(`global.${historico.operacao}`)}}</strong></td>
                                <td><strong>{{historico.dataoperacao}}</strong></td>
                                <td><strong>{{historico.usuario}}</strong></td>
                                <td colspan="2">
                                    <strong class="es-old">{{getChanges(historico)['oldvalue']}}</strong><br>
                                    <strong class="es-new">{{getChanges(historico)['newvalue']}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="form-row" style="flex-direction:row-reverse;">
                        <div class="form-group col-sm-auto">
                            <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">{{$t('global.fechar')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'

export default {
    components: {},
    props: {
        estoqueproduto: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            estoqueEditado: {},
            historicoEstoqueProduto: []            
        }
    },
    methods: {
        getChanges(item) {
            let result = {oldvalue:{},newvalue:{}}
            // Se tiver old e new, ver diferenças...
            if (item.newvalue && item.oldvalue) {
                let objKeyList = item.newvalue ? item.newvalue : item.oldvalue
                let keys = Object.keys(objKeyList)
                //console.error(`keys:${keys}`)
                for (let i = 0; i < keys.length; i++) {
                    let propA = item.oldvalue[keys[i]];
                    let propB = item.newvalue[keys[i]];
                    if (propA != propB) {
                        result['oldvalue'][keys[i]] = propA
                        result['newvalue'][keys[i]] = propB
                    }
                }
            } else {
                // senão trazer como está...
                result = {oldvalue: item.oldvalue , newvalue : item.newvalue};
            }
            return result
        },
        carregarHistorico(){
           this.produtos = []
           var  params = {
                "idestoqueproduto" : this.estoqueproduto.idestoqueproduto
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/estoque/produto/historico',
                    params, { headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.historicoEstoqueProduto = response.data
                    this.$forceUpdate();
                }
            })
        }
    },
    computed: {
    },
    beforeMount() {
        this.carregarHistorico();
    },
    mounted() {
        
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
.es-icon {
	padding: 0.3em;
	font-size: 2.5em;
}
.es-btn {
    font-weight: bold;
    font-size: 1.4em;
}
.es-input{
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 70px;
    font-size: 1.6em;
}
.es-old {
    background-color: azure;
}
.es-new {
    color:blue;
}
</style>