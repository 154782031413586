<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="tipoSolicitacaoEditada.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="tipoSolicitacaoEditada.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-md-7">
                                <label>{{interna?$t('global.descricao'):$t('global.titulo')}}:</label>
                                <input v-model="tipoSolicitacaoEditada.titulo" 
                                    type="text" class="form-control" placeholder="Dê um título ao tipo de solicitação">
                            </div>
                            <div v-if="!interna" class="form-group col-md-7">
                                <label>Subtítulo:</label>
                                <input v-model="tipoSolicitacaoEditada.subtitulo" 
                                    type="text" class="form-control" placeholder="Dê um subtítulo ao tipo de solicitação">
                            </div>
                            <div v-if="!interna" class="form-group col-sm-6">
                                <label>Gerar botão de confirmação para cada ítem?</label>
                                <select class="form-control" v-model="tipoSolicitacaoEditada.confirmacaoindividual"
                                    @change="verificarMultipla(tipoSolicitacaoEditada)"
                                >
                                    <option :value="true" :selected="tipoSolicitacaoEditada.confirmacaoindividual"
                                    >Sim</option>
                                    <option :value="false" :selected="!tipoSolicitacaoEditada.confirmacaoindividual"
                                    >Não</option>
                                </select>
                            </div>                            
                            <div v-if="!interna" :id="'divMultipla_'+tipoSolicitacaoEditada.idtiposolicitacao" class="form-group col-sm-6">
                                <label>Gerar múltiplas solicitações para cada ítem?</label>
                                <select class="form-control" v-model="tipoSolicitacaoEditada.solicitacoesmultiplas"
                                    :disabled="multiplaDisabled"
                                >
                                    <option :value="true" :selected="tipoSolicitacaoEditada.solicitacoesmultiplas"
                                    >Sim</option>
                                    <option :value="false" :selected="!tipoSolicitacaoEditada.solicitacoesmultiplas"
                                    >Não</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('save',tipoSolicitacaoEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-md-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        tipoSolicitacao: {
            type: Object,
            required: true,
        },
        interna: {
            type: Boolean,
            required: false,
            default: false
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            tipoSolicitacaoEditada: {},
            multiplaDisabled: false
        }
    },
    methods: {
        hide(id) {
            var el = document.getElementById(id)
            if (el) {
                el.style.display = 'none';
            }
        },
        show(id) {
            var el = document.getElementById(id)
            if (el) {
                el.style.display = 'block';
            }
        },
        verificarMultipla(tipo) {
            if (tipo.confirmacaoindividual) {
                tipo.solicitacoesmultiplas = false
                this.multiplaDisabled = true
                this.hide('divMultipla_'+tipo.idtiposolicitacao)
            } else {
                tipo.solicitacoesmultiplas = true
                this.multiplaDisabled = false
                this.show('divMultipla_'+tipo.idtiposolicitacao)
            }
        },
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    watch:{
        tipoSolicitacao(){
            this.tipoSolicitacaoEditada = {... this.tipoSolicitacao}
        }
    },
    mounted() {
        this.tipoSolicitacaoEditada = {... this.tipoSolicitacao}
    }
}
</script>

<style>

</style>