<template>
    <div class="row">        
        <!-- Filtrar -->
        <div class="col-xl-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.filtrar')}}</h4>
                    <div @click="limparFiltro()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">
                        <span>Limpar Filtro</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="basic-form">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.setor')}}:</label>
                                    <select class="form-control" v-model="filtro.idcategoriaservico"
                                        @change="loadServicos()">
                                        <template v-if="categoriaServicos && categoriaServicos.length > 0">
                                            <option v-for="cat in categoriaServicos" v-bind:key="cat.idcategoria"
                                                :value="cat.idcategoria"
                                                :selected="filtro.idcategoriaservico==cat.idcategoria"
                                            >{{cat.descricao}}
                                            </option>
                                        </template>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.titulo')}}:</label>
                                    <input v-model="filtro.titulo" 
                                        @keyup="loadServicos()" type="text" class="form-control" placeholder="">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.subtitulo')}}:</label>
                                    <input v-model="filtro.subtitulo" 
                                        @keyup="loadServicos()" type="text" class="form-control" placeholder="">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.valor')}}:</label>
                                    <input v-model="filtro.valor"
                                        @keyup="loadServicos()" type="number" class="form-control">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>{{$t('ser.status')}}:</label>
                                    <select class="form-control" v-model="filtro.ativo"
                                        @change="loadServicos()">
                                        <option :value="true">{{$t('avaliacao.ativo')}}</option>
                                        <option :value="false">{{$t('avaliacao.inativo')}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Serviços -->
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{$t('ser.servicoscadastrados')}}</h4>
                    <a @click="cadastrarServico()" class="ponteiro text-white btn btn-success rounded ml-2 btn-sm px-4">+ {{$t('ser.cadastrar')}}</a>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-responsive-md">
                            <thead>
                                <tr>
                                    <th style="width:80px;"><strong>#</strong></th>
                                    <th><strong>{{$t('ser.setor')}}</strong></th>
                                    <th><strong>{{$t('ser.titulo')}}</strong></th>
                                    <th><strong>{{$t('ser.subtitulo')}}</strong></th>
                                    <th><strong>{{$t('ser.valor')}}</strong></th>
                                    <th><strong>{{$t('ser.status')}}</strong></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, idx) in servicos" :key="idx">
                                <tr>
                                    <td><strong>{{item.idservico}}</strong></td>
                                    <td>{{item.categoriaservico}}</td>
                                    <td>{{item.titulo}}</td>
                                    <td>{{item.subtitulo}}</td>
                                    <td>R$ {{item.valor}}</td>
                                    <td><span :class="{ 'badge-success' : item.ativo, 'badge-danger' : !item.ativo }" class="badge light">{{(item.ativo?$t('avaliacao.ativo'):$t('avaliacao.inativo'))}}</span></td>
                                    <td>
                                        <div class="dropdown">
                                            <button type="button" :class="{ 'btn-success' : item.ativo == true, 'btn-warning' : item.ativo == false   }" class="btn light sharp" data-toggle="dropdown">
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg>
                                            </button>
                                            <div class="dropdown-menu" v-if="item.ativo == true">
                                                <a @click="editarServico(item)" class="dropdown-item" >{{$t('ser.editar')}}</a>
                                                <a @click="desativarServico(item)" class="dropdown-item ponteiro">{{$t('ser.desativar')}}</a>
                                            </div>
                                            <div class="dropdown-menu" v-else>
                                                <a @click="editarServico(item)" class="dropdown-item" >{{$t('ser.editar')}}</a>
                                                <a @click="ativarServico(item)" class="dropdown-item ponteiro">{{$t('ser.ativar')}}</a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>      
    </div>
</template>

<script>
import CONSTANTS from '../constants'
import axios from 'axios'
export default {
 data() {
        return{
            servicos: [],
            headers: {
                    'x-access-token': this.$store.getters.GetLoginInfo.token
                    },
            nomeParceiro: '',
            categoriaServicos: [],
            filtro: {
                idcategoriaservico: -1,
                titulo: '',
                subtitulo: '',
                descricao: '',
                valor: null,
                desconto: null,
                ativo: null
            },
            newItem: {
                idcategoriaservico: -1,
                titulo: '',
                subtitulo: '',
                descricao: '',
                valor: null,
                desconto: null,
                ativo: null
            },
        }
    },
     methods: {
        limparFiltro() {
            this.filtro = {
                idcategoriaservico: -1,
                titulo: '',
                subtitulo: '',
                descricao: '',
                valor: null,
                ativo: null
            }
            this.loadServicos()
        },
        loadServicos() {
            var params = {
                idhotel : this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
            }
            if (this.filtro.idcategoriaservico > 0) {
                params.idcategoriaservico = this.filtro.idcategoriaservico
            }
            if (this.filtro.titulo.trim()) {
                params.titulo = this.filtro.titulo.trim()
            }
            if (this.filtro.subtitulo.trim()) {
                params.subtitulo = this.filtro.subtitulo.trim()
            }
            if (this.filtro.valor && this.filtro.valor.trim()) {
                params.valor = parseFloat(this.filtro.valor.trim())
            }
            if (this.filtro.ativo != null) {
                params.ativo = this.filtro.ativo
            }
            axios.post(CONSTANTS.API_URL + '/fdguest/servico/select', params, { headers: this.headers })
            .then(response => {
                if(response.status == 200) {
                    this.servicos = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        buscarCatServico() {
            axios.post(CONSTANTS.API_URL + '/fdguest/categoriaservico/select', {
                idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma:this.$i18n.locale,
            }, {headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.categoriaServicos = response.data
                } else {
                    console.log(JSON.stringify(response))
                }
            })
        },
        desativarServico(item) {
            var params = {
                idservico: item.idservico, 
                ativo: false,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/servico/update', params,{headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.loadServicos()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
        },
        ativarServico(item) {
             var params = {
                idservico: item.idservico, 
                ativo: true,
				alterado_por: this.$store.getters.GetLoginInfo.idusuario
            }
            axios.put(CONSTANTS.API_URL + '/fdguest/servico/update', params,{headers: this.headers}).then(response => {
                if(response.status == 200) {
                    this.loadServicos()
                } else {
                    console.log('Erro: ' + JSON.stringify(response))
                }
            })
        },
        cadastrarServico() {
            this.$store.dispatch('set_Edit_Status', false);
            this.$store.dispatch('set_Edit_Item', this.newItem);
            this.$router.push('/painel/cadsalaospa')
        },
        editarServico(item) {
            this.$store.dispatch('set_Edit_Status', true);
            this.$store.dispatch('set_Edit_Item', item);
            this.$router.push('/painel/cadsalaospa')
        }
    }, 
    mounted() {
        this.loadServicos();
        this.buscarCatServico()
    }
}
</script>

<style>

</style>