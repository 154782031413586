<template>
  <div class="timeline dialog-overlay">
    <div
      class="dialog-content"
      style="overflow-y: auto"
      ref="timelineContainer"
    >
      <div
        v-for="event in events"
        :key="event.id"
        class="timeline-event"
        @click="openDialog(event)"
        style="background-color: white"
      >
        <div
          class="timeline-bubble"
          :style="{
            backgroundColor:
              event.tipo_evento === 'pausa' ? 'orange' : '#4caf50',
          }"
        ></div>
        <div class="timeline-content">
          <h4>{{ event.tipo_evento }} - Técnico {{ event.nomeusuario }}</h4>
          <p>{{ event.textohotlinesolicitacoes }}</p>
          <p>{{ formatDate(event.data_evento) }}</p>
        </div>
      </div>
      <button @click="$emit('fecharDetalhes')">Fechar</button>
    </div>

    <div v-if="dialogVisible" class="dialog-overlay" @click="closeDialog">
      <div class="dialog-content" @click.stop>
        <h3>Detalhes do Evento</h3>
        <div>
          <h4>Tipo de Evento: {{ selectedEvent.tipo_evento }}</h4>
          <p><strong>Técnico ID:</strong> {{ selectedEvent.tecnico_id }}</p>
          <p><strong>Técnico:</strong> {{ selectedEvent.nomeusuario }}</p>
          <p>
            <strong>Solicitação ID:</strong> {{ selectedEvent.solicitacao_id }}
          </p>
          <p>
            <strong>Data do Evento:</strong>
            {{ formatDate(selectedEvent.data_evento) }}
          </p>
        </div>
        <button @click="closeDialog">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectedEvent: null,
    };
  },
  mounted() {
    this.scrollToBottom();
  },
  methods: {
    scrollToBottom() {
      const container = this.$refs.timelineContainer;
      container.scrollTop = container.scrollHeight;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    openDialog(event) {
      this.selectedEvent = event;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.timeline-event {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.timeline-bubble {
  width: 12px;
  height: 12px;
  background-color: #4caf50;
  border-radius: 50%;
  margin-right: 15px;
}

.timeline-content {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.timeline-event:hover {
  background-color: #e9f5e9; /* Efeito visual de hover */
}

/* Estilos do Dialog */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-height: 400px; /* Defina a altura máxima que você deseja para a área de eventos */
  overflow-y: auto; /* Habilita a rolagem vertical */
  width: 100%; /* Ou o valor que você desejar */
}

.dialog-content h3 {
  margin-top: 0;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}
</style>
