<template>
  <div class="card">
    <div v-if="titulo" class="card-header">
      <h4>{{titulo}}</h4>
    </div>
    <div class="card-body">
      <div class="basic-form">
          <div class="form-row">
            <div class="form-group col-auto">
              <label for="datepicker">Escolha uma data para adicionar à lista:</label>
              <input id="datepicker" type="date"
                v-model="dataAtual" 
                class="form-control"
              />
            </div>
            <div class="form-group col-md-2 card-header mx-3">
              <button @click="addDates" class="btn btn-primary rounded">Adicionar</button>
            </div>
          </div>
      </div>
      <ul class="fluid-container">
        <li class="fluid-item border" v-for="(date, index) in datasSelecionadas" :key="index">
          <div class="fluid-container">
            <div class="fluid-item">
              <div class="p-3"><strong>{{ formatarData(date) }}</strong></div>
            </div>
            <div class="fluid-item">
              <button @click="removeDate(index)" class="btn btn-danger btn-sm rounded mx-3">Remover</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

  export default {
    components: {
      
    },
    props: {
      indice: {
          type: Number,
          required: false,
          default: 0
      },
      titulo: {
          type: String,
          required: false
      },
      datas: {
          type: String,
          required: false
      },
    },
    data() {
      return {
        dataAtual: null,
        datasSelecionadas: []
      };
    },
    methods: {
      formatarData(aDate) {
        return moment(aDate).locale(this.$i18n.locale).format('ll');
      },
      addDates() {
        if (this.dataAtual) {
          let arrayIndex = this.datasSelecionadas.findIndex(x => x === this.dataAtual)
          if (arrayIndex) {
            this.datasSelecionadas = this.datasSelecionadas.concat(this.dataAtual);
            this.datasSelecionadas.sort();
            this.$emit('atualizar',JSON.stringify(this.datasSelecionadas));
            this.dataAtual = null;
          } else {
            this.$snackW("Data escolhida já está presente.")
          }
        }
      },
      removeDate(index) {
        this.datasSelecionadas.splice(index, 1);
        this.datasSelecionadas.sort();
        this.$emit('atualizar',JSON.stringify(this.datasSelecionadas));
      }
    },
    created() {
      if (this.datas) {
        this.datasSelecionadas = JSON.parse(this.datas);
      }
    }
};
</script>

<style scoped>
/* Estilos de componente */
    .fluid-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        background-color: #fff;
        /* background-color: orange; */
        /* gap: 0.3rem; */
    }

    .fluid-item {
        flex: 1 1 100px;
        background-color: #fff;
        margin: 0.3rem;
        border-radius: 0.3rem;
        padding: 0.5rem;
    }

    .border {
        border: 1px solid gray;
    }

</style>
