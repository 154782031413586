<template>
  <div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">{{$t('sols.produtos')}} #{{idSol}}</h5>
                  <button type="button" class="close" @click="$emit('close')">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <table class="table table-responsive" style="font-size: 10px;">
                    <thead>
                        <tr >
                            <th>Cód / PMS</th>
                            <th>Descrição</th>
                            <th>Unidade</th>
                            <th>Em estoque (res.)</th>
                            <th colspan="3" class="es-grouped" style="text-align:center;">
                                <div class="row" style="align-items:center;">
                                    <div class="col-12">
                                        <span>Quantidades</span>
                                    </div>
                                </div>
                                <div class="row" style="align-items:center;">
                                    <div class="col-sm">
                                        <label>Solicitada</label>
                                    </div>
                                    <div class="col-sm">
                                        <label>Liberada</label>
                                    </div>
                                    <div class="col-sm">
                                        <label>Entregue</label>
                                    </div>
                                </div>
                            </th>
                            <th>Observação</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody v-for="(p,index) in produtosSolicitacao" :key="index">
                        <tr class="es-highlight">
                            <td>
                                <span>{{`${p.idproduto} / ${p.codprodutopms}`}}</span>
                            </td>
                            <td v-if="!p.idproduto">
                                <SearchList
                                    :items="produtosTodosStr"
                                    @choosed="setProduto(p,$event)"
                                >
                                </SearchList>
                            </td>
                            <td v-else>{{p.descproduto}}</td>
                            <td>{{p.unidade}}</td>
                            <td v-if="p.qtdereservada>0">{{p.quantidade | formatarMoeda}} ({{p.qtdereservada}}) </td>
                            <td v-else>{{p.quantidade | formatarMoeda}}</td>
                            <td>
                                <input class="form-control-sm rounded" type="number" min="1"
                                    v-model="p.qtdesolicitada" style="width:80px;text-align:center;"
                                    :id="`qtde_sol_${index}`"
                                > 
                            </td>
                            <td>
                                <input class="form-control-sm rounded" type="number" min="0" 
                                    :readonly="idusuariotipo==4 || p.idhotlinesolicitacoesitens<=0"
                                    :max="p.quantidade" @blur="checkValid(p)" 
                                    :class="{ 'es-valid' : p.validLiberada, 'es-invalid' : !p.validLiberada }"
                                    v-model="p.qtdeliberada" style="width:80px;text-align:center;"
                                    :id="`qtde_lib_${index}`"
                                > 
                            </td>
                            <td style="width:90px;text-align:center;">
                                <input class="form-control-sm rounded" type="number" min="0"
                                    :max="p.qtdeliberada"
                                    :readonly="idusuariotipo==4 || p.idhotlinesolicitacoesitens<=0"
                                    @blur="checkValid(p)" 
                                    :class="{ 'es-valid' : p.validEntregue, 'es-invalid' : !p.validEntregue }"
                                    v-model="p.qtdeentregue" style="width:80px;text-align:center;"
                                    :id="`qtde_ent_${index}`"
                                > 
                            </td>
                            <td>
                                <input class="form-control-sm rounded" type="text"
                                    v-model="p.comentario" 
                                    :id="'comentario_'+`${produtosSolicitacao.indexOf(p)}`"
                                > 
                            </td>
                            <td>
                                <div style="display:flex;flex-direction:row;">
                                    <div class="col-sm-auto px-1 mx-0" v-if="parseInt(p.idproduto) > 0">
                                        <button @click="salvarProdutos(p)" type="submit" class="btn btn-primary btn-sm">{{$t(`global.salvar`)}}</button>
                                    </div>
                                    <div v-if="idusuariotipo!=4 && parseInt(p.idproduto) > 0" class="col-sm-auto px-1 mx-0">
                                        <button @click="abrirMovimentacaoEstoque(p)" type="submit" class="btn btn-success btn-sm">{{$t(`global.ajustar`)}}</button>
                                    </div>
                                    <div class="col-sm-auto px-1 mx-0" v-if="p.qtdeentregue==0">
                                        <button @click="excluirItem(p,index)" type="submit" class="btn btn-danger btn-sm">{{$t(`global.apagar`)}}</button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="9" v-if="p">
                                <div :id="`save_edit_${p.idproduto}`" style="display:none;">
                                    <EstoqueEditarInLine :estoque="p"
                                        @save="salvarEstoque($event,p)"
                                        @close="fecharEstoque($event,p)"
                                    ></EstoqueEditarInLine>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <hr>
                    <div class="pb-3" style="text-align:center;">
                        <button @click="addProd()" type="button" class="ponteiro text-white btn btn-primary rounded ml-2 mt-2 btn-sm px-4">+</button>
                    </div>
                </table>
                </div>
                <div class="modal-footer">
                  <button @click="salvarProdutos(null)" type="button" class="btn btn-primary">{{$t('ser.salvar')}}</button>
                  <button type="button" class="btn btn-secondary"
                    @click="$emit('close')"
                  >{{$t('sols.fechar')}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- <button @click="showModal = true">Click</button> -->
  </div>  
</template>

<script>
  import SearchList from './SearchList.vue'
  import EstoqueEditarInLine from './EstoqueEditarInLine.vue'

  export default {
    name: 'SolicitacaoProdutosEditar',
    components: {SearchList, EstoqueEditarInLine},
    data() {
      return {
        validEntregue: true,
        validLiberada: true,
        showModal: false
      }
    },
    props: {
      produtosSolicitacao: {
        type: Array,
        required: false,
        default: () => [],
      },
      produtosTodosStr: {
        type: Array,
        required: true
      },
      show: {
        type: Boolean,
        required: true
      },
      idSol: {
        type: Number,
        required: true
      },
      idusuariotipo: {
        type: Number,
        required: true
      }
    },
    watch: {
      show(val) {
        if (val) {
          this.showModal = true;
        } else {
          this.showModal = false;
        }
      }
    },
    methods: {
        checkValid(p){
            let qtde_liberada = 0;
            let qtde_entregue = 0;
            let qtde_estoque = 0;
            let result = true;
            try {
                qtde_liberada = parseFloat(p.qtdeliberada);
                qtde_entregue = parseFloat(p.qtdeentregue);
                qtde_estoque = parseFloat(p.quantidade);
            } catch (e) {
                result = false;
                console.error(JSON.stringify(e));
                return result;
            }
            
            if (qtde_liberada > qtde_estoque) {
                p.validLiberada = false;
                result = false;
            } else {
                p.validLiberada = true;
            }
            if (qtde_entregue > qtde_liberada) {
                p.validEntregue = false;
                result = false;
            } else {
                p.validEntregue = true;
            }

            return result;
        },
        setProduto(p,$event) {
            this.$emit('item-changed',{p: p, index: $event})
        },
        valid(p) {
          let pArray = [];
          if (p) {
            pArray = [p];
          } else {
            pArray = this.produtosSolicitacao;
          }
          let result = true;
          for (var i = 0; i < pArray.length; i++) {
            let x = pArray[i]
            //console.error(JSON.stringify(x))
            if (parseInt(x.qtdeliberada) > parseInt(x.quantidade) 
                || parseInt(x.qtdeentregue) > parseInt(x.qtdeliberada)) {
              result = false;
              break;
            }
          }
          return result;
        },
        salvarProdutos(p) {
            if (!this.valid(p)) {
              this.$snackW(this.$t('sols.verificarquantidades'))
            } else {
              this.$emit('save',p);
            }
        },
        abrirMovimentacaoEstoque(p) {
            let el = document.getElementById(`save_edit_${p.idproduto}`);
            this.$unfade(el);
        },
        excluirItem(p,index) {
            this.$emit('item-deleted',{p: p, index: index})
        },
        salvarEstoque(item,itemSol) {
            this.$emit('save-stock',{item: item, itemSol: itemSol})
        },
        fecharEstoque(p) {
            this.$fade(document.getElementById(`save_edit_${p.idproduto}`));
        },
        addProd() {
            this.$emit('add-prod')
        }
    },
    created() {
      
    }
  }
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>