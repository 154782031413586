<template>
  <div id="app">
      <router-view/>
      <div class="snack-footer">
        <div id="snack-element" class="snack-div">
          <div style="width:70%;text-align:center;">
            <span id="snack-message" class="px-2" style="color:white;"></span>
          </div>
          <div style="width:30%;text-align:center;">
            <button class="btn btn-sm mx-2 my-2" id="snack-button" @click="close()">{{$t('global.fechar')}}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    methods: {
      close() {
        let el = document.getElementById('snack-element');
        if (el) {
          el.style.display = 'none'
        }
      }
    }
  }
</script>

<style>
.snack-footer {
  position: fixed;
  left: 0;
  bottom: 120px;
  width: 100%;
  text-align: center;
  z-index: 10000;
}
.snack-div {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 1.0rem;
  border-radius: 0.4rem;
  margin: auto;
  width: 350px;
  background-color: rgb(54, 53, 53);
}
.icone-filtro {
    color: black;
    font-size: 1.3rem;
}
</style>
