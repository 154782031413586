<template>
  <div class="row">
    <!-- Filtrar -->
    <div class="col-xl-12">
      <div class="es-card">
        <div class="card-header py-2">
          <div
            @click="exibirOcultarFiltro()"
            class="ponteiro btn rounded ml-2 btn-sm btn-primary px-4 btn-filter tooltip"
            style="z-index: 1"
          >
            <span
              v-if="!exibirFiltro"
              class="material-icons icon icone-filtro ponteiro"
              >arrow_drop_down</span
            >
            <span
              v-if="exibirFiltro"
              class="material-icons icon icone-filtro ponteiro"
              >arrow_drop_up</span
            >
            <span class="tooltiptext">Exibir ou ocultar o filtro.</span>
          </div>
          <h4>{{ $t("sols.filtro") }}</h4>
          <div
            v-on:click="limparFiltro()"
            class="ponteiro text-white btn btn-primary rounded ml-2 btn-sm px-4"
          >
            <span>Limpar Filtro</span>
          </div>
        </div>
        <div id="filter_body" class="card-body" v-show="exibirFiltro">
          <div class="basic-form">
            <div class="form-row">
              <!-- Descricao da UH -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.room") }}</label>
                <input v-model="filtro.room" type="text" class="form-control" />
              </div>
              <!-- Número da UH -->
              <div class="form-group col-md-auto">
                <label>#</label>
                <input
                  v-model="filtro.roomid"
                  type="text"
                  class="form-control"
                />
              </div>
              <!-- Tipo de Quarto -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.roomtype") }}</label>
                <input
                  v-model="filtro.roomtype"
                  type="text"
                  class="form-control"
                />
              </div>
              <!-- Andar -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.floor") }}</label>
                <input
                  v-model="filtro.floor"
                  type="text"
                  class="form-control"
                />
              </div>
              <!-- Status da Governança -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.hkstatus") }}</label>
                <select class="form-control" v-model="filtro.hkstatus">
                  <option :value="null">{{ $t("global.ambos") }}</option>
                  <option :value="`Limpo`">{{ $t("uh.limpo") }}</option>
                  <option :value="`Em Limpeza`">
                    {{ $t("uh.emlimpeza") }}
                  </option>
                  <option :value="`Sujo`">{{ $t("uh.sujo") }}</option>
                </select>
              </div>
              <!-- Status Ocupado -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.occ") }}</label>
                <select class="form-control" v-model="filtro.occ">
                  <option :value="null">{{ $t("global.ambos") }}</option>
                  <option :value="true">{{ $t("global.sim") }}</option>
                  <option :value="false">{{ $t("global.nao") }}</option>
                </select>
              </div>
              <!-- Interdição -->
              <div class="form-group col-md-auto">
                <label>{{ $t("uh.outoforder") }}</label>
                <select class="form-control" v-model="filtro.outoforder">
                  <option :value="null">{{ $t("global.ambos") }}</option>
                  <option :value="true">{{ $t("global.sim") }}</option>
                  <option :value="false">{{ $t("global.nao") }}</option>
                </select>
              </div>
              <!-- Status -->
              <div class="form-group col-md-auto">
                <label>{{ $t("sols.status") }}</label>
                <select class="form-control" v-model="filtro.act">
                  <option :value="null">{{ $t("global.todos") }}</option>
                  <option :value="true">{{ $t("global.ativo") }}</option>
                  <option :value="false">{{ $t("global.inativo") }}</option>
                </select>
              </div>
            </div>
            <div class="form-row justify-content-end">
              <div
                v-on:click="carregarUHs()"
                class="ponteiro text-white btn btn-primary rounded btn-sm px-4 my-4"
              >
                <span>{{ $t("sols.filtro") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Dados -->
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">{{ $t("uh.titulo") }}</h4>
        </div>
        <div class="card-body">
          <div class="row mb-5">
            <div class="col-md-6" v-for="item in uhs" :key="item.roomid">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{ item.room }}</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    #{{ item.roomid }}
                  </h6>
                  <hr class="my-hr" style="border-color: #ccc; border-width: 2px; border-style: dashed;">
                  <p class="card-text">
                    <strong>{{ $t("uh.roomtype") }}:</strong> {{ item.roomtype
                    }}<br />
                    <strong>{{ $t("uh.floor") }}:</strong> {{ item.floor
                    }}<br />
                    <strong>{{ $t("uh.hkstatus") }}:</strong> <span :class="classeHkStatus(item)">{{ item.hkstatus
                    }} </span><br />
                    <strong>{{ $t("uh.ocorrencia") }}:</strong>
                    {{ item.ocorrencia }}<br />
                    <strong>{{ $t("uh.occ") }}:</strong>
                    <span
                      v-if="item.occdetails"
                      class="material-icons icon font-md text-green ponteiro mx-2"
                      @click="exibirDetalhes(item.occdetails)"
                    >
                      people
                    </span>
                    <span
                      v-if="!item.occdetails"
                      class="material-icons icon font-md text-red mx-2"
                    >
                      no_accounts
                    </span>
                    <br />
                    <strong>{{ $t("uh.outoforder") }}:</strong>
                    <span
                      :class="{
                        'badge-success': !item.outoforder,
                        'badge-danger': item.outoforder,
                      }"
                      class="badge light mx-2"
                    >
                      {{
                        item.outoforder ? $t("global.sim") : $t("global.nao")
                      }} </span
                    ><br />
                    <strong>{{ $t("global.status") }}:</strong>
                    <span
                      :class="{
                        'badge-success': item.act,
                        'badge-danger': !item.act,
                      }"
                      class="badge light mx-2"
                    >
                      {{ item.act ? $t("global.ativo") : $t("global.inativo") }}
                    </span>
                  </p>
                  <div class="dropdown">
                    <strong>{{ $t("sols.acoes") }}:</strong>
                    <button
                      type="button"
                      class="btn btn-warning light sharp mx-2"
                      data-toggle="dropdown"
                    >
                      <svg
                        width="20px"
                        height="20px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item ponteiro"
                        @click="inspecionar(item)"
                        >{{ $t("uh.inspecionar") }}</a
                      >
                      <a
                        v-if="item.hkstatus != 'Limpo'"
                        class="dropdown-item ponteiro"
                        @click="marcarLimpo(item)"
                        >{{ $t("uh.marcarlimpo") }}</a
                      >
                      <a
                        v-if="item.hkstatus != 'Sujo'"
                        class="dropdown-item ponteiro"
                        @click="marcarSujo(item)"
                        >{{ $t("uh.marcarsujo") }}</a
                      >
                      <a
                        v-if="item.occdetails"
                        class="dropdown-item ponteiro"
                        @click="naoPerturbe(item)"
                        >{{ $t("uh.naoperturbe") }}</a
                      >
                      <a
                        v-if="item.occdetails"
                        class="dropdown-item ponteiro"
                        @click="dormiuFora(item)"
                        >{{ $t("uh.dormiufora") }}</a
                      >
                      <a
                        v-if="item.occdetails"
                        class="dropdown-item ponteiro"
                        @click="dispensouServico(item)"
                        >{{ $t("uh.dispensouservico") }}</a
                      >
                      <a
                        class="dropdown-item ponteiro"
                        @click="nenhumaOcorrencia(item)"
                        >{{ $t("uh.nenhumaocorrencia") }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detalhes -->
    <ModalESObjeto
      :valor="exibirModalObjeto"
      :objeto="detalhesItem"
      @fechar="exibirModalObjeto = false"
    ></ModalESObjeto>
  </div>
</template>

<script>
import axios from "axios";
import CONSTANTS from "../constants";
import ModalESObjeto from "./ModalESObjeto.vue";

export default {
  components: { ModalESObjeto },
  data() {
    return {
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      uhs: [],
      filtro: {
        roomid: "",
        room: "",
        roomtype: "",
        floor: "",
        hkstatus: null,
        outoforder: null,
        occ: null,
        act: null,
      },
      exibirFiltro: false,
      exibirModalObjeto: false,
      detalhesItem: null,
    };
  },
  methods: {
    classeHkStatus(item) {
      let resultado = ''
      switch (item.hkstatus) {
        case 'Limpo':
          resultado = 'hkstatus hkstatus-limpo'
          break;
        case 'Sujo':
        case 'Em limpeza':
          resultado = 'hkstatus hkstatus-sujo'
          break;
        case 'Inspecionar':
          resultado = 'hkstatus hkstatus-inspecionar'
          break;

        default:
          break;
      }
      return resultado;
    },
    exibirDetalhes(detalhes) {
      //console.error(JSON.stringify(detalhes))
      this.detalhesItem = JSON.parse(detalhes);
      this.exibirModalObjeto = true;
    },
    exibirOcultarFiltro() {
      var el = document.getElementById("filter_body");
      if (el) {
        if (el.style.display == "none") {
          el.style.display = "block";
          this.exibirFiltro = true;
        } else {
          el.style.display = "none";
          this.exibirFiltro = false;
        }
      }
    },
    limparFiltro() {
      this.uhs = [];
      this.filtro = {
        roomid: "",
        room: "",
        roomtype: "",
        floor: "",
        hkstatus: null,
        outoforder: null,
        occ: null,
        act: null,
      };
      window.setTimeout(this.carregarUHs(), 1000);
    },
    inspecionar(item) {
      var params = {
        roomid: item.roomid,
        rooms: [item.room],
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/inspecionar", params, {
          headers: this.headers,
        })
        .then(() => {
          this.$snack(
            `Status de Governança da UH modificado para 'Inspecionar'.`
          );
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar mudar status da governança: ${JSON.stringify(e)}`
          );
        });
    },
    marcarLimpo(item) {
      var params = {
        roomid: item.roomid,
        rooms: [item.room],
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/marcarlimpo", params, {
          headers: this.headers,
        })
        .then(() => {
          this.$snack(`Status de Governança da UH modificado para 'Limpo'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar mudar status da governança: ${JSON.stringify(e)}`
          );
        });
    },
    marcarSujo(item) {
      var params = {
        roomid: item.roomid,
        rooms: [item.room],
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/marcarsujo", params, {
          headers: this.headers,
        })
        .then(() => {
          this.$snack(`Status de Governança da UH modificado para 'Sujo'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar mudar status da governança: ${JSON.stringify(e)}`
          );
        });
    },
    naoPerturbe(item) {
      var params = {
        roomid: item.roomid,
        room: item.room,
      };
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/uh/ocorrencia/naoperturbe",
          params,
          { headers: this.headers }
        )
        .then(() => {
          this.$snack(`Marcado como 'Não perturbe'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar marcar ocorrência para 'Não perturbe': ${JSON.stringify(
              e
            )}`
          );
        });
    },
    dormiuFora(item) {
      var params = {
        roomid: item.roomid,
        room: item.room,
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/ocorrencia/dormiufora", params, {
          headers: this.headers,
        })
        .then(() => {
          this.$snack(`Marcado como 'Dormiu fora'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar marcar ocorrência para 'Dormiu fora': ${JSON.stringify(
              e
            )}`
          );
        });
    },
    dispensouServico(item) {
      var params = {
        roomid: item.roomid,
        room: item.room,
      };
      axios
        .post(
          CONSTANTS.API_URL + "/fdguest/uh/ocorrencia/servicodispensado",
          params,
          { headers: this.headers }
        )
        .then(() => {
          this.$snack(`Marcado como 'Serviço dispensado'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar marcar ocorrência para 'Serviço dispensado': ${JSON.stringify(
              e
            )}`
          );
        });
    },
    nenhumaOcorrencia(item) {
      var params = {
        roomid: item.roomid,
        room: item.room,
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/ocorrencia/nenhuma", params, {
          headers: this.headers,
        })
        .then(() => {
          this.$snack(`Marcado como 'Sem ocorrências'.`);
          this.carregarUHs();
        })
        .catch((e) => {
          this.$snackE(
            `Erro ao tentar marcar ocorrência para 'Sem ocorrências': ${JSON.stringify(
              e
            )}`
          );
        });
    },
    carregarUHs() {
      var params = {
        roomid: this.filtro.roomid,
        room: this.filtro.room,
        roomtype: this.filtro.roomtype,
        floor: this.filtro.floor,
        hkstatus: this.filtro.hkstatus,
        occ: this.filtro.occ,
        outoforder: this.filtro.outoforder,
        act: this.filtro.act,
      };
      //console.log(params)
      axios
        .post(CONSTANTS.API_URL + "/fdguest/uh/select", params, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.uhs = response.data;
            this.recordCount = response.data.recordCount;
            this.pageCount = response.data.pageCount
              ? response.data.pageCount
              : 1;
          }
        });
    },
  },
  computed: {},
  mounted() {
    this.carregarUHs();
  },
};
</script>
<style lang="scss" scoped>
.hkstatus {
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 1.25rem;
}
.hkstatus-limpo {
  color: rgb(255, 255, 255);
  background-color: rgb(118, 168, 248);
}
.hkstatus-sujo {
  color: white;
  background-color: rgb(123, 21, 59);
}

.hkstatus-inspecionar {
  color: white;
  background-color: rgb(127, 32, 152);
}

</style>