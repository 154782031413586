<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card azure">
                <div class="card-header">
                    <div class="basic-form" style="width:100%">
                        <div class="form-row">
                            <div class="form-group col-sm-4">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="hotelSobreEditado.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="hotelSobreEditado.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-8">
                                <label>Título:</label>
                                <input v-model="hotelSobreEditado.titulo" 
                                    type="text" class="form-control" placeholder="Título do ítem sobre o hotel">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-8">
                                <label>Subtítulo:</label>
                                <input v-model="hotelSobreEditado.subtitulo" 
                                    type="text" class="form-control" placeholder="Subtítulo do ítem sobre o hotel">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-12">
                                <label>Texto:</label>
                                <input v-model="hotelSobreEditado.texto" 
                                    type="text" class="form-control" placeholder="Texto do ítem sobre o hotel">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',hotelSobreEditado)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        hotelSobre: {
            type: Object,
            required: true,
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            hotelSobreEditado: {}
        }
    },
    methods: {
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.hotelSobreEditado = {... this.hotelSobre}
    }
}
</script>

<style scoped>
.azure {
    background-color: azure!important;
}
</style>