<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="card es-azure">
                <div class="card-header">
                    <div class="basic-form">
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <label>Idioma:</label>
                                <select class="form-control" v-model="categoriaPerguntaEditada.idioma">
                                    <template v-if="idiomas && idiomas.length > 0">
                                        <option v-for="idioma in idiomas" v-bind:key="idioma.ididioma"
                                            :value="idioma.codidioma"
                                            :selected="categoriaPerguntaEditada.idioma==idioma.codidioma">{{idioma.descidioma}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Ícone:</label>
                                <select class="form-control" v-model="categoriaPerguntaEditada.icone">
                                    <template v-if="icones && icones.length > 0">
                                        <option v-for="(icone,index) in icones" v-bind:key="index"
                                            :value="icone.chave"
                                            :selected="categoriaPerguntaEditada.icone==icone.chave">{{icone.chave}}
                                        </option>
                                    </template>
                                </select>
                            </div>
                            <div class="form-group col-sm-auto">
                                <label>Categoria de pergunta:</label>
                                <input v-model="categoriaPerguntaEditada.descricao" 
                                    type="text" class="form-control" placeholder="Descreva a categoria">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('save',categoriaPerguntaEditada)" class="ponteiro text-white btn btn-info mt-4">Salvar</a>
                            </div>
                            <div class="form-group col-sm-auto">
                                <a v-on:click="$emit('close')" class="ponteiro text-white btn btn-info mt-4">Fechar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
export default {
    props: {
        categoriaPergunta: {
            type: Object,
            required: true,
        }
    },    
    data() {
        return {
            idiomas: [],
            headers: {
                'x-access-token': this.$store.getters.GetLoginInfo.token
            },
            categoriaPerguntaEditada: {},
            icones: [
                {chave: 'face'},
                {chave: 'map'},
                {chave: 'handyman'},
                {chave: 'pool'},
                {chave: 'hotel'},
                {chave: 'room_service'},
                {chave: 'security'},
                {chave: 'local_atm'},
                {chave: 'wifi'},
                {chave: 'restaurant'},
                {chave: 'shopping_cart'}
            ]
        }
    },
    methods: {
        async loadIdiomas() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/idiomas/suportados', {
				tipo: "U",
				idusuario: this.$store.getters.GetLoginInfo.idusuario
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.idiomas = response.data;
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadIdiomas();
    },
    mounted() {
        this.categoriaPerguntaEditada = {... this.categoriaPergunta}
    }
}
</script>

<style>
.es-azure {
    background-color: azure!important;
}
</style>