<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="text-info">{{ $t("estoquerelatorios.titulo") }}</h4>
          <p>{{ $t("estoquerelatorios.descricao") }}</p>
        </div>
        <div class="card-body">
          <!-- Filtro -->
          <div class="basic-form">
            <form>
              <div class="form-row" style="justify-content: center">
                <!-- Relatório -->
                <div class="form-group col-auto">
                  <label>{{ $t("estoquerelatorios.relatorio") }}:</label>
                  <select
                    class="form-control"
                    v-model="idrelatorio"
                    @change="relatorioChanged()"
                  >
                    <template v-if="relatorios && relatorios.length > 0">
                      <option
                        v-for="relatorio in relatorios"
                        v-bind:key="relatorio.idrelatorio"
                        :value="relatorio.idrelatorio"
                        :selected="idrelatorio == relatorio.idrelatorio"
                      >
                        {{ relatorio.titulo }}
                      </option>
                    </template>
                  </select>
                </div>
                <!-- Data Inicial -->
                <div class="form-group col-auto">
                  <label>{{ $t("global.periodode") }}:</label>
                  <input
                    v-model="periodo.dataInicial"
                    type="date"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <!-- Data Final -->
                <div class="form-group col-auto">
                  <label>{{ $t("global.periodoate") }}:</label>
                  <input
                    v-model="periodo.dataFinal"
                    type="date"
                    class="form-control"
                    placeholder=""
                  />
                </div>
                <!--Responsável-->
                <div v-if="idrelatorio == 7" class="form-group col-auto">
                  <label>{{ $t("sols.responsavel") }}:</label>
                  <select
                    class="form-control"
                    v-model="idresponsavel"
                    @change="relatorioChanged()"
                  >
                    <template v-if="responsaveis && responsaveis.length > 0">
                      <option
                        v-for="responsavel in responsaveis"
                        v-bind:key="responsavel.idusuario"
                        :value="responsavel.idusuario"
                        :selected="idresponsavel == responsavel.idusuario"
                      >
                        {{ responsavel.nome }}
                      </option>
                    </template>
                    <option :value="0" :selected="true">Todos</option>
                  </select>
                </div>
                <!-- Chamado -->
                <div v-if="idrelatorio == 8" class="form-group col-auto">
                  <label>{{ $t("estoquerelatorios.chamado") }}:</label>
                  <input
                    v-model="chamado"
                    type="number"
                    class="form-control"
                    placeholder="N° do chamado"
                  />
                </div>
                <!-- Opções -->
                <div
                  v-if="
                    relatorio && relatorio.opcoes && relatorio.opcoes.length > 0
                  "
                  class="form-group col-auto"
                >
                  <label>{{ $t("estoquerelatorios.opcao") }}:</label>
                  <select
                    class="form-control"
                    v-model="opcao"
                    @change="relatorioChanged()"
                  >
                    <option
                      v-for="opn in relatorio.opcoes"
                      v-bind:key="opn.id"
                      :value="opn.id"
                      :selected="opcao == opn.id"
                    >
                      {{ opn.desc }}
                    </option>
                  </select>
                </div>
                <!-- Filtrar -->
                <div class="form-group col-auto">
                  <button
                    v-on:click="reloadResults(indiceGeralOrdem)"
                    type="button"
                    class="btn btn-primary mt-4"
                    id="toastr-danger-top-right"
                  >
                    {{ $t("global.filtrar") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- Relatório -->
          <RelatoriosManutencaoN
            :relatorio="relatorio"
            v-on:reload="reloadResults($event)"
            :dados="dados"
            :cabecalhos="relatorio.cabecalhos"
            @abrir="abrirModal($event)"
            @abrirDetalhes="abrirDetalhes($event)"
          />
        </div>
      </div>
    </div>
    <ModalES
      :title="`Lista de Chamados`"
      :text="modalText"
      :value="showModal"
      :details="detalhes"
      :headers="cabecalhosDetalhes"
      @input="showModal = $event"
    ></ModalES>
    <TimelineSolicitacoes
      v-if="showModalDetalhes"
			:events="this.events"
      @fecharDetalhes="() => (this.showModalDetalhes = !this.showModalDetalhes)"
    />
  </div>
</template>

<script>
import CONSTANTS from "../constants";
import axios from "axios";
import moment from "moment";
import RelatoriosManutencaoN from "./RelatoriosManutencaoN.vue";
import TimelineSolicitacoes from "./TimelineSolicitacoes.vue";
import ModalES from "./ModalES.vue";
import Rels from "./RelatoriosManutencaoDef";

export default {
  name: "RelatoriosEstoque",
  components: { RelatoriosManutencaoN, ModalES, TimelineSolicitacoes },
  data() {
    return {
      showModalDetalhes: false,
      headers: {
        "x-access-token": this.$store.getters.GetLoginInfo.token,
      },
      indiceGeralOrdem: "",
      idresponsavel: 0,
      responsaveis: [],
      relatorios: Rels,
      idrelatorio: 1,
      periodo: {
        dataInicial: moment(moment().year().toString() + "-01-01").format(
          "YYYY-MM-DD"
        ),
        dataFinal: moment().format("YYYY-MM-DD"),
      },
      dados: [],
      dadosAnteriores: [],
      opcao: 1,
      chamado: null,
      showModal: false,
      modalText: "",
      detalhes: [],
      cabecalhosDetalhes: [],
      events: [],
    };
  },
  watch: {
    idrelatorio() {
      this.$forceUpdate();
    },
  },
  computed: {
    relatorio() {
      let result = null;
      for (var i = 0; i < this.relatorios.length; i++) {
        if (this.relatorios[i]["idrelatorio"] == this.idrelatorio) {
          result = Object.assign({}, this.relatorios[i]);
        }
      }
      return result;
    },
  },
  methods: {
    async abrirDetalhes(event) {
      console.log(event);
      const params = {
        tecnico_id: event.tecnico_id,
      };
      await axios
        .post(
          CONSTANTS.API_URL + "/fdguest/hotlinesolicitacoesEventos/select", params, { headers: this.headers }
        )
        .then((response) => {
          if (response.status == 200) {
						this.events = response.data;
          }
        });
      this.showModalDetalhes = !this.showModalDetalhes;
    },
    loadResponsaveis() {
      var searchParams = {
        idioma: this.$i18n.locale,
        idhotel: this.$store.getters.GetLoginInfo.idhotel,
        page: 1,
        pagesize: 1000,
      };
      axios
        .post(CONSTANTS.API_URL + "/fdguest/usuarios/tecnicos", searchParams, {
          headers: this.headers,
        })
        .then((response) => {
          try {
            if (response.status == 200) {
              if (response.data.dados) {
                this.responsaveis = response.data.dados;
              }
            } else {
              console.log("Erro:" + JSON.stringify(response));
            }
          } catch (e) {
            console.log(e);
          }
        })
        .catch((err) => {
          console.log({ err_menu: err });
        });
    },
    relatorioChanged() {
      this.buscarDadosRelatorio();
      this.$forceUpdate();
    },
    abrirModal(item) {
      var params = {
        idioma: this.$i18n.locale,
        idrelatorio: this.idrelatorio,
        idestoque: this.$store.getters.GetLoginInfo.idestoque,
        periodo: {
          de: this.periodo.dataInicial,
          ate: this.periodo.dataFinal,
        },
        opcao: this.opcao,
        idhotlinesolicitacoes: this.chamado,
        idresponsavel: item.idresponsavel,
        detalhe: true,
        idtiposolicitacao: item.idtiposolicitacao,
        idusuario: item.idusuario,
        coduh: item.coduh,
        idstatus: item.idstatus,
        idlocalatendimento: item.idlocalatendimento,
        idproduto: item.idproduto,
        idfornecedor: item.idfornecedor,
      };
      if (this.indiceGeralOrdem) {
        params.orderby = this.indiceGeralOrdem;
      }
      //console.error(JSON.stringify(params))
      axios
        .post(CONSTANTS.API_URL + "/fdguest/relatorio", params, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.detalhes = response.data;
            this.cabecalhosDetalhes = this.relatorio.cabecalhosDetalhes;
            this.modalText = `Solicitações da Categoria`;
            this.showModal = true;
          }
        });
    },
    reloadResults(ordem) {
      this.indiceGeralOrdem = ordem;
      this.buscarDadosRelatorio();
      this.$forceUpdate();
    },
    buscarDadosRelatorio() {
      var params = {
        idioma: this.$i18n.locale,
        idrelatorio: this.idrelatorio,
        idestoque: this.$store.getters.GetLoginInfo.idestoque,
        periodo: {
          de: this.periodo.dataInicial,
          ate: this.periodo.dataFinal,
        },
        opcao: this.opcao,
        idhotlinesolicitacoes: this.chamado,
        idresponsavel: this.idresponsavel,
      };
      if (this.indiceGeralOrdem) {
        params.orderby = this.indiceGeralOrdem;
      }
      //console.error(JSON.stringify(params))
      axios
        .post(CONSTANTS.API_URL + "/fdguest/relatorio", params, {
          headers: this.headers,
        })
        .then((response) => {
          if (response.status == 200) {
            this.relatorio.dados = response.data;
            console.log(response.data);
            this.dados = response.data;
          }
        });
    },
  },
  mounted() {
    this.reloadResults("");
    this.loadResponsaveis();
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.btn-rel {
  display: inline-block;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  margin: 0 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn-rel {
    transition: none;
  }
}
.btn-rel:hover {
  color: #e9e7e7;
  text-decoration: none;
}
.btn-rel:focus,
.btn-rel.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 47, 185, 0.25);
}
.btn-rel.disabled,
.btn-rel:disabled {
  opacity: 0.65;
}
</style>
