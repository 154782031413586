<template>
	<div class="row">
		<!-- Filtro do Período -->
		<div class="col-xl-12">
			<div v-if="(env === 'development')">
				<h6>{{largura}}x{{altura}}</h6>
			</div>
			<div class="card">
				<div class="card-header border-0 pb-0 d-sm-flex d-block">
					<h3>{{$t('reviewsdashboard.titulo')}}</h3>
					<h5>{{$t('reviewsdashboard.descricao')}}</h5>
				</div>
				<div class="card-body">
					<div class="basic-form">
						<form>
							<div class="form-row">
								<div class="form-group col-sm-auto">
									<label>{{$t('reviewsdashboard.periodode')}}:</label>
									<input v-on:change="reloadAll()" v-model="periodo.dataInicial" type="date" class="form-control" placeholder="" >
								</div>
								<div class="form-group col-sm-auto">
									<label>{{$t('reviewsdashboard.ate')}}:</label>
									<input v-on:change="reloadAll()" v-model="periodo.dataFinal" type="date" class="form-control" placeholder="">
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- Histórico da Média Geral -->
		<div class="col-xl-12 mt-4">
			<div id="user-activity" class="card">
				<div class="card-header border-0 pb-0 d-sm-flex d-block">
					<h3>{{$t('reviewsdashboard.hitoricogeral')}}</h3>
				</div>
				<div class="card-body">
					<div class="tab-content" id="myTabContent">
						<line-chart :chart-data="dadosGeral" :options="lineoptions"></line-chart>
					</div>
				</div>
			</div>
		</div>
		<!-- Divisão geral das Notas -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-header border-0 pb-3 d-sm-flex d-block ">
					<h3>{{$t('reviewsdashboard.divisaogeralnotas')}}</h3>
				</div>
				<div class="card-body">
					<div class="row mx-0 align-items-center">
						<div class="col-sm-8 col-md-7 col-xxl-7 px-0 text-center mb-3 mb-sm-0">
							<div>
								<doughnut-chart :chart-data="dadosNotasGeral" :options="doughnutoptions"></doughnut-chart>
							</div>
						</div>
						<div class="col-sm-4 col-md-5 col-xxl-5 px-2">
							<div class="chart-deta">
								<div v-for="(item, index) in doughnutitens" :key="index" class="col px-0">
									<span :class="coresLegenda[item.nota-1]"></span>
									<!-- <span class="bg-warning"></span> -->
									<div>
										<p class="fs-14" 
											:style="'padding: 5px; border-radius: 0.5rem; color: white; background-color: '+ coresGrafico[item.nota-1] + ';'"
										>{{item.label}}</p>
										<h3>{{item.total}}</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Histórico da Média por Avaliação -->
		<div class="col-xl-12">
			<div id="user-activity" class="card">
				<div class="card-header border-0 pb-0 d-sm-flex d-block">
					<div>
						<h3>{{$t('reviewsdashboard.historicomediaavaliacao')}}</h3>
					</div>
					<div class="d-flex mt-3 mt-sm-0">
						<div class="dropdown ">
							<button id="selectAval" type="button" class="btn btn-primary dropdown-toggle light btn-sm btn-rounded" data-toggle="dropdown" aria-expanded="false">
								{{avaliacaoselecionada.desctipoavaliacao}}
							</button>
							<div class="dropdown-menu" >
								<a v-for="aval in avaliacoes" :key="aval.idtipoavaliacao"
									class="dropdown-item" @click="loadDadosAvaliacao(aval);"
								>{{aval.desctipoavaliacao}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="tab-content" id="myTabContent">
						<line-chart :chart-data="dadosAvaliacao" :options="lineoptions"></line-chart>
					</div>
				</div>
			</div>
		</div>
		<!-- Histórico da Média por Categoria -->
		<div class="col-xl-12">
			<div id="user-activity" class="card">
				<div class="card-header border-0 pb-0 d-sm-flex d-block">
					<div>
						<h3>Histórico da Média por Categoria</h3>
					</div>
					<div class="d-flex mt-3 mt-sm-0">
						<div class="dropdown ">
							<button id="selectCat" type="button" class="btn btn-primary dropdown-toggle light btn-sm btn-rounded" data-toggle="dropdown" aria-expanded="false">
								{{categoriaselecionada.descricao}}
							</button>
							<div class="dropdown-menu" >
								<a v-for="item in categorias" :key="item.idcategoriapergunta"
									class="dropdown-item" @click="loadDadosCategoria(item);"
								>{{item.descricao}}</a>
							</div>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="tab-content" id="myTabContent">
						<line-chart :chart-data="dadosCategoria" :options="lineoptions"></line-chart>
					</div>
				</div>
			</div>
		</div>
		<!-- Médias por Categoria -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-header border-0 pb-0 d-sm-flex d-block">
					<div>
						<h3>Média por Categoria</h3>
					</div>
				</div>
				<div class="card-body">
					<div class="row mx-0">
						<div class="col-sm-12 col-lg-8 px-0">
							<bar-chart :chart-data="bardata" :options="baroptions"></bar-chart>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import axios from 'axios'
import CONSTANTS from '../constants'
import LineChart from './ChartLine.js'
import DoughnutChart from './ChartDoughnut.js'
import BarChart from './ChartBar.js'
import moment from "moment";

export default {
  components: { LineChart, DoughnutChart, BarChart },
    data () {
      return {
		// Opções para os gráficos de linha
		lineoptions:{
			legend: {
				display: false
			},
			maintainAspectRatio: false
		},
		// Histórico da média geral
        dadosGeral: {},
		// Histórico da média por categoria
		categorias: [],
		dadosCategoria: {},
		// Histórico da média por avaliacao
		avaliacoes: [],
		dadosAvaliacao: {},
		// Gráfico doughnut - notas Geral
		dadosNotasGeral: {},
		doughnutoptions:{
			legend: {
				display: false
			}
		},
		notas: [],
		coresGrafico: ['#ff2625' , '#ff7a01', '#ffe600', '#8ddb95', '#21b731'],
		coresLegenda: ['nota-1', 'nota-2', 'nota-3', 'nota-4', 'nota-5'],
		// Gráfico de barras
		bardata: {},
		baroptions:{
			legend: {
				display: true
			}, 
			title: {
				display: false
			},
			responsive: true,
			maintainAspectRatio: false,
		},
		headers: {
			'x-access-token': this.$store.getters.GetLoginInfo.token
		},
		largura: 0,
		altura: 0,
		env: '',
		periodo: {
			dataInicial: '',
			dataFinal: ''
		},
		avaliacaoselecionada: {
			idtipoavaliacao: 0,
			desctipoavaliacao: ''
		},
		categoriaselecionada: {
			idcategoriapergunta: 0,
			descricao: ''
		}
      }
    },
    computed: {
		doughnutitens() {
			var result = []
			if (this.dadosNotasGeral.datasets) {
				var ds = this.dadosNotasGeral.datasets[0]
				for (var i = 0; i < ds.data.length; i++) {
					result.push({
						label: this.dadosNotasGeral.labels[i],
						total: ds.data[i],
						nota: this.notas[i]
					})
				}
			}
			return result
		}
    },
    methods:{
		reloadAll() {
			this.loadGeral();
			this.loadNotasGeral();
			this.loadDadosAvaliacao(this.avaliacaoselecionada);
			this.loadDadosCategoria(this.categoriaselecionada);
			this.loadMediaCategorias();
		},
		loadGeral () {
			var diff = moment(this.periodo.dataFinal).diff(this.periodo.dataInicial, 'days')
			if (diff < 365) {
				axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/reviews/historicogeral', {
						"idhotel": this.$store.getters.GetLoginInfo.idhotel,
						"periodo" : {
							de: this.periodo.dataInicial,
							ate: this.periodo.dataFinal
						},
						"idioma": this.$i18n.locale
					}, {headers: this.headers})
				.then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.dadosGeral = {
								labels: response.data.rotulos,
								datasets: [
									{
										label: this.$t('reviewsdashboard.periodo'),
										backgroundColor: '#dcf3fa',
										borderColor: '#2c4bcc',
										borderWidth: '6',
										data: response.data.dados_periodo
									},
									{
										label: this.$t('reviewsdashboard.periodoanterior'),
										backgroundColor: '#eceffc',
										borderColor: '#c2cfcf',
										borderWidth: '6',
										data: response.data.dados_anterior
									}								
								]
							}
						}
					} else {
						console.log(response.message)
					}
				})
				.catch((err) => {
					console.log({ err_menu: err });
				});
			} else {
				alert('diferença entre as datas deve ser menor que um ano.')
			}
		},
		loadNotasGeral () {
			axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/reviews/notasgeral', {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"periodo" : {
						de: this.periodo.dataInicial,
						ate: this.periodo.dataFinal
					},
					"idioma": this.$i18n.locale
				}, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.notas = response.data.notas
						this.dadosNotasGeral = {
							labels: response.data.rotulos,
							datasets: [
								{
									backgroundColor: this.notas.map(val => {
										return this.coresGrafico[val-1]
									}),
									borderWidth: '6',
									data: response.data.dados
								}
							]
						}
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
        async loadAvaliacoes() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/tipoavaliacao/select', {
				idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.avaliacoes = response.data;
							if (this.avaliacoes.length > 0) {
								this.loadDadosAvaliacao(this.avaliacoes[0]);
							}
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
		loadDadosAvaliacao (aval) {
			var diff = moment(this.periodo.dataFinal).diff(this.periodo.dataInicial, 'days')
			if (diff < 365) {
				axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/reviews/historicoavaliacao', {
						"idhotel": this.$store.getters.GetLoginInfo.idhotel,
						"periodo" : {
							de: this.periodo.dataInicial,
							ate: this.periodo.dataFinal
						},
						idtipoavaliacao: aval.idtipoavaliacao,
						"idioma": this.$i18n.locale
					}, {headers: this.headers})
				.then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.dadosAvaliacao = {
								labels: response.data.rotulos,
								datasets: [
									{
										label: this.$t('reviewsdashboard.periodo'),
										backgroundColor: '#fdeae3',
										borderColor: '#FF7F50',
										borderWidth: '6',
										data: response.data.dados_periodo
									},
									{
										label: this.$t('reviewsdashboard.periodoanterior'),
										backgroundColor: '#eceffc',
										borderColor: '#c2cfcf',
										borderWidth: '6',
										data: response.data.dados_anterior
									}								
								]
							}
							this.avaliacaoselecionada = aval
						}
					} else {
						console.log(response.message)
					}
				})
				.catch((err) => {
					console.log({ err_menu: err });
				});
			} else {
				alert('diferença entre as datas deve ser menor que um ano.')
			}
		},
        async loadCategorias() {
			await axios.post(CONSTANTS.API_URL + '/fdguest/categoriapergunta/select', {
				idhotel: this.$store.getters.GetLoginInfo.idhotel,
                idioma: this.$i18n.locale
			}, {headers: this.headers})
			.then(response => {
				try {
					if (response.status == 200) {
						if (response.data) {
							this.categorias = response.data;
							if (this.categorias.length > 0) {
								this.loadDadosCategoria(this.categorias[0]);
							}
						}
					}
				}
				catch(e) {
					console.log(e);
				}
			}).catch((err) => {
				console.log({ err_menu: err });
			});
        },
		loadDadosCategoria (cat) {
			var diff = moment(this.periodo.dataFinal).diff(this.periodo.dataInicial, 'days')
			if (diff < 365) {
				axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/reviews/historicocategoria', {
						"idhotel": this.$store.getters.GetLoginInfo.idhotel,
						"periodo" : {
							de: this.periodo.dataInicial,
							ate: this.periodo.dataFinal
						},
						idcategoriapergunta: cat.idcategoriapergunta,
						"idioma": this.$i18n.locale
					}, {headers: this.headers})
				.then(response => {
					if (response.status == 200) {
						if (response.data) {
							this.dadosCategoria = {
								labels: response.data.rotulos,
								datasets: [
									{
										label: this.$t('reviewsdashboard.periodo'),
										backgroundColor: '#d9f7d9',
										borderColor: '#32CD32',
										borderWidth: '6',
										data: response.data.dados_periodo
									},
									{
										label: this.$t('reviewsdashboard.periodoanterior'),
										backgroundColor: '#eceffc',
										borderColor: '#c2cfcf',
										borderWidth: '6',
										data: response.data.dados_anterior
									}								
								]
							}
							this.categoriaselecionada = cat
						}
					} else {
						console.log(response.message)
					}
				})
				.catch((err) => {
					console.log({ err_menu: err });
				});
			} else {
				alert('diferença entre as datas deve ser menor que um ano.')
			}
		},
		loadMediaCategorias () {
			axios.post(CONSTANTS.API_URL + '/fdguest/respostasformavaliacao/reviews/mediacategoria', {
					"idhotel": this.$store.getters.GetLoginInfo.idhotel,
					"periodo" : {
						de: this.periodo.dataInicial,
						ate: this.periodo.dataFinal
					},
					"idioma": this.$i18n.locale
				}, {headers: this.headers})
			.then(response => {
				if (response.status == 200) {
					if (response.data) {
						this.bardata = {
							labels: response.data.rotulos,
							datasets: [
								{
									label: this.$t('reviewsdashboard.periodoanterior'),
									backgroundColor: '#f0f0f0',
									borderColor: '#8a8a8a',
									borderWidth: '3',
									barThickness: '25',
									data: response.data.dados_anterior
								},
								{
									label: this.$t('reviewsdashboard.periodo'),
									backgroundColor: '#d9f7d9',
									borderColor: '#32CD32',
									borderWidth: '3',
									barThickness: '25',
									data: response.data.dados_periodo
								}
							]
						}
					}
				} else {
					console.log(response.message)
				}
			})
			.catch((err) => {
				console.log({ err_menu: err });
			});
		},
		onResize() {
			this.largura = window.innerWidth
			this.altura = window.innerHeight
		},		
	},
	beforeMount() {
		//window.addEventListener("resize", this.onResize);
	},	
	mounted() {
		this.periodo.dataInicial = (moment(moment().year().toString()+'-01-01').format('YYYY-MM-DD'))
		this.periodo.dataFinal = (moment().format('YYYY-MM-DD'))
		this.loadGeral();
		this.loadCategorias();
		this.loadAvaliacoes();
		this.loadNotasGeral();
		this.loadMediaCategorias();
		this.onResize();
		window.addEventListener("resize", this.onResize);
	},
	created() {
		this.env = process.env.NODE_ENV;
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	}
}
</script>

<style>
.x {
	color: #8a8a8a
}
</style>